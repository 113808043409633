<template>
    <searchContainer :searchHandler="search" :resetHandler="reset">
        <template v-slot:items>
            <div class="row">
                <input-text v-model="filters.name" :options="{ id: 'name', label: 'name' }"/>
                <select-ticket-root-language-codes v-model="filters.language_code" :options="{ id: 'language_code', label: 'language_code', placeholder: 'placeholders.all' }"/>
                <select-ticket-root-types v-model="filters.type" :options="{ id: 'type', label: 'type', placeholder: 'placeholders.all' }"/>
                <select-yes-no v-model="filters.enabled" :options="{ id: 'enabled', label: 'enabled', placeholder: 'placeholders.all' }"/>
            </div>
            <div class="row">
            </div>
        </template>
    </searchContainer>
</template>
<script>

    import {mapGetters} from "vuex";
    import {CRUD_SEARCH_RESET} from "actions/crud";
    import searchContainer from "@/components/crud/card/CardSearch";

    const CRUD_CODE = "tickets.roots";
    export default {
        name: "TicketRootSearch",
        components: {
            searchContainer
        },
        props: {
            listHandler: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                transPrefix: CRUD_CODE,
                filters: {
                    name: this.getFilterByKey('name'),
                    type: this.getFilterByKey('type'),
                    language_code: this.getFilterByKey('language_code'),
                    enabled: this.getFilterByKey('enabled'),
                },
                filtersMap: {
                    name: 'name',
                    type: 'type',
                    language_code: 'languageCode',
                    enabled: 'enabled',
                }
            }
        },
        mounted() {
            this.setSearchParams(this.filters, this.filtersMap);
        },
        computed: {
            ...mapGetters([
                "getSearchFilters"
            ])
        },
        methods: {
            search() {
                this.resetPagination();
                this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                this.pushRoute(this.getSearchFilters);
                this.listHandler();
            },
            reset() {
                this.resetPagination();
                this.$store.dispatch(CRUD_SEARCH_RESET);
                this.filters = this.clearFilters(this.filters);
                this.pushRoute();
                this.listHandler();
            },
            resetPagination() {
                //Reset Pagination
                this.$emit('input', 0);
            },
            pushRoute(query = {}) {
                this.$router.push({name: this.$route.name, query}).catch(() => {});
            },
        }
    };
</script>
