<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="generateReport">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <select-countries v-model="countryId" :options="{ id: 'country_id', label: 'country', placeholder: 'placeholders.all', error: fieldErrors.countryId }"/>
              <select-cities v-model="cityIds" :country_id="countryId" :options="{ id: 'city_id', label: 'city', placeholder: 'placeholders.all', disabled: !countryId, multiple: true, error: fieldErrors.cityId }"/>
              <select-platforms v-model="platformId" :options="{ id: 'platform_id', label: 'platform', error: fieldErrors.ownerId }"/>
              <select-platform-brands v-model="brandIds" :platform_id="platformId" :options="{ id: 'brands', label: 'brands', error: fieldErrors.brandIds, multiple: true }"/>
              <input-datetime v-model="fromDate" :options="{ id: 'from_date', label: 'from_date', error: fieldErrors.fromDate }"/>
              <input-datetime v-model="toDate" :options="{ id: 'to_date', label: 'to_date', error: fieldErrors.toDate }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index` }"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {ASSIGNMENT_METHODS_REPORT} from 'actions/monitoring';
  export default {
    name: "AssignmentMethodsReportCreate",
    data(){
      return {
        routePrefix: 'monitoring',
        fromDate: '',
        toDate: '',
        countryId: '',
        cityIds: '',
        platformId: '',
        brandIds: '',
        serverError: '',
        fieldErrors: {},
        loading: false
      }
    },
    methods: {
      generateReport() {
        this.loading = true;

        this.$store.dispatch(ASSIGNMENT_METHODS_REPORT, {
          countryId: this.countryId,
          cityIds: this.cityIds,
          platformId: this.platformId,
          brandIds: this.brandIds,
          fromDate: this.prepareDateTime("fromDate"),
          toDate: this.prepareDateTime("toDate"),
        })
            .then(response => {
              swal({
                icon: 'success',
                text: response.data.message,
                buttons:false,
                timer: 5000
              });
              this.$router.push({ name: `${this.routePrefix}.index` })
            })
            .catch(error => {
              this.fieldErrors={};
              this.serverError='';

              if(error.response){
                let responseData=error.response.data;

                if(responseData.errors){
                  this.fieldErrors=responseData.errors;
                }else{
                  this.serverError = 'Invalid Request.'
                }
              }else{
                this.serverError = this.$i18n.t("messages.no_internet_connection");
              }

              if(this.fieldErrors.error){
                this.serverError = this.fieldErrors.error;
              }
            })
            .finally(() => this.loading = false);
      }
    }
  };
</script>
