<template>
  <div>
    <div :id="id" class="tracking-map"></div>
  </div>
</template>

<script>
import Stomp from "webstomp-client";
import {
  TRACKING_ADD_CAPTAINS,
  TRACKING_RESET_CAPTAINS,
  TRACKING_ADD_UPDATE_CAPTAIN
} from "actions/sockets/tracking";
import { TRACKING_CAPTAIN_SHOW, TRACKING_CAPTAINS_LIST } from "actions/suppliers_portal/tracking";
import { mapGetters } from "vuex";
import { SuppliersTrackingMap } from "@/util/map/suppliers/tracking";

export default {
  name: "SupplierFieldsMapsTracking",
  data() {
    return {
      id: "map",
      centerPosition: { lat: 24.7253981, lng: 46.3398977 },
      zoom: 6,
      trackingMap: null,
      stompClient: null,
      stompOptions: {
        debug: false,
        heartbeat: {
          outgoing: 0,
          incoming: 20000
        }
      }
    };
  },
  mounted() {
    this.$store.commit(TRACKING_RESET_CAPTAINS);
    this.initMap();
  },
  watch: {
    isStompLoaded: function (newVal) {
      if (newVal) {
        this.connect();
      }
    }
  },
  computed: {
    ...mapGetters([
      "isStompLoaded",
      "getStompConnectionUrl",
      "getStompReadyToWorkTopic",
      "getStompConnectionsTopic",
      "getStompLocationsTopic",
      "getAuthorizationHeader",
      "getCaptains"
    ])
  },
  beforeDestroy() {
    if (this.isConnected()) {
      this.stompClient.disconnect();
    }
  },
  methods: {
    initMap() {
      this.trackingMap = new SuppliersTrackingMap(this);
      this.trackingMap
          .init()
          .then(() => this.trackingMap.registerCaptainDetailsFunction(captainId => this.getCaptainById(captainId)))
          .then(() => this.isStompLoaded && this.connect())
          .then(() => this.initCaptains())
          .then(() => this.initCaptainMarkers())
          .catch(error => console.log(error));
    },
    getCaptainById(id) {
      return this.$store.dispatch(TRACKING_CAPTAIN_SHOW, { id: id })
          .catch(() => {
            swal({ icon: "error", buttons: false, timer: 5000, text: "Failed to get captain info" })
            return Promise.reject();
          });
    },
    initCaptains() {
      return this.$store.dispatch(TRACKING_CAPTAINS_LIST)
          .then(response => {
            const captains = {};
            response.data.forEach(captain => {
              captains[captain.id] = this.buildCaptainFromResponse(captain);
            });
            this.$store.commit(TRACKING_ADD_CAPTAINS, captains);
          });
    },
    initCaptainMarkers() {
      Object.values(this.getCaptains).forEach(captain => this.trackingMap.addUpdateCaptainMarker(captain));
    },
    buildCaptainFromResponse(captain) {
      if (captain?.id) {
        const { lastKnownLocation, ...captainInfo } = captain;
        return { ...lastKnownLocation, ...captainInfo };
      }
      return null;
    },
    isConnected() {
      return this.stompClient && this.stompClient.connected;
    },
    connect() {
      this.stompClient = Stomp.client(this.getStompConnectionUrl, this.stompOptions);
      this.stompClient.connect(
          {"X-Authorization": this.getAuthorizationHeader},
          () => {
            this.stompClient.subscribe(this.getStompReadyToWorkTopic, tick => this.processReadyToWork(tick));
            this.stompClient.subscribe(this.getStompConnectionsTopic, tick => this.processConnection(tick));
            this.stompClient.subscribe(this.getStompLocationsTopic, tick => this.processLocation(tick));
          },
          error => {
            console.log(error);
          }
      );
    },
    processReadyToWork(tick) {
      const response = JSON.parse(tick.body);
      const captain = { ...response.lastKnownLocation, id: response.captainId, readyToWork: response.readyToWork };

      this.$store.commit(TRACKING_ADD_UPDATE_CAPTAIN, { ...captain });
      this.trackingMap.addUpdateCaptainMarker(this.getCaptains[captain.id]);
    },
    processConnection(tick) {
      const response = JSON.parse(tick.body);
      const captain = { id: response.captainId, connected: response.connected };

      this.$store.commit(TRACKING_ADD_UPDATE_CAPTAIN, { ...captain });
      this.trackingMap.updateCaptainMarkerIcon(this.getCaptains[captain.id]);
    },
    processLocation(tick) {
      const response = JSON.parse(tick.body);
      const captain = { ...response, id: response.captainId, connected: true };

      this.$store.commit(TRACKING_ADD_UPDATE_CAPTAIN, { ...captain });
      this.trackingMap.addUpdateCaptainMarker(this.getCaptains[captain.id]);
    }
  }
}
</script>

<style scoped>

</style>
