import CaptainCard from "@/views/suppliers_portal/tracking/partials/captainCard";
import Vue from "vue";

export class SuppliersTrackingMap {
  constructor(trackingMapComponent) {
    this.trackingMapComponent = trackingMapComponent;
    this.captainMarkers = {};
    this.activeCaptain = {};
  }

  init() {
    return this.trackingMapComponent.$google.then(google => {
      this.google = google;
      this.map = new this.google.maps.Map(document.getElementById(this.trackingMapComponent.id), {
        center: this.trackingMapComponent.centerPosition,
        zoom: this.trackingMapComponent.zoom
      });
      this.google.maps.event.addListener(this.map, "click", () => this.closeCaptainDetailsInfoWindow());
    });
  }

  registerCaptainDetailsFunction(handler) {
    this.captainDetailsFunction = handler;
  }

  addCaptainMarker(captain) {
    if (captain.latitude && captain.longitude) {
      let marker = new this.google.maps.Marker({
        position: { lat: captain.latitude, lng: captain.longitude },
        map: this.map
      });
      marker.setIcon({
        url: this.getCaptainIconUrl(captain),
        scaledSize: new this.google.maps.Size(15, 15),
        anchor: new this.google.maps.Point(7, 7)
      });
      this.google.maps.event.addListener(marker, "click", this.getCaptainMarkerClickHandler(captain.id));
      this.captainMarkers[captain.id] = marker;
    }
  }

  getCaptainMarkerClickHandler(captainId) {
    return () => {
      const previousCaptainId = this.activeCaptain.infoWindow?.getMap() ? this.activeCaptain.captainId : null;
      this.closeCaptainDetailsInfoWindow();
      if (previousCaptainId !== captainId) {
        this.captainDetailsFunction(captainId).then(response => this.openCaptainDetailsInfoWindow(response.data));
      }
    };
  }

  openCaptainDetailsInfoWindow(captain) {
    const CaptainCardComponent = Vue.extend(CaptainCard);
    const captainCardInstance = new CaptainCardComponent({ parent: this.trackingMapComponent, propsData: { captain: captain } });
    captainCardInstance.$mount();
    const infoWindow = new this.google.maps.InfoWindow({ content: captainCardInstance.$el });
    infoWindow.open({ anchor: this.captainMarkers[captain.id], map: this.map });

    this.activeCaptain = { captainId: captain.id, captainCard: captainCardInstance, infoWindow: infoWindow };
  }

  closeCaptainDetailsInfoWindow() {
    this.activeCaptain.infoWindow?.close();
    this.activeCaptain.captainCard?.$destroy();
    this.activeCaptain = {};
  }

  addUpdateCaptainMarker(captain) {
    if (this.captainMarkers[captain.id]) {
      this.updateCaptainMarkerIcon(captain);
      this.updateCaptainMarkerPosition(captain);
    } else {
      this.addCaptainMarker(captain);
    }
  }

  updateCaptainMarkerIcon(captain) {
    if (this.captainMarkers[captain.id]) {
      const oldIcon = this.captainMarkers[captain.id].getIcon();
      const newIcon = {
        url: this.getCaptainIconUrl(captain),
        scaledSize: oldIcon.scaledSize,
        anchor: oldIcon.anchor
      };
      this.captainMarkers[captain.id].setIcon(newIcon);
    }
  }

  updateCaptainMarkerPosition(captain) {
    if (this.captainMarkers[captain.id] && captain.latitude && captain.longitude) {
      const latlng = new this.google.maps.LatLng(captain.latitude, captain.longitude);
      this.captainMarkers[captain.id].setPosition(latlng);
    }
  }

  getCaptainIconUrl(captain) {
    const icons = {
      DISCONNECTED: require("@/assets/theme/icons/red_point.png"),
      CONNECTED: require("@/assets/theme/icons/green_point.png"),
      BUSY: require("@/assets/theme/icons/yellow_point.png")
    };

    let icon = null;
    if (!captain.connected) {
      icon = icons["DISCONNECTED"];
    } else if (captain.workingStatus === "BUSY") {
      icon = icons["BUSY"];
    } else {
      icon = icons["CONNECTED"];
    }
    return icon;
  }
}
