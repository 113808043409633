<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <router-link v-if="!serverError" :to="{ name: `${routePrefix}.edit`,params:{id} }"  class="btn btn-primary btn-sm" v-can:edit="permissionPrefix">
            <i class="fa fa-edit"></i>
          </router-link>
          <restore-captain-action v-if="deleted" :captain-id="id" @success="getItem" v-can:restore="permissionPrefix"/>
          <reset-password-action :itemID="id" v-can:send_reset_password="permissionPrefix"/>
        </card-header>
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <files-image v-model="image" :options="{ id: 'image', label: 'image', disabled: true, containerClass: 'col-12 col-md-4' }"/>
              <files-image v-model="identity" :options="{ id: 'identity', transPrefix: `${transPrefix}.fields`, label: 'identity', disabled: true, containerClass: 'col-12 col-md-4' }"/>
              <files-image v-model="driving_license" :options="{ id: 'driving_license', transPrefix: `${transPrefix}.fields`, label: 'driving_license', disabled: true, containerClass: 'col-12 col-md-4' }"/>
              <files-image v-model="registration_license" :options="{ id: 'registration_license', transPrefix: `${transPrefix}.fields`, label: 'registration_license', disabled: true, containerClass: 'col-12 col-md-4' }"/>
            </div>
            <div class="row" v-if="!serverError">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', disabled: true }"/>
              <input-email v-model="email" :options="{ id: 'email', label: 'email', disabled: true }"/>
              <input-text v-model="city_name" :options="{ id: 'city', label: 'city', disabled: true }"/>
              <input-text v-model="mobile_number" :options="{ id: 'mobile_number', label: 'mobile_number', disabled: true }"/>
              <input-date v-model="date_of_birth" :options="{ id: 'date_of_birth', label: 'date_of_birth', disabled: true }"/>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="nationality" class="d-block">{{ $t(`fields.nationality`)}}</label>
                  <vue-country-code id="nationality" class="w-25" v-if="loadNationality()" @onSelect="onSelectNationality" :defaultCountry="nationality" :dropdownOptions="{ disabledDialCode: true }" :disabled="true"/>
                  <input-text :value="nationality_label" :options="{ disabled: true ,fullLayout: false , inputClass: 'form-control w-75 d-inline-block' }"/>
                </div>
              </div>
              <input-text v-model="id_number" :options="{ id: 'id_number', transPrefix: `${transPrefix}.fields`, label: 'id_number', disabled: true }"/>
              <select-captain-id-type v-model="id_type" :options="{ id: 'id_type', transPrefix: `${transPrefix}.fields`, label: 'id_type', disabled: true }"/>
              <select-captain-status v-model="status" :options="{ id: 'status', label: 'status', disabled: true }"/>
              <select-captain-working-status v-model="working_status" :options="{ id: 'working_status', transPrefix:`${transPrefix}.fields`, label: 'working_status', disabled: true }"/>
              <capabilities-field :itemID="id" :permissionPrefix="permissionPrefix" :capabilities="capabilities"/>
              <input-text v-model="supplier" :options="{ id: 'supplier', transPrefix: `${transPrefix}.fields`, label: 'supplier', disabled: true }"/>
              <input-text v-if="referralCode" v-model="referralCode" :options="{ id: 'referral_code', transPrefix: `${transPrefix}.fields`, label: 'referral_code', disabled: true }"/>
            </div>
            <div class="row" v-if="!serverError">
              <input-checkbox v-model="ready_to_work" :options="{ id: 'ready_to_work', transPrefix: `${transPrefix}.fields`, label: 'ready_to_work', disabled: true }"/>
              <input-checkbox v-model="connected" :options="{ id: 'connected', transPrefix: `${transPrefix}.fields`, label: 'connected', disabled: true }"/>
              <input-checkbox v-model="suspended" :options="{ id: 'suspended', transPrefix: `${transPrefix}.fields`, label: 'suspended', disabled: true }"/>
              <input-checkbox v-model="deleted" :options="{ id: 'deleted', transPrefix: `${transPrefix}.fields`, label: 'deleted', disabled: true }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <a class="btn btn-danger btn-sm" v-if="suspensionReasons.length>0"
                   data-target="#modal-suspension-reasons"
                   data-toggle="modal"
                   href="javascript:;"
                   v-b-tooltip.hover="{ animation: false}"
                   :title="$t('labels.cancellation_reasons')"
                >
                  <i class="fas fa-ban"></i>
                </a>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div
            aria-hidden="true"
            aria-labelledby="modal-suspension-reasons"
            class="modal fade"
            id="modal-suspension-reasons"
            role="dialog"
            tabindex="-1"
    >
      <div
              class="modal-dialog modal-dialog-centered modal-lg"
              role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">
              {{ $t("labels.suspension_reasons") }}
            </h6>
            <button
                    aria-label="Close"
                    class="close"
                    data-dismiss="modal"
                    type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <ul class="list">

                <li v-for="(suspensionReason,index) in suspensionReasons" :key="index">
                  {{suspensionReason.reason}}  <p style="color: #1496ba; font-size: 12px;">    -{{suspensionReason.suspendedBy}}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button
                    class="btn btn-primary ml-auto"
                    data-dismiss="modal"
                    type="button"
            >
              {{ $t("labels.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { CAPTAINS_SHOW as CRUD_ACTION_SHOW } from 'actions/captains';
  import resetPasswordAction from "./actions/reset_password";
  import restoreCaptainAction from "./actions/restore_captain"
  import capabilitiesField from "./fields/capabilities";
  const CRUD_CODE="captains";
  export default {
    name: "CaptainShow",
    components:{
      resetPasswordAction,
      restoreCaptainAction,
      capabilitiesField
    },
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        id_number: '',
        supplier: '',
        status: '',
        connected: false,
        id_type: '',
        ready_to_work: false,
        suspended: false,
        deleted: false,
        suspensionReasons: [],
        working_status:'',
        default_city_id:'',
        city_name:'',
        email: '',
        mobile_number: '',
        nationality: '',
        referralCode: '',
        nationality_label: '',
        is_nationality_load: false,
        date_of_birth: '',
        name:'',
        capabilities:[],
        serverError: '',
        image:{
          file:'',
          thumbnail:'',
        },
        driving_license:{
          file:'',
          thumbnail:'',
        },
        registration_license:{
          file:'',
          thumbnail:'',
        },
        identity:{
          file:'',
          thumbnail:'',
        },
        responseStatus:'',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus && this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.id_number=response.data.idNumber;
          this.supplier=response.data.supplier;
          this.status=response.data.status;
          this.working_status=response.data.workingStatus;
          this.default_city_id=response.data.defaultCityId;
          this.city_name=response.data.city.name;
          this.connected=response.data.connected;
          this.id_type=response.data.idType;
          this.ready_to_work=response.data.readyToWork;
          this.suspended=response.data.suspended;
          this.suspensionReasons=response.data.suspensionReasons;
          this.deleted=response.data.deleted;
          this.date_of_birth=response.data.dob?this.$moment(response.data.dob).format("Y-MM-DD"):"";
          this.nationality=response.data.nationality;
          this.referralCode=response.data.referralCode;
          this.email=response.data.user?response.data.user.email:'';
          this.mobile_number=response.data.user?response.data.user.userMobile.mobileNumber:'';
          this.name=response.data.user?response.data.user.name:'';
          this.image.thumbnail=response.data.user?(response.data.user.profilePicture?response.data.user.profilePicture.url:''):'';
          this.driving_license.thumbnail=response.data.drivingLicenseFile?response.data.drivingLicenseFile.url:'';
          this.registration_license.thumbnail=response.data.registrationFile?response.data.registrationFile.url:'';
          this.identity.thumbnail=response.data.idFile?response.data.idFile.url:'';
          this.capabilities=response.data.capabilities;

          this.is_nationality_load=!!this.nationality;
          this.responseStatus=response.status;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      onSelectNationality({name, iso2, dialCode}) {
        this.nationality=iso2.toLowerCase();
        this.nationality_label=`${name} (${iso2})`;
      },
      loadNationality(){
        return this.isItemFound() && this.is_nationality_load;
      }
    }
  };
</script>
