<template>
  <div aria-labelledby="transactions-tab" class="tab-pane fade" id="transactions" role="tabpanel">
    <table-container :headers="tableHeaders">
      <template v-slot>
        <tr v-for="transaction in transactions" :key="transaction.id">
          <td> {{ transaction.id }} </td>
          <td> {{ transaction.actionAt | moment("Y-MM-DD hh:mm:ss A") }} </td>
          <td> {{ transaction.transactionReference }} </td>
          <td> {{ transaction.reference }} </td>
          <td> {{ transaction.merchantReference }} </td>
          <td> {{ transaction.status }} </td>
        </tr>
        <tr class="text-center" v-if="!transactions.length">
          <td colspan="999">{{ $t('messages.no_result_found') }}</td>
        </tr>
      </template>
    </table-container>
  </div>
</template>
<script>
  export default {
    name: "WithdrawRequestTabsTransactions",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      transactions: {
        type: Array,
        default: () => []
      },
    },
    data(){
      return {
        tableHeaders:[
          {
            'title':`fields.id`
          },
          {
            'title':`fields.action_at`
          },
          {
            'title':`${this.transPrefix}.fields.transaction_reference`
          },
          {
            'title':`${this.transPrefix}.fields.reference`
          },
          {
            'title':`${this.transPrefix}.fields.merchant_reference`
          },
          {
            'title':`fields.status`
          },
        ]
      }
    },
  };
</script>
