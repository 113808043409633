import {
    PROFILE_ME as PROFILE_ME,
    PROFILE_CHANGE_PASSWORD as PROFILE_CHANGE_PASSWORD,
} from "../actions/profile";
import axioses from "@/axios/config";
import i18n from "@/i18n";

const routePrefix='users';

const state = {};

const getters = {};

const actions = {
  [PROFILE_ME]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', null, { root: true });
      axioses.base
          .get(`/${routePrefix}/me`)
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', null, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', null, { root: true });
            reject(error);
          });
    });
  },
  [PROFILE_CHANGE_PASSWORD]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      if(params.newPassword!==params.newPasswordConfirmed){
          reject({
              response:{
                  data:{
                      errors:{
                          newPasswordConfirmed:i18n.t('validation.password_confirmed')
                      }
                  }
              }
          });
      }else{
          axioses.base
              .post(`/${routePrefix}/change-password`, params)
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                reject(error);
              });
      }
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
