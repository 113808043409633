import Index from "@/views/orders/index";
import Show from "@/views/orders/show";
import AllocationWindowsIndex from "@/views/orders/allocation_windows/index";
import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='orders';
const PERMISSION_PREFIX='orders';
const ALLOCATION_WINDOWS_CODE='allocation_windows';
const routes = [
  {
    path: `${CODE}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id`,
    name:`${CODE}.show`,
    component: Show,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `:id`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ALLOCATION_WINDOWS_CODE}/:allocation_window_id`,
    name:`${CODE}.${ALLOCATION_WINDOWS_CODE}.index`,
    component: AllocationWindowsIndex,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view_history`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ALLOCATION_WINDOWS_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `:id`,
          },
          {
            'title': `${CODE}.${ALLOCATION_WINDOWS_CODE}.title`,
          },
          {
            'title': `:allocation_window_id`,
          },
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
