<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <files-image v-model="image" :options="{ id: 'image', label: 'image', error: fieldErrors.profilePicture, containerClass: 'col-12 col-md-4' }"/>
              <files-image v-model="identity" :options="{ id: 'identity', transPrefix: `${transPrefix}.fields`, label: 'identity', error: fieldErrors.identity, containerClass: 'col-12 col-md-4' }"/>
              <files-image v-model="driving_license" :options="{ id: 'driving_license', transPrefix: `${transPrefix}.fields`, label: 'driving_license', error: fieldErrors.drivingLicense, containerClass: 'col-12 col-md-4' }"/>
              <files-image v-model="registration_license" :options="{ id: 'registration_license', transPrefix: `${transPrefix}.fields`, label: 'registration_license', error: fieldErrors.registrationLicense, containerClass: 'col-12 col-md-4' }"/>
            </div>
            <div class="row" v-if="isItemFound()">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', error: fieldErrors.name }"/>
              <input-email :value="email" :options="{ id: 'email', label: 'email', preventSpace: true, error: fieldErrors.email }"/>
              <input-text v-model="mobile_number" :options="{ id: 'mobile_number', label: 'mobile_number', error: fieldErrors['userMobile.mobileNumber'] }"/>
              <input-date v-model="date_of_birth" :options="{ id: 'date_of_birth', label: 'date_of_birth', error: fieldErrors['details.dateOfBirth'] }"/>
              <select-countries v-model="country_id" :options="{ id: 'country_id', label: 'country', disabled: true }"/>
              <select-cities v-model="default_city_id" :country_id="country_id" :options="{ id: 'default_city_id', label: 'default_city', transPrefix: `${transPrefix}.fields`, error: fieldErrors.defaultCityId }"/>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="nationality" class="d-block">{{ $t(`fields.nationality`)}}</label>
                  <vue-country-code id="nationality"  class="w-25" @onSelect="onSelectNationality" :defaultCountry="nationality" :dropdownOptions="{ disabledDialCode: true }"/>
                  <input-text :value="nationality_label" :options="{ error: fieldErrors['details.nationality'], disabled: true ,fullLayout: false , inputClass: 'form-control w-75 d-inline-block' }"/>
                  <span v-if="fieldErrors['details.nationality']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['details.nationality'] }}</strong>
                  </span>
                </div>
              </div>
              <input-text v-model="id_number" :options="{ id: 'id_number', transPrefix: `${transPrefix}.fields`, label: 'id_number', preventSpace: true, error: fieldErrors.idNumber }"/>
              <capabilities-field :itemID="id" :permissionPrefix="permissionPrefix" :capabilities="capabilities"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { CAPTAINS_SHOW as CRUD_ACTION_SHOW,CAPTAINS_UPDATE as CRUD_ACTION_UPDATE } from 'actions/captains';
  import capabilitiesField from "./fields/capabilities";

  const CRUD_CODE="captains";
  export default {
    name: "CaptainEdit",
    components:{
      capabilitiesField,
    },
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        image:{
          file:'',
          thumbnail:'',
        },
        profilePictureImageIsChanged: false,
        driving_license:{
          file:'',
          thumbnail:'',
        },
        drivingLicenseImageIsChanged: false,
        registration_license:{
          file:'',
          thumbnail:'',
        },
        identity:{
          file:'',
          thumbnail:'',
        },
        identityImageIsChanged: false,
        name: '',
        email: '',
        mobile_number: '',
        date_of_birth: '',
        country_id: '',
        default_city_id: '',
        nationality: '',
        nationality_label: '',
        id_number:'',
        capabilities:[],
        serverError: '',
        fieldErrors: {},
        responseStatus:'',
        formMode: 'edit',
        loading: false,
      }
    },
    watch: {
      image: function() {
        this.profilePictureImageIsChanged = true;
      },
      driving_license: function() {
        this.drivingLicenseImageIsChanged = true;
      },
      identity: function() {
        this.identityImageIsChanged = true;
      },
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus && this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.id_number=response.data.idNumber;
          this.email=response.data.user?response.data.user.email:'';
          this.mobile_number=response.data.user?response.data.user.userMobile.mobileNumber:'';
          this.date_of_birth=response.data.dob?this.$moment(response.data.dob).format("Y-MM-DD"):"";
          this.country_id = response.data.countryId;
          this.default_city_id = response.data.defaultCityId;
          this.nationality=response.data.nationality;
          this.name=response.data.user?response.data.user.name:'';
          this.image.thumbnail=response.data.user?(response.data.user.profilePicture?response.data.user.profilePicture.url:''):'';
          this.driving_license.thumbnail=response.data.drivingLicenseFile?response.data.drivingLicenseFile.url:'';
          this.registration_license.thumbnail=response.data.registrationFile?response.data.registrationFile.url:'';
          this.identity.thumbnail=response.data.idFile?response.data.idFile.url:'';
          this.capabilities=response.data.capabilities;

          this.responseStatus=response.status;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      prepareDetailsRequestData() {
        let details={};
        if(this.date_of_birth || this.nationality){
          details= {
            "details.nationality": this.sanitizeField('nationality'),
            "details.dateOfBirth": this.sanitizeField('date_of_birth'),
          };
        }
        return details;
      },
      prepareRequestData(){
        return {
          email: this.sanitizeField('email'),
          name: this.sanitizeField('name'),
          idNumber: this.sanitizeField('id_number'),
          "userMobile.mobileNumber":this.sanitizeField('mobile_number'),
          defaultCityId: this.sanitizeField('default_city_id'),
          ...this.prepareFile('image',"profilePicture"),
          ...this.prepareFile('driving_license',"drivingLicense"),
          ...this.prepareFile('registration_license',"registrationLicense"),
          ...this.prepareFile('identity',"identity"),
          ...this.prepareDetailsRequestData(),
          ...this.prepareUpdateFlags(),
        }
      },
      prepareUpdateFlags() {
        return {
          "identityImageIsChanged": this.identityImageIsChanged,
          "drivingLicenseImageIsChanged": this.drivingLicenseImageIsChanged,
          "profilePictureImageIsChanged": this.profilePictureImageIsChanged,
        }
      },
      update() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE, {
          id: this.id,
          data: {
            ...this.prepareRequestData()
          },
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
      onSelectNationality({name, iso2, dialCode}) {
        this.nationality=iso2.toLowerCase();
        this.nationality_label=`${name} (${iso2})`;
      },
    },
  };
</script>
