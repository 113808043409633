<template>
    <a href="javascript:;" @click.prevent="showPopUp(itemID)" class="btn btn-danger btn-sm">
        <span class="d-none d-lg-inline mr-2">
          {{ $t(`labels.block`) }}
        </span>
        <span>
          <i class="fas fa-ban"></i>
        </span>
    </a>
</template>

<script>
    import { CAPTAINS_REGISTRATION_REQUESTS_UPDATE_STATUS as CRUD_ACTION_UPDATE_STATUS } from "actions/captains_registration_requests";
    export default {
        name: "CaptainRegistrationRequestActionsBlock",
        props: {
            itemID:{}
        },
        data(){
            return {}
        },
        methods:{
            showPopUp(id) {
                swal({
                    title: this.$i18n.t("labels.block_item"),
                    text: this.$i18n.t("messages.block_item"),
                    icon: "warning",
                    buttons: [this.$i18n.t("labels.cancel"), this.$i18n.t("labels.yes")],
                    dangerMode: true,
                }).then((willDoAction) => {
                    if (willDoAction) {
                        this.action(id);
                    }
                });
            },
            action(id) {
                this.$store.dispatch(CRUD_ACTION_UPDATE_STATUS, {
                    id,
                    type:'block',
                    data:{}
                })
                .then(() => {
                    this.$emit('input',false);
                    swal({
                        icon: "success",
                        text: this.$i18n.t("messages.block_successfully"),
                        buttons: false,
                        timer: 5000,
                    });
                })
                .catch((error) => {
                    let message = "";
                    if (error.response) {
                        let responseData = error.response.data;

                        if (responseData.errors && responseData.errors.error) {
                            message = responseData.errors.error;
                        } else {
                            message = this.$i18n.t("messages.update_error");
                        }
                    }
                    if(message){
                        swal({
                            icon: "error",
                            text: message,
                            buttons: false,
                            timer: 5000,
                        });
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>