<template>
  <div aria-labelledby="general-tab" class="tab-pane fade show active" id="general" role="tabpanel">
    <div class="row">
      <input-text v-model="fields.captain.id" :options="{ id: 'captain_id', label: 'captain_id', disabled: true }"/>
      <input-text v-model="fields.captain.user.name" :options="{ id: 'captain_name', label: 'captain_name', disabled: true }"/>
      <select-transaction-types v-model="fields.transaction_type" :options="{ id:'transaction_type', transPrefix: `${transPrefix}.fields`, label: 'transaction_type', disabled: true }"/>
      <select-withdraw-status v-model="fields.status" :options="{ id:'status', label: 'status', disabled: true }"/>
      <input-text v-model="fields.wallet_id" :options="{ id: 'wallet_id', label: 'wallet_id', disabled: true }"/>
      <input-text v-model="fields.amount" :options="{ id: 'amount', label: 'amount', disabled: true }"/>
      <input-text  v-if="fields.refusal_reason_message" v-model="fields.refusal_reason_message" :options="{ id: 'refusal_reason_message', transPrefix: `${transPrefix}.fields`, label: 'refusal_reason', disabled: true }"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: "WithdrawRequestTabsGeneral",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      fields: {
        type: Object,
        default: () => { }
      },
    },
    data(){
      return {
      }
    },
  };
</script>
