<template>
  <div class="mb-4">
    <div class="row">
      <div class="col-12">
        <div class="text-center h3">
          Cancelled And Failed-To-Assign Orders
        </div>
      </div>
    </div>
    <div class="row chart-container">
      <div class="col-12">
        <v-chart class="chart" :option="option" />
      </div>
    </div>
  </div>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {TreemapChart} from "echarts/charts";
import {LegendComponent, TitleComponent, TooltipComponent} from "echarts/components";
import VChart, {INIT_OPTIONS_KEY} from "vue-echarts";

use([
  CanvasRenderer,
  TreemapChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

const CRUD_CODE = "monitoring";
export default {
  name: "CancelledOrdersChart",
  props: {
    chartData: {
      type: Array
    }
  },
  components: {
    VChart
  },
  provide: {
    [INIT_OPTIONS_KEY]: {
      height: 600
    }
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_CODE,
      fieldErrors: {},
      loading: false,
      option: {
        color: ['#aaa'],
        title: {
          text: "",
          left: "center",
        },
        tooltip: {
          formatter: (params) => params.name + (params.value && " (" + params.value?.toFixed(0) + ")")
        },
        series: {
          type: "treemap",
          width: "100%",
          height: "100%",
          leafDepth: 1,
          upperLabel: {
            show: true,
            height: 30
          },
          itemStyle: {
            gapWidth: 4
          },
          label: {
            position: 'insideTopLeft',
            formatter: (params) => params.name + (params.value && " (" + params.value?.toFixed(0) + ")")
          },
          data: []
        }
      }
    }
  },
  mounted() {
    this.option.series.data = this.buildOrdersData(this.chartData);
  },
  methods: {
    buildOrdersData(chartData) {
      const data = [];
      const orders = chartData;

      for (const order of orders) {
        if (!order.isAlive && !order.isDelivered) {
          if (!this.option.series.name) {
            this.option.title.text = order.countryName;
            this.option.series.name = order.countryName;
          }

          let city = data.find(e => e.name === order.cityName);
          if (!city) {
            city = { name: order.cityName, children: [], value: 1 };
            data.push(city);
          } else {
            city.value++;
          }

          let cancellationReason;
          if (order.cancellationReason) {
            cancellationReason = order.cancellationReason;
          } else if (order.status === "FAILED_TO_ASSIGN") {
            cancellationReason = order.status;
          } else {
            cancellationReason = "UNKNOWN_REASON"
          }

          let cancellationReasonNode = city.children.find(e => e.name === cancellationReason);
          if (!cancellationReasonNode) {
            cancellationReasonNode = { name: cancellationReason, children: [], value: 1 };
            city.children.push(cancellationReasonNode);
          } else {
            cancellationReasonNode.value++;
          }

          cancellationReasonNode.children.push({ name: order.backendId, value: 1, label: this.getOrderLabel(order), tooltip: this.getOrderTooltip(order) });
        }
      }
      return data;
    },
    getOrderLabel(order) {
      return {
        position: 'insideTopLeft',
        formatter: () => {
          let label = ["#" + order.backendId];
          order.status && label.push("Status: " + order.status);
          order.updatedAt && label.push("Updated At: " + this.$moment(order.updatedAt).format("Y-MM-DD hh:mm:ss A"));
          order.brandName && label.push("Brand: " + order.brandName);
          order.captainId ? label.push("Captain ID: " + order.captainId) : label.push("NO DRIVER FOUND");
          order.captainName && label.push("Captain Name: " + order.captainName);
          order.supplierId && label.push("Supplier ID: " + order.supplierId);
          order.supplierName && label.push("Supplier Name: " + order.supplierName);
          return label.join("\n")
        }
      }
    },
    getOrderTooltip(order) {
      return {
        formatter: () => {
          let label = ["#" + order.backendId];
          order.status && label.push("Status: " + order.status);
          order.updatedAt && label.push("Updated At: " + this.$moment(order.updatedAt).format("Y-MM-DD hh:mm:ss A"));
          order.brandName && label.push("Brand: " + order.brandName);
          order.captainId ? label.push("Captain ID: " + order.captainId) : label.push("NO DRIVER FOUND");
          order.captainName && label.push("Captain Name: " + order.captainName);
          order.supplierId && label.push("Supplier ID: " + order.supplierId);
          order.supplierName && label.push("Supplier Name: " + order.supplierName);
          return label.join("<br>")
        }
      }
    }
  },
  watch: {
    chartData: function(newVal) {
      this.option.series.data = this.buildOrdersData(newVal);
    }
  }
};
</script>

<style scoped>
.chart-container {
  height: 600px
}
</style>