<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <select-fintech-type v-model="account_type" :options="{ id: 'account_type', transPrefix: `${transPrefix}.fields`, label: 'fintech_type', error: fieldErrors.accountType }"/>
              <input-text v-model="account_id" :options="{ id: 'account_id', transPrefix: `${transPrefix}.fields`, label: 'fintech_id', error: fieldErrors.accountId }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${fintechsRoutePrefix}.show`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { FINTECHS_SHOW as CRUD_ACTION_SHOW,FINTECHS_UPDATE as CRUD_ACTION_UPDATE } from 'actions/fintechs';
  const CRUD_CODE="captains";
  const FINTECHS_CODE="fintechs";
  export default {
    name: "FintechEdit",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        fintechsRoutePrefix: `${CRUD_CODE}.${FINTECHS_CODE}`,
        fintechsTransPrefix: `${CRUD_CODE}.${FINTECHS_CODE}`,
        id:this.$router.currentRoute.params.id,
        fintech_id: this.$router.currentRoute.params.fintech_id,
        account_type: '',
        account_id:'',
        serverError: '',
        fieldErrors: {},
        responseStatus:'',
        formMode: 'edit',
        loading: false,
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.fintech_id=response.data.id;
          this.account_type=response.data.accountType;
          this.account_id=response.data.accountId;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      prepareRequestData(){
        return {
          accountId: this.sanitizeField("account_id"),
          accountType: this.sanitizeField("account_type"),
        }
      },
      update() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE, {
          id: this.fintech_id,
          data: {
            ...this.prepareRequestData()
          },
        })
        .then(() => {
          this.loading = false;
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.fintechsRoutePrefix}.show` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    },
  };
</script>
