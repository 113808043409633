import {
    AUDIT_LOGS_LIST as CRUD_ACTION_LIST,
    AUDIT_LOGS_ITEM_TYPES_LIST,
    AUDIT_LOGS_EVENT_TYPES_LIST,
    DISPATCHER_PERFORMANCE_REPORT
} from "../actions/audit_logs";
import axioses from "@/axios/config";
import qs from "qs"

const routePrefix='audit_logs';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [AUDIT_LOGS_ITEM_TYPES_LIST]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          commit('START_LOADING', null, { root: true });
          axioses.base
              .get(`/${routePrefix}/item_types`)
              .then(response => {
                  resolve(response);
                  commit('STOP_LOADING', null, { root: true });
              })
              .catch(error => {
                  commit('STOP_LOADING', null, { root: true });
                  reject(error);
              });
      });
  },
  [AUDIT_LOGS_EVENT_TYPES_LIST]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          commit('START_LOADING', null, { root: true });
          axioses.base
              .get(`/${routePrefix}/event_types`)
              .then(response => {
                  resolve(response);
                  commit('STOP_LOADING', null, { root: true });
              })
              .catch(error => {
                  commit('STOP_LOADING', null, { root: true });
                  reject(error);
              });
      });
  },
  [DISPATCHER_PERFORMANCE_REPORT]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          commit('START_LOADING', params.withLoading, { root: true });
          axioses.base
              .get(`/${routePrefix}/dispatcher_performance_report`, { params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
              .then(response => {
                  commit('STOP_LOADING', params.withLoading, { root: true });
                  resolve(response);
              })
              .catch(error => {
                  commit('STOP_LOADING', params.withLoading, { root: true });
                  reject(error);
              });
      });
  }
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
