export const ORDERS_LIST = "ORDERS_LIST";
export const ORDERS_CREATE = "ORDERS_CREATE";
export const ORDERS_UPDATE = "ORDERS_UPDATE";
export const ORDERS_DELETE = "ORDERS_DELETE";
export const ORDERS_SHOW = "ORDERS_SHOW";
export const ORDERS_CANCEL= "ORDERS_CANCEL";
export const ORDERS_REASSIGN = "ORDERS_REASSIGN";
export const ORDERS_MANUAL_ASSIGN = "ORDERS_MANUAL_ASSIGN";
export const ORDER_TASKS_UPDATE_STATUS = "ORDER_TASKS_UPDATE_STATUS";
export const ORDERS_CANCELLATION_REASONS_LIST = "ORDERS_CANCELLATION_REASONS_LIST ";
export const ORDER_TYPES_LIST = "ORDER_TYPES_LIST ";
export const ORDER_ASSIGNMENT_METHODS_LIST = "ORDER_ASSIGNMENT_METHODS_LIST";
export const ORDERS_GIFT_STATUS_LIST = "ORDERS_GIFT_STATUS_LIST";
export const ORDERS_TIMELINE_STATUS_LIST = "ORDERS_TIMELINE_STATUS_LIST";
export const ORDERS_PAYMENT_TYPE_LIST = "ORDERS_PAYMENT_TYPE_LIST";
export const ORDERS_STATUS_LIST = "ORDERS_STATUS_LIST";
export const ORDERS_TASKS_TIMELINES_SHOW = "ORDERS_TASKS_TIMELINES_SHOW";
