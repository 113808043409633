<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm" v-can:add="permissionPrefix">
            <i class="fa fa-plus"></i>
          </router-link>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td>
              <div class="media align-items-center">
                <a v-if="getImageThumbnail(item)" href="javascript:;" class="avatar rounded-circle">
                  <img alt="Image placeholder" :src="getImageThumbnail(item)">
                </a>
                <div class="media-body">
                  <span class="name mb-0 text-sm"></span>
                </div>
              </div>
            </td>
            <td> {{ item.name }} </td>
            <td> {{ item.nameAr }} </td>
            <td> {{ item.webHookUrl }} </td>
            <td class="">
              <input-checkbox v-model="item.enabled" :options="{ id: 'status', fullLayout: false, disabled: true }"/>
            </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <table-item-actions :actions="tableItemActions" :params="{'id':item.id}"/>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import { PLATFORMS_LIST as CRUD_ACTION_LIST} from 'actions/platforms';
  const CRUD_CODE="platforms";
  const CRUD_PERMISSION_CODE=`${CRUD_CODE}`;
  export default {
    name: "PlatformIndex",
    data(){

      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        tableHeaders:[
          {
            'title':`fields.image`
          },
          {
            'title':`fields.en_name`
          },
          {
            'title':`fields.ar_name`
          },
          {
            'title':`${CRUD_CODE}.fields.web_hook_url`
          },
          {
            'title':`fields.enabled`
          },
          {
            'title':`fields.created_at`
          },
          {
            'title':`fields.actions`
          }
        ],
        tableItemActions:[
          {
            code:'show',
            route_name:`${CRUD_CODE}.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
          },
          {
            code:'edit',
            route_name:`${CRUD_CODE}.edit`,
            can:`${CRUD_PERMISSION_CODE}_can_update`,
          },
          {
            code:'details',
            route_name:`${CRUD_CODE}.details.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
            color_class:`text-red`,
            icon:`fas fa-at`,
            title: `${CRUD_CODE}.details.title`,
          },
          {
            code:'users',
            route_name:`${CRUD_CODE}.users.index`,
            color_class:`text-indigo`,
            icon:`fas fa-users`,
            can:`platform_users_can_view`,
            title: `${CRUD_CODE}.users.title`,
          },
          {
            code:'apps',
            route_name:`${CRUD_CODE}.apps.index`,
            color_class:`text-blue`,
            icon:`fas fa-mobile-alt`,
            can:`platform_apps_can_view`,
            title: `${CRUD_CODE}.apps.title`,
          },
          {
            code:'configs',
            route_name:`${CRUD_CODE}.configs.show`,
            color_class:`text-orange`,
            icon: `fas fa-sliders-h`,
            can:`platform_configs_can_view`,
            title: `${CRUD_CODE}.configs.title`,
          },
          {
            code:'files',
            route_name:`${CRUD_CODE}.files.show`,
            color_class:`text-gray`,
            icon: `fas fa-folder-open`,
            can: `platform_files_can_view`,
            title: `${CRUD_CODE}.files.title`,
          },
          {
            code:'invoices',
            route_name:`${CRUD_CODE}.invoices.index`,
            color_class:`text-purple`,
            icon:`fa fa-file-invoice`,
            can:`platform_invoices_can_view`,
            title: `${CRUD_CODE}.invoices.title`,
          },
          {
            code:'brands',
            route_name:`${CRUD_CODE}.brands.index`,
            color_class:`text-pink`,
            icon:`fa fa-building`,
            can:`platform_brands_can_view`,
            title: `${CRUD_CODE}.brands.title`,
          },
          {
            code:'branches',
            route_name:`${CRUD_CODE}.branches.index`,
            color_class:`text-teal`,
            icon:`fa fa-store`,
            can:`platform_branches_can_view`,
            title: `${CRUD_CODE}.branches.title`,
          },
          {
            code:'settings',
            route_name:`${CRUD_CODE}.settings.show`,
            color_class:`text-success`,
            icon:`fa fa-cogs`,
            can:`platform_settings_can_view`,
            title: `${CRUD_CODE}.settings.title`,
          },
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          pageId: this.getCurrentPage()-1,
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        });
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
      getImageThumbnail(item){
        let image='';
        if(item.image){
          image=item.image.url;
        }
        return image;
      }
    }
  };
</script>
