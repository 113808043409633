<template>
    <abstract-dropdown-container v-model="selectedValue" :options="{...defaultOptions, ...options}">
        <template v-slot:hint-top>
            <slot name="hint-top"></slot>
        </template>
        <template v-slot:hint-bottom>
            <slot name="hint-bottom"></slot>
        </template>
    </abstract-dropdown-container>
</template>

<script>
    import {ORDERS_CANCELLATION_REASONS_LIST as CRUD_ACTION_LIST} from 'actions/orders';

    export default {
        name: "FieldsDropdownOrderCancellationReasons",
        props: {
            value: {},
            options: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                selectedValue: this.value,
                defaultOptions: {
                    id:                                     "order_cancellation_reason",
                    prepareResponseDataHandler:             this.prepareResponseData,
                    customLabelHandler:                     this.customLabel,
                    action:                                 CRUD_ACTION_LIST,
                }
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            }
        },
        methods: {
            customLabel(value) {
                return this.$i18n.t(`${value.name}`);
            },
            prepareResponseData(data) {
                let preparedData = [];
                for (const [key, value] of Object.entries(data)) {
                    preparedData.push({'id': key, 'name': `${value}`});
                }
                return preparedData;
            }
        }
    }
</script>

<style scoped>

</style>
