<template>
    <li class="nav-item" v-if="item.permission ? can(item.permission) : true">
        <template v-if="item.items" >
            <a class="nav-link" :href="`#side-menu-item-${item.code}-${index}`" data-toggle="collapse" role="button" :aria-expanded="isActiveMenu(item.code)" aria-controls="navbar-maps" v-bind:class="{ 'active': isActiveMenu(item.code) }">
                <i v-bind:class="[item.icon, item.color]"></i>
                <span class="nav-label">{{ $t(item.label) }}</span>
            </a>
            <div :id="`side-menu-item-${item.code}-${index}`" class="collapse" v-bind:class="{ 'show': isActiveMenu(item.code) }">
                <ul class="nav nav-sm flex-column">
                    <side-menu-item v-for="(childItem, childIndex) in item.items" :item="childItem" :index="childIndex"/>
                </ul>
            </div>
        </template>
        <router-link v-else active-class="active" :to="{ name: item.route }" class="nav-link">
            <i v-bind:class="[item.icon, item.color]"></i>
            <span class="nav-link-text mr-auto">{{ $t(item.label) }}</span>
            <span v-if="item.badge" class="badge" v-bind:class="[item.badge.color]">{{ $t(item.badge.label) }}</span>
        </router-link>
    </li>
</template>

<script>
export default {
    name: "SideMenuItem",
    props: {
        item: {
            type: [Number, Object],
            default: () => {}
        },
        index: {
            type: [Number, String],
            default: 0
        },
    },
    data() {
        return {
        }
    },
    methods:{
        isActiveMenu(code){
            let isActive=false;
            switch (code) {
                case 'settings':
                    isActive=this.$route.path.search(/allocation_configs|roles|withdraw_configs/i)!==-1;
                    break;
            }
            return isActive;
        }
    }
}
</script>

<style scoped>

</style>
