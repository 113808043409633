<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <select-app-device-types v-model="type" :options="{ id: 'app_device_type', label: 'type', params: { 'platformId':id }, error: fieldErrors.type }"/>
              <input-text v-model="name" :options="{ id: 'name', label: 'name', error: fieldErrors.name }"/>
              <input-text v-model="bundle_id" :options="{ id: 'bundle_id', transPrefix: `${transPrefix}.fields`, label: 'bundle_id', error: fieldErrors.bundleId }"/>
              <input-text v-model="firebase_api_key" :options="{ id: 'firebase_api_key', transPrefix: `${transPrefix}.fields`, label: 'firebase_api_key', error: fieldErrors.firebaseApiKey }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`,params:{id} }"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { PLATFORMS_APPS_CREATE as CRUD_ACTION_CREATE } from 'actions/platforms';
  const PLATFORMS_CODE="platforms";
  const CRUD_CODE=`${PLATFORMS_CODE}.apps`;
  export default {
    name: "PlatformAppCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        platformsRoutePrefix: `${PLATFORMS_CODE}`,
        platformsTransPrefix: `${PLATFORMS_CODE}`,
        id:this.$router.currentRoute.params.id,
        type: '',
        name: '',
        bundle_id: '',
        firebase_api_key: '',
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    methods: {
      prepareRequestData(){
        return {
          type: this.sanitizeField('type'),
          name: this.sanitizeField('name'),
          bundleId: this.sanitizeField('bundle_id'),
          firebaseApiKey: this.sanitizeField('firebase_api_key'),
        }
      },
      create() {
        this.loading = true
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
          platformId:this.id,
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index`, params:{id:this.id} })
        })
        .catch(error => {
          this.loading = false
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    }
  };
</script>
