<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <select-countries v-model="country_id" :options="{ id: 'country_id', label: 'country', error: fieldErrors.countryId }"/>
              <input-number v-model="min_balance_to_keep" :options="{ id: 'min_balance_to_keep', transPrefix: `${transPrefix}.fields`, label: 'min_balance_to_keep', error: fieldErrors.minBalanceToKeep }"/>
              <input-number v-model="min_amount" :options="{ id: 'min_amount', transPrefix: `${transPrefix}.fields`, label: 'min_amount', error: fieldErrors.minAmount }"/>
              <input-number v-model="max_amount" :options="{ id: 'max_amount', transPrefix: `${transPrefix}.fields`, label: 'max_amount', error: fieldErrors.maxAmount }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { WITHDRAW_CONFIGS_CREATE as CRUD_ACTION_CREATE } from 'actions/withdraw_configs';
  const CRUD_CODE="withdraw_configs";
  export default {
    name: "WithdrawConfigCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        min_balance_to_keep: '',
        min_amount: '',
        max_amount: '',
        country_id: "",
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    methods: {
      prepareRequestData(){
        return {
          countryId: this.sanitizeField('country_id'),
          minBalanceToKeep: this.sanitizeField('min_balance_to_keep'),
          minAmount: this.sanitizeField('min_amount'),
          maxAmount: this.sanitizeField('max_amount'),
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),

        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    }
  };
</script>
