import Index from "@/views/audit_logs";
import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"
import DispatcherPerformanceReport from "@/views/audit_logs/report/dispatcher_performance_report";

const CODE='audit_logs';
const PERMISSION_PREFIX='audit_logs';
const routes = [
  {
    path: `${CODE}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    }
  },
  {
    path: `${CODE}/dispatcher_performance_report`,
    name:`${CODE}.dispatcher_performance_report`,
    component: DispatcherPerformanceReport,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_report`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.dispatcher_performance_report_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.create_dispatcher_performance_report`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
