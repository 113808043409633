<template>
     <div  aria-hidden="true" aria-labelledby="modal-change-status-report" class="modal fade" id="modal-change-status-report"  role="dialog" tabindex="-1">
       <div class="modal-dialog modal-dialog-centered" role="document">
         <div class="modal-content">
           <div class="modal-header">
             <h6 class="modal-title" id="modal-title-default">{{ $t('messages.update_item_status')}}</h6>
             <button aria-label="Close" class="close" data-dismiss="modal" type="button">
               <span aria-hidden="true">×</span>
             </button>
           </div>
           <form role="form" @submit.prevent="changeStatus">
             <div class="modal-body">
               <div class="row">
                 <div class="col-12">
                   <div v-if="serverError" class="alert alert-danger">
                     <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                   </div>
                 </div>
               </div>
               <select-supplier-report-statuses v-model="status" :options="{ id: 'status', params: { ...activeParams, ...additionalParams }, error: fieldErrors.status, containerClass: 'col-12' }"/>
             </div>
             <div class="modal-footer">
               <button class="btn btn-danger btn-sm ml-auto" data-dismiss="modal" type="button">
                 {{ $t('labels.close') }}
               </button>
               <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                 <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                 {{ $t('labels.submit') }}
               </button>
             </div>
           </form>
         </div>
       </div>
     </div>
 </template>

 <script>
     import { SUPPLIERS_REPORTS_UPDATE_STATUS as CRUD_ACTION_UPDATE} from 'actions/suppliers';
     export default {
         name: "SupplierReportActionsModalsChangeStatus",
         props: {
             activeParams:{
                 type:Object,
                 default:function(){
                   return {};
                 }
             },
             additionalParams:{
               type:Object,
               default:function(){
                return {};
               }
             },
             successCallback: {
                 type: Function,
                 default: () => { }
             },
         },
         data(){
             return {
                 serverError: '',
                 fieldErrors: {},
                 loading: false,
                 status:"",
                 activeId:null,
                 activeIndex:null,
             }
         },
         watch:{
           activeParams:function(newVal, oldVal) {
             this.serverError=null;
             this.option="";
             this.activeId=newVal.activeId;
             this.activeIndex=newVal.activeIndex;
             this.activeParams=newVal;
             this.status=newVal.activeStatus;
           },
         },
         methods:{
             changeStatus(){
                 this.loading = true;
                 this.$store.dispatch(CRUD_ACTION_UPDATE, {
                     id:this.activeId,
                     status:this.sanitizeField('status'),
                     data: {
                         ...this.prepareRequestData()
                     },
                     ...this.additionalParams
                 })
                 .then(() => {
                     document.querySelector('#modal-change-status-report [data-dismiss]').click();
                     this.loading = false;
                     this.status="";
                     this.fieldErrors={};
                     this.serverError='';
                     this.successCallback();
                     swal({
                         icon: 'success',
                         text: this.$i18n.t("messages.update_successfully"),
                         buttons:false,
                         timer: 5000
                     });
                 })
                 .catch(error => {
                     this.loading = false;
                     this.fieldErrors={};
                     this.serverError='';

                     if(error.response){
                         let responseData=error.response.data;

                         if(responseData.errors){
                             this.fieldErrors=responseData.errors;
                         }else{
                             this.serverError = 'Invalid Request.'
                         }
                     }else{
                         this.serverError = this.$i18n.t("messages.no_internet_connection");
                     }

                     if(this.fieldErrors.error){
                         this.serverError = this.fieldErrors.error;
                     }
                 })
             },
             prepareRequestData(){
                 return {}
             },
         }
     }
 </script>

 <style scoped>

 </style>
