<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', disabled: true }"/>
              <input-text v-model="phone" :options="{ id: 'phone', label: 'phone', disabled: true }"/>
              <input-text v-model="latitude" :options="{ id: 'latitude', label: 'latitude', disabled: true }"/>
              <input-text v-model="longitude" :options="{ id: 'longitude', label: 'longitude', disabled: true }"/>
              <input-textarea v-model="description" :options="{ id: 'description', label: 'description', disabled: true, containerClass: 'col-12' }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`,params: {id}}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { PLATFORMS_BRANCHES_SHOW as CRUD_ACTION_SHOW } from 'actions/platforms';
  const PLATFORMS_CODE="platforms";
  const CRUD_CODE=`${PLATFORMS_CODE}.branches`;
  const CRUD_PERMISSION_CODE=`platform_branches`;
  export default {
    name: "PlatformBranchShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: `${CRUD_PERMISSION_CODE}`,
        platformsRoutePrefix: `${PLATFORMS_CODE}`,
        platformsTransPrefix: `${PLATFORMS_CODE}`,
        id:this.$router.currentRoute.params.id,
        branch_id:this.$router.currentRoute.params.branch_id,
        latitude: '',
        longitude: '',
        name: '',
        phone: '',
        description: '',
        serverError: '',
        responseStatus:'',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          platformId: this.id,
          id: this.branch_id,
        }).then(response=>{
          this.latitude=response.data.latitude;
          this.name=response.data.name;
          this.longitude=response.data.longitude;
          this.description=response.data.description;
          this.phone=response.data.phone;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
