<template>
  <div aria-labelledby="platform-tab" class="tab-pane fade" id="platform" role="tabpanel">
    <div class="row">
      <files-image v-model="fields.platform_image" :options="{ id: 'image', transPrefix: '', disabled: true }"/>
    </div>
    <div class="row">
      <input-text v-model="fields.platform_en_name" :options="{ id: 'platform_en_name', label: 'en_name', disabled: true }"/>
      <input-text v-model="fields.platform_ar_name" :options="{ id: 'platform_ar_name', label: 'ar_name', disabled: true }"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: "OrderTabsPlatform",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      fields: {
        type: Object,
        default: () => { }
      },
    },
    data(){
      return {
      }
    },
  };
</script>
