<template>
    <a href="javascript:;" @click.prevent="showAttachCaptainPopUp(itemID)" class="btn btn-success btn-sm">
        <span class="d-none d-lg-inline mr-2">
          {{ $t(`labels.attach_captain`) }}
        </span>
        <span>
          <i class="fas fa-link"></i>
        </span>
    </a>
</template>

<script>
import {
  SUPPLIERS_ATTACH_CAPTAIN as CRUD_ACTION_ATTACH_CAPTAIN,
  SUPPLIERS_DE_ATTACH_CAPTAIN as CRUD_ACTION_DE_ATTACH_CAPTAIN,
  SUPPLIERS_OF_CAPTAIN
} from "actions/suppliers";
    export default {
        name: "SupplierActionsAttachCaptain",
        props: {
            itemID:{}
        },
        data(){
            return {}
        },
        methods:{
            showAttachCaptainPopUp(id) {
                swal({
                    title: this.$i18n.t("labels.attach_captain"),
                    text: this.$i18n.t("labels.attach_captain_item"),
                    type: "input",
                    showCancelButton: true,
                    closeOnConfirm: false,
                    content: "input",
                    buttons: [
                        this.$i18n.t("labels.cancel"),
                        this.$i18n.t("labels.attach_captain"),
                    ],
                    inputPlaceholder: "Write something",
                }).then((value) => {
                    const captainId = parseInt(value, 0);
                    const supplierId = parseInt(id, 0);
                    if (value !== null && Object.keys(value).length !== 0 && captainId) {
                        this.getSuppliersOfCaptain(captainId)
                            .then(suppliers => suppliers.filter(supplier => supplier.id !== supplierId))
                            .then(otherSuppliers => otherSuppliers.length > 0
                                ? this.reAttachCaptain(supplierId, otherSuppliers, captainId)
                                : this.attachCaptain(supplierId, captainId))
                    }
                });
            },
            reAttachCaptain(newSupplierId, oldSuppliers, captainId) {
              return swal({
                title: this.$i18n.t("labels.re_attach_captain"),
                text: this.$i18n.t("labels.re_attach_captain_item", [captainId, oldSuppliers.map(s => s.name).join(", ")]),
                icon: "warning",
                dangerMode: true,
                buttons: [
                  this.$i18n.t("labels.cancel"),
                  this.$i18n.t("labels.yes"),
                ],
              }).then(confirmed => {
                if (confirmed) {
                  return Promise.all(oldSuppliers.map(supplier => this.deAttachCaptain(supplier.id, captainId)))
                      .then(() => this.attachCaptain(newSupplierId, captainId));
                }
              })

            },
            getSuppliersOfCaptain(captain_id) {
                return this.$store.dispatch(SUPPLIERS_OF_CAPTAIN, { captain_id }).then(response => response.data)
            },
            deAttachCaptain(id, captain_id) {
              return this.$store.dispatch(CRUD_ACTION_DE_ATTACH_CAPTAIN, { id, captain_id})
            },
            attachCaptain(id, captain_id) {
              return this.$store.dispatch(CRUD_ACTION_ATTACH_CAPTAIN, { id, captain_id })
                  .then(() => this.showSuccessPopup())
                  .catch((error) => this.showErrorPopup(error));
            },
            showSuccessPopup() {
              this.$emit('input',false);
              swal({
                icon: "success",
                text: this.$i18n.t("messages.attach_captain_success"),
                buttons: false,
                timer: 5000,
              });
            },
            showErrorPopup(error) {
              let message = "";
              if (error.response) {
                let responseData = error.response.data;

                if (responseData.errors && responseData.errors.error) {
                  message = responseData.errors.error;
                } else {
                  message = this.$i18n.t("messages.attach_captain_error");
                }
              }
              if(message){
                swal({
                  icon: "error",
                  text: message,
                  buttons: false,
                  timer: 5000,
                });
              }
            }
        }
    }
</script>

<style scoped>

</style>
