<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Tabs -->
        <ul class="nav nav-tabs px-2" role="tablist">
          <li class="nav-item">
            <a href="#general" id="general-tab" aria-controls="general" aria-selected="true" class="nav-link active" data-toggle="tab" role="tab">
              {{ $t("tabs.general") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="#history" id="history-tab" aria-controls="history" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.history") }}
            </a>
          </li>
          <li class="nav-item" v-if="transactions">
            <a href="#transactions" id="transactions-tab" aria-controls="transactions" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.transactions") }}
            </a>
          </li>
        </ul>
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="alert alert-danger" v-if="serverError">
                <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
              </div>
            </div>
          </div>
          <div class="tab-content" v-if="!serverError && isItemFound">
            <general-tab :fields="getGeneralTabFields()" :transPrefix="transPrefix"/>
            <history-tab :items="history" :transPrefix="transPrefix"/>
            <transactions-tab :transactions="transactions" :transPrefix="transPrefix"/>
          </div>
        </div>
        <!-- Card footer -->
        <div class="card-footer">
          <div class="row">
            <div class="col text-right">
              <router-link :to="{ name: `${routePrefix}.index` }" class="btn btn-neutral btn-sm">
                <i class="fa fa-arrow-left"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WITHDRAW_REQUESTS_SHOW as CRUD_ACTION_SHOW } from "actions/withdraw_requests";
import generalTab from "./tabs/general";
import historyTab from "./tabs/history";
import transactionsTab from "./tabs/transactions";

const CRUD_CODE = "withdraw_requests";
export default {
  name: "WithdrawRequestShow",
  components: {
    generalTab,
    historyTab,
    transactionsTab
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_CODE,
      id: this.$router.currentRoute.params.id,
      history: [],
      transaction_type: "",
      wallet_id: "",
      refusal_reason_message: "",
      captain: {},
      status: "",
      owner_id: "",
      amount: 0,
      transactions: [],
      refusal_reason:"",
      serverError: "",
      isItemFound: false,
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.$store.dispatch(CRUD_ACTION_SHOW, {
          id: this.id,
        })
        .then((response) => {
          this.transaction_type = response.data.transactionType;
          this.wallet_id = response.data.walletId;
          this.refusal_reason_message = response.data.refusalReasonMessage;
          this.captain = response.data.captain;
          this.status = response.data.status;
          this.owner_id = response.data.ownerId;
          this.amount = response.data.amount;
          this.refusal_reason = response.data.refusalReason;
          this.transactions = response.data.transactions ? response.data.transactions : [];

          this.history = response.data.history;
          this.isItemFound = true;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";
          if (error.response) {
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }
          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    getGeneralTabFields(){
      return {
        transaction_type:this.transaction_type,
        wallet_id:this.wallet_id,
        refusal_reason_message:this.refusal_reason_message,
        captain:this.captain,
        status:this.status,
        owner_id:this.owner_id,
        amount:this.amount,
        refusal_reason:this.refusal_reason,
      };
    }
  },
};
</script>
