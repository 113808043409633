<template>
  <div class="counters-container opened">
    <div class="row justify-content-center">
      <div class="col-auto px-1">
        <div class="alert bg-light text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.total`)">
          <i class="fas fa-users mx-1"/>
          {{ totalCaptains }}
        </div>
      </div>
      <div class="col-auto px-1">
        <div class="alert bg-light text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.ready_to_work`)">
          <i class="fas fa-globe mx-1"/>
          {{ totalReadyToWorkCaptains }}
        </div>
      </div>
      <div class="col-auto px-1">
        <div class="alert bg-light text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.connected`)">
          <i class="fas fa-wifi mx-1"/>
          {{ totalConnectedCaptains }}
        </div>
      </div>
      <div class="col-auto px-1">
        <div class="alert bg-red text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.not_connected`)">
          <i class="fas fa-ban mx-1"/>
          {{ totalNotConnectedCaptains }}
        </div>
      </div>
      <div class="col-auto px-1">
        <div class="alert bg-light text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.location_unknown`)">
          <i class="fas fa-question-circle mx-1"/>
          {{ totalLocationUnknownCaptains }}
        </div>
      </div>
      <div class="col-auto px-1">
        <div class="alert bg-green text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.free`)">
          <i class="fas fa-taxi mx-1" />
          {{ totalFreeCaptains }}
        </div>
      </div>
      <div class="col-auto px-1">
        <div class="alert bg-yellow text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.busy_captains_with_one_order`)">
          <i class="fas fa-truck mx-1"/>
          {{ totalBusyCaptains }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from "vuex";
const CRUD_CODE = "tracking";
export default {
  name: "TrackingCounter",
  data() {
    return {
      transPrefix: CRUD_CODE
    };
  },
  computed: {
    ...mapGetters(["getCaptains"]),
    totalCaptains() {
      return Object.keys(this.getCaptains).length;
    },
    totalReadyToWorkCaptains() {
      return Object.values(this.getCaptains).filter(captain => captain.readyToWork).length;
    },
    totalConnectedCaptains() {
      return Object.values(this.getCaptains).filter(captain => captain.connected).length;
    },
    totalNotConnectedCaptains() {
      return Object.values(this.getCaptains).filter(captain => !captain.connected).length;
    },
    totalLocationUnknownCaptains() {
      return Object.values(this.getCaptains).filter(captain => !(captain.latitude && captain.longitude)).length;
    },
    totalFreeCaptains() {
      return Object.values(this.getCaptains).filter(captain => captain.connected && captain.workingStatus === "FREE").length;
    },
    totalBusyCaptains() {
      return Object.values(this.getCaptains).filter(captain => captain.connected && captain.workingStatus === "BUSY").length;
    }
  }
};
</script>
