<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <input-text v-model="captain_id" :options="{ id: 'captain_id', label: 'captain_id', disabled: true }"/>
              <input-text v-model="allocation_window_id" :options="{ id: 'allocation_window_id', label: 'allocation_window_id', disabled: true }"/>
              <input-text v-model="earnings" :options="{ id: 'earnings', label: 'earnings', disabled: true }"/>
              <select-allocation-window-statuses v-model="status" :options="{ id: 'status', label: 'status', disabled: true }"/>
              <input-text v-model="captain_name" :options="{ id: 'captain_name', label: 'captain_name', disabled: true }"/>
              <input-text v-model="captain_mobile" :options="{ id: 'captain_mobile', label: 'captain_mobile', disabled: true }"/>
              <input-text v-model="order_id" :options="{ id: 'order_id', label: 'order_id', disabled: true }"/>
              <select-order-status v-model="order_status" :options="{ id: 'order_status', label: 'order_status', disabled: true }"/>
              <input-datetime v-model="created_at" :options="{ id: 'created_at', label: 'created_at', disabled: true }"/>
              <input-datetime v-if="expired_at" v-model="expired_at" :options="{ id: 'expired_at', label: 'expired_at', disabled: true }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ALLOCATION_WINDOWS_SHOW as CRUD_ACTION_SHOW } from 'actions/allocation_windows';
  const CRUD_CODE="allocation_windows";
  export default {
    name: "AllocationWindowShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: `${CRUD_CODE}`,
        id:this.$router.currentRoute.params.id,
        captain_id: '',
        allocation_window_id: '',
        earnings: '',
        status: '',
        captain_name: '',
        captain_mobile: '',
        order_id: '',
        order_status:"",
        created_at: '',
        expired_at: '',
        serverError: '',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.captain_id=response.data.captainId;
          this.allocation_window_id=response.data.allocationWindowId;
          this.earnings=response.data.earnings;
          this.status=response.data.status;
          this.captain_name=response.data.captainName;
          this.captain_mobile=response.data.captainMobile;
          this.order_id=response.data.orderId;
          this.order_status=response.data.orderStatus;
          this.created_at=response.data.createdAt?this.$moment(response.data.createdAt).format("Y-MM-DDTHH:mm"):"";
          this.expired_at=response.data.expiredAt?this.$moment(response.data.expiredAt).format("Y-MM-DDTHH:mm"):"";
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
