<template>
    <a href="javascript:;"
       class="text-gray px-1"
       :data-target="modalSelector"
       data-toggle="modal"
       @click="setActiveParams(index,itemID)"
       v-b-tooltip.hover="{ animation: false}"
       :title="$t('fields.roles')"
    >
        <i class="fa fa-user-cog"></i>
    </a>
</template>

<script>
    export default {
        name: "UserActionsRoles",
        props: {
            itemID:{
                type: Number
            },
            index: {
                type: Number
            },
            modalSelector:{
                type:String,
                default:"#modal-user-roles"
            },
            additionalParams:{
                type:Object,
                default:function(){
                    return {};
                }
            }
        },
        data(){
            return {}
        },
        methods:{
            setActiveParams(index,id) {
                this.$emit('input',{
                    activeId : id,
                    activeIndex : index,
                    ...this.additionalParams,
                });
            },
        }
    }
</script>

<style scoped>

</style>
