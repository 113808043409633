<template>
     <a href="javascript:;"
        class="text-purple px-1"
        :data-target="modalSelector"
        data-toggle="modal"
        @click="setActiveParams(index,itemID)"
        v-b-tooltip.hover="{ animation: false}"
        :title="$t('messages.update_item_status')"
     >
         <i class="fas fa-tasks"></i>
     </a>
 </template>

 <script>
     export default {
         name: "SupplierReportActionsChangeStatus",
         props: {
             itemID:{
                 type: Number
             },
             index: {
                 type: Number
             },
             modalSelector:{
                 type:String,
                 default:"#modal-change-status-report"
             },
             additionalParams:{
                 type:Object,
                 default:function(){
                     return {};
                 }
             }
         },
         data(){
             return {}
         },
         methods:{
             setActiveParams(index,id) {
                 this.$emit('input',{
                     activeId : id,
                     activeIndex : index,
                     ...this.additionalParams
                 });
             },
         }
     }
 </script>

 <style scoped>

 </style>
