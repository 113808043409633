<template>
    <multiselect
            v-bind:class="{'is-invalid':defaultOptions.error}"
            ref="multiSelect"
            :id="defaultOptions.id"
            :disabled="defaultOptions.disabled"
            :multiple="defaultOptions.multiple"
            :track-by="defaultOptions.keyField"
            v-model="selectedValue"
            @input="updateValue"
            @select="onSelectHandler"
            @remove="defaultOptions.onRemoveHandler"
            :options="showSelectAll ? [{ label : 'labels.select_all' , items }] : items"
            :searchable="true"
            :custom-label="customLabel"
            :close-on-select="true"
            :show-labels="false"
            :loading="defaultOptions.isLoading"
            :allow-empty="defaultOptions.allowEmpty"
            :hide-selected="defaultOptions.hideSelected"
            :group-values="groupValuesKey"
            :group-label="groupLabelKey"
            :group-select="showSelectAll"
            :placeholder="$t(defaultOptions.placeholder)">

        <template slot="option" slot-scope="props">{{ props.option.$isLabel ? $t(props.option.$groupLabel) : customLabel(props.option) }}</template>

        <template slot="tag" slot-scope="{ option, remove }">
            <span class="multiselect__tag" v-bind:class="{'disabled':defaultOptions.disabled}">
                <span>{{ customLabel(option) }}</span>
                <i v-if="!defaultOptions.disabled" aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
            </span>
        </template>

        <span slot="noResult">{{ $t(defaultOptions.noResultMessage) }}</span>

        <template slot="clear" slot-scope="props">
            <div class="multiselect__clear" v-if="!isEmpty(selectedValue) && defaultOptions.allowEmpty && !defaultOptions.disabled" @mousedown.prevent.stop="clearSelected(props.search)"></div>
        </template>

        <template slot="afterList">
            <div v-observe-visibility="pagination"></div>
        </template>

    </multiselect>
</template>


<script>
    export default {
        name: "AbstractsDropdown",
        props: {
            value: {},
            items: {
                type: Array,
                default: () => []
            },
            options: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                selectedValue: null,
                showSelectAll: this.options ? this.options.canSelectAll : false,
                defaultOptions: {
                    id:                             "dropdown",
                    multiple:                       false,
                    disabled:                       false,
                    hideSelected:                   false,
                    placeholder:                    'placeholders.dropdown',
                    noResultMessage:                'messages.no_result_found',
                    allowEmpty:                     true,
                    canSelectAll:                   false,
                    keyField:                       "id",
                    error:                          "",
                    isLoading:                      false,
                    paginationHandler:              function(){},
                    customLabelHandler:             function(){},
                    onAddHandler:                   function(){},
                    onRemoveHandler:                function(){},
                    ...this.options
                }
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                if (this.isEmpty(newVal)) {
                    this.selectedValue = null;
                }
                this.getDefaultValue();
            },
            items: function (newVal, oldVal) {
                this.getDefaultValue();
            },
            selectedValue: function(newVal,oldVal){
                new Promise(resolve => setTimeout(resolve, 100)).then(()=>{
                    this.showSelectAll = this.defaultOptions.canSelectAll && this.isEmpty(newVal);
                });
            },
            options: {
                deep: true,
                handler: function (newVal, oldVal) {
                    this.defaultOptions = { ...this.defaultOptions, ...newVal};
                }
            }
        },
        computed: {
            computedCustomLabelHandler(){
                if (typeof this.defaultOptions.customLabelHandler === 'function')
                    return this.defaultOptions.customLabelHandler.bind(this);

                return ((value) => {
                    return this.$i18n.t(value.name);
                })
            },
            groupValuesKey(){
                return this.showSelectAll ? `items` : '';
            },
            groupLabelKey(){
                return this.showSelectAll ? `label` : '';
            }
        },
        methods: {
            pagination(isVisible) {
                this.defaultOptions.paginationHandler(isVisible);
            },
            customLabel(value) {
                return this.computedCustomLabelHandler(value);
            },
            updateValue(value) {
                if (this.defaultOptions.multiple) {
                    let items = value.map(item => item[this.defaultOptions.keyField]);
                    this.$emit('input', items);
                } else {
                    this.$emit('input', value ? value[this.defaultOptions.keyField] : value);
                }
            },
            isEmpty(value) {
                return ((!Array.isArray(value) && !value) || (Array.isArray(value) && value.length === 0));
            },
            getDefaultValue() {

                if (!this.isEmpty(this.value) && !this.isSameSelected()) {
                    if (this.defaultOptions.multiple) {
                        this.selectedValue = this.items.filter(item => (Array.isArray(this.value) && this.convertToStringArray(this.value).includes("" + item[this.defaultOptions.keyField])) || "" + item[this.defaultOptions.keyField] === "" + this.value);
                    } else {
                        this.selectedValue = this.items.find(item => "" + item[this.defaultOptions.keyField] === "" + this.value);
                    }
                    if (!this.isSameSelected()) {
                        this.pagination(true);
                    }
                }
                return this.selectedValue;
            },
            isSameSelected() {
                if (this.defaultOptions.multiple) {
                    let value = this.convertToArray(this.value),
                        selectedValue = this.convertToArray(this.selectedValue);
                    return selectedValue.length === value.length;
                } else {
                    let value = "" + this.value;
                    let selectedValue = this.selectedValue != null ? "" + this.selectedValue[this.defaultOptions.keyField] : null;
                    return selectedValue === value;
                }
            },
            convertToArray(value) {

                if (!Array.isArray(value)) {
                    if (value == null) {
                        value = [];
                    } else {
                        value = [this.value];
                    }
                }

                return value;
            },
            convertToStringArray(value) {
              return value.map(val => "" + val);
            },
            clearSelected() {
                this.onRemoveAll(this.selectedValue);
                this.selectedValue = null;
                this.$emit('input', null);
            },
            onRemoveAll(selectedItems) {
                Object.keys(selectedItems).forEach(key => {
                    this.defaultOptions.onRemoveHandler(selectedItems[key]);
                });
            },
            onSelectHandler(selectedItems) {
                if(this.$refs.multiSelect.closeOnSelect) this.$refs.multiSelect.deactivate();
                this.defaultOptions.onAddHandler(selectedItems);
            },
        }
    }
</script>

<style scoped>

</style>
