<template>
    <abstract-input-container v-model="selectedValue" :options="{...defaultOptions, ...options}">
        <template v-slot:hint>
            <slot name="hint"></slot>
        </template>
    </abstract-input-container>
</template>

<script>
    export default {
        name: "FieldsInputNumber",
        props: {
            value: {},
            options: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                selectedValue: this.value,
                defaultOptions: {
                    id:                                     "input-number",
                    type:                                   "number",
                    step:                                   "any",
                    ...this.options
                }
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            },
            options: {
                deep: true,
                handler: function (newVal, oldVal) {
                    this.defaultOptions = { ...this.defaultOptions, ...newVal};
                }
            }
        },
    }
</script>

<style scoped>

</style>
