<template>
    <a href="javascript:;" @click.prevent="cancel(id)" class="text-orange px-1" v-b-tooltip.hover="{ animation: false}" :title="$t(title)">
        <i class="fas fa-reply"></i>
    </a>
</template>

<script>
    import {FUTURE_ALLOCATION_CONFIGS_DELETE} from "actions/allocation_configs";
    export default {
        name: "CancelFutureAllocationConfig",
        props: {
            id: {
                type: Number
            },
            title: {
                type: String
            },
            successCallback: {
                type: Function,
                default: () => { }
            },
        },
        data() {
            return {}
        },
        methods:{
            cancel(id){
                this.$store.dispatch(FUTURE_ALLOCATION_CONFIGS_DELETE, { id })
                .then(() => {
                    this.successCallback();
                    swal({
                        icon: 'success',
                        text: this.$i18n.t("messages.cancel_successfully"),
                        buttons:false,
                        timer: 5000
                    });
                })
                .catch(error => {
                    let message="";
                    if(error.response){
                        let responseData=error.response.data;

                        if(responseData.errors && responseData.errors.error){
                            message = responseData.errors.error;
                        }
                        else{
                            message = this.$i18n.t("messages.cancel_error");
                        }
                    }
                    if(message){
                        swal({
                            icon: 'error',
                            text: message,
                            buttons:false,
                            timer: 5000
                        });
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
