<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <files-image v-model="image" :options="{ id: 'image', label: 'image', error: fieldErrors.image }"/>
            </div>
            <div class="row" v-if="isItemFound()">
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { ENVELOPES_SHOW as CRUD_ACTION_SHOW,ENVELOPES_UPDATE as CRUD_ACTION_UPDATE } from 'actions/envelopes';
  const CRUD_CODE="envelopes";
  export default {
    name: "EnvelopeEdit",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        image:{
          file:'',
          thumbnail:'',
        },
        serverError: '',
        fieldErrors: {},
        responseStatus:'',
        formMode: 'edit',
        loading: false,
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.code=response.data.code;
          this.name=response.data.name;
          this.swift_code=response.data.swiftCode;
          this.country_id=response.data.country?response.data.country.id:null;
          this.image.thumbnail=response.data.image?response.data.image.url:'';
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      prepareRequestData(){
        return {
          ...this.prepareFile('image',"image"),
        }
      },
      update() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE, {
          id: this.id,
          data: {
            ...this.prepareRequestData()
          },
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    },
  };
</script>
