<template>
  <div class="mb-4">
    <div class="row">
      <div class="col-12">
        <div class="text-center h3">
          Acceptance And Delivery Rates Of Captains
        </div>
      </div>
    </div>
    <div class="row chart-container">
      <div class="col-12">
        <v-chart class="chart" :option="option" />
      </div>
    </div>
  </div>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {TreemapChart} from "echarts/charts";
import {LegendComponent, TitleComponent, TooltipComponent} from "echarts/components";
import VChart, { INIT_OPTIONS_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  TreemapChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

const CRUD_CODE = "monitoring";
export default {
  name: "AcceptanceRatesChart",
  props: {
    chartData: {
      type: Array
    }
  },
  components: {
    VChart
  },
  provide: {
    [INIT_OPTIONS_KEY]: {
      height: 600
    }
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_CODE,
      fieldErrors: {},
      loading: false,
      option: {
        color: ['#942e38', '#aaa', '#269f3c'],
        title: {
          text: "",
          left: "center",
        },
        tooltip: {
          formatter: (params) => [
            params.name,
            "Average Acceptance Rate: " + ((params.value[0] * 100).toFixed(0) + "%"),
            "Average Delivery Rate: " + ((params.value[1] * 100).toFixed(0) + "%"),
            "Average Delivery Time: " + (params.value[2]?.toFixed(0) + " MINS")
          ].join("<br>")
        },
        series: {
          type: "treemap",
          width: "100%",
          height: "100%",
          leafDepth: 1,
          visualMin: 0.8,
          visualMax: 1,
          visualDimension: 1,
          colorMappingBy: 'value',
          color: ['#942e38', '#aaa', '#269f3c'],
          upperLabel: {
            show: true,
            height: 30,
            formatter: (params) => params.name
          },
          label: {
            position: 'insideTopLeft',
            formatter: (params) => [
              params.name,
              "Average Acceptance Rate: " + ((params.value[0] * 100).toFixed(0) + "%"),
              "Average Delivery Rate: " + ((params.value[1] * 100).toFixed(0) + "%"),
              "Average Delivery Time: " + (params.value[2]?.toFixed(0) + " MINS")
            ].join("\n")
          },
          itemStyle: {
            gapWidth: 4
          },
          data: []
        }
      }
    }
  },
  mounted() {
    this.option.series.data = this.buildCaptainsData(this.chartData);
  },
  methods: {
    buildCaptainsData(chartData) {
      const data = [];
      const captains = chartData;

      for (const captain of captains) {
        if (!this.option.series.name) {
          this.option.title.text = captain.countryName;
          this.option.series.name = captain.countryName;
        }

        let city = data.find(e => e.name === captain.cityName);
        if (!city) {
          city = { name: captain.cityName, children: [], value: [0, 0, 0, 0, 0, 0, 0] };
          data.push(city);
        }

        const supplierName = captain.supplierName || "FREE_LANCER";
        let supplier = city.children.find(e => e.name === supplierName);
        if (!supplier) {
          supplier = { name: supplierName, children: [], value: [0, 0, 0, 0, 0, 0, 0] };
          city.children.push(supplier);
        }

        supplier.children.push({
          name: captain.captainId + ": " + captain.captainName,
          value: this.getCaptainValue(captain),
          label: this.getCaptainLabel(captain),
          tooltip: this.getCaptainTooltip(captain)
        });
      }
      data.forEach(city => this.calculateAverageValues(city))
      return data;
    },
    calculateAverageValues(data) {
      if (data.children) {
        let count = 0;
        let sum = 0;
        data.children
            .map(e => this.calculateAverageValues(e))
            .map(e => {
              data.value[3] += e.value[3];
              data.value[4] += e.value[4];
              data.value[6] += e.value[6];
              if (e.value[2] > 0) {
                count += e.value[6];
                sum += e.value[2] * e.value[6];
              }
            });
        data.value[0] = data.value[3] / (data.value[3] + data.value[4]);
        data.value[1] = data.value[6] / data.value[3];
        if (count > 0) {
          data.value[2] = sum / count;
        }
      }

      return data;
    },
    getCaptainValue(captain) {
      return [
        captain.acceptedOrdersCount / (captain.acceptedOrdersCount + captain.notAcceptedOrdersCount),
        captain.deliveredOrdersCount / captain.acceptedOrdersCount,
        captain.averageDeliveryTime,
        captain.acceptedOrdersCount,
        captain.notAcceptedOrdersCount,
        captain.reassignedOrdersCount,
        captain.deliveredOrdersCount,
        captain.inProgressOrdersCount,
        captain.cancelledOrdersCount
      ];
    },
    getCaptainInfo(captain) {
      return [
        captain.captainId + ": " + captain.captainName,
        "Acceptance Rate: " + (((captain.acceptedOrdersCount / (captain.acceptedOrdersCount + captain.notAcceptedOrdersCount)) * 100).toFixed(0) + "%"),
        "Delivery Rate: " + (((captain.deliveredOrdersCount / captain.acceptedOrdersCount) * 100).toFixed(0) + "%"),
        "Average Delivery Time: " + (captain.averageDeliveryTime ? captain.averageDeliveryTime.toFixed(0) + " MINS" : "UNKNOWN"),
        "Total: " + (captain.acceptedOrdersCount + captain.notAcceptedOrdersCount),
        "Accepted: " + captain.acceptedOrdersCount,
        "Not Accepted: " + captain.notAcceptedOrdersCount,
        "Reassigned: " + captain.reassignedOrdersCount,
        "Delivered: " + captain.deliveredOrdersCount,
        "In Progress: " + captain.inProgressOrdersCount,
        "Cancelled: " + captain.cancelledOrdersCount,
      ];
    },
    getCaptainLabel(captain) {
      return {
        position: 'insideTopLeft',
        formatter: () => this.getCaptainInfo(captain).join("\n")
      };
    },
    getCaptainTooltip(captain) {
      return {
        formatter: () => this.getCaptainInfo(captain).join("<br>")
      };
    }
  },
  watch: {
    chartData: function(newVal) {
      this.option.series.data = this.buildCaptainsData(newVal);
    }
  }
};
</script>

<style scoped>
.chart-container {
  height: 600px
}
</style>