<template>
    <textarea v-if="['textarea'].includes(defaultOptions.type)"
            v-model="selectedValue"
            :id="defaultOptions.id"
            :disabled="defaultOptions.disabled"
            :readonly="defaultOptions.readonly"
            :autocomplete="defaultOptions.autocomplete ? 'on' :'off'"
            :maxlength="defaultOptions.maxLength"
            @keydown="onKeydownHandler"
            @change="onChangeHandler"
            :rows="defaultOptions.rows"
            :type="defaultOptions.type"
            :step="defaultOptions.step"
            :min="defaultOptions.min"
            :max="defaultOptions.max"
            :name="defaultOptions.id"
            :placeholder="getPlaceholder()"
            v-bind:class="[{ 'is-invalid': defaultOptions.error }, defaultOptions.inputClass]"
            @keydown.space="(event) => defaultOptions.preventSpace ? event.preventDefault(): false"
    ></textarea>

    <label v-else-if="['checkbox-custom'].includes(defaultOptions.type)" class="custom-toggle">
        <abstract-input v-model="selectedValue" :options="{...defaultOptions, ...options, ...{type: 'checkbox'}}"/>
        <span class="custom-toggle-slider rounded-circle" :data-label-off="$t(defaultOptions.offLabel)" :data-label-on="$t(defaultOptions.onLabel)"></span>
    </label>

    <input v-else
           v-model="selectedValue"
           :id="defaultOptions.id"
           :disabled="defaultOptions.disabled"
           :readonly="defaultOptions.readonly"
           :autocomplete="defaultOptions.autocomplete ? 'on' :'off'"
           :maxlength="defaultOptions.maxLength"
           @keydown="onKeydownHandler"
           @change="onChangeHandler"
           :type="defaultOptions.type"
           :step="defaultOptions.step"
           :min="defaultOptions.min"
           :max="defaultOptions.max"
           :name="defaultOptions.id"
           :accept="defaultOptions.accept"
           :placeholder="getPlaceholder()"
           v-bind:class="[{ 'is-invalid': defaultOptions.error }, defaultOptions.inputClass]"
           @keydown.space="(event) => defaultOptions.preventSpace ? event.preventDefault(): false"
    >
</template>

<script>
    export default {
        name: "AbstractsInput",
        props: {
            value: {},
            options: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                selectedValue: this.value,
                defaultOptions: {
                    id:                                     "input-text",
                    type:                                   "text",
                    disabled:                               false,
                    preventSpace:                           false,
                    error:                                  "",
                    inputClass:                             "form-control",
                    onKeydownHandler:                       () => {},
                    onKeydownParams:                        {},
                    onChangeHandler:                        () => {},
                    onChangeParams:                         {},
                    ...this.options
                }
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            },
            options: {
                deep: true,
                handler: function (newVal, oldVal) {
                    this.defaultOptions = { ...this.defaultOptions, ...newVal};
                }
            }
        },
        methods: {
            getPlaceholder() {
                return this.defaultOptions.placeholder ? this.$i18n.t(this.defaultOptions.placeholder) : "";
            },
            onKeydownHandler(event) {
                return this.defaultOptions.onKeydownHandler(event,this.defaultOptions.onKeydownParams);
            },
            onChangeHandler(event) {
                return this.defaultOptions.onChangeHandler(event,this.defaultOptions.onChangeParams);
            }
        }
    }
</script>

<style scoped>

</style>
