<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title"> </card-header>
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <files-single v-model="contract" :options="{ id: 'contract', transPrefix: `${transPrefix}.fields`, label: 'contract', error: fieldErrors.contract, disabled: !can(permissionPrefix,'edit'), containerClass: 'col-12 col-md-4' }"/>
              <files-single v-model="cr" :options="{ id: 'cr', transPrefix: `${transPrefix}.fields`, label: 'cr', error: fieldErrors.cr, disabled: !can(permissionPrefix,'edit'), containerClass: 'col-12 col-md-4' }"/>
              <files-single v-model="vat_certificate" :options="{ id: 'vat_certificate', transPrefix: `${transPrefix}.fields`, label: 'vat_certificate', error: fieldErrors.vatCertificate, disabled: !can(permissionPrefix,'edit'), containerClass: 'col-12 col-md-4' }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `platforms.index` }" class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading"  v-can:edit="permissionPrefix">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { PLATFORMS_FILES_SHOW as CRUD_ACTION_SHOW, PLATFORMS_FILES_UPDATE as CRUD_ACTION_UPDATE } from "actions/platforms";
const PLATFORMS_CODE = "platforms";
const CRUD_CODE = `${PLATFORMS_CODE}.files`;
const CRUD_PERMISSION_CODE = `platform_files`;
export default {
  name: "PlatformFilesShow",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: `${CRUD_PERMISSION_CODE}`,
      platformsRoutePrefix: `${PLATFORMS_CODE}`,
      platformsTransPrefix: `${PLATFORMS_CODE}`,
      id: this.$router.currentRoute.params.id,
      contract:{
        file:'',
        thumbnail:'',
        original_name:'',
      },
      vat_certificate:{
        file:'',
        thumbnail:'',
        original_name:'',
      },
      cr:{
        file:'',
        thumbnail:'',
        original_name:'',
      },
      fieldErrors: {},
      serverError: "",
      responseStatus: "",
      loading: false,

    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    isItemFound(){
      return this.responseStatus && this.responseStatus!==404;
    },
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          platformId: this.id,
        })
        .then((response) => {
          if(response.data.contract){
            this.contract.thumbnail=response.data.contract.url;
            this.contract.original_name=response.data.contract.originalFileName;
          }

          if(response.data.vatCertificate){
            this.vat_certificate.thumbnail=response.data.vatCertificate.url;
            this.vat_certificate.original_name=response.data.vatCertificate.originalFileName;
          }

          if(response.data.cr){
            this.cr.thumbnail=response.data.cr.url;
            this.cr.original_name=response.data.cr.originalFileName;
          }
          this.responseStatus=response.status;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    prepareRequestData(){
      return {
        ...this.prepareFile('contract',"contract"),
        ...this.prepareFile('cr',"cr"),
        ...this.prepareFile('vat_certificate',"vatCertificate"),
      }
    },
    update() {
      this.loading = true;
      this.$store.dispatch(CRUD_ACTION_UPDATE, {
        platformId: this.id,
        data: {
          ...this.prepareRequestData()
        },
      })
      .then(() => {
        swal({
          icon: 'success',
          text: this.$i18n.t("messages.update_successfully"),
          buttons:false,
          timer: 5000
        });
        this.loading = false;
        this.contract.file = "";
        this.cr.file = "";
        this.vat_certificate.file = "";
        this.$router.push({ name: `${this.platformsRoutePrefix}.index` });
      })
      .catch(error => {
        this.loading = false
        this.fieldErrors={};
        this.serverError='';

        if(error.response){
          this.responseStatus=error.response.status;
          let responseData=error.response.data;

          if(responseData.errors){
            this.fieldErrors=responseData.errors;
          }else{
            this.serverError = 'Invalid Request.'
          }
        }else{
          this.serverError = this.$i18n.t("messages.no_internet_connection");
        }

        if(this.fieldErrors.error){
          this.serverError = this.fieldErrors.error;
        }
      })
    },
  },
};
</script>
