<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="change">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <input-password v-model="current_password" :options="{ id: 'current_password', label: 'current_password', error: fieldErrors.oldPassword }"/>
            </div>
            <div class="row">
              <input-password v-model="new_password" :options="{ id: 'new_password', label: 'new_password', error: fieldErrors.newPassword }"/>
              <input-password v-model="new_password_confirmed" :options="{ id: 'new_password_confirmed', label: 'new_password_confirmed', error: fieldErrors.newPasswordConfirmed }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.me`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { PROFILE_CHANGE_PASSWORD as CRUD_ACTION_CHANGE_PASSWORD } from 'actions/profile';
  const CRUD_CODE="profile";
  export default {
    name: "ProfileChangePassword",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        current_password: '',
        new_password: '',
        new_password_confirmed: '',
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    methods: {
      prepareRequestData(){
        return {
          oldPassword: this.sanitizeField('current_password'),
          newPassword: this.sanitizeField('new_password'),
          newPasswordConfirmed: this.sanitizeField('new_password_confirmed'),
        }
      },
      change() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CHANGE_PASSWORD, {
          ...this.prepareRequestData(),
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.updated_password_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.me` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    }
  };
</script>
