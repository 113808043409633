import {
    SUPPLIERS_LIST as CRUD_ACTION_LIST,
    SUPPLIERS_CREATE as CRUD_ACTION_CREATE,
    SUPPLIERS_SHOW as CRUD_ACTION_SHOW,
    SUPPLIERS_UPDATE as CRUD_ACTION_UPDATE,
    SUPPLIERS_DELETE as CRUD_ACTION_DELETE,
    SUPPLIERS_TYPE_LIST as CRUD_ACTION_TYPE_LIST,
    SUPPLIERS_USERS_LIST,
    SUPPLIERS_USERS_CREATE,
    SUPPLIERS_USERS_SHOW,
    SUPPLIERS_USERS_DELETE,
    SUPPLIERS_ATTACH_CAPTAIN,
    SUPPLIERS_DE_ATTACH_CAPTAIN,
    SUPPLIERS_DETAILS_SHOW,
    SUPPLIERS_DETAILS_UPDATE,
    SUPPLIERS_CONFIGS_SHOW,
    SUPPLIERS_CONFIGS_UPDATE,
    SUPPLIERS_FILES_SHOW,
    SUPPLIERS_FILES_UPDATE,
    SUPPLIERS_REPORTS_LIST,
    SUPPLIERS_REPORTS_CREATE,
    SUPPLIERS_REPORTS_SHOW,
    SUPPLIERS_REPORTS_STATUS_LIST,
    SUPPLIERS_REPORTS_UPDATE_STATUS,
    SUPPLIERS_OF_CAPTAIN
} from "../actions/suppliers";
import axioses from "@/axios/config";
import i18n from "@/i18n";

const additionalHeaders={
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};

const routePrefix='suppliers';
const usersRoutePrefix='users';
const detailsRoutePrefix='details';
const configsRoutePrefix='billing_configs';
const filesRoutePrefix='files';
const reportsRoutePrefix='reports';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params })
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        if(["CAPTAINS_PLATFORM_DEDICATED"].includes(params.type) && !params.ownerId){
            reject({
                response:{
                    data:{
                        errors:{
                            ownerId:i18n.t('validation.required')
                        }
                    }
                }
            });
        } else {
            let fData=new FormData();
            Object.keys(params).forEach(key=>{
                if(typeof params[key] !== "undefined"  && params[key] !==null){
                    fData.append(key,params[key]);
                }
            });
            axioses.base
                .post(`/${routePrefix}/create`, fData,additionalHeaders)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        }
    });


  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData=new FormData();
        Object.keys(params.data).forEach(key=>{
            if(typeof params.data[key] !== "undefined"  && params.data[key] !==null){
                fData.append(key,params.data[key]);
            }
        });

        axioses.base
            .patch(`/${routePrefix}/${params.id}`,fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}/delete`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_USERS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.supplierId}/${usersRoutePrefix}`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_USERS_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {

        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
        axioses.base
            .post(`/${routePrefix}/${params.supplierId}/${usersRoutePrefix}/create`, fData, additionalHeaders)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [SUPPLIERS_USERS_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.supplierId}/${usersRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_USERS_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}/${usersRoutePrefix}/${params.user_id}/delete`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_OF_CAPTAIN]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          commit('START_LOADING', null, { root: true });
          axioses.base
              .get(`/${routePrefix}/captain/${params.captain_id}`)
              .then(response => {
                  resolve(response);
                  commit('STOP_LOADING', null, { root: true });
              })
              .catch(error => {
                  commit('STOP_LOADING', null, { root: true });
                  reject(error);
              });
      });
  },
  [SUPPLIERS_ATTACH_CAPTAIN]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.id}/attach/${params.captain_id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_DE_ATTACH_CAPTAIN]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.id}/de-attach/${params.captain_id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_DETAILS_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.supplierId}/${detailsRoutePrefix}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_DETAILS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.supplierId}/${detailsRoutePrefix}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_CONFIGS_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.supplierId}/${configsRoutePrefix}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_CONFIGS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.supplierId}/${configsRoutePrefix}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_FILES_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.supplierId}/${filesRoutePrefix}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_FILES_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData=new FormData();
        Object.keys(params.data).forEach(key=>{
            if(typeof params.data[key] !== "undefined"  && params.data[key] !==null){
                fData.append(key,params.data[key]);
            }
        });
        axioses.base
            .patch(`/${routePrefix}/${params.supplierId}/${filesRoutePrefix}`,fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_REPORTS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.supplierId}/${reportsRoutePrefix}`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_REPORTS_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .post(`/${routePrefix}/${params.supplierId}/${reportsRoutePrefix}/create`, params)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [SUPPLIERS_REPORTS_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.supplierId}/${reportsRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_REPORTS_STATUS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.supplierId}/${reportsRoutePrefix}/status`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [SUPPLIERS_REPORTS_UPDATE_STATUS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .patch(`/${routePrefix}/${params.supplierId}/${reportsRoutePrefix}/${params.id}/${params.status}`, {...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
