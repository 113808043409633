export const TICKETS_LIST = "TICKETS_LIST";
export const TICKETS_SHOW = "TICKETS_SHOW";
export const TICKETS_OWNER_TYPE_LIST = "TICKETS_OWNER_TYPE_LIST";
export const TICKETS_RELATED_TYPE_LIST = "TICKETS_RELATED_TYPE_LIST";
export const TICKETS_REFERENCE_TYPE_LIST = "TICKETS_REFERENCE_TYPE_LIST";
export const TICKETS_ROOTS_LIST = "TICKETS_ROOTS_LIST";
export const TICKETS_ROOTS_CREATE = "TICKETS_ROOTS_CREATE";
export const TICKETS_ROOTS_UPDATE = "TICKETS_ROOTS_UPDATE";
export const TICKETS_ROOTS_DELETE = "TICKETS_ROOTS_DELETE";
export const TICKETS_ROOTS_SHOW = "TICKETS_ROOTS_SHOW";
export const TICKETS_ROOTS_TYPE_LIST = "TICKETS_ROOTS_TYPE_LIST";
export const TICKETS_ROOTS_LANGUAGE_CODE_LIST = "TICKETS_ROOTS_LANGUAGE_CODE_LIST";
export const TICKETS_ROOTS_UPDATE_STATUS= "TICKETS_ROOTS_UPDATE_STATUS";
export const TICKETS_ROOTS_TYPES_LIST = "TICKETS_ROOTS_TYPES_LIST";
export const TICKETS_ROOTS_TYPES_CREATE = "TICKETS_ROOTS_TYPES_CREATE";
export const TICKETS_ROOTS_TYPES_UPDATE = "TICKETS_ROOTS_TYPES_UPDATE";
export const TICKETS_ROOTS_TYPES_SHOW = "TICKETS_ROOTS_TYPES_SHOW";
export const TICKETS_ROOTS_TYPES_UPDATE_STATUS= "TICKETS_ROOTS_TYPES_UPDATE_STATUS";
export const TICKETS_ROOTS_TYPES_ACTIONS_LIST = "TICKETS_ROOTS_TYPES_ACTIONS_LIST";
export const TICKETS_ROOTS_TYPES_ACTIONS_CREATE = "TICKETS_ROOTS_TYPES_ACTIONS_CREATE";
export const TICKETS_ROOTS_TYPES_ACTIONS_UPDATE = "TICKETS_ROOTS_TYPES_ACTIONS_UPDATE";
export const TICKETS_ROOTS_TYPES_ACTIONS_SHOW = "TICKETS_ROOTS_TYPES_ACTIONS_SHOW";
export const TICKETS_ROOTS_TYPES_ACTIONS_UPDATE_STATUS= "TICKETS_ROOTS_TYPES_ACTIONS_UPDATE_STATUS";
export const TICKETS_ROOTS_TYPES_ACTIONS_TYPE_LIST = "TICKETS_ROOTS_TYPES_ACTIONS_TYPE_LIST";

