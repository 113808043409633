import Index from "@/views/roles/index";
import Create from "@/views/roles/create";
import PermissionsEdit from "@/views/roles/permissions/edit";
import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='roles';
const PERMISSIONS_CODE='permissions';
const PERMISSION_PREFIX='settings';

const routes = [
  {
    path: `${CODE}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/create`,
    name:`${CODE}.create`,
    component: Create,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${PERMISSIONS_CODE}/edit`,
    name:`${CODE}.${PERMISSIONS_CODE}.edit`,
    component: PermissionsEdit,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${PERMISSIONS_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id'
          },
          {
            'title': `${CODE}.${PERMISSIONS_CODE}.title`
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
