<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <select-envelope-type v-model="envelope_type" :options="{ id: 'envelope_type', label: 'envelope_type', containerClass: 'col-12' }" />
            </div>
            <div v-if="envelope_type === 'ENVELOPE'" class="row">
              <files-image v-model="image" :options="{ id: 'image', label: 'image', error: fieldErrors.image }"/>
            </div>
            <div v-if="envelope_type" class="row">
              <select-countries v-model="country_id" :options="{ id: 'country_id', label: 'country', placeholder: 'placeholders.all', error: fieldErrors.countryId }"/>
              <select-cities v-model="city_id" :country_id="country_id" :options="{ id: 'city_id', label: 'city', placeholder: 'placeholders.all', disabled: !country_id, error: fieldErrors.cityId }"/>
            </div>
            <div v-if="envelope_type === 'ENVELOPE'" class="row">
              <input-number v-model="captain_id" :options="{ id: 'captain_id', step: 1, label: 'captain', error: fieldErrors.captainId }"/>
              <select-language-codes v-model="language_code" :options="{ id: 'language_code', label: 'language_code', error: fieldErrors.languageCode }"/>
            </div>
            <div v-if="envelope_type" class="row">
              <select-envelope-sender-id v-model="sender_id" :options="{ id: 'sender_id', label: 'envelope_sender_id', error: fieldErrors.senderId }"/>
            </div>
            <div v-if="envelope_type" class="row">
              <input-text v-model="title" :options="{ id: 'title', label: 'title', error: fieldErrors.title, containerClass: 'col-12' }"/>
              <input-textarea v-model="message" :options="{ id: 'message', label: 'message', error: fieldErrors.message, containerClass: 'col-12' }"/>
            </div>
            <div v-if="envelope_type === 'ENVELOPE'" class="row">
              <input-checkbox v-model="cloud_notify" :options="{ id: 'cloud_notify', transPrefix: `${transPrefix}.fields`, label: 'cloud_notify', error: fieldErrors.cloudNotify }"/>
              <input-checkbox v-model="sms_notify" :options="{ id: 'sms_notify', transPrefix: `${transPrefix}.fields`, label: 'sms_notify', error: fieldErrors.smsNotify }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading || !envelope_type">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { ENVELOPES_CREATE as CRUD_ACTION_CREATE } from 'actions/envelopes';

  const CRUD_CODE="envelopes";
  export default {
    name: "EnvelopeCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        envelope_type: '',
        title: '',
        language_code: '',
        sender_id: '',
        message: '',
        city_id: '',
        country_id: "",
        captain_id: this.$router.currentRoute.query.captain_id ? parseInt(this.$router.currentRoute.query.captain_id) : "",
        cloud_notify: false,
        sms_notify: false,
        image:{
          file:'',
          thumbnail:'',
        },
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    methods: {
      prepareRequestData(){
        return {
          type: this.sanitizeField('envelope_type'),
          languageCode: this.sanitizeField('language_code'),
          senderId: this.sanitizeField('sender_id'),
          title: this.sanitizeField('title'),
          message: this.sanitizeField('message'),
          countryId: this.sanitizeField('country_id'),
          cityId: this.sanitizeField('city_id'),
          captainId: this.sanitizeField('captain_id'),
          smsNotify: this.sanitizeField('sms_notify'),
          cloudNotify: this.sanitizeField('cloud_notify'),
          ...this.prepareFile('image',"image"),
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    }
  };
</script>
