<template>
  <div aria-labelledby="tasks-tab" class="tab-pane fade" id="tasks" role="tabpanel">
    <div class="row">
      <div class="col-12">
        <table-container :headers="headers">
          <tr :key="item.id" v-for="(item, index) in items">
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.rank }}</td>
            <td>{{ item.taskStatus }}</td>
            <td>{{ item.taskType }}</td>
            <td class="text-direction-ltr">
              {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }}
            </td>
            <td class="text-direction-ltr">
              {{ item.arrivedAt | moment("Y-MM-DD hh:mm:ss A") }}
            </td>
            <td class="text-direction-ltr">
              {{ item.completedAt | moment("Y-MM-DD hh:mm:ss A") }}
            </td>
            <td>
              <a @click.prevent="setActiveTask(item,index)" data-target="#modal-additional-information" v-b-tooltip.hover="{ animation: false}" :title="$t('crud.show')" class="text-info px-1" data-toggle="modal" href="javascript:;">
                <i class="fas fa-eye"></i>
              </a>
              <a @click.prevent="getTaskTimeline(item.id)" data-target="#modal-timeline" v-b-tooltip.hover="{ animation: false}" :title="$t('labels.timeline')" class="text-gray px-1" data-toggle="modal" href="javascript:;">
                <i class="fa fa-sort-numeric-down"></i>
              </a>
              <a v-if="item.taskStatus=='PENDING' || item.taskStatus=='STARTED' || item.taskStatus == 'NEAR_DESTINATION' || item.taskStatus == 'AT_DESTINATION' || item.taskStatus == 'LEFT_DESTINATION'" @click.prevent="setActiveTask(item,index)" data-target="#modal-task-change-status" v-b-tooltip.hover="{ animation: false}" :title="$t('labels.update_task')" v-can:update_task="permissionPrefix" class="text-green px-1" data-toggle="modal" href="javascript:;">
                <i class="fas fa-check-circle"></i>
              </a>
            </td>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">
              {{ $t("messages.no_result_found") }}
            </td>
          </tr>
        </table-container>
      </div>
    </div>

    <div aria-hidden="true" id="modal-additional-information" aria-labelledby="modal-additional-information" class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">
              {{ $t("labels.additional_information") }}
            </h6>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <input-text v-model="activeTask.collectAtDelivery" :options="{ id: 'collect_at_delivery', transPrefix: `${transPrefix}.fields`, label: 'collect_at_delivery', disabled: true }"/>
              <input-text v-model="activeTask.payAtPickup" :options="{ id: 'pay_at_pickup', transPrefix: `${transPrefix}.fields`, label: 'pay_at_pickup', disabled: true }"/>
              <input-text v-model="activeTaskAddress.description" :options="{ id: 'address_description', transPrefix: `${transPrefix}.fields`, label: 'address_description', disabled: true }"/>
              <input-text v-model="activeTaskAddress.email" :options="{ id: 'address_email', transPrefix: `${transPrefix}.fields`, label: 'address_email', disabled: true }"/>
              <input-text v-model="activeTaskAddress.phone" :options="{ id: 'address_phone', transPrefix: `${transPrefix}.fields`, label: 'address_phone', disabled: true }"/>
              <input-text v-model="activeTaskAddress.latitude" :options="{ id: 'address_latitude', transPrefix: `${transPrefix}.fields`, label: 'address_latitude', disabled: true }"/>
              <input-text v-model="activeTaskAddress.longitude" :options="{ id: 'address_longitude', transPrefix: `${transPrefix}.fields`, label: 'address_longitude', disabled: true }"/>
              <input-text v-if="activeTask.deliveryConfirmationCode" v-model="activeTask.deliveryConfirmationCode" :options="{ id: 'delivery_confirmation_code', transPrefix: `${transPrefix}.fields`, label: 'delivery_confirmation_code', disabled: true }"/>
              <files-image v-if="activeTask.receipt" v-model="activeTask.receipt" :options="{ id: 'receipt', transPrefix: `${transPrefix}.fields`, label: 'receipt', disabled: true }"/>
            </div>
            <div class="row">
              <div class="col-12">
                <h6 class="modal-title mb-3">
                  {{ $t("tabs.items") }}
                </h6>
                <table-container :headers="taskItemsTableHeaders">
                  <tr :key="item.id" v-for="item in activeTaskItems">
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.price }}</td>
                    <td>{{ item.quantity }}</td>
                  </tr>
                  <tr class="text-center" v-if="!activeTaskItems.length">
                    <td colspan="999">
                      {{ $t("messages.no_result_found") }}
                    </td>
                  </tr>
                </table-container>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary ml-auto" data-dismiss="modal" type="button">
              {{ $t("labels.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div aria-hidden="true" id="modal-timeline" aria-labelledby="modal-timeline" class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-timeline">
              {{ $t("labels.timeline") }}
            </h6>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="vertical-timeline vertical-timeline--animate" v-for="item in getItemSortedByDate">
                  <div class="vertical-timeline-element">
                    <div>
                      <span class="vertical-timeline-element-icon bounce-in" v-bind:style="{ color: item.statusColor }">
                        <i class="fas fa-dot-circle"></i>
                      </span>
                      <div class="vertical-timeline-element-content bounce-in">
                        <h4 class="timeline-title">{{ item.statusDisplayName }}</h4>
                        <h6 class="timeline-message" v-if="item.captainId">{{ $t('fields.captain_id') }} : {{ item.captainId }}</h6>
                        <p></p>
                        <span class="vertical-timeline-element-date text-direction-ltr">{{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary ml-auto" data-dismiss="modal" type="button">
              {{ $t("labels.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div aria-hidden="true" aria-labelledby="modal-task-change-status" class="modal fade" id="modal-task-change-status" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-task-change-status-title-default">
              {{ $t('labels.update_task')}}
            </h6>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form role="form" @submit.prevent="changeTaskStatus">
            <div class="modal-body">
              <div class="row">
                <div class="col-12 mb-4">
                  {{ $t('messages.update_task')}}
                </div>
                <div class="col-12">
                  <div v-if="serverError" class="alert alert-danger">
                    <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                  </div>
                </div>
              </div>
              <div class="row">
                <input-number v-if="isOnDemandPickUp(activeTask)" v-model="amount_paid" :options="{ id: 'task_amount_paid', label: 'amount', error: fieldErrors.amountPaid }"/>
                <input-text v-if="isNeedDeliveryConfirmationCode(activeTask)" v-model="delivery_confirmation_code" :options="{ id: 'task_delivery_confirmation_code', transPrefix: `${transPrefix}.fields`, label: 'delivery_confirmation_code', autocomplete: false, maxLength: activeTask.deliveryConfirmationCodeLength, onKeydownHandler: onlyDigits, onChangeHandler: onlyDigits, error: fieldErrors.deliveryConfirmationCode }"/>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary btn-sm ml-auto" data-dismiss="modal" type="button">
                {{ $t('labels.cancel') }}
              </button>
              <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                {{ $t('labels.yes') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ORDER_TASKS_UPDATE_STATUS as CRUD_ACTION_UPDATE_TASK, ORDERS_TASKS_TIMELINES_SHOW as TASKS_TIMELINES_SHOW } from "actions/orders";
  const CRUD_CODE = "orders";
  export default {
    name: "OrderTabsTasks",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      permissionPrefix:{
        type: String,
        default: CRUD_CODE
      },
      items: {
        type: Array,
        default: function(){
          return []
        }
      },
      updateTaskItemHandler: {
        type: Function,
        default: () => {
        }
      },
      headers: {
        type: Array,
        default: function(){
          return [
            {
              title: `fields.name`,
            },
            {
              title: `fields.description`,
            },
            {
              title: `${CRUD_CODE}.fields.rank`,
            },
            {
              title: `fields.status`,
            },
            {
              title: `fields.type`,
            },
            {
              title: `fields.created_at`,
            },
            {
              title: `fields.arrived_at`,
            },
            {
              title: `fields.completed_at`,
            },
            {
              title: `fields.actions`,
            },
          ]
        }
      },
      taskItemsTableHeaders: {
        type: Array,
        default: function(){
          return [
            {
              title: `fields.name`,
            },
            {
              title: `fields.description`,
            },
            {
              title: `fields.price`,
            },
            {
              title: `fields.quantity`,
            },
          ]
        }
      },
    },
    data(){
      return {
        activeTaskAddress: {},
        activeTask: {},
        activeTaskItems: [],
        activeTimelines: [],
        activeTaskIndex: "",
        delivery_confirmation_code:"",
        amount_paid:"",
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    computed:{
      getItemSortedByDate(){
        return this.activeTimelines.sort(function(a, b) {
          let dateA = new Date(a.createdAt),
                  dateB = new Date(b.createdAt);
          return dateA - dateB;
        });
      }
    },
    methods:{
      setActiveTask(item,index) {
        this.activeTaskAddress = item.address;
        this.activeTask = item;
        this.activeTaskItems = item.items;
        this.activeTaskIndex = index;
      },
      isOnDemandPickUp(item) {
        return item && ["ON_DEMAND_PICK_UP"].includes(item.taskType);
      },
      isNeedDeliveryConfirmationCode(item){
        return item && item.deliveryConfirmationCodeRequired;
      },
      prepareOnDemandData(){
        let data = {};
        if(this.isOnDemandPickUp(this.activeTask)){
          data.amountPaid = this.amount_paid;
        }

        return data;
      },
      prepareDeliveryConfirmationCodeData(){
        let data = {};
        if(this.isNeedDeliveryConfirmationCode(this.activeTask)){
          data.deliveryConfirmationCode = this.delivery_confirmation_code;
        }

        return data;
      },
      prepareRequestData(){
        return {
          id: this.activeTask.id,
          ...this.prepareOnDemandData(),
          ...this.prepareDeliveryConfirmationCodeData(),
        }
      },
      changeTaskStatus() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE_TASK, {
          ...this.prepareRequestData(),
        })
        .then((response) => {
          document.querySelector('#modal-task-change-status [data-dismiss]').click();
          this.updateTaskItemHandler(response.data,this.activeTaskIndex);
          swal({
            icon: "success",
            text: this.$i18n.t("messages.update_task_success"),
            buttons: false,
            timer: 5000,
          });
        })
        .catch((error) => {
          this.fieldErrors={};
          this.serverError='';

          if(error.response) {
            let responseData = error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = this.$i18n.t("messages.update_task_error")
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
        .finally(() => (this.loading = false));
      },
      getTaskTimeline(id, additionalParams={}) {
        this.activeTimelines = [];
        this.$store.dispatch(TASKS_TIMELINES_SHOW, {
          id,
          ...additionalParams
        })
        .then((response) => {
          this.activeTimelines = response.data;
        })
        .catch((error) => {
          let message = "";
          if (error.response) {
            let responseData = error.response.data;

            if (responseData.errors && responseData.errors.error) {
              message = responseData.errors.error;
            } else {
              message = this.$i18n.t("messages.update_error");
            }
          }
          if(message){
            swal({
              icon: "error",
              text: message,
              buttons: false,
              timer: 5000,
            });
          }
        });
      },
    }
  };
</script>
