import Vue from "vue";
// Make Text UpperCase
Vue.filter("uppercase", function(value) {
  return value.toUpperCase();
});

// Make Text Shorten
Vue.filter("shorten", function(value, length) {
  return value && value.length && value.length > length ? value.substring(0, length - 3) + "..." : value;
});

// Make Decimal To Digits Fraction
Vue.filter("twoDigitsFraction", function(value, fractions = 2) {
  let returnedValue=parseFloat(value).toFixed(fractions);
  return returnedValue !== "NaN" ? returnedValue : "-";
});
