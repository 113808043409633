import Index from "@/views/suppliers/index";
import Create from "@/views/suppliers/create";
import Show from "@/views/suppliers/show";
import UsersIndex from "@/views/suppliers/users/index";
import UsersCreate from "@/views/suppliers/users/create";
import UsersShow from "@/views/suppliers/users/show";
import DetailsShow from "@/views/suppliers/details/show";
import DetailsEdit from "@/views/suppliers/details/edit";
import ConfigsShow from "@/views/suppliers/configs/show";
import ConfigsEdit from "@/views/suppliers/configs/edit";
import FilesShow from "@/views/suppliers/files/show";
import ReportsIndex from "@/views/suppliers/reports/index";
import ReportsCreate from "@/views/suppliers/reports/create";

import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='suppliers';
const PERMISSION_PREFIX='suppliers';
const USERS_CODE='users';
const USERS_PERMISSION_PREFIX='supplier_users';
const DETAILS_CODE='details';
const DETAILS_PERMISSION_PREFIX='suppliers';
const CONFIGS_CODE='configs';
const CONFIGS_PERMISSION_PREFIX='supplier_configs';
const FILES_CODE='files';
const FILES_PERMISSION_PREFIX='supplier_files';
const REPORTS_CODE='reports';
const REPORTS_PERMISSION_PREFIX='supplier_reports';


const routes = [
  {
    path: `${CODE}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/create`,
    name:`${CODE}.create`,
    component: Create,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id`,
    name:`${CODE}.show`,
    component: Show,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `:id`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${USERS_CODE}`,
    name:`${CODE}.${USERS_CODE}.index`,
    component: UsersIndex,
    meta: {
      'can': [`${USERS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${USERS_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${USERS_CODE}.title`,
          },
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${USERS_CODE}/create`,
    name:`${CODE}.${USERS_CODE}.create`,
    component: UsersCreate,
    meta: {
      'can': [`${USERS_PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${USERS_CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${USERS_CODE}.title`,
          },
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${USERS_CODE}/:user_id`,
    name:`${CODE}.${USERS_CODE}.show`,
    component: UsersShow,
    meta: {
      'can': [`${USERS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${USERS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${USERS_CODE}.title`,
          },
          {
            'title': ':user_id',
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${DETAILS_CODE}`,
    name:`${CODE}.${DETAILS_CODE}.show`,
    component: DetailsShow,
    meta: {
      'can': [`${DETAILS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${DETAILS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${DETAILS_CODE}.title`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${DETAILS_CODE}/edit`,
    name:`${CODE}.${DETAILS_CODE}.edit`,
    component: DetailsEdit,
    meta: {
      'can': [`${DETAILS_PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${DETAILS_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${DETAILS_CODE}.title`,
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${CONFIGS_CODE}`,
    name:`${CODE}.${CONFIGS_CODE}.show`,
    component: ConfigsShow,
    meta: {
      'can': [`${CONFIGS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${CONFIGS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${CONFIGS_CODE}.title`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${CONFIGS_CODE}/edit`,
    name:`${CODE}.${CONFIGS_CODE}.edit`,
    component: ConfigsEdit,
    meta: {
      'can': [`${CONFIGS_PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${CONFIGS_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${CONFIGS_CODE}.title`,
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${FILES_CODE}`,
    name:`${CODE}.${FILES_CODE}.show`,
    component: FilesShow,
    meta: {
      'can': [`${FILES_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${FILES_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${FILES_CODE}.title`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${REPORTS_CODE}`,
    name:`${CODE}.${REPORTS_CODE}.index`,
    component: ReportsIndex,
    meta: {
      'can': [`${REPORTS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${REPORTS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${REPORTS_CODE}.title`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${REPORTS_CODE}/create`,
    name:`${CODE}.${REPORTS_CODE}.create`,
    component: ReportsCreate,
    meta: {
      'can': [`${REPORTS_PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${REPORTS_CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${REPORTS_CODE}.title`,
          },
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
