<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title">
          <router-link
            v-if="!serverError && fintech_id"
            :to="{ name: `${fintechsRoutePrefix}.edit`, params: { fintech_id } }"
            class="btn btn-primary btn-sm"
          >
            <i class="fa fa-edit"></i>
          </router-link>
        </card-header>
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div v-if="serverError" class="alert alert-danger">
                <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
              </div>
            </div>
          </div>
          <div class="row" v-if="!serverError">
            <input-text v-model="account_type" :options="{ id: 'account_type', transPrefix: `${fintechsTransPrefix}.fields`, label: 'account_type', disabled: true }"/>
            <input-text v-model="account_id" :options="{ id: 'account_id', transPrefix: `${fintechsTransPrefix}.fields`, label: 'account_id', disabled: true }"/>
          </div>
        </div>
        <!-- Card footer -->
        <div class="card-footer">
          <div class="row">
            <div class="col text-right">
              <router-link
                :to="{ name: `${routePrefix}.index` }"
                class="btn btn-neutral btn-sm"
              >
                <i class="fa fa-arrow-left"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FINTECHS_SHOW as CRUD_ACTION_SHOW } from "actions/fintechs";
const CRUD_CODE = "captains";
const FINTECHS_CODE = "fintechs";
export default {
  name: "FintechShow",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      fintechsRoutePrefix: `${CRUD_CODE}.${FINTECHS_CODE}`,
      fintechsTransPrefix: `${CRUD_CODE}.${FINTECHS_CODE}`,
      id: this.$router.currentRoute.params.id,
      fintech_id: "",
      account_type: "",
      account_id: "",
      captain_id: "",
      serverError: "",
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          id: this.id,
        })
        .then((response) => {
          this.fintech_id = response.data.id;
          this.account_type = response.data.accountType;
          this.account_id = response.data.accountId;
          this.captain_id = response.data.captainId;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
  },
};
</script>
