<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${transactionsRoutePrefix}.index` }"  class="btn btn-primary btn-sm">
            <span class="d-none d-lg-inline mr-2">{{ $t(`${transactionsRoutePrefix}.title`)}}</span>
            <span>
              <i class="fa fa-money-bill"></i>
            </span>
          </router-link>
        </template>

        <template v-slot:card-header-search>
          <search v-model="lastPage" :listHandler="getList"/>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td> {{ item.id   }} </td>
            <td> {{ item.ownerType }} </td>
            <td> {{ item.ownerId }} </td>
            <td> {{ item.captainName }} </td>
            <td> {{ item.balanceWithCurrency }} </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>

        <template v-slot:card-footer>
          <export-action :crudCode="exportCode" v-can:report="permissionPrefix"></export-action>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import {mapGetters} from "vuex";
  import { WALLETS_LIST as CRUD_ACTION_LIST} from 'actions/wallets';
  import search from "./partials/search";
  const WALLET_TRANSACTION_CODE=`wallet_transactions`;
  const CRUD_CODE=`wallets`;
  const CRUD_PERMISSION_CODE=`${CRUD_CODE}`;
  export default {
    name: "WalletIndex",
    components:{
      search,
    },
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        transactionsRoutePrefix: `${WALLET_TRANSACTION_CODE}`,
        transactionsTransPrefix: `${WALLET_TRANSACTION_CODE}`,
        exportCode:`wallet`,
        tableHeaders:[
          {
            'title':`fields.id`
          },
          {
            'title':`fields.owner_type`
          },
          {
            'title':`fields.owner_id`
          },
          {
            'title':`fields.captain_name`
          },
          {
            'title':`fields.balance`
          },
          {
            'title':`fields.created_at`
          }
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0,
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    mounted() {
      this.getList();
    },
    computed:{
      ...mapGetters([
        "getSearchFilters","getSearchFiltersIfExists","getSearchFiltersSensitized"
      ])
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          data:{
            pageId: this.getCurrentPage()-1,
            ...this.getSearchFiltersSensitized
          },
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        });
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page,
              ...this.getSearchFiltersIfExists
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
    }
  };
</script>
