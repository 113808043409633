<template>
    <a href="javascript:;" @click.prevent="acceptPopup(index,itemID)" class="text-success px-1" v-b-tooltip.hover="{ animation: false}" :title="$t('labels.accept_item')">
        <i class="fas fa-check"></i>
    </a>
</template>

<script>
    import { WITHDRAW_REQUESTS_ACCEPT as CRUD_ACTION_ACCEPT} from 'actions/withdraw_requests';
    export default {
        name: "WithdrawRequestActionsAccept",
        props: {
            itemID:{
                type: Number
            },
            index: {
                type: Number
            },
            successCallback: {
                type: Function,
                default: () => { }
            },
        },
        data(){
            return {}
        },
        methods:{
            acceptPopup(index, id){
                swal({
                    title: this.$i18n.t("labels.accept_item"),
                    text: this.$i18n.t("messages.accept_item"),
                    icon: "warning",
                    showCancelButton: true,
                    closeOnConfirm: false,
                    buttons: [
                        this.$i18n.t("labels.cancel"),
                        this.$i18n.t("labels.submit"),
                    ],
                }).then((value) => {
                    if(value){
                        this.accept(index,id);
                    }
                });
            },
            accept(index, id){
                this.$store.dispatch(CRUD_ACTION_ACCEPT, {
                    id,
                })
                .then(() => {
                    this.successCallback();
                    swal({
                        icon: 'success',
                        text: this.$i18n.t("messages.accept_successfully"),
                        buttons:false,
                        timer: 5000
                    });
                })
                .catch(error => {
                    let message="";
                    if(error.response){
                        let responseData=error.response.data;

                        if(responseData.errors && responseData.errors.error){
                            message = responseData.errors.error;
                        }
                        else{
                            message = this.$i18n.t("messages.accept_error");
                        }
                    }
                    if(message){
                        swal({
                            icon: 'error',
                            text: message,
                            buttons:false,
                            timer: 5000
                        });
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
