<template>
    <span>
        <a href="javascript:;" class="btn btn-warning btn-sm" :data-target="modalSelector" data-toggle="modal">
            <span class="d-none d-lg-inline mr-2">
              {{ $t(`labels.reject`) }}
            </span>
            <span>
              <i class="fas fa-window-close"></i>
            </span>
        </a>
    </span>
</template>

<script>
    export default {
        name: "CaptainRegistrationRequestActionsReject",
        props: {
            itemID:{},
            modalSelector:{
                type:String,
                default:"#modal-reject-registration_requests"
            },
        },
        methods:{

        }
    }
</script>

<style scoped>

</style>