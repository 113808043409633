<template>
  <div class="mb-4">
    <div class="row">
      <div class="col-12">
        <div class="text-center h3">
          Suppliers Performance
        </div>
      </div>
    </div>
    <div class="row chart-container">
      <div class="col-12">
        <v-chart class="chart" :option="option" />
      </div>
    </div>
  </div>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {TreemapChart} from "echarts/charts";
import {LegendComponent, TitleComponent, TooltipComponent} from "echarts/components";
import VChart, {INIT_OPTIONS_KEY} from "vue-echarts";

use([
  CanvasRenderer,
  TreemapChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

const CRUD_CODE = "monitoring";
export default {
  name: "SuppliersPerformanceOrdersChart",
  props: {
    chartData: {
      type: Array
    }
  },
  components: {
    VChart
  },
  provide: {
    [INIT_OPTIONS_KEY]: {
      height: 600
    }
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_CODE,
      fieldErrors: {},
      loading: false,
      option: {
        color: ['#269f3c', '#aaa', '#942e38'],
        title: {
          text: "",
          left: "center",
        },
        tooltip: {
          formatter: (params) => {
            let label = params.name;
            if (params.value[0] !== undefined && params.value[1] !== undefined) {
              label += " (Total: " + params.value[0] + ", ADT: " + params.value[1]?.toFixed(0) + " MINS)";
            }
            return label;
          }
        },
        series: {
          type: "treemap",
          width: "100%",
          height: "100%",
          leafDepth: 1,
          visualMin: 15,
          visualMax: 75,
          visualDimension: 1,
          colorMappingBy: 'value',
          color: ['#269f3c', '#aaa', '#942e38'],
          upperLabel: {
            show: true,
            height: 30
          },
          label: {
            position: 'insideTopLeft',
            formatter: (params) => {
              let label = params.name;
              if (params.value[0] !== undefined && params.value[1] !== undefined) {
                label += " (Total: " + params.value[0] + ", ADT: " + params.value[1]?.toFixed(0) + " MINS)";
              }
              return label;
            }
          },
          itemStyle: {
            gapWidth: 4
          },
          data: []
        }
      }
    }
  },
  mounted() {
    this.option.series.data = this.buildOrdersData(this.chartData);
  },
  methods: {
    buildOrdersData(chartData) {
      const data = [];
      const orders = chartData;

      for (const order of orders) {
        if (!this.option.series.name) {
          this.option.title.text = order.countryName;
          this.option.series.name = order.countryName;
        }

        let supplier;
        if (order.captainId) {
          const supplierName = order.supplierName || "FREE_LANCER";
          supplier = data.find(e => e.name === supplierName);
          if (!supplier) {
            supplier = { name: supplierName, children: [], value: [0, 0] };
            data.push(supplier)
          }
        } else {
          continue;
        }

        let captain = supplier.children.find(e => e.name === (order.captainId + ": " + order.captainName));
        if (!captain) {
          captain = { name: order.captainId + ": " + order.captainName, children: [], value: [0, 0] };
          supplier.children.push(captain)
        }

        captain.children.push({
          name: order.backendId,
          value: [1, this.getDeliveryTime(order)],
          label: this.getOrderLabel(order),
          tooltip: this.getOrderTooltip(order)
        });
      }
      data.forEach(e => this.calculateValues(e));
      return data;
    },
    calculateValues(data) {
      if (data.children) {
        let count = 0;
        let sum = 0;
        data.children
            .map(e => this.calculateValues(e))
            .map(e => {
              data.value[0] += e.value[0];
              if (e.value[1] > 0) {
                count += e.value[0];
                sum += e.value[1] * e.value[0];
              }
            });
        if (count > 0) {
          data.value[1] = sum / count;
        }
      }
      if (data.value[1] === 0) {
        data.itemStyle = { color: "#aaa" };
      }
      return data;
    },
    getOrderInfo(order) {
      let orderInfo = ["#" + order.backendId];
      order.status && orderInfo.push("Status: " + order.status);
      order.updatedAt && orderInfo.push("Updated At: " + this.$moment(order.updatedAt).format("Y-MM-DD hh:mm:ss A"));
      order.brandName && orderInfo.push("Brand: " + order.brandName);
      order.captainId ? orderInfo.push("Captain ID: " + order.captainId) : orderInfo.push("NO DRIVER FOUND");
      order.captainName && orderInfo.push("Captain Name: " + order.captainName);
      order.supplierId && orderInfo.push("Supplier ID: " + order.supplierId);
      order.supplierName && orderInfo.push("Supplier Name: " + order.supplierName);
      const deliveryTime = this.getDeliveryTime(order);
      deliveryTime && orderInfo.push("Delivery Time: " + deliveryTime.toFixed(0) + " MINS");
      return orderInfo;
    },
    getOrderLabel(order) {
      return {
        position: 'insideTopLeft',
        formatter: () => this.getOrderInfo(order).join("\n")
      };
    },
    getOrderTooltip(order) {
      return {
        formatter: () => this.getOrderInfo(order).join("<br>")
      };
    },
    getDeliveryTime(order) {
      if (order.deliveryTime) {
        return order.deliveryTime;
      } else if (order.isAlive || order.isDelivered) {
        return this.getTimeDiff(order.createdAt);
      }
      return 0;
    },
    getTimeDiff(startTime) {
      let startDate = this.$moment(startTime),
          endDate   = this.$moment(this.currentDate);

      return this.$moment.duration(endDate.diff(startDate)).asMinutes();
    }
  },
  watch: {
    chartData: function(newVal) {
      this.option.series.data = this.buildOrdersData(newVal);
    }
  }
};
</script>

<style scoped>
.chart-container {
  height: 600px
}
</style>