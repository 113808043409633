<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <router-link v-if="!serverError" :to="{ name: `${routePrefix}.edit`,params:{id} }"  class="btn btn-primary btn-sm" v-can:edit="permissionPrefix">
            <i class="fa fa-edit"></i>
          </router-link>
        </card-header>
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <select-device-types v-model="device_type" :options="{ id: 'device_type', transPrefix:`${transPrefix}.fields`, label: 'device_type', disabled: true }"/>
              <input-number v-model="build_number" :options="{ id: 'build_number', step: 1, transPrefix: `${transPrefix}.fields`, label: 'build_number', disabled: true }"/>
              <input-text v-model="build_version" :options="{ id: 'build_version', transPrefix: `${transPrefix}.fields`, label: 'build_version', disabled: true }"/>
              <input-text v-model="store_url" :options="{ id: 'store_url', transPrefix: `${transPrefix}.fields`, label: 'store_url', disabled: true }"/>
              <select-update-actions v-model="update_action" :options="{ id: 'update_action', transPrefix:`${transPrefix}.fields`, label: 'update_action', disabled: true }"/>

            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { APP_VERSIONS_SHOW as CRUD_ACTION_SHOW } from 'actions/app_versions';
  const CRUD_CODE="app_versions";
  export default {
    name: "AppVersionShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        device_type: '',
        build_number: '',
        build_version: '',
        store_url: '',
        update_action: '',
        serverError: '',
        responseStatus:'',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.device_type=response.data.deviceType;
          this.build_number=response.data.buildNumber;
          this.build_version=response.data.buildVersion;
          this.store_url=response.data.storeUrl
          this.update_action=response.data.updateAction;
        }).catch(error => {
          this.loading = false
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
