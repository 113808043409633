<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <retry-bulk-action v-model="activeRetryBulkParams" :selected-elements-count="items.length" v-can:retry="permissionPrefix" />
          <countdown-action v-model="activeParams" v-can:view="`${permissionPrefix}_rounds`"/>
        </template>

        <template v-slot:card-header-search>
          <search v-model="lastPage" :listHandler="getList"/>
        </template>

        <template v-slot:table-rows>
          <tr v-for="(item,index) in items" :key="item.id">
            <td> {{ item.captain.id }} </td>
            <td> {{ item.captain.user.name }} </td>

            <td> {{ $t(`${transPrefix}.lists.status.${item.status}`) }} </td>
            <td> {{ item.walletId }} </td>
            <td> {{ $t(`${transPrefix}.lists.transaction_type.${item.transactionType}`) }} </td>
            <td> {{ item.amount }} </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <table-item-actions :actions="tableItemActions" :params="{'id':item.id}">
              <template v-slot:additional-items>
                <accept-action v-if="item.status==='PENDING'" :itemID="item.id" :index="index" :successCallback="getList" v-can:edit="permissionPrefix"/>
                <reject-action v-model="activeRejectParams" v-if="item.status==='PENDING' || item.status ==='ACCEPTED'" :itemID="item.id" :index="index" :successCallback="getList" v-can:edit="permissionPrefix"/>
                <retry-one-action v-model="activeRetryOneParams" v-if="item.status === 'FAILED'" :item="item" v-can:retry="permissionPrefix" />
              </template>
            </table-item-actions>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>

        <template v-slot:card-footer>
          <export-action :crudCode="routePrefix" v-can:report="permissionPrefix"></export-action>
        </template>
      </card-container>
      <reject-action-modal :activeRejectParams="activeRejectParams" :successCallback="getList" v-can:edit="permissionPrefix"/>
      <countdown-action-modal :activeParams="activeParams" v-can:view="`${permissionPrefix}_rounds`"/>
      <retry-bulk-action-modal :activeRetryBulkParams="activeRetryBulkParams" :successCallback="getList" v-can:retry="permissionPrefix" />
      <retry-one-action-modal :activeRetryOneParams="activeRetryOneParams" :successCallback="getList" v-can:retry="permissionPrefix" />
    </div>
  </div>
</template>
<script>

  import { mapGetters } from "vuex";
  import { WITHDRAW_REQUESTS_LIST as CRUD_ACTION_LIST } from 'actions/withdraw_requests';
  import search from "./partials/search";
  import acceptAction from "./actions/accept";
  import rejectAction from "./actions/reject";
  import rejectActionModal from "./actions/modals/reject";
  import countdownAction from "./actions/countdown";
  import countdownActionModal from "./actions/modals/countdown";
  import retryBulkAction from "./actions/retry-bulk";
  import retryBulkActionModal from "./actions/modals/retry-bulk"
  import retryOneAction from "./actions/retry-one";
  import retryOneActionModal from "./actions/modals/retry-one"
  const CRUD_CODE="withdraw_requests";
  const CRUD_PERMISSION_CODE=`${CRUD_CODE}`;
  export default {
    name: "WithdrawRequestIndex",
    components:{
      search,
      acceptAction,
      rejectAction,
      rejectActionModal,
      countdownAction,
      countdownActionModal,
      retryBulkAction,
      retryBulkActionModal,
      retryOneAction,
      retryOneActionModal
    },
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        tableHeaders:[
          {
            'title':`fields.captain_id`
          },
          {
            'title':`fields.captain_name`
          },
          {
            'title':`fields.status`
          },
          {
            'title':`fields.wallet_id`
          },
          {
            'title':`${CRUD_CODE}.fields.transaction_type`
          },
          {
            'title':`fields.amount`
          },
          {
            'title':`fields.created_at`
          },
          {
            'title':`fields.actions`
          }
        ],
        tableItemActions:[
          {
            code:'show',
            route_name:`${CRUD_CODE}.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
          },
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0,
        selectedItems:[],
        activeRejectParams:{},
        selectedAction:"",
        activeParams:{},
        activeRetryBulkParams: {},
        activeRetryOneParams: {}
      }
    },
    mounted() {
      this.getList();
    },
    computed:{
      ...mapGetters([
        "getSearchFilters","getSearchFiltersIfExists","getSearchFiltersSensitized"
      ]),
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          pageId: this.getCurrentPage()-1,
          ...this.getSearchFiltersSensitized
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
          this.selectedItems=[];
        });
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page,
              ...this.getSearchFiltersIfExists
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
    }
  };
</script>
