<template>
  <div class="mb-4">
    <div class="row">
      <div class="col-12">
        <div class="text-center h3">
          All-In-One Orders Statistics {{ selectedCategories.length ? "(" + selectedCategories.join(" -> ") + ")" : "" }}
        </div>
      </div>
      <select-chart-categories v-model="selectedCategories" />
    </div>
    <div class="row chart-container">
      <div class="col-12">
        <v-chart class="chart" :option="option" />
      </div>
    </div>
  </div>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {TreemapChart} from "echarts/charts";
import {LegendComponent, TitleComponent, TooltipComponent} from "echarts/components";
import VChart, { INIT_OPTIONS_KEY } from "vue-echarts";
import SelectChartCategories from "../partials/select-chart-categories";

use([
  CanvasRenderer,
  TreemapChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

const CRUD_CODE = "monitoring";
export default {
  name: "AllInOneOrdersChart",
  props: {
    chartData: {
      type: Array
    }
  },
  components: {
    VChart,
    SelectChartCategories
  },
  provide: {
    [INIT_OPTIONS_KEY]: {
      height: 600
    }
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_CODE,
      selectedCategories: [],
      categories: [
        { id: "city", fieldName: "cityName" },
        { id: "status", fieldName: "status" },
        { id: "brand", fieldName: "brandName" },
        { id: "supplier", fieldName: "supplierName" },
        { id: "captain", fieldName: "captainName" }
      ],
      option: {
        color: ['#269f3c', '#aaa', '#942e38'],
        title: {
          text: "",
          left: "center",
        },
        tooltip: {
          formatter: (params) => [
            params.name,
            "Total Orders: " + params.value[0],
            "Accepted Orders: " + params.value[1],
            "Delivered Orders: " + params.value[2],
            "Average Delivery Time: " + (params.value[3] ? params.value[3].toFixed(0) + " MINS" : "UNKNOWN"),
            "Acceptance Rate: " + ((params.value[1] / params.value[0]) * 100).toFixed(0) + "%",
            "Delivery Rate: " + (params.value[1] ? ((params.value[2] / params.value[1]) * 100).toFixed(0) + "%" : "UNKNOWN")
          ].join("<br>")
        },
        series: {
          type: "treemap",
          width: "100%",
          height: "100%",
          leafDepth: 1,
          visualMin: 15,
          visualMax: 75,
          visualDimension: 3,
          colorMappingBy: 'value',
          color: ['#269f3c', '#aaa', '#942e38'],
          upperLabel: {
            show: true,
            height: 30,
            formatter: (params) => params.name
          },
          label: {
            position: 'insideTopLeft',
            formatter: (params) => [
                params.name,
                "Total Orders: " + params.value[0],
                "Accepted Orders: " + params.value[1],
                "Delivered Orders: " + params.value[2],
                "Average Delivery Time: " + (params.value[3] ? params.value[3].toFixed(0) + " MINS" : "UNKNOWN"),
                "Acceptance Rate: " + ((params.value[1] / params.value[0]) * 100).toFixed(0) + "%",
                "Delivery Rate: " + (params.value[1] ? ((params.value[2] / params.value[1]) * 100).toFixed(0) + "%" : "UNKNOWN")
            ].join("\n")
          },
          itemStyle: {
            gapWidth: 4
          },
          data: []
        }
      }
    }
  },
  mounted() {
    this.selectedCategories = ["city", "status", "brand"];
    this.option.series.data = this.buildOrdersData(this.chartData);
  },
  methods: {
    buildOrdersData(chartData) {
      const data = { children: [] };
      const orders = chartData;
      const categories = this.selectedCategories.map(categoryId => this.categories.find(c => c.id === categoryId));

      for (const order of orders) {
        if (!this.option.series.name) {
          this.option.title.text = order.countryName;
          this.option.series.name = order.countryName;
        }

        let parent = data;
        categories.forEach((category) => {
          const fieldValue = order[category.fieldName] || "No " + category.id;
          let current = parent.children.find(e => e.name === fieldValue);
          if (!current) {
            current = { name: fieldValue, children: [], value: [0, 0, 0, 0] };
            parent.children.push(current);
          }

          parent = current;
        });

        parent.children.push({
          name: order.backendId,
          value: [1, order.captainId ? 1 : 0, order.isDelivered ? 1 : 0, this.getDeliveryTime(order)],
          label: this.getOrderLabel(order),
          tooltip: this.getOrderTooltip(order)
        });
      }
      data.children.forEach(e => this.calculateValues(e));
      return data.children;
    },
    calculateValues(data) {
      if (data.children) {
        let count = 0;
        let sum = 0;
        data.children
            .map(e => this.calculateValues(e))
            .map(e => {
              data.value[0] += e.value[0];
              data.value[1] += e.value[1];
              data.value[2] += e.value[2];
              if (e.value[3] > 0) {
                count += e.value[0];
                sum += e.value[3] * e.value[0];
              }
            });
        if (count > 0) {
          data.value[3] = sum / count;
        }
      }
      if (data.value[3] === 0) {
        data.itemStyle = { color: "#aaa" };
      }
      return data;
    },
    getOrderInfo(order) {
      let orderInfo = ["#" + order.backendId];
      order.status && orderInfo.push("Status: " + order.status);
      order.updatedAt && orderInfo.push("Updated At: " + this.$moment(order.updatedAt).format("Y-MM-DD hh:mm:ss A"));
      order.brandName && orderInfo.push("Brand: " + order.brandName);
      order.captainId ? orderInfo.push("Captain ID: " + order.captainId) : orderInfo.push("NO DRIVER FOUND");
      order.captainName && orderInfo.push("Captain Name: " + order.captainName);
      order.supplierId && orderInfo.push("Supplier ID: " + order.supplierId);
      order.supplierName && orderInfo.push("Supplier Name: " + order.supplierName);
      const deliveryTime = this.getDeliveryTime(order);
      deliveryTime && orderInfo.push("Delivery Time: " + deliveryTime.toFixed(0) + " MINS");
      return orderInfo;
    },
    getOrderLabel(order) {
      return {
        position: 'insideTopLeft',
        formatter: () => this.getOrderInfo(order).join("\n")
      };
    },
    getOrderTooltip(order) {
      return {
        formatter: () => this.getOrderInfo(order).join("<br>")
      };
    },
    getDeliveryTime(order) {
      if (order.deliveryTime) {
        return order.deliveryTime;
      } else if (order.isAlive || order.isDelivered) {
        return this.getTimeDiff(order.createdAt);
      }
      return 0;
    },
    getTimeDiff(startTime){
      let startDate = this.$moment(startTime),
          endDate   = this.$moment(this.currentDate);

      return this.$moment.duration(endDate.diff(startDate)).asMinutes();
    }
  },
  watch: {
    selectedCategories: function() {
      this.option.series.data = this.buildOrdersData(this.chartData);
    },
    chartData: function(newVal) {
      this.option.series.data = this.buildOrdersData(newVal);
    }
  }
};
</script>

<style scoped>
.chart-container {
  height: 600px
}
</style>