<template>
  <div>
    <div v-if="editable" class="row align-items-center">
      <div class="col"><hr /></div>
      <div class="col-auto">
        <a href="javascript;" class="btn btn-outline-info btn-sm" @click.prevent="addTier(0)"><i class="fa fa-plus"></i></a>
      </div>
    </div>
    <div v-for="(tier, index) in tiers" :key="index">
      <div class="row align-items-center">
        <h3 class="mx-3">{{ $t('allocation_configs.allocation_tier_title', [index + 1]) }}</h3>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <div class="row">
            <input-number v-model="tier.initialEarning" :options="{ id: 'initial_earning', transPrefix: `allocation_configs.fields`, label: 'initial_earning', disabled: !editable, error: fieldErrors[`tiers[${index}].initialEarning`] }"/>
            <input-number v-model="tier.perKm" :options="{ id: 'per_km', transPrefix: `allocation_configs.fields`, label: 'per_km', disabled: !editable, error: fieldErrors[`tiers[${index}].perKm`] }"/>
            <input-number v-model="tier.maxEarning" :options="{ id: 'max_earning', transPrefix: `allocation_configs.fields`, label: 'max_earning', disabled: !editable, error: fieldErrors[`tiers[${index}].maxEarning`] }"/>
            <input-number v-model="tier.kmStartsAfter" :options="{ id: 'km_start_after', transPrefix: `allocation_configs.fields`, label: 'km_start_after', disabled: !editable, error: fieldErrors[`tiers[${index}].kmStartsAfter`] }"/>
            <input-number v-model="tier.tierCompletionOrdersCount" :options="{ id: 'tier_completion_orders_count', transPrefix: `allocation_configs.fields`, label: 'tier_completion_orders_count', disabled: index + 1 === tiers.length || !editable, error: fieldErrors[`tiers[${index}].tierCompletionOrdersCount`] }"/>
            <input-number v-model="tier.tierCompletionBonus" :options="{ id: 'tier_completion_bonus', transPrefix: `allocation_configs.fields`, label: 'tier_completion_bonus', disabled: index + 1 === tiers.length || !editable, error: fieldErrors[`tiers[${index}].tierCompletionBonus`] }"/>
          </div>
        </div>
        <div v-if="editable" class="col-auto">
          <a href="javascript;" class="btn btn-outline-danger btn-sm" v-bind:class="{ 'disabled': tiers.length <= 1 }" @click.prevent="removeTier(index)"><i class="fa fa-trash"></i></a>
        </div>
      </div>
      <div v-if="editable" class="row align-items-center">
        <div class="col"><hr/></div>
        <div class="col-auto">
          <a href="javascript;" class="btn btn-outline-info btn-sm" @click.prevent="addTier(index + 1)"><i class="fa fa-plus"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AllocationConfigTiersList",
  props: {
    value: {},
    editable: {
      type: Boolean,
      default: false
    },
    fieldErrors: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      tiers: this.value
    };
  },
  watch: {
    value: function(newVal) {
      this.tiers = newVal;
    },
    tiers: function(newVal) {
      this.$emit("input", newVal);
    }
  },
  methods: {
    addTier(index) {
      const newTier = {
        initialEarning: 0,
        perKm: 0,
        maxEarning: 0,
        kmStartsAfter: 0,
        tierCompletionOrdersCount: null,
        tierCompletionBonus: null
      };
      this.tiers.splice(index, 0, newTier);
    },
    removeTier(index) {
      this.tiers.splice(index, 1);
      this.tiers[this.tiers.length - 1].tierCompletionOrdersCount = null;
      this.tiers[this.tiers.length - 1].tierCompletionBonus = null;
    }
  }
};
</script>

<style scoped>

</style>