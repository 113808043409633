<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <router-link v-if="!serverError" :to="{ name: `${routePrefix}.edit`,params:{id,type_id,action_id} }"  class="btn btn-primary btn-sm" v-can:edit="permissionPrefix">
            <i class="fa fa-edit"></i>
          </router-link>
        </card-header>
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <select-ticket-root-type-action-types v-model="type" :options="{ id: 'type', label: 'type', params: { ticketId: id, typeId: type_id }, disabled: true }"/>
            </div>
            <div class="row" v-if="!serverError">
              <input-checkbox v-model="is_auto" :options="{ id: 'is_auto', label: 'is_auto', disabled: true }"/>
              <input-checkbox v-model="enabled" :options="{ id: 'enabled', label: 'enabled', disabled: true }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { TICKETS_ROOTS_TYPES_ACTIONS_SHOW as CRUD_ACTION_SHOW } from 'actions/tickets';
  const TICKETS_CODE="tickets";
  const TICKETS_ROOTS_CODE=`${TICKETS_CODE}.roots`;
  const TICKETS_ROOTS_TYPES_CODE=`${TICKETS_ROOTS_CODE}.types`;
  const CRUD_CODE=`${TICKETS_ROOTS_TYPES_CODE}.actions`;
  const CRUD_PERMISSION_CODE=`${TICKETS_CODE}`;
  export default {
    name: "TicketRootTypeActionShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: `${CRUD_PERMISSION_CODE}`,
        ticketsRoutePrefix: TICKETS_CODE,
        ticketsTransPrefix: TICKETS_CODE,
        ticketRootsRoutePrefix: TICKETS_ROOTS_CODE,
        ticketRootsTransPrefix: TICKETS_ROOTS_CODE,
        ticketRootTypesRoutePrefix: TICKETS_ROOTS_TYPES_CODE,
        ticketRootTypesTransPrefix: TICKETS_ROOTS_TYPES_CODE,
        id:this.$router.currentRoute.params.id,
        type_id:this.$router.currentRoute.params.type_id,
        action_id:this.$router.currentRoute.params.action_id,
        type: '',
        is_auto: false,
        enabled: false,
        serverError: '',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          ticketId: this.id,
          typeId: this.type_id,
          id: this.action_id,
        }).then(response=>{
          this.type=response.data.type;
          this.is_auto=response.data.isAuto;
          this.enabled=response.data.enabled;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
