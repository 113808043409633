<template>
  <div class="mx-0 px-0 row">
    <div class="col-6">
      <h4>{{ id }}</h4>
    </div>
    <div class="col-6 text-right">
      <span class="btn badge mb-2" style="font-size: 0.75rem;" v-bind:class="{
          'bg-green text-white': getCaptainOrders.length === 0,
          'bg-yellow': getCaptainOrders.length === 1,
          'bg-orange text-white': getCaptainOrders.length > 1
        }" @click.stop="showHideCaptainOrders()">
        {{ getCaptainOrders.length === 0
            ? $t(`${captainTransPrefix}.lists.working_status.FREE`)
            : $t(`${captainTransPrefix}.lists.working_status.BUSY`) + " (" + getCaptainOrders.length + ")" }}
      </span>
    </div>
    <div class="col-6">
      <span>{{ name }}</span>
    </div>
    <div class="col-6 text-right">
      <span>{{ distanceToTargetPoint | twoDigitsFraction }} KM</span>
    </div>
    <div class="col-12">
      <span>{{ supplier }}</span>
    </div>
    <div v-if="showOrders && getCaptainOrders.length > 0" class="mt-2 p-2 bg-lighter">
      <div class="col-12 text-center">
        <span><strong>{{ $t(`${captainTransPrefix}.fields.orders`) }}</strong></span>
      </div>
      <div class="assignment-candidate-order mx-0 px-0 row" v-bind:class="{ 'too-late': isTooLate(item) }"
        v-for="item in getCaptainOrders" :key="item.id">
        <div class="col-6">
          <h4 class="order-id mb-0">{{ item.backendId }}</h4>
        </div>
        <div class="col-6 text-right">
          <span
            class="payment-type badge pb-1" v-bind:class="{'bg-danger text-white': item.paymentType == 'PREPAID', 'bg-green text-white': item.paymentType == 'CASH_ON_DELIVERY'}">
            {{ item.paymentType }}
          </span>
        </div>
        <div class="col-6">
          <span class="status font-size-12" v-bind:style="{ color: item.statusColor }">
            <strong> {{ item.status }} </strong>
          </span>
        </div>
        <div class="col-6 text-right">
          <span class="total-distance">
            {{ item.totalDistance }}
          </span>
        </div>
        <div class="col-6 text-left">
          <span class="platform">
            {{ item.brand || item.platform }}
          </span>
        </div>
        <div class="col-6 text-right">
          <span class="city">
            {{ item.city }}
          </span>
        </div>
        <div class="col-12">
          <span class="created-at font-size-12">
            <span class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }} </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  TRACKING_SET_HIGHLIGHTED_CANDIDATE
} from "actions/sockets/tracking";

export default {
  name: "TrackingAssignmentCandidate",
  props: {
    id: { type: Number },
    name: { type: String },
    distanceToTargetPoint: { type: Number },
    supplier: { type: String }
  },
  data() {
    return {
      captainTransPrefix: "captains",
      showOrders: false
    };
  },
  computed: {
    ...mapGetters(["getCaptains", "getOrders"]),
    getCaptainOrders() {
      return this.getCaptains[this.id].orders.map(orderId => this.getOrders[orderId]);
    }
  },
  methods: {
    showHideCaptainOrders() {
      this.showOrders = !this.showOrders;
    },
    isTooLate(order) {
      return this.$moment.duration(this.$moment(new Date()).diff(this.$moment(order.createdAt))).asMinutes() > 45;
    }
  }
};
</script>

<style type="text/css" scoped>
.scrollbar-inner .scroll-element.scroll-y {
  background-color: #e5e5e594 !important;
  width: 8px !important;
  opacity: 1 !important;
}

.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  opacity: unset !important;
}

.font-size-12 {
  font-size: 12px;
}
</style>
