<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <input-email v-model="billingEmail" :options="{ id: 'billingEmail', transPrefix: `${transPrefix}.fields`, label: 'billing_email', error: fieldErrors.billingEmail }"/>
              <input-text v-model="zipCode" :options="{ id: 'zipCode', transPrefix: `${transPrefix}.fields`, label: 'zip_code', error: fieldErrors.zipCode }"/>
              <input-text v-model="poBox" :options="{ id: 'poBox', transPrefix: `${transPrefix}.fields`, label: 'po_box', error: fieldErrors.poBox }"/>
              <input-text v-model="addressLine1" :options="{ id: 'addressLine1', transPrefix: `${transPrefix}.fields`, label: 'address_line_1', error: fieldErrors.addressLine1 }"/>
              <input-text v-model="addressLine2" :options="{ id: 'addressLine2', transPrefix: `${transPrefix}.fields`, label: 'address_line_2', error: fieldErrors.addressLine2 }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.show` }" class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  PLATFORM_DETAILS_SHOW as CRUD_ACTION_SHOW,
  PLATFORM_DETAILS_UPDATE as CRUD_ACTION_UPDATE,
} from "actions/platforms_portal/platform";

const PLATFORMS_CODE = "platforms";
const CRUD_CODE = `${PLATFORMS_CODE}.details`;
const CRUD_PERMISSION_CODE = `partner_platform`;

export default {
  name: "PlatformDetailEdit",
  data() {
    return {
      routePrefix: `platforms_portal.platform`,
      transPrefix: CRUD_CODE,
      permissionPrefix: `${CRUD_PERMISSION_CODE}`,
      platformsRoutePrefix: `${PLATFORMS_CODE}`,
      platformsTransPrefix: `${PLATFORMS_CODE}`,
      billingEmail: "",
      zipCode: "",
      poBox: "",
      addressLine1: "",
      addressLine2: "",
      serverError: "",
      fieldErrors: {},
      responseStatus: "",
      formMode: 'edit',
      loading: false,
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    isItemFound() {
      return this.responseStatus !== 404;
    },
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
        })
        .then((response) => {
          this.billingEmail = response.data.billingEmail;
          this.zipCode = response.data.zipCode;
          this.poBox = response.data.poBox;
          this.addressLine1 = response.data.addressLine1;
          this.addressLine2 = response.data.addressLine2;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    prepareRequestData() {
      return {
        billingEmail: this.sanitizeField("billingEmail"),
        zipCode: this.sanitizeField("zipCode"),
        poBox: this.sanitizeField("poBox"),
        addressLine1: this.sanitizeField("addressLine1"),
        addressLine2: this.sanitizeField("addressLine2"),
      };
    },
    update() {
      this.loading = true;
      this.$store
        .dispatch(CRUD_ACTION_UPDATE, {
          data: {
            ...this.prepareRequestData(),
          },
        })
        .then(() => {
          this.loading = false;
          swal({
            icon: "success",
            text: this.$i18n.t("messages.update_successfully"),
            buttons: false,
            timer: 5000,
          });
          this.$router.push({
            name: `${this.routePrefix}.show`
          });
        })
        .catch((error) => {
          this.loading = false;
          this.fieldErrors = {};
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
  },
};
</script>
