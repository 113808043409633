export const ALLOCATION_CONFIGS_LIST = "ALLOCATION_CONFIGS_LIST";
export const ALLOCATION_CONFIGS_CREATE = "ALLOCATION_CONFIGS_CREATE";
export const ALLOCATION_CONFIGS_UPDATE = "ALLOCATION_CONFIGS_UPDATE";
export const ALLOCATION_CONFIGS_DELETE = "ALLOCATION_CONFIGS_DELETE";
export const ALLOCATION_CONFIGS_SHOW = "ALLOCATION_CONFIGS_SHOW";
export const ALLOCATION_CONFIGS_TYPE_LIST = "ALLOCATION_CONFIGS_TYPE_LIST ";
export const ALLOCATION_CONFIGS_UPDATE_STATUS= "ALLOCATION_CONFIGS_UPDATE_STATUS";

export const FUTURE_ALLOCATION_CONFIGS_LIST = "FUTURE_ALLOCATION_CONFIGS_LIST";
export const FUTURE_ALLOCATION_CONFIGS_CREATE = "FUTURE_ALLOCATION_CONFIGS_CREATE";
export const FUTURE_ALLOCATION_CONFIGS_UPDATE = "FUTURE_ALLOCATION_CONFIGS_UPDATE";
export const FUTURE_ALLOCATION_CONFIGS_DELETE = "FUTURE_ALLOCATION_CONFIGS_DELETE";
export const FUTURE_ALLOCATION_CONFIGS_SHOW = "FUTURE_ALLOCATION_CONFIGS_SHOW";
export const FUTURE_ALLOCATION_CONFIGS_UPDATE_STATUS= "FUTURE_ALLOCATION_CONFIGS_UPDATE_STATUS";