<template>
    <span>
        <a class="btn btn-danger btn-sm" :data-target="modalSelector" v-b-tooltip.hover="{ animation: false}" :title="$t('labels.cancellation_reasons')" data-toggle="modal" href="javascript:;" >
          <i class="fas fa-ban"></i>
        </a>
    </span>
</template>

<script>
    export default {
        name: "CaptainRegistrationRequestActionsRejectReasons",
        props: {
            itemID:{},
            modalSelector:{
                type:String,
                default:"#modal-rejection-reasons"
            },
        },
        methods:{

        }
    }
</script>

<style scoped>

</style>
