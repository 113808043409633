import Index from "@/views/monitoring/index";
import CustomReport from "@/views/monitoring/report/custom_report";
import AssignmentMethodsReport from "@/views/monitoring/report/assignment_methods_report";
import { ifAuthenticated, commonBeforeEnter } from "../helper"

const CODE='monitoring';
const PERMISSION_PREFIX='monitoring';
const routes = [
  {
    path: `${CODE}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': ``,
        'show': true,
        'add_primary_color': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/report`,
    name:`${CODE}.report`,
    component: CustomReport,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_report`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.report_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `monitoring.create_report`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/assignment_methods_report`,
    name:`${CODE}.assignment_methods_report`,
    component: AssignmentMethodsReport,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_report`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.assignment_methods_report_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `monitoring.create_assignment_methods_report`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
