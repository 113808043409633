<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title">
          <router-link v-if="!serverError" :to="{ name: `${routePrefix}.edit`, params: { id } }" class="btn btn-primary btn-sm" v-can:edit="permissionPrefix">
            <i class="fa fa-edit"></i>
          </router-link>
        </card-header>
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div v-if="serverError" class="alert alert-danger">
                <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
              </div>
            </div>
          </div>
          <div class="row" v-if="!serverError">
            <input-number v-model="perTask" :options="{ id: 'perTask', transPrefix: `${transPrefix}.fields`, label: 'per_task', disabled: true }"/>
            <input-number v-model="kmStartAfter" :options="{ id: 'kmStartAfter', transPrefix: `${transPrefix}.fields`, label: 'km_start_after', disabled: true }"/>
            <input-number v-model="perKm" :options="{ id: 'perKm', transPrefix: `${transPrefix}.fields`, label: 'per_km', disabled: true }"/>
            <input-number v-model="maxOrderAmount" :options="{ id: 'maxOrderAmount', transPrefix: `${transPrefix}.fields`, label: 'max_order_amount', disabled: true }"/>
          </div>
        </div>
        <!-- Card footer -->
        <div class="card-footer">
          <div class="row">
            <div class="col text-right">
              <router-link :to="{ name: `suppliers.index` }" class="btn btn-neutral btn-sm">
                <i class="fa fa-arrow-left"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SUPPLIERS_CONFIGS_SHOW as CRUD_ACTION_SHOW } from "actions/suppliers";
const SUPPLIERS_CODE = "suppliers";
const CRUD_CODE = `${SUPPLIERS_CODE}.configs`;
const CRUD_PERMISSION_CODE = `supplier_configs`;
export default {
  name: "SupplierConfigShow",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: `${CRUD_PERMISSION_CODE}`,
      suppliersRoutePrefix: `${SUPPLIERS_CODE}`,
      suppliersTransPrefix: `${SUPPLIERS_CODE}`,
      id: this.$router.currentRoute.params.id,
      perTask: "",
      kmStartAfter: "",
      maxOrderAmount: "",
      perKm: "",
      serverError: "",
      responseStatus: "",
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          supplierId: this.id
        })
        .then((response) => {
          this.perTask = response.data.data.perTask;
          this.kmStartAfter = response.data.data.kmStartAfter;
          this.maxOrderAmount = response.data.data.maxOrderAmount;
          this.perKm = response.data.data.perKm;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
  },
};
</script>
