<template>
    <searchContainer :searchHandler="search" :resetHandler="reset">
        <template v-slot:items>
            <div class="row">
                <select-analysis-types v-model="filters.range" :options="{ id: 'analysis-type', label: 'range', placeholder: filters.range, allowEmpty: false }"/>
                <select-platforms v-model="filters.platform_id" :options="{ id: 'platform_id', label: 'platform', multiple: true, canSelectAll: true, error: fieldErrors.platformId }"/>
            </div>
        </template>
    </searchContainer>
</template>
<script>

    import {mapGetters} from "vuex";
    import {CRUD_SEARCH_RESET} from "actions/crud";
    import searchContainer from "@/components/crud/card/CardSearch";

    const CRUD_CODE = "analysis";
    export default {
        name: "AnalysisSearch",
        components: {
            searchContainer
        },
        props: {
            listHandler: {
                type: Function,
                default: () => {
                }
            },
            resetHandler: {
                type: Function,
                default: () => {
                }
            },
            fieldErrors: Object,
            default: () => {
            }
        },
        data() {
            return {
                transPrefix: CRUD_CODE,
                filters: {
                    range: this.getFilterByKey('range') ?? "YESTERDAY",
                    platform_id: this.getFilterByKey('platform_id'),

                },
                filtersMap: {
                    range: 'range',
                    platform_id: 'platformId',
                },
            }
        },
        mounted() {
            this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
        },
        computed: {
            ...mapGetters([
                "getSearchFilters"
            ]),
        },
        methods: {
            search() {
                this.resetPagination();
                this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                this.pushRoute(this.getSearchFilters);
                this.listHandler();
            },
            reset() {
                this.resetPagination();
                this.$store.dispatch(CRUD_SEARCH_RESET).then(() => {
                    this.filters.range = "YESTERDAY";
                    this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                    this.resetHandler();
                });
                this.filters = this.clearFilters(this.filters);
                this.pushRoute();
            },
            resetPagination() {
                //Reset Pagination
                this.$emit('input', 0);
            },
            pushRoute(query = {}) {
                this.$router.push({name: this.$route.name, query}).catch(() => {});
            },
            canSelectCountry() {
                return true;
            },
            canNotSelectCountry() {
                return !this.canSelectCountry();
            },
            canSelectCity() {
                return this.filters.country_id;
            },
            canNotSelectCity() {
                return !this.canSelectCity();
            },
        }
    };
</script>
