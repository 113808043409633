<template>
    <nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main">
        <div class="scrollbar-inner">
            <!-- Brand -->
            <div class="sidenav-header  align-items-center">
                <TopNavbarSideMenu tag="div"/>
                <a class="navbar-brand" href="javascript:void(0)">
                    <img src="@/assets/logo.png" class="navbar-brand-img" alt="...">
                </a>
            </div>
            <div class="navbar-inner pt-2">
                <!-- Collapse -->
                <div class="collapse navbar-collapse" id="sidenav-collapse-main">
                    <!-- Nav items -->
                    <ul class="navbar-nav">
                        <side-menu-item v-for="(item, index) in items" :item="item" :index="index"/>
                    </ul>
                </div>
            </div>
        </div>
    </nav>

</template>

<script>
import $ from "jquery";
import "js-cookie";
import "jquery.scrollbar";
import "jquery-scroll-lock";
import TopNavbarSideMenu from "./partials/TopNavbarSideMenu";
import SideMenuItem from "./SideMenuItem";
export default {
    name: "SideMenu",
    components: {
        TopNavbarSideMenu,
        SideMenuItem
    },
    data() {
        return {
            items: [
                {
                    label: 'dashboard.title',
                    route: 'dashboard.index',
                    icon: 'ni ni-tv-2',
                    color: 'text-primary',
                },
                {
                  label: 'monitoring.title',
                  route: 'monitoring.index',
                  icon: 'fa fa-chart-pie',
                  color: 'text-red',
                  permission: 'monitoring_can_view',
                  badge: {
                    color: 'badge-warning',
                    label: 'labels.beta',
                  },
                },
                {
                    label: 'analysis.title',
                    route: 'analysis.index',
                    icon: 'ni ni-tv-2',
                    color: 'text-teal',
                    permission: 'analysis_can_view',
                    badge: {
                        color: 'badge-warning',
                        label: 'labels.beta',
                    },
                },
                {
                    label: 'tracking.title',
                    route: 'tracking.index',
                    icon: 'fa fa-map-signs',
                    color: 'text-gray',
                    permission: 'tracking_can_view',
                },
                {
                    label: 'platforms.title',
                    route: 'platforms.index',
                    icon: 'fa fa-store',
                    color: 'text-gray-dark',
                    permission: 'platforms_can_view',
                },
                {
                    label: 'orders.title',
                    route: 'orders.index',
                    icon: 'fa fa-shopping-cart',
                    color: 'text-green',
                    permission: 'orders_can_view',
                },
                {
                    label: 'allocation_windows.title',
                    route: 'allocation_windows.index',
                    icon: 'fas fa-people-carry',
                    color: 'text-yellow',
                    permission: 'allocation_windows_can_view',
                },
                {
                    label: 'orders.title',
                    route: 'suppliers_portal.orders.index',
                    icon: 'fa fa-shopping-cart',
                    color: 'text-green',
                    permission: 'supplier_orders_can_view',
                },
                {
                    label: 'orders.title',
                    route: 'platforms_portal.orders.index',
                    icon: 'fa fa-shopping-cart',
                    color: 'text-green',
                    permission: 'partner_orders_can_view',
                },
                {
                    label: 'labels.manual_dispatch',
                    route: 'platforms_portal.orders.create',
                    icon: 'fa fa-cart-plus',
                    color: 'text-blue',
                    permission: 'partner_orders_can_create',
                },
                {
                    label: 'platforms.invoices.title',
                    route: 'platforms_portal.invoices.index',
                    icon: 'fas fa-file-invoice',
                    color: 'text-yellow',
                    permission: 'partner_invoice_can_view',
                },
                {
                    label: 'fields.platform',
                    route: 'platforms_portal.platform.show',
                    icon: 'fa fa-store-alt',
                    color: 'text-gray-dark',
                    permission: 'partner_platform_can_view',
                },
                {
                    label: 'platforms.branches.title',
                    route: 'platforms_portal.branches.index',
                    icon: 'fa fa-store',
                    color: 'text-teal',
                    permission: 'partner_branches_can_view',
                },
                {
                    label: 'captains.title',
                    route: 'captains.index',
                    icon: 'fa fa-truck',
                    color: 'text-blue',
                    permission: 'captains_can_view',
                },
                {
                  label: 'tracking.title',
                  route: 'suppliers_portal.tracking.index',
                  icon: 'fa fa-map-signs',
                  color: 'text-gray',
                  permission: 'supplier_tracking_can_view',
                },
                {
                    label: 'captains.title',
                    route: 'suppliers_portal.captains.index',
                    icon: 'fa fa-truck',
                    color: 'text-blue',
                    permission: 'supplier_captains_can_view',
                },
                {
                  label: 'captain_working_summaries.title',
                  route: 'suppliers_portal.captain_working_summaries.index',
                  icon: 'fa fa-briefcase',
                  color: 'text-pink',
                  permission: 'supplier_captain_working_summaries_can_view',
                },
                {
                    label: 'fields.supplier',
                    route: 'suppliers_portal.supplier.show',
                    icon: 'fa fa-charging-station',
                    color: 'text-gray-dark',
                    permission: 'supplier_supplier_can_view',
                },
                {
                    label: 'suppliers.reports.title',
                    route: 'suppliers_portal.supplier_reports.index',
                    icon: 'fas fa-file-invoice',
                    color: 'text-red',
                    permission: 'supplier_supplier_reports_can_view',
                },
                {
                    label: 'envelopes.title',
                    route: 'envelopes.index',
                    icon: 'fa fa-envelope',
                    color: 'text-danger',
                    permission: 'captain_envelopes_can_view',
                },
                {
                    label: 'withdraw_requests.title',
                    route: 'withdraw_requests.index',
                    icon: 'fa fa-cash-register',
                    color: 'text-success',
                    permission: 'withdraw_requests_can_view',
                },
                {
                    label: 'wallets.title',
                    route: 'wallets.index',
                    icon: 'fas fa-wallet',
                    color: 'text-primary',
                    permission: 'wallets_can_view',
                },
                {
                    label: 'users.title',
                    route: 'users.index',
                    icon: 'fa fa-users',
                    color: 'text-orange',
                    permission: 'users_can_view',
                },
                {
                    label: 'users.title',
                    route: 'platforms_portal.users.index',
                    icon: 'fa fa-users',
                    color: 'text-orange',
                    permission: 'partner_users_can_view',
                },
                {
                    label: 'platforms.apps.title',
                    route: 'platforms_portal.apps.index',
                    icon: 'fa fa-mobile-alt',
                    color: 'text-dark',
                    permission: 'partner_apps_can_view',
                },
                {
                    label: 'countries.title',
                    route: 'countries.index',
                    icon: 'ni ni-pin-3',
                    color: 'text-primary',
                    permission: 'countries_can_view',
                },
                {
                    label: 'banks.title',
                    route: 'banks.index',
                    icon: 'fa fa-money-bill',
                    color: 'text-yellow',
                    permission: 'banks_can_view',
                },
                {
                    label: 'captains_registration_requests.title',
                    route: 'captains_registration_requests.index',
                    icon: 'fa fa-file',
                    color: 'text-teal',
                    permission: 'captains_registration_requests_can_view',
                },
                {
                    label: 'tickets.title',
                    route: 'tickets.index',
                    icon: 'fa fa-ticket-alt',
                    color: 'text-gray',
                    permission: 'tickets_can_view',
                },
                {
                    label: 'captain_working_summaries.title',
                    route: 'captain_working_summaries.index',
                    icon: 'fa fa-briefcase',
                    color: 'text-pink',
                    permission: 'captain_working_summary_can_view',
                },
                {
                    label: 'suppliers.title',
                    route: 'suppliers.index',
                    icon: 'fas fa-charging-station',
                    color: 'text-indigo',
                    permission: 'suppliers_can_view',
                },
                {
                    label: 'captain_bonuses.title',
                    route: 'captain_bonuses.index',
                    icon: 'fas fa-gifts',
                    color: 'text-red',
                    permission: 'captain_bonuses_can_view',
                },
                {
                    label: 'referral_programs.title',
                    route: 'referral_programs.index',
                    icon: 'fa fa-user-friends',
                    color: 'text-green',
                    permission: 'referral_programs_can_view',
                },
                {
                    label: 'app_versions.title',
                    route: 'app_versions.index',
                    icon: 'fa fa-mobile-alt',
                    color: 'text-dark',
                    permission: 'app_versions_can_view',
                },
                {
                  label: 'audit_logs.title',
                  route: 'audit_logs.index',
                  icon: 'fa fa-cog',
                  color: 'text-dark',
                  permission: 'audit_logs_can_view',
                },
                {
                    label: 'settings.title',
                    route: 'settings.index',
                    icon: 'fas fa-cog',
                    color: 'text-gray',
                    permission: 'settings_can_view',
                    code: 'settings',
                    items: [
                        {
                            label: 'allocation_configs.title',
                            route: 'allocation_configs.index',
                            icon: 'fa fa-map-marker',
                            color: 'text-gray',
                        },
                        {
                            label: 'withdraw_configs.title',
                            route: 'withdraw_configs.index',
                            icon: 'fa fa-money-bill',
                            color: 'text-gray',
                        },
                        {
                            label: 'roles.title',
                            route: 'roles.index',
                            icon: 'fa fa-user-cog',
                            color: 'text-gray',
                        },
                    ]
                },
            ],

        }
    },
    mounted() {
        $('.scrollbar-inner,.scrollbar-outer').scrollbar().scrollLock();
    },
}
</script>

<style scoped>

</style>
