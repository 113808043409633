import { render, staticRenderFns } from "./fintech_type.vue?vue&type=template&id=341fd8ad&scoped=true&"
import script from "./fintech_type.vue?vue&type=script&lang=js&"
export * from "./fintech_type.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "341fd8ad",
  null
  
)

export default component.exports