<template>
    <searchContainer :searchHandler="search" :resetHandler="reset">
        <template v-slot:items>
            <div class="row">
                <select-captain-bonus-types v-model="filters.type" :options="{ id: 'captain_bonus_type', label: 'type', placeholder: 'placeholders.all' }"/>
                <select-captain-bonus-owner-types v-model="filters.owner_type" :options="{ id: 'captain_bonus_owner_type', label: 'owner_type', placeholder: 'placeholders.all' }"/>
                <select-countries v-model="filters.country_id" :options="{ id: 'county_id', label: 'country', placeholder: 'placeholders.all', disabled: canNotSelectCountry() }"/>
                <select-cities v-model="filters.city_id" :country_id="filters.country_id" :options="{ id: 'city_id', labels: 'city', placeholder: 'placeholders.all', disabled: canNotSelectCity() }"/>
                <select-zones v-model="filters.zone_id" :city_id="filters.city_id" :options="{ id: 'zone_id', label: 'zone', placeholder: 'placeholders.all', disabled: canNotSelectZone() }"/>
                <select-yes-no v-model="filters.enabled" :options="{ id: 'enabled', label: 'enabled', placeholder: 'placeholders.all' }"/>
            </div>
            <div class="row">
                <input-date v-model="filters.from_date" :options="{ id: 'from_date', label: 'from_date' }"/>
                <input-date v-model="filters.to_date" :options="{ id: 'to_date', label: 'to_date' }"/>
                <input-date v-model="filters.created_from" :options="{ id: 'created_from', label: 'created_from' }"/>
                <input-date v-model="filters.created_to" :options="{ id: 'created_to', label: 'created_to' }"/>
            </div>
        </template>
    </searchContainer>
</template>
<script>

    import {mapGetters} from "vuex";
    import {CRUD_SEARCH_RESET} from "actions/crud";
    import searchContainer from "@/components/crud/card/CardSearch";

    const CRUD_CODE = "captain_bonuses";
    export default {
        name: "CaptainBonusSearch",
        components: {
            searchContainer
        },
        props: {
            listHandler: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                transPrefix: CRUD_CODE,
                filters: {
                    type: this.getFilterByKey('type'),
                    owner_type: this.getFilterByKey('owner_type'),
                    enabled: this.getFilterByKey('enabled'),
                    from_date: this.getFilterByKey('from_date'),
                    to_date: this.getFilterByKey('to_date'),
                    country_id: this.getFilterByKey('country_id'),
                    city_id: this.getFilterByKey('city_id'),
                    zone_id: this.getFilterByKey('zone_id'),
                    created_from: this.getFilterByKey('created_from'),
                    created_to: this.getFilterByKey('created_to'),
                    owner_id: this.getFilterByKey('owner_id'),
                },
                filtersMap: {
                    type: 'type',
                    owner_type: 'ownerType',
                    owner_id: 'ownerId',
                    enabled: 'enabled',
                    from_date: 'fromDate',
                    to_date: 'toDate',
                    created_from: 'startDate',
                    created_to: 'endDate',
                },
            }
        },
        mounted() {
            this.setSearchParams(this.filters, this.filtersMap);
        },
        computed: {
            ...mapGetters([
                "getSearchFilters"
            ]),
            owner_id() {
                let owner_id;
                switch (this.filters.owner_type) {
                    case "COUNTRY":
                        owner_id = this.filters.country_id;
                        break;
                    case "CITY":
                        owner_id = this.filters.city_id;
                        break;
                    case "ZONE":
                        owner_id = this.filters.zone_id;
                        break;
                }
                return owner_id;
            },
            owner_type(){
                return this.filters.owner_type;
            }
        },
        watch: {
            owner_id: function (newVal, oldVal) {
                this.filters.owner_id = newVal;
            },
            owner_type: function (newVal, oldval) {
                switch (newVal) {
                    case "COUNTRY":
                        this.filters.city_id = null;
                        this.filters.zone_id = null;
                        break;
                    case "CITY":
                        this.filters.zone_id = null;
                        break;
                    case "ZONE":
                        break;
                    default:
                        this.filters.country_id = null;
                        this.filters.city_id = null;
                        this.filters.zone_id = null;
                        break;
                }
            }
        },
        methods: {
            search() {
                this.resetPagination();
                this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                this.pushRoute(this.getSearchFilters);
                this.listHandler();
            },
            reset() {
                this.resetPagination();
                this.$store.dispatch(CRUD_SEARCH_RESET);
                this.filters = this.clearFilters(this.filters);
                this.pushRoute();
                this.listHandler();
            },
            resetPagination() {
                //Reset Pagination
                this.$emit('input', 0);
            },
            pushRoute(query = {}) {
                this.$router.push({name: this.$route.name, query}).catch(() => {});
            },
            canSelectCountry() {
                return ["COUNTRY", "CITY", "ZONE"].includes(this.filters.owner_type);
            },
            canNotSelectCountry() {
                return !this.canSelectCountry();
            },
            canSelectCity() {
                return ["CITY", "ZONE"].includes(this.filters.owner_type) && this.filters.country_id;
            },
            canNotSelectCity() {
                return !this.canSelectCity();
            },
            canSelectZone() {
                return ["ZONE"].includes(this.filters.owner_type) && this.filters.city_id && this.filters.country_id;
            },
            canNotSelectZone() {
                return !this.canSelectZone();
            }
        }
    };
</script>
