import {
    ENVELOPES_LIST as CRUD_ACTION_LIST,
    ENVELOPES_CREATE as CRUD_ACTION_CREATE,
    ENVELOPES_SHOW as CRUD_ACTION_SHOW,
    ENVELOPES_UPDATE as CRUD_ACTION_UPDATE,
    ENVELOPES_DELETE as CRUD_ACTION_DELETE,
    ENVELOPES_UPDATE_STATUS as CRUD_ACTION_UPDATE_STATUS,
    ENVELOPES_TYPE_LIST,
    ENVELOPES_SENDER_ID_LIST
} from "../actions/envelopes";
import axioses from "@/axios/config";
import qs from "qs"
const additionalHeaders={
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};
const routePrefix='captains/envelopes';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
        axioses.base
            .post(`/${routePrefix}/create`, fData,additionalHeaders)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE_STATUS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let statusList={false:'DISABLE',true:'ENABLE'};

        axioses.base
            .patch(`/${routePrefix}/${params.id}/${statusList[params.value]}`, {...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ENVELOPES_TYPE_LIST]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          commit('START_LOADING', null, { root: true });
          axioses.base
              .get(`/${routePrefix}/types`)
              .then(response => {
                  resolve(response);
                  commit('STOP_LOADING', null, { root: true });
              })
              .catch(error => {
                  commit('STOP_LOADING', null, { root: true });
                  reject(error);
              });
      });
  },
  [ENVELOPES_SENDER_ID_LIST]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          commit('START_LOADING', null, { root: true });
          axioses.base
              .get(`/${routePrefix}/sender_ids`)
              .then(response => {
                  resolve(response);
                  commit('STOP_LOADING', null, { root: true });
              })
              .catch(error => {
                  commit('STOP_LOADING', null, { root: true });
                  reject(error);
              });
      });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
