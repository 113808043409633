<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <select-envelope-type v-model="envelope_type" :options="{ id: 'envelope_type', label: 'envelope_type', containerClass: 'col-12', disabled: true }" />
            </div>
            <div class="row" v-if="!serverError && envelope_type === 'ENVELOPE'">
              <files-image v-model="image" :options="{ id: 'image', disabled: true }"/>
            </div>
            <div v-if="!serverError && envelope_type" class="row">
              <select-countries v-model="country_id" :options="{ id: 'country_id', label: 'country', placeholder: 'placeholders.all', disabled: true }"/>
              <select-cities v-model="city_id" :country_id="country_id" :options="{ id: 'city_id', label: 'city', placeholder: 'placeholders.all', disabled: true }" />
            </div>
            <div v-if="!serverError && envelope_type === 'ENVELOPE'" class="row">
              <input-text :value="captain_name ? captain_name : $t('placeholders.all')" :options="{ id: 'captain_name', label: 'captain', disabled: true }"/>
              <input-text v-model="language_code" :options="{ id: 'language_code', label: 'language_code', disabled: true }"/>
            </div>
            <div class="row" v-if="!serverError && envelope_type">
              <input-text v-model="title" :options="{ id: 'title', label: 'title', containerClass: 'col-12', disabled: true }"/>
              <input-textarea v-model="message" :options="{ id: 'message', label: 'message', disabled: true, containerClass: 'col-12' }"/>
              <input-checkbox v-model="enabled" :options="{ id: 'enabled', label: 'enabled', disabled: true }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ENVELOPES_SHOW as CRUD_ACTION_SHOW } from 'actions/envelopes';
  const CRUD_CODE="envelopes";
  export default {
    name: "EnvelopeShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: `captain_${CRUD_CODE}`,
        id:this.$router.currentRoute.params.id,
        envelope_type: '',
        title: '',
        language_code: '',
        message: '',
        city_id: '',
        country_id: "",
        captain_name: "",
        enabled: false,
        serverError: '',
        image:{
          file:'',
          thumbnail:'',
        },
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.title=response.data.title;
          this.envelope_type=response.data.type || 'ENVELOPE';
          this.language_code=response.data.languageCode;
          this.message=response.data.message;
          this.enabled=response.data.enabled;
          this.country_id=response.data.country?response.data.country.id:null;
          this.city_id=response.data.city?response.data.city.id:null;
          this.captain_name=response.data.captain?response.data.captain.user.name:null;
          this.image.thumbnail=response.data.file?response.data.file.url:'';
        }).catch(error => {
          this.loading = false
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
