<template>
  <div class="container captain-card">
    <div class="row mt-2">
      <div class="col-9">
        <h3>
          <router-link :to="{ name: `${this.captainRoutePrefix}.show`, params: { id: this.captain.id } }" target='_blank' v-can:view="captainPermissionPrefix">
            {{ $t('fields.id') }}: <u>{{ id }}</u>
          </router-link>
          <a href="javascript:;" @click.prevent="copyId"><i class="far fa-copy mx-2"></i></a>
          <transition name="copied">
            <small v-if="id_copied === true" class="border border-gray rounded text-body p-1">{{ $t('labels.copied_to_clipboard') }}</small>
          </transition>
        </h3>
      </div>
      <div class="col-3 text-right">
        <span class="badge" style="font-size: 0.75rem;" v-bind:class="{
          'bg-green text-white': getCaptainOrders.length === 0,
          'bg-yellow': getCaptainOrders.length === 1,
          'bg-orange text-white': getCaptainOrders.length > 1
          }">
            {{ getCaptainOrders.length === 0
            ? $t(`${captainTransPrefix}.lists.working_status.FREE`)
            : $t(`${captainTransPrefix}.lists.working_status.BUSY`) + " (" + getCaptainOrders.length + ")" }}
        </span>
      </div>
    </div>
    <div class="row align-items-baseline">
      <div class="col-4"><span>{{ $t('fields.name') }}</span></div>
      <div class="col-8">
        <div class="row">
          <input-text v-model="name" :options="{ id: 'name', disabled: true, containerClass: 'col-12' }"/>
        </div>
      </div>
      <div class="col-4"><span> {{ $t('fields.mobile_number') }} </span></div>
      <div class="col-8">
        <div class="row">
          <input-text v-model="phone" :options="{ id: 'phone', disabled: true, containerClass: 'col-12' }"/>
        </div>
      </div>
      <div class="col-4"><span>{{ $t(`${captainTransPrefix}.fields.supplier`) }}</span></div>
      <div class="col-8">
        <div class="row">
          <input-text v-model="supplier" :options="{ id: 'supplier', disabled: true, containerClass: 'col-12' }"/>
        </div>
      </div>
      <div class="col-4"><span>{{ $t(`${captainTransPrefix}.fields.shift_start_time`) }}</span></div>
      <div class="col-8">
        <div class="row">
          <input-text v-model="shiftStartTime" :options="{ id: 'shift_start_time', disabled: true, containerClass: 'col-12' }"/>
        </div>
      </div>
      <div class="col-4"><span>{{ $t(`${captainTransPrefix}.fields.today_deliveries`) }}</span></div>
      <div class="col-8">
        <div class="row">
          <input-text v-model="todayDeliveries" :options="{ id: 'today_deliveries', disabled: true, containerClass: 'col-12' }"/>
        </div>
      </div>
    </div>
    <div class="row align-items-baseline mt-2">
      <input-checkbox v-model="getReadyToWork" :options="{ id: 'ready_to_work', transPrefix: `${captainTransPrefix}.fields`, label: 'ready_to_work', disabled: true }"/>
      <input-checkbox v-model="getConnected" :options="{ id: 'connected', transPrefix: `${captainTransPrefix}.fields`, label: 'connected', disabled: true }"/>
      <input-checkbox v-model="suspended" :options="{ id: 'suspended', transPrefix: `${captainTransPrefix}.fields`, label: 'suspended', onChangeHandler: changeSuspendedStatus, onChangeParams: { id: id, isSuspended: !suspended }, disabled: !can(captainPermissionPrefix,'suspend') }"/>
      <input-checkbox v-model="deleted" :options="{ id: 'deleted', transPrefix: `${captainTransPrefix}.fields`, label: 'deleted', disabled: true }"/>
    </div>
    <div v-if="getCaptainOrders.length > 0" class="row mt-2">
      <div class="col-12 text-center">
        <span><strong>{{ $t(`${captainTransPrefix}.fields.orders`) }}</strong></span>
      </div>
      <div class="col-12 p-0" @click="showOrderHandler(order)" v-for="order in getCaptainOrders" :key="order.id">
        <div class="tracking-order-item-small m-1 py-2 row" v-bind:class="{ 'too-late' : isTooLate(order) }">
          <div class="col-6">
            <h4 class="order-id mb-0">{{ order.backendId }}</h4>
          </div>
          <div class="col-6 text-right">
            <span class="payment-type badge pb-1" v-bind:class="{
              'bg-danger text-white': order.paymentType == 'PREPAID',
              'bg-green text-white': order.paymentType == 'CASH_ON_DELIVERY'
            }">
              {{ order.paymentType }}
            </span>
          </div>
          <div class="col-6">
            <span class="status" v-bind:style="{ color: order.statusColor }">
              <strong> {{ order.status }} </strong>
            </span>
          </div>
          <div class="col-6 text-right">
            <span class="total-distance">
              {{ order.totalDistance }}
            </span>
          </div>
          <div class="col-6 text-left">
            <span class="platform">
              {{ order.brand || order.platform }}
            </span>
          </div>
          <div class="col-6 text-right">
            <span class="city">
              {{ order.city }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CAPTAINS_UPDATE_RIDER_SUSPENSION_STATUS } from "actions/captains";

const CAPTAIN_CODE = "captains";
const ORDER_CODE = "orders";
export default {
  name: "TrackingCaptainCard",
  props: {
    captain: {
      type: Object
    }
  },
  data() {
    return {
      captainRoutePrefix: CAPTAIN_CODE,
      captainPermissionPrefix: CAPTAIN_CODE,
      captainTransPrefix: CAPTAIN_CODE,
      orderRoutePrefix: ORDER_CODE,
      id: "",
      name: "",
      phone: "",
      supplier: "",
      shiftStartTime: "",
      suspended: false,
      deleted: false,
      todayDeliveries: 0,
      id_copied: false
    };
  },
  beforeMount() {
    this.id = this.captain.id;
    this.name = this.captain.name;
    this.phone = this.captain.phone;
    this.supplier = this.captain.supplier;
    this.shiftStartTime = this.captain.shiftStartTime ? this.$moment(this.captain.shiftStartTime).format("Y-MM-DD hh:mm:ss A") : "-";
    this.suspended = this.captain.suspended;
    this.deleted = this.captain.deleted;
    this.todayDeliveries = this.captain.todayDeliveredOrdersCount;
  },
  computed: {
    ...mapGetters(["getCaptains", "getOrders"]),
    getConnected: {
      get() {
        return this.getCaptains[this.id]?.connected || false;
      },
      set(connected) {
        return connected;
      }
    },
    getReadyToWork: {
      get() {
        return this.getCaptains[this.id]?.readyToWork || false;
      },
      set(readyToWork) {
        return readyToWork;
      }
    },
    getCaptainOrders() {
      return this.getCaptains[this.id].orders.map(orderId => this.getOrders[orderId]);
    }
  },
  methods: {
    showOrderHandler(order) {
      let routeData = this.$router.resolve({ name: `${this.orderRoutePrefix}.show`, params: {id: order.id} });
      window.open(routeData.href, "_blank");
    },
    isTooLate(order) {
      return this.$moment.duration(this.$moment(new Date()).diff(this.$moment(order.createdAt))).asMinutes() > 45;
    },
    copyId(){
      navigator.clipboard.writeText(this.id);
      this.id_copied = true;
      setTimeout(() => this.id_copied = false, 500);
    },
    changeSuspendedStatus(event, params) {
      if (params.isSuspended) {
        swal({
          title: this.$i18n.t("messages.suspend_title"),
          text: this.$i18n.t("messages.suspension_reason"),
          type: "input",
          showCancelButton: true,
          closeOnConfirm: false,
          content: "input",
          buttons: [
            this.$i18n.t("labels.cancel"),
            this.$i18n.t("labels.suspend"),
          ],
          inputPlaceholder: "Write something",
        }).then((value) => {
          if (value !== null && Object.keys(value).length !== 0) {
            this.requestChangeSuspendedStatus(params.id, params.isSuspended, value);
          } else {
            this.suspended = false;
          }
        });
      } else {
        this.requestChangeSuspendedStatus(params.id, params.isSuspended, null);
      }
    },
    requestChangeSuspendedStatus(id, isSuspended, reason) {
      this.$store.dispatch(CAPTAINS_UPDATE_RIDER_SUSPENSION_STATUS, {
        id,
        value: isSuspended,
        data: {
          "reason": reason
        }
      })
          .then(() => {
            swal({
              icon: 'success',
              text: this.$i18n.t("messages.update_successfully"),
              buttons:false,
              timer: 5000
            });
          })
          .catch(error => {
            let message="";
            if(error.response){
              let responseData=error.response.data;

              if(responseData.errors && responseData.errors.error){
                message = responseData.errors.error;
              }
              else{
                message = this.$i18n.t("messages.update_error");
              }
            }
            if(message){
              swal({
                icon: 'error',
                text: message,
                buttons:false,
                timer: 5000
              });
            }
          })
    }
  }
};
</script>

<style scoped>
  .copied-leave-active {
    transition: opacity 1s ease;
  }

  .copied-leave-to {
    opacity: 0;
  }

  .border-gray {
    border-color: gray !important;
  }
</style>