<template>
    <div  aria-hidden="true" aria-labelledby="modal-user-roles" class="modal fade" id="modal-user-roles"  role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">{{ $t('fields.roles')}}</h6>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form role="form" @submit.prevent="changeStatus">
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div v-if="serverError" class="alert alert-danger">
                    <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                  </div>
                </div>
              </div>
              <div class="row">
                <select-roles v-bind:value="rolesIds" :options="{ id: 'roles', params: { ...activeParams, ...additionalParams }, multiple: true, disabled: true, containerClass: 'col-12' }"/>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-danger btn-sm ml-auto" data-dismiss="modal" type="button">
                {{ $t('labels.close') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>

<script>
    import {USERS_ROLES_LIST as CRUD_ACTION_LIST} from 'actions/users';

    export default {
        name: "UserActionsModalsRoles",
        props: {
            activeParams: {
                type: Object,
                default: function () {
                    return {};
                }
            },
            additionalParams: {
                type: Object,
                default: function () {
                    return {};
                }
            },
            successCallback: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                serverError: '',
                fieldErrors: {},
                loading: false,
                roles: [],
                activeId: null,
                activeIndex: null,
            }
        },
        computed:{
            rolesIds :{
                get: function () {
                    let rolesIds=[];
                    Object.keys(this.roles).forEach(key=>{
                        let role=this.roles[key];
                        rolesIds.push("" + role.id);
                    });
                    return rolesIds;
                },
            }
        },
        watch: {
            activeParams: function (newVal, oldVal) {
                this.serverError = null;
                this.activeId = newVal.activeId;
                this.activeIndex = newVal.activeIndex;
                this.activeParams = newVal;
                this.getRoles();
            },
        },
        methods: {
            getRoles() {
              this.roles=[];
              this.$store.dispatch(CRUD_ACTION_LIST, {
                  pageId: 0,
                  id: this.activeId,
              }).then(response => {
                  this.roles = response.data;
              });
            }
        }
    }
</script>

<style scoped>

</style>
