<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <!-- Begin Basic Information -->
            <div class="row">
              <div class="col-12">
                <div class="card-title">
                  <h1 class="text-primary">{{ $t(`labels.basic_information`) }}</h1>
                </div>
              </div>
              <div class="row card-body">
                <input-text v-model="backend_id" :options="{ id: 'name', transPrefix: `${transPrefix}.fields`, label: 'backend_id', error: fieldErrors.backendId }">
                  <template v-slot:hint>
                    <small class="text-danger">* ({{ $t(`labels.required`) }})</small>
                  </template>
                </input-text>
                <select-payment-types v-model="payment_type" :options="{ id: 'payment_type', transPrefix: `${transPrefix}.fields`, label: 'payment_type', error: fieldErrors.paymentType }">
                  <template v-slot:hint-top>
                    <small class="text-danger">* ({{ $t(`labels.required`) }})</small>
                  </template>
                </select-payment-types>
              </div>
            </div>
            <!-- End Basic Information -->

            <template v-if="payment_type && backend_id">
              <!-- Begin Pickup Task Details -->
              <div class="row">
                <div class="col-12">
                  <div class="card-title">
                    <h1 class="text-primary d-inline">{{ $t(`labels.pickup_task_details`) }}</h1>
                  </div>
                </div>

                <div class="row card-body">
                  <input-number  v-if="payment_type === 'CASH_ON_DELIVERY'" v-model="pickupTask.payAtPickup" :options="{ id: 'pickupTask_payAtPickup', transPrefix: `${transPrefix}.fields`, label: 'pay_at_pickup', error: fieldErrors['tasks[].payAtPickup'], disabled: payment_type !== 'CASH_ON_DELIVERY', containerClass: 'col-12 col-md-4' }"/>
                  <div class="col-12">
                    <div class="card-title">
                      <h3 class="text-primary d-inline">{{ $t(`labels.address`) }}</h3>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div class="form-group">
                      <label for="branches">{{ $t(`fields.branches`)}}</label>
                      <search-platform-branches v-model="branch" placeholder="placeholders.search" :ajaxSearch="true"/>
                    </div>
                  </div>
                  <div class="row col-12" v-if="!branch">
                    <input-text v-model="pickupTask.address.phone" :options="{ id: 'pickupTask_address_phone', transPrefix: `${transPrefix}.fields`, label: 'address_phone', containerClass: 'col-12 col-lg-6 col-xl-3' }"/>
                    <input-text v-model="pickupTask.address.description" :options="{ id: 'pickupTask_address_description', transPrefix: `${transPrefix}.fields`, label: 'address_description', error: fieldErrors['tasks[].address.description'], containerClass: 'col-12 col-lg-6 col-xl-3' }"/>
                    <input-number v-model="pickupTask.address.latitude" :options="{ id: 'pickupTask_address_latitude', transPrefix: `${transPrefix}.fields`, label: 'address_latitude', error: fieldErrors['tasks[].address.latitude'], containerClass: 'col-12 col-lg-6 col-xl-3' }">
                      <template v-slot:hint>
                        <small class="text-danger">* ({{ $t(`labels.required`) }})</small>
                        <a href="#" class="mx-1 text-blue" @click.prevent="showPickupMapPicker">
                          <i class="fa fa-map-pin "></i>
                        </a>
                      </template>
                    </input-number>
                    <input-number v-model="pickupTask.address.longitude" :options="{ id: 'pickupTask_address_longitude', transPrefix: `${transPrefix}.fields`, label: 'address_longitude', error: fieldErrors['tasks[].address.longitude'], containerClass: 'col-12 col-lg-6 col-xl-3' }">
                      <template v-slot:hint>
                        <small class="text-danger">* ({{ $t(`labels.required`) }})</small>
                        <a href="#" class="mx-1 text-blue" @click.prevent="showPickupMapPicker">
                          <i class="fa fa-map-pin "></i>
                        </a>
                      </template>
                    </input-number>
                  </div>
                  <div v-if="pickupMapPicker" class="col-12 position-fixed top-9 left-0 p-5"  style="z-index: 99999">
                    <button class="btn btn-danger btn-sm" @click.prevent="hidePickupMapPicker">
                      {{ $t(`labels.close`) }}
                      <i class="fa fa-window-close"></i>
                    </button>
                    <maps-pick-address :position="{lat:pickupTask.address.latitude,lng:pickupTask.address.longitude}" v-model="pickupMarkerPosition"/>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row justify-content-end">
                  <div class="col px-0">
                    <div class="card-title">
                      <h3 class="text-primary d-inline">{{ $t(`labels.products_opt`) }}</h3>
                    </div>
                  </div>
                  <div class="col text-right">
                    <button class="btn btn-success btn-sm mx-2" @click.prevent="addPickupTaskItemRow">
                      <strong>{{ $t(`labels.add_new_product`) }}</strong> <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <template v-for="(pickupTaskItem,index) in pickupTask.items">
                    <div class="col-12">
                      <h4 class="text-primary d-inline">{{ $t(`labels.product`) }} {{index+1}}</h4>
                      <a href="#" class="text-danger mx-2" @click.prevent="removePickupTaskItemRow(index)">
                        <i class="fa fa-trash"></i>
                      </a>
                    </div>
                    <input-text v-model="pickupTaskItem.name" :options="{ id: `name_${index}`, label: 'name', error: fieldErrors['tasks[].items[].name'], containerClass: 'col-12 col-md-3' }"/>
                    <input-text v-model="pickupTaskItem.description" :options="{ id: `description_${index}`, label: 'description', error: fieldErrors['tasks[].items[].description'], containerClass: 'col-12 col-md-3' }"/>
                    <input-number v-model="pickupTaskItem.quantity" :options="{ id: `quantity_${index}`, step: 1, label: 'quantity', error: fieldErrors['tasks[].items[].quantity'], containerClass: 'col-12 col-md-3' }"/>
                    <input-number v-model="pickupTaskItem.price" :options="{ id: `price_${index}`, label: 'price', error: fieldErrors['tasks[].items[].price'], containerClass: 'col-12 col-md-3' }"/>
                    <div class="col-12">
                      <hr/>
                    </div>
                  </template>
                </div>
              </div>
              <!-- End Pickup Task Details -->

              <!-- Begin Delivery Task Details -->
              <div class="row">
                <div class="col-12">
                  <div class="card-title">
                    <h1 class="text-primary d-inline">{{ $t(`labels.delivery_task_details`) }}</h1>
                  </div>
                </div>
                <div class="row card-body mb-5">
                  <input-number  v-if="payment_type === 'CASH_ON_DELIVERY'" v-model="deliveryTask.collectAtDelivery" :options="{ id: 'deliveryTask_collectAtDelivery', transPrefix: `${transPrefix}.fields`, label: 'collect_at_delivery', error: fieldErrors['tasks[].collectAtDelivery'], disabled: payment_type !== 'CASH_ON_DELIVERY', containerClass: 'col-12 col-md-4' }"/>
                  <div class="col-12">
                    <div class="card-title">
                      <h3 class="text-primary d-inline">{{ $t(`labels.address`) }}</h3>
                    </div>
                  </div>
                  <div class="row col-12">
                    <input-text v-model="deliveryTask.address.phone" :options="{ id: 'deliveryTask_address_phone', transPrefix: `${transPrefix}.fields`, label: 'address_phone', error: fieldErrors['tasks[].address.phone'], containerClass: 'col-12 col-lg-6 col-xl-3' }">
                      <template v-slot:hint>
                        <small class="text-danger">* ({{ $t(`labels.required`) }})</small>
                      </template>
                    </input-text>
                    <input-text v-model="deliveryTask.address.description" :options="{ id: 'deliveryTask_address_description', transPrefix: `${transPrefix}.fields`, label: 'address_description', error: fieldErrors['tasks[].address.description'], containerClass: 'col-12 col-lg-6 col-xl-3' }">
                      <template v-slot:hint>
                        <small class="text-danger">* ({{ $t(`labels.required`) }})</small>
                      </template>
                    </input-text>
                    <input-number v-model="deliveryTask.address.latitude" :options="{ id: 'deliveryTask_address_latitude', transPrefix: `${transPrefix}.fields`, label: 'address_latitude', error: fieldErrors['tasks[].address.latitude'], containerClass: 'col-12 col-lg-6 col-xl-3' }">
                      <template v-slot:hint>
                        <small class="text-danger">* ({{ $t(`labels.required`) }})</small>
                        <a href="#" class="mx-1 text-blue" @click.prevent="showDeliveryMapPicker">
                          <i class="fa fa-map-pin "></i>
                        </a>
                      </template>
                    </input-number>
                    <input-number v-model="deliveryTask.address.longitude" :options="{ id: 'deliveryTask_address_longitude', transPrefix: `${transPrefix}.fields`, label: 'address_longitude', error: fieldErrors['tasks[].address.longitude'], containerClass: 'col-12 col-lg-6 col-xl-3' }">
                      <template v-slot:hint>
                        <small class="text-danger">* ({{ $t(`labels.required`) }})</small>
                        <a href="#" class="mx-1 text-blue" @click.prevent="showDeliveryMapPicker">
                          <i class="fa fa-map-pin "></i>
                        </a>
                      </template>
                    </input-number>
                  </div>
                  <div v-if="deliveryMapPicker" class="col-12 position-fixed top-9 left-0 p-5"  style="z-index: 99999">
                    <button class="btn btn-danger btn-sm" @click.prevent="hideDeliveryMapPicker">
                      {{ $t(`labels.close`) }}
                      <i class="fa fa-window-close"></i>
                    </button>
                    <maps-pick-address id="deliveryPicker" :position="{lat:deliveryTask.address.latitude,lng:deliveryTask.address.longitude}" v-model="deliveryMarkerPosition"/>
                  </div>
                </div>
              </div>
              <!-- End Delivery Task Details -->

              <!-- Begin Meta Data Details-->

              <div class="row justify-content-end">
                <div class="col">
                  <div class="card-title">
                    <h1 class="text-primary d-inline">{{ $t(`labels.meta_data_opt`) }}</h1>
                  </div>
                </div>
                <div class="col text-right">
                  <button class="btn btn-success btn-sm mx-2" @click.prevent="addMetaDataItemRow">
                    {{ $t(`labels.add_new_item`) }} <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>

              <div class="row card-body mb-5 pt-0">
                <template v-for="(metaDataItem,index) in metaDataItems">
                  <div class="col-12">
                    <h3 class="text-primary d-inline">{{ $t(`labels.item`) }} {{index+1}}</h3>
                    <a href="#" class="text-danger mx-2" @click.prevent="removeMetaDataItemRow(index)">
                      <i class="fa fa-trash"></i>
                    </a>
                  </div>
                  <input-text v-model="metaDataItem.label" :options="{ id: `label_${index}`, label: 'label', error: fieldErrors['metaData[].label'], containerClass: 'col-12 col-md-4' }"/>
                  <input-text v-model="metaDataItem.data" :options="{ id: `data_${index}`, label: 'data', error: fieldErrors['metaData[].data'], containerClass: 'col-12 col-md-4' }"/>
                  <select-language-codes v-model="metaDataItem.languageCode" :options="{ id: `language_code_${index}`, label: 'language_code', error: fieldErrors['metaData[].languageCode'], containerClass: 'col-12 col-md-4' }">
                    <template v-slot:hint-top>
                      <small class="text-danger">* ({{ $t(`labels.required`) }})</small>
                    </template>
                  </select-language-codes>
                  <div class="col-12">
                    <hr/>
                  </div>
                </template>
              </div>

              <!-- End Meta Data Details-->

            </template>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { ORDERS_CREATE as CRUD_ACTION_CREATE } from 'actions/platforms_portal/orders';
  const ORDERS_CODE="orders";
  const CRUD_CODE=`platforms_portal.${ORDERS_CODE}`;
  const CRUD_TRANS_CODE=`${ORDERS_CODE}`;
  export default {
    name: "PlatformsPortalOrderCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_TRANS_CODE,
        metaDataItems:[],
        pickupTask:{
          "taskType": "PICK_UP",
          "rank": 0,
          "collectAtDelivery": 0,
          "payAtPickup": 0,
          "name":"PICK_UP",
          "address": {
            "phone": "",
            "description": "",
            "latitude": "",
            "longitude": ""
          },
          "items":[],
        },
        deliveryTask:{
          "taskType": "DELIVERY",
          "rank": 1,
          "collectAtDelivery": 0,
          "payAtPickup": 0,
          "name":"DELIVERY",
          "address": {
            "phone": "",
            "description": "",
            "latitude": "",
            "longitude": ""
          },
        },
        backend_id: '',
        payment_type:'',

        pickupMapPicker:false,
        deliveryMapPicker:false,
        pickupMarkerPosition:{},
        deliveryMarkerPosition:{},
        branch:null,
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    watch:{
      pickupMarkerPosition:function(newVal,oldVal){
        this.pickupTask.address.latitude=newVal.lat;
        this.pickupTask.address.longitude=newVal.lng;

      },
      deliveryMarkerPosition:function(newVal,oldVal){
        this.deliveryTask.address.latitude=newVal.lat;
        this.deliveryTask.address.longitude=newVal.lng;
      },
      payment_type:function (newVal,oldVal) {
        if(newVal!=='CASH_ON_DELIVERY'){
          this.deliveryTask.collectAtDelivery=0;
          this.pickupTask.payAtPickup=0;
        }
      },
      branch:function (newVal,oldVal) {
        if(newVal && typeof newVal ==="object"){
          this.pickupTask.address.latitude=newVal.latitude;
          this.pickupTask.address.longitude=newVal.longitude;
          this.pickupTask.address.phone=newVal.phone;
          this.pickupTask.address.description=newVal.description;
          this.pickupTask.name=newVal.name;
        }else{
          this.pickupTask.address.latitude=
          this.pickupTask.address.longitude=
          this.pickupTask.address.phone=
          this.pickupTask.address.description="";
          this.pickupTask.name="PICK_UP";
        }
      }
    },
    methods: {
      prepareRequestData(){
        return {
          paymentType: this.sanitizeField('payment_type'),
          backendId: this.sanitizeField('backend_id'),
          metaData: this.sanitizeField('metaDataItems'),
          tasks: [
            this.sanitizeField('pickupTask'),
            this.sanitizeField('deliveryTask')
          ],
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index`})
        })
        .catch(error => {
          this.loading = false
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
      addMetaDataItemRow(){
        this.metaDataItems.push({label: '', data: '' , languageCode: ''});
      },
      removeMetaDataItemRow(index){
        this.metaDataItems.splice(index,1);
      },
      addPickupTaskItemRow(){
        this.pickupTask.items.push({name: '', description: '' , quantity: 0,price:0});
      },
      removePickupTaskItemRow(index){
        this.pickupTask.items.splice(index,1);
      },
      hidePickupMapPicker(){
        this.pickupMapPicker=false;
      },
      showPickupMapPicker(){
        this.pickupMapPicker=true;
      },
      hideDeliveryMapPicker(){
        this.deliveryMapPicker=false;
      },
      showDeliveryMapPicker(){
        this.deliveryMapPicker=true;
      }
    }
  };
</script>
