<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <files-image v-model="image" :options="{ id: 'image', label: 'image', error: fieldErrors.profilePicture }"/>
            </div>
            <div class="row">
              <input-email v-model="email" :options="{ id: 'email', label: 'email', error: fieldErrors.email }"/>
              <input-text v-model="name" :options="{ id: 'name', label: 'name', error: fieldErrors.name }"/>
              <input-text v-model="mobile_number" :options="{ id: 'mobile_number', label: 'mobile_number', error: fieldErrors['userMobile.mobileNumber'] }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`,params:{id} }"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { SUPPLIERS_USERS_CREATE as CRUD_ACTION_CREATE } from 'actions/suppliers';
  const SUPPLIERS_CODE="suppliers";
  const CRUD_CODE=`${SUPPLIERS_CODE}.users`;
  export default {
    name: "SupplierUserCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        suppliersRoutePrefix: `${SUPPLIERS_CODE}`,
        suppliersTransPrefix: `${SUPPLIERS_CODE}`,
        id:this.$router.currentRoute.params.id,
        image:{
          file:'',
          thumbnail:'',
        },
        email: '',
        name: '',
        mobile_number: '',
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    methods: {
      prepareRequestData(){
        return {
          email: this.sanitizeField('email'),
          name: this.sanitizeField('name'),
          "userMobile.mobileNumber":this.sanitizeField('mobile_number'),
          ...this.prepareFile('image',"profilePicture"),
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
          supplierId:this.id,
        })
                .then(() => {
                  swal({
                    icon: 'success',
                    text: this.$i18n.t("messages.create_successfully"),
                    buttons:false,
                    timer: 5000
                  });
                  this.$router.push({ name: `${this.routePrefix}.index`, params:{id:this.id} })
                })
                .catch(error => {
                  this.loading = false;
                  this.fieldErrors={};
                  this.serverError='';
                  if(error.response){
                    let responseData=error.response.data;
                    if(responseData.errors){
                      this.fieldErrors=responseData.errors;
                    }else{
                      this.serverError = 'Invalid Request.'
                    }
                  }else{
                    this.serverError = this.$i18n.t("messages.no_internet_connection");
                  }
                  if(this.fieldErrors.error){
                    this.serverError = this.fieldErrors.error;
                  }
                })
      }
    }
  };
</script>
