<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${captainsRoutePrefix}.index`}"  class="btn btn-primary btn-sm">
            <i class="fa fa-arrow-left"></i>
          </router-link>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">

            <td> {{ item.language }} </td>
            <td> {{ item.deviceType }} </td>
            <td> {{ item.appBuild }} </td>
            <td> {{ item.deviceManufacturer }} </td>
            <td class="text-direction-ltr"> {{ item.openedAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <table-item-actions :actions="tableItemActions" :params="{'id':id,'device_id':item.id}"/>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>
  import { CAPTAINS_DEVICES_LIST as CRUD_ACTION_LIST} from 'actions/captains';
  const CAPTAINS_CODE="captains";
  const CRUD_CODE=`${CAPTAINS_CODE}.devices`;
  const CRUD_PERMISSION_CODE=`${CAPTAINS_CODE}_devices`;
  export default {
    name: "CaptainDeviceIndex",
    data(){

      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        captainsRoutePrefix: `${CAPTAINS_CODE}`,
        captainsTransPrefix: `${CAPTAINS_CODE}`,
        id:this.$router.currentRoute.params.id,
        tableHeaders:[
          {
            'title':`fields.language`
          },
          {
            'title':`${CRUD_CODE}.fields.device_type`
          },
          {
            'title':`${CRUD_CODE}.fields.app_build`
          },
          {
            'title':`${CRUD_CODE}.fields.manufacturer`
          },
          {
            'title':`fields.opened_at`
          },
          {
            'title':`fields.created_at`
          },
          {
            'title':`fields.actions`
          }
        ],
        tableItemActions:[
          {
            code:'show',
            route_name:`${CRUD_CODE}.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
          },
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          pageId: this.getCurrentPage()-1,
          id:this.id
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        })
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
    }
  };
</script>
