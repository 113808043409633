<template>
    <div aria-hidden="true" aria-labelledby="modal-retry-bulk" class="modal fade" id="modal-retry-bulk"  role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">{{ $t('messages.retry_withdrawal_bulk_title')}}</h6>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form role="form" @submit.prevent="retryWithdrawals">
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div v-if="serverError" class="alert alert-danger">
                    <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                  </div>
                </div>
                <div class="col-12 text-center">
                  <span v-if="loading" class="spinner-border"></span>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-12 col-lg-10" v-if="!loading" v-html="$t('messages.retry_withdrawal_bulk_text', [count, createdFrom, createdTo, totalAmount])">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-danger btn-sm ml-auto" data-dismiss="modal" type="button">
                {{ $t('labels.close') }}
              </button>
              <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                {{ $t('labels.submit') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>

<script>
import {
  WITHDRAW_REQUESTS_RETRY_BULK,
  WITHDRAW_REQUESTS_RETRY_SUMMARY
} from 'actions/withdraw_requests';
import {mapGetters} from "vuex";
    export default {
        name: "WithdrawRequestActionsModalsRetryBulk",
        props: {
          activeRetryBulkParams:{
            type:Object,
            default: () => {}
          },
          successCallback: {
            type: Function,
            default: () => { }
          }
        },
        data(){
            return {
                serverError: '',
                fieldErrors: {},
                loading: false,
                createdFrom: '',
                createdTo: '',
                totalAmount: 0,
                count: 0
            }
        },
        computed:{
          ...mapGetters(["getSearchFilters", "getSearchFiltersSensitized"]),
        },
        watch:{
          activeRetryBulkParams: function() {
            this.serverError=null;
            this.getRetrySummary();
          },
        },
        methods:{
            getRetrySummary() {
              this.loading = true;
              this.$store.dispatch(WITHDRAW_REQUESTS_RETRY_SUMMARY, { ...this.getSearchFiltersSensitized })
                  .then(response=> {
                    this.createdFrom = response.data.createdFrom;
                    this.createdTo = response.data.createdTo;
                    this.totalAmount = response.data.totalAmount;
                    this.count = response.data.count;
                  })
                  .catch(error => this.handleError(error))
                  .finally(() => this.loading = false);
            },
            retryWithdrawals() {
              this.loading = true;
              const payload = {
                ...this.getSearchFiltersSensitized,
                withdrawStatuses: Array.isArray(this.getSearchFiltersSensitized.withdrawStatuses)
                    ? this.getSearchFiltersSensitized.withdrawStatuses
                    : [this.getSearchFiltersSensitized.withdrawStatuses]
              }
              this.$store.dispatch(WITHDRAW_REQUESTS_RETRY_BULK, payload)
                  .then(response => {
                    document.querySelector('#modal-retry-bulk [data-dismiss]').click();
                    this.successCallback();
                    swal({
                      icon: 'success',
                      text: response.data.message,
                      buttons:false,
                      timer: 5000
                    });
                  })
                  .catch(error => this.handleError(error))
                  .finally(() => this.loading = false)
            },
            handleError(error) {
              this.loading = false;
              this.fieldErrors={};
              this.serverError='';

              if(error.response){
                let responseData=error.response.data;

                if(responseData.errors){
                  this.fieldErrors=responseData.errors;
                }else{
                  this.serverError = 'Invalid Request.'
                }
              }else{
                this.serverError = this.$i18n.t("messages.no_internet_connection");
              }

              if(this.fieldErrors.error){
                this.serverError = this.fieldErrors.error;
              }
            }
        }
    }
</script>

<style scoped>

</style>
