<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm" v-can:add="permissionPrefix">
            <i class="fa fa-plus"></i>
          </router-link>
        </template>

        <template v-slot:card-header-search>
          <search v-model="lastPage" :listHandler="getList" style="margin-top: 30px"/>
        </template>

        <template v-slot:table-rows>
          <tr v-for="(item,index) in items" :key="item.id">
            <td>
              <div class="media align-items-center">
                <a v-if="getImageThumbnail(item)" href="javascript:;" class="avatar rounded-circle">
                  <img alt="Image placeholder" :src="getImageThumbnail(item)">
                </a>
                <div class="media-body">
                  <span class="name mb-0 text-sm"></span>
                </div>
              </div>
            </td>
            <td> {{ item.id }} </td>
            <td> {{ item.user.name  }} </td>
            <td> {{ item.supplierName }} </td>
            <td> {{ item.city.name  }} </td>
            <td> {{ item.user.email }} </td>
            <td>
              <input-checkbox v-model="item.suspended" :options="{ id: 'suspended', onChangeHandler: changeSuspendedStatus, onChangeParams: { index, id: item.id, isSuspended: !item.suspended }, fullLayout: false, disabled: !can(permissionPrefix,'suspend') }"/>
            </td>
            <td>
              {{ item.deleted ? $t('labels.yes') : $t('labels.no')}}
            </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <table-item-actions :actions="tableItemActions" :params="{'id':item.id}">
              <template v-slot:additional-items>
                <router-link :to="{ name:`envelopes.create`,query:{'captain_id':item.id} }" class="text-success px-1" v-b-tooltip.hover="{ animation: false}" :title="$t('envelopes.create_title')" v-can:add="`captain_envelopes`">
                  <i class="fas fa-envelope"></i>
                </router-link>
              </template>
            </table-item-actions>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>

        <template v-slot:card-footer>
          <export-action :crudCode="routePrefix" v-can:report="permissionPrefix"></export-action>
        </template>
        
      </card-container>
    </div>
  </div>
</template>
<script>

  import { mapGetters } from "vuex";
  import {CAPTAINS_LIST as CRUD_ACTION_LIST, CAPTAINS_UPDATE_RIDER_SUSPENSION_STATUS} from 'actions/captains';
  import search from "./partials/search";
  const CRUD_CODE="captains";
  const CRUD_PERMISSION_CODE=`${CRUD_CODE}`;
  export default {
    name: "CaptainIndex",
    components: {
      search
    },
    data(){

      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        tableHeaders:[
          {
            'title':`fields.image`
          },
          {
            'title' : `fields.id`
          },
          {
            'title':`fields.name`
          },
          {
            'title':`fields.supplier_name`
          },
          {
            'title':`fields.city`
          },
          {
            'title':`fields.email`
          },
          {
            'title':`${CRUD_CODE}.fields.suspended`
          },
          {
            'title':`${CRUD_CODE}.fields.deleted`
          },
          {
            'title':`fields.created_at`
          },
          {
            'title':`fields.actions`
          }
        ],
        tableItemActions:[
          {
            code:'show',
            route_name:`${CRUD_CODE}.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
          },
          {
            code:'edit',
            route_name:`${CRUD_CODE}.edit`,
            can:`${CRUD_PERMISSION_CODE}_can_edit`,
          },
          {
            code:'wallet',
            route_name:`${CRUD_CODE}.wallet.show`,
            color_class:`text-gray`,
            icon:`fas fa-wallet`,
            can:`wallets_can_view`,
            title: `${CRUD_CODE}.wallet.title`,
          },
          {
            code:'ibans',
            route_name:`${CRUD_CODE}.ibans.show`,
            color_class:`text-orange`,
            icon:`fas fa-university`,
            can:`${CRUD_CODE}_bank_can_view`,
            title: `${CRUD_CODE}.ibans.title`,
          },
          {
            code:'fintechs',
            route_name:`${CRUD_CODE}.fintechs.show`,
            color_class:`text-purple`,
            icon:`fas fa-money-check-alt`,
            can:`fintechs_can_view`,
            title: `${CRUD_CODE}.fintechs.title`,
          },
          {
            code:'devices',
            route_name:`${CRUD_CODE}.devices.index`,
            color_class:`text-primary`,
            icon:`fas fa-mobile-alt`,
            can:`${CRUD_CODE}_devices_can_view`,
            title: `${CRUD_CODE}.devices.title`,
          },
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    computed:{
      ...mapGetters([
        "getSearchFilters","getSearchFiltersIfExists","getSearchFiltersSensitized"
      ])
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          pageId: this.getCurrentPage()-1,
          ...this.getSearchFiltersSensitized
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        });
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page, 
              ...this.getSearchFiltersIfExists
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
      changeSuspendedStatus(event, params){
        if (params.isSuspended) {
          swal({
              title: this.$i18n.t("messages.suspend_title"),
              text: this.$i18n.t("messages.suspension_reason"),
              type: "input",
              showCancelButton: true,
              closeOnConfirm: false,
              content: "input",
              buttons: [
                this.$i18n.t("labels.cancel"),
                this.$i18n.t("labels.suspend"),
              ],
              inputPlaceholder: "Write something",
          }).then((value) => {
              if (value !== null && Object.keys(value).length !== 0) {
                this.requestChangeSuspendedStatus(params.id, params.isSuspended, value);
              }
              else {
                this.items[params.index].suspended = false;
              }
          });
        }
        else {
          this.requestChangeSuspendedStatus(params.id,params.isSuspended, null);
        }
      },
      requestChangeSuspendedStatus(id,isSuspended, reason){
        this.$store.dispatch(CAPTAINS_UPDATE_RIDER_SUSPENSION_STATUS, {
          id,
          value:isSuspended,
          data: {
            "reason": reason
          }
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
        })
        .catch(error => {
          let message="";
          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors && responseData.errors.error){
              message = responseData.errors.error;
            }
            else{
              message = this.$i18n.t("messages.update_error");
            }
          }
          if(message){
            swal({
              icon: 'error',
              text: message,
              buttons:false,
              timer: 5000
            });
          }
        })
      },
      getImageThumbnail(item){
        let image='';
        if(item.user && item.user.profilePicture){
          image=item.user.profilePicture.url;
        }
        return image;
      }
    }
  };
</script>
