<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${ticketRootsRoutePrefix}.index`}"  class="btn btn-primary btn-sm">
            <i class="fa fa-arrow-left"></i>
          </router-link>
          <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm"  v-can:add="permissionPrefix">
            <i class="fa fa-plus"></i>
          </router-link>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td> {{ item.id }} </td>
            <td> {{ item.title }} </td>
            <td>
              <input-checkbox v-model="item.enabled" :options="{ id: 'status', onChangeHandler: changeStatus, onChangeParams: { id: item.id, value: !item.enabled }, fullLayout: false, disabled: !can(permissionPrefix,'edit') }"/>
            </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <table-item-actions :actions="tableItemActions" :params="{id,'type_id':item.id}"/>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import { TICKETS_ROOTS_TYPES_LIST as CRUD_ACTION_LIST, TICKETS_ROOTS_TYPES_UPDATE_STATUS as CRUD_ACTION_UPDATE_STATUS  } from 'actions/tickets';
  const TICKETS_CODE="tickets";
  const TICKETS_ROOTS_CODE=`${TICKETS_CODE}.roots`;
  const CRUD_CODE=`${TICKETS_ROOTS_CODE}.types`;
  const CRUD_PERMISSION_CODE=`${TICKETS_CODE}`;

  export default {
    name: "TicketRootTypeIndex",
    data(){

      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        ticketsRoutePrefix: TICKETS_CODE,
        ticketsTransPrefix: TICKETS_CODE,
        ticketRootsRoutePrefix: TICKETS_ROOTS_CODE,
        ticketRootsTransPrefix: TICKETS_ROOTS_CODE,
        id:this.$router.currentRoute.params.id,
        tableHeaders:[
          {
            'title':`fields.id`
          },
          {
            'title':`fields.title`
          },
          {
            'title':`fields.enabled`
          },
          {
            'title':`fields.created_at`
          },
          {
            'title':`fields.actions`
          }
        ],
        tableItemActions:[
          {
            code:'show',
            route_name:`${CRUD_CODE}.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
          },
          {
            code:'edit',
            route_name:`${CRUD_CODE}.edit`,
            can:`${CRUD_PERMISSION_CODE}_can_edit`,
          },
          {
            code:'actions',
            route_name:`${CRUD_CODE}.actions.index`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
            color_class:`text-purple`,
            icon:"fa fa-list",
            title:`${CRUD_CODE}.actions.title`,
          },
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          ticketId:this.id,
          pageId: this.getCurrentPage()-1,
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        })
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
      changeStatus(event, params){
        this.$store.dispatch(CRUD_ACTION_UPDATE_STATUS, {
          ticketId:this.id,
          id: params.id,
          value: params.value,
          data: {}
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
        })
        .catch(error => {
          let message="";
          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors && responseData.errors.error){
              message = responseData.errors.error;
            }
            else{
              message = this.$i18n.t("messages.update_error");
            }
          }
          if(message){
            swal({
              icon: 'error',
              text: message,
              buttons:false,
              timer: 5000
            });
          }
        })
      },
    }
  };
</script>
