<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <files-image v-model="image" :options="{ id: 'image', label: 'image', disabled: true, containerClass: 'col-12 col-md-4' }"/>
              <files-image v-model="identity" :options="{ id: 'identity', transPrefix: `${transPrefix}.fields`, label: 'identity', disabled: true, containerClass: 'col-12 col-md-4' }"/>
              <files-image v-model="driving_license" :options="{ id: 'driving_license', transPrefix: `${transPrefix}.fields`, label: 'driving_license', disabled: true, containerClass: 'col-12 col-md-4' }"/>
              <files-image v-model="registration_license" :options="{ id: 'registration_license', transPrefix: `${transPrefix}.fields`, label: 'registration_license', disabled: true, containerClass: 'col-12 col-md-4' }"/>
            </div>
            <div class="row" v-if="isItemFound()">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', disabled: true }"/>
              <input-text v-model="mobile_number" :options="{ id: 'mobile_number', label: 'mobile_number', disabled: true }"/>
              <input-date v-model="date_of_birth" :options="{ id: 'date_of_birth', label: 'date_of_birth', disabled: true }"/>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="nationality" class="d-block">{{ $t(`fields.nationality`)}}</label>
                  <vue-country-code id="nationality" class="w-25" v-if="loadNationality()" @onSelect="onSelectNationality" :defaultCountry="nationality" :dropdownOptions="{ disabledDialCode: true }" :disabled="true"/>
                  <input-text :value="nationality_label" :options="{ disabled: true ,fullLayout: false , inputClass: 'form-control w-75 d-inline-block' }"/>
                </div>
              </div>
              <input-text v-model="id_number" :options="{ id: 'id_number', label: 'id_number', disabled: true }"/>
              <select-captains-registration-requests-status v-model="status" :options="{ id: 'status', label: 'status', disabled: true }"/>
              <input-text :value="$i18n.locale === 'ar' ? city.nameAr : city.name" :options="{ id: 'city', label: 'city', disabled: true }"/>
              <input-text :value="$i18n.locale === 'ar' ? country.nameAr : country.name" :options="{ id: 'country', label: 'country', disabled: true }"/>
              <input-text :value="bank.name" :options="{ id: 'bank', label: 'bank', disabled: true }"/>
              <input-text v-model="iban_name" :options="{ id: 'iban_name', label: 'iban_name', disabled: true }"/>
              <input-text v-model="iban_number" :options="{ id: 'iban_number', label: 'iban_number', disabled: true }"/>
              <input-text v-model="fintech_id" :options="{ id: 'fintech_id', label: 'fintech_id', disabled: true }"/>
              <input-text v-if="refereeCode" v-model="refereeCode" :options="{ id: 'refereeCode', transPrefix: `${transPrefix}.fields`, label: 'referee_code', disabled: true }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <block-action v-model="can_action" v-if="can_action" :itemID="id" v-can:edit="permissionPrefix"/>
                <accept-action v-model="can_action" v-if="can_action" :itemID="id" v-can:edit="permissionPrefix"/>
                <reject-action v-model="can_action" v-if="can_action" :itemID="id" v-can:edit="permissionPrefix"/>
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <reject-reasons-action v-if="rejection_reasons.length>0"/>
              </div>
            </div>
          </div>
      </div>
    </div>
    <reject-action-modal :itemID="id" v-model="can_action"/>
    <reject-reasons-action-modal :rejectionReasons="rejection_reasons"/>
  </div>
</template>
<script>
  import { CAPTAINS_REGISTRATION_REQUESTS_SHOW as CRUD_ACTION_SHOW } from 'actions/captains_registration_requests';
  const CRUD_CODE="captains_registration_requests";
  const CRUD_PERMISSION_CODE=`${CRUD_CODE}`;
  import blockAction from "./actions/block";
  import acceptAction from "./actions/accept";
  import rejectAction from "./actions/reject";
  import rejectReasonsAction from "./actions/reject_reasons";
  import rejectActionModal from "./actions/modals/reject";
  import rejectReasonsActionModal from "./actions/modals/reject_reasons";
  export default {
    name: "CaptainRegistrationRequestShow",
    components:{
      blockAction,
      acceptAction,
      rejectAction,
      rejectReasonsAction,
      rejectActionModal,
      rejectReasonsActionModal
    },
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        id:this.$router.currentRoute.params.id,
        id_number: '',
        status: '',
        fintech_id: '',
        iban_name: '',
        iban_number: '',
        city:{},
        country:{},
        bank:{},
        mobile_number: '',
        nationality: '',
        nationality_label: '',
        is_nationality_load: false,
        date_of_birth: '',
        name:'',
        can_action:false,
        serverError: '',
        refereeCode: '',
        image:{
          file:'',
          thumbnail:'',
        },
        driving_license:{
          file:'',
          thumbnail:'',
        },
        registration_license:{
          file:'',
          thumbnail:'',
        },
        identity:{
          file:'',
          thumbnail:'',
        },
        rejection_reasons:[],
        responseStatus:'',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus && this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.id_number=response.data.idNumber;
          this.status=response.data.status;
          this.fintech_id=response.data.fintechAccountId;
          this.iban_name=response.data.ibanName;
          this.iban_number=response.data.iban;
          this.country=response.data.country;
          this.city=response.data.city;
          this.bank=response.data.bank;
          this.date_of_birth=response.data.dob?this.$moment(response.data.dob).format("Y-MM-DD"):"";
          this.nationality=response.data.nationality;
          this.mobile_number=response.data.mobileNumber;
          this.name=response.data.name;
          this.can_action=response.data.canAction;
          this.image.thumbnail=response.data.profilePicFile?response.data.profilePicFile.url:'';
          this.driving_license.thumbnail=response.data.drivingLicenseFile?response.data.drivingLicenseFile.url:'';
          this.registration_license.thumbnail=response.data.registrationFile?response.data.registrationFile.url:'';
          this.identity.thumbnail=response.data.idFile?response.data.idFile.url:'';
          this.rejection_reasons=response.data.rejectionReasons;
          this.refereeCode= response.data.refereeCode;
          this.is_nationality_load=!!this.nationality;
          this.responseStatus=response.status;
        }).catch(error => {
          this.loading = false
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      onSelectNationality({name, iso2, dialCode}) {
        this.nationality=iso2.toLowerCase();
        this.nationality_label=`${name} (${iso2})`;
      },
      loadNationality(){
        return this.isItemFound() && this.is_nationality_load;
      }
    }
  };
</script>
