<template>
    <searchContainer :searchHandler="search" :resetHandler="reset">
        <template v-slot:items>
            <div class="row">
                <input-number v-model="filters.captain_id" :options="{ id: 'captain_id', step: 1, transPrefix: `${transPrefix}.fields`, label: 'captain_id' }"/>
                <input-text v-model="filters.captain_name" :options="{ id: 'captain_name', label: 'name' }"/>
                <input-text v-model="filters.captain_email" :options="{ id: 'captain_email', label: 'email' }"/>
                <input-text v-model="filters.captain_mobile_number" :options="{ id: 'captain_mobile_number', label: 'mobile_number' }"/>
                <input-text v-model="filters.captain_id_number" :options="{ id: 'captain_id_number', transPrefix: `${transPrefix}.fields`, label: 'id_number' }"/>
                <select-suppliers v-model="filters.supplier_id" :options="{ id: 'supplier_id', label: 'supplier', placeholder: 'placeholders.all', multiple: true }"/>
                <select-countries v-model="filters.country_id" :options="{ id: 'country_id', label: 'country', action: countries_list_action, placeholder: 'placeholders.all', disabled: canNotSelectCountry() }"/>
                <select-cities v-model="filters.city_id" :country_id="filters.country_id" :options="{ id: 'city_id', label: 'city', action: cities_list_action, placeholder: 'placeholders.all', disabled: canNotSelectCity() }"/>
                <select-yes-no v-model="filters.ready_to_work" :options="{ id: 'ready_to_work', transPrefix: `${transPrefix}.fields`, label: 'ready_to_work', placeholder: 'placeholders.all' }"/>
                <select-yes-no v-model="filters.suspended" :options="{ id: 'suspended', transPrefix: `${transPrefix}.fields`, label: 'suspended', placeholder: 'placeholders.all' }"/>
            </div>
        </template>
    </searchContainer>
</template>
<script>

    import {mapGetters} from "vuex";
    import {CRUD_SEARCH_RESET} from "actions/crud";
    import searchContainer from "@/components/crud/card/CardSearch";
    import {COUNTRIES_PUBLIC_LIST} from 'actions/countries';
    import {CITIES_PUBLIC_LIST} from 'actions/cities';

    const CRUD_CODE = "captains";
    export default {
        name: "CaptainSearch",
        components: {
            searchContainer
        },
        props: {
            listHandler: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                transPrefix: CRUD_CODE,
                filters: {
                    captain_id: this.getFilterByKey('captain_id'),
                    captain_name: this.getFilterByKey('captain_name'),
                    captain_email: this.getFilterByKey('captain_email'),
                    captain_mobile_number: this.getFilterByKey('captain_mobile_number'),
                    captain_id_number: this.getFilterByKey('captain_id_number'),
                    supplier_id: this.getFilterByKey('supplier_id'),
                    country_id: this.getFilterByKey('country_id'),
                    city_id: this.getFilterByKey('city_id'),
                    ready_to_work: this.getFilterByKey('ready_to_work'),
                    suspended: this.getFilterByKey('suspended'),
                },
                filtersMap: {
                    captain_id: 'captainId',
                    supplier_id: 'supplierId',
                    captain_name: 'name',
                    captain_email: 'email',
                    captain_mobile_number: 'mobileNumber',
                    captain_id_number: 'idNumber',
                    city_id: 'cityId',
                    ready_to_work: 'readyToWork',
                    suspended: 'suspended'
                },
                countries_list_action: COUNTRIES_PUBLIC_LIST,
                cities_list_action: CITIES_PUBLIC_LIST,
            }
        },
        mounted() {
            this.setSearchParams(this.filters, this.filtersMap);
        },
        computed: {
            ...mapGetters([
                "getSearchFilters"
            ])
        },
        methods: {
            search() {
                this.resetPagination();
                this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                this.pushRoute(this.getSearchFilters);
                this.listHandler();
            },
            reset() {
                this.resetPagination();
                this.$store.dispatch(CRUD_SEARCH_RESET);
                this.filters = this.clearFilters(this.filters);
                this.pushRoute();
                this.listHandler();
            },
            resetPagination() {
                //Reset Pagination
                this.$emit('input', 0);
            },
            pushRoute(query = {}) {
                this.$router.push({name: this.$route.name, query}).catch(() => {});
            },
            canSelectCountry() {
                return true;
            },
            canNotSelectCountry() {
                return !this.canSelectCountry();
            },
            canSelectCity() {
                return this.filters.country_id;
            },
            canNotSelectCity() {
                return !this.canSelectCity();
            },
        }
    };
</script>
