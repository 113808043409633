<template>
  <div>
    <nav class="navbar navbar-vertical pt-5 navbar-expand-xs navbar-light opened right-0 pb-0 component-sidenav" id="sidenav-active-order-tracking">
      <div class="scrollbar-inner bg-lighter">
        <div class="d-flex align-items-end bg-lighter pr-0 position-fixed top-0 left-0 w-100">
          <div class="py-2 m-0 row flex-row-reverse align-items-center w-100">
            <div class="col-12">
              <div class="form-group search m-0">
                <label for="filter_order_id" class="position-absolute left-4 top-1 opacity-7">
                  <i class="fa fa-search"></i>
                </label>
                <input-text v-model="filters.orderId" :options="{ id: 'filter_order_id', fullLayout: false , inputClass: 'form-control form-control-sm border-primary border-2' }"/>
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-inner bg-lighter">
          <!-- Collapse -->
            <div class="collapse navbar-collapse">
              <div v-if="loading" class="text-center position-fixed overflow-hidden bottom-0 top-0 mt-5 w-100 left-0 right-0 bg-lighter opacity-7 swal-overlay--show-modal">
                <span class="spinner-border loader text-dark m-auto position-absolute top-0 right-0 left-0 bottom-0 force-right-0"></span>
              </div>
              <!-- Nav items -->
              <ul class="navbar-nav">
                <li class="nav-item nav-link mx-0 p-0"
                    v-for="item in getFilteredItems(this.getItems, this.filters)"
                    :key="item.id">
                  <order-card v-bind="item" />
                </li>
                <li class="nav-item nav-link mx-0 px-0 row" v-if="getFilteredItems(this.getItems, this.filters).length===0">
                  <div class="col-12 text-center">
                    <h2 class="order-id"> {{ $t('messages.no_result_found')}} </h2>
                  </div>
                </li>
              </ul>
            </div>
          </div>
      </div>
    </nav>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import $ from "jquery";
  import orderCard from "@/views/tracking/partials/orderCard";

  export default {
    name: "TrackingActiveOrders",
    components: {
      orderCard
    },
    data(){
      return {
        loading: true,
        isOpened: true,
        filters: {
          orderId: null
        }
      }
    },
    watch:{
      getActiveOrder:function(newVal){
        const show = (!newVal.action || "RE_ASSIGN" === newVal.action) && this.isOpened;
        const hide = "MANUALLY_ASSIGN" === newVal.action && this.isOpened;
        if (show) {
          this.openSideMenu()
        } else if (hide) {
          this.hideSideMenu()
        }
      },
      getMapConfig: function(newVal) {
        if (newVal.activeOrdersEnabled !== this.isOpened) {
          newVal.activeOrdersEnabled ? this.openSideMenu() : this.hideSideMenu();
          this.isOpened = newVal.activeOrdersEnabled;
        }
      },
      getMapFilters: function(newVal) {
        this.filters = { ...this.filters, ...newVal };
      },
      isOrdersLoading: function (newVal) {
        this.loading = newVal;
      }
    },
    computed:{
      ...mapGetters([
        "getActiveOrder", "isOrdersLoading", "getMapConfig", "getOrders", "getMapFilters"
      ]),
      getItems() {
        return Object.values(this.getOrders).filter(order => order.inProgress).sort((o1, o2) => o2.id - o1.id);
      }
    },
    mounted() {
      $('.scrollbar-rail,.scrollbar-macosx,.scrollbar-light,.scrollbar-inner,.scrollbar-outer,.scrollbar-dynamic,.scrollbar-chrome').scrollbar().scrollLock();

      this.filters = { ...this.filters, ...this.getMapFilters };
      this.isOpened = this.getMapConfig.activeOrdersEnabled;
      if (!this.getMapConfig.activeOrdersEnabled) {
        $('#sidenav-active-order-tracking').removeClass('opened').addClass(['closed', 'd-none']);
      }
    },
    methods: {
      openSideMenu() {
        $('#sidenav-active-order-tracking').removeClass('closed d-none').addClass('opened');
      },
      hideSideMenu() {
        $('#sidenav-active-order-tracking').removeClass('opened').addClass('closed');
      },
      getFilteredItems(items, filters){
        return items
            .filter(item => !filters.orderId || item.backendId.search(`${filters.orderId}`) !== -1)
            .filter(item => !filters.countryId || item.countryId === filters.countryId)
            .filter(item => !filters.cityIds || filters.cityIds.length === 0 || filters.cityIds.includes(item.cityId))
            .filter(item => !filters.platformId || item.platformId === filters.platformId)
            .filter(item => !filters.brandIds || filters.brandIds.length === 0 || filters.brandIds.includes(item.brandId))
      }
    }
  };
</script>
<style type="text/css"  scoped>
  .scrollbar-inner .scroll-element.scroll-y{
    background-color: #e5e5e594 !important;
    width: 8px !important;
    opacity: 1 !important;
  }
  .scrollbar-inner > .scroll-element .scroll-element_track, .scrollbar-inner > .scroll-element .scroll-bar{
    opacity: unset!important;
  }

  .loader.force-right-0{
    right:0!important;
  }
</style>
