<template>
  <a href="javascript:;"
     class="text-orange px-1"
     data-target="#modal-retry-one"
     data-toggle="modal"
     @click="openRetryPopup(item)"
     v-b-tooltip.hover="{ animation: false }"
     :title="$t('messages.retry_withdrawal_one_title')">
     <i class="fas fa-sync"></i>
  </a>
</template>

<script>
    export default {
        name: "WithdrawRequestActionsRetryOne",
        props: {
            value: {},
            item: {}
        },
        data(){
            return {}
        },
        methods:{
          openRetryPopup(item) {
            this.$emit('input',{ ...item });
          }
        }
    }
</script>

<style scoped>

</style>
