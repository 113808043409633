<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', error: fieldErrors.name }"/>
              <select-allocation-config-types v-model="type" :options="{ id: 'type', label: 'type', error: fieldErrors.type }"/>
              <select-suppliers v-if="['SUPPLIER','SUPPLIER_CITY'].includes(type)" v-model="supplier_id" :includeFreelancers="false" :options="{ id: 'supplier_id', label: 'supplier', error: fieldErrors.supplierId }"/>
              <select-countries v-if="['COUNTRY','CITY','ZONE','SUPPLIER_CITY'].includes(type)" v-model="country_id" :options="{ id: 'country_id', label: 'country', error: fieldErrors.countryId }"/>
              <select-cities v-if="['CITY','ZONE','SUPPLIER_CITY'].includes(type)" v-model="city_id" :country_id="country_id" :options="{ id: 'city_id', label: 'city', error: fieldErrors.cityId, disabled: !country_id }"/>
              <select-zones v-if="['ZONE'].includes(type)" v-model="zone_id" :city_id="city_id" :options="{ id: 'zone_id', label: 'zone', error: fieldErrors.zoneId, disabled: !city_id }"/>
              <select-platforms v-if="['PLATFORM'].includes(type)" v-model="platform_id" :options="{ id: 'platform_id', label: 'platform', error: fieldErrors.platformId }"/>
            </div>
            <allocation-config-tiers-list v-model="tiers" :editable="true" :fieldErrors="fieldErrors" />
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { FUTURE_ALLOCATION_CONFIGS_CREATE as CRUD_ACTION_CREATE } from 'actions/allocation_configs';
  import AllocationConfigTiersList from './partials/allocation_tiers';

  const CRUD_CODE="allocation_configs";
  export default {
    name: "AllocationConfigCreate",
    components: {
      AllocationConfigTiersList
    },
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        name: '',
        tiers: [
          {
            initialEarning: 0,
            perKm: 0,
            maxEarning: 0,
            kmStartsAfter: 0,
            tierCompletionOrdersCount: null,
            tierCompletionBonus: null
          }
        ],
        country_id: '',
        city_id:'',
        zone_id:'',
        platform_id:'',
        supplier_id:'',
        type:'',
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    methods: {
      prepareRequestData() {
        const preparedTiers = this.tiers.map((tier, index) => ({ ...tier, level: index + 1 }))
        return {
          name: this.sanitizeField('name'),
          type: this.sanitizeField('type'),
          countryId: this.sanitizeField('country_id'),
          cityId: this.sanitizeField('city_id'),
          zoneId: this.sanitizeField('zone_id'),
          platformId: this.sanitizeField('platform_id'),
          supplierId: this.sanitizeField('supplier_id'),
          tiers: preparedTiers
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData()
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
          window.scroll({ top: 0, left: 0, behavior: "smooth" });
        })
      }
    }
  };
</script>
