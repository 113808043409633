<template>
  <div class="container captain-card">
    <div class="row mt-2">
      <div class="col-6">
        <router-link :to="{ name: `${this.captainRoutePrefix}.show`, params: { id: this.captain.id } }" target='_blank' v-can:view="captainPermissionPrefix">
          <h3>{{ $t('fields.id') }}: <u>{{ id }}</u></h3>
        </router-link>
      </div>
      <div class="col-6 text-right">
        <span class="badge" style="font-size: 0.75rem;" v-bind:class="{ 'bg-green text-white': workingStatus === 'FREE', 'bg-yellow': workingStatus === 'BUSY' }">
            {{ workingStatus === 'FREE'
            ? $t(`${captainTransPrefix}.lists.working_status.FREE`)
            : $t(`${captainTransPrefix}.lists.working_status.BUSY`) }}
        </span>
      </div>
    </div>
    <div class="row align-items-baseline">
      <div class="col-4"><span>{{ $t('fields.name') }}</span></div>
      <div class="col-8">
        <div class="row">
          <input-text v-model="name" :options="{ id: 'name', disabled: true, containerClass: 'col-12' }"/>
        </div>
      </div>
      <div class="col-4"><span> {{ $t('fields.mobile_number') }} </span></div>
      <div class="col-8">
        <div class="row">
          <input-text v-model="phone" :options="{ id: 'phone', disabled: true, containerClass: 'col-12' }"/>
        </div>
      </div>
      <div class="col-4"><span>{{ $t(`${captainTransPrefix}.fields.supplier`) }}</span></div>
      <div class="col-8">
        <div class="row">
          <input-text v-model="supplier" :options="{ id: 'supplier', disabled: true, containerClass: 'col-12' }"/>
        </div>
      </div>
      <div class="col-4"><span>{{ $t(`${captainTransPrefix}.fields.shift_start_time`) }}</span></div>
      <div class="col-8">
        <div class="row">
          <input-text v-model="shiftStartTime" :options="{ id: 'shift_start_time', disabled: true, containerClass: 'col-12' }"/>
        </div>
      </div>
      <div class="col-4"><span>{{ $t(`${captainTransPrefix}.fields.today_deliveries`) }}</span></div>
      <div class="col-8">
        <div class="row">
          <input-text v-model="todayDeliveries" :options="{ id: 'today_deliveries', disabled: true, containerClass: 'col-12' }"/>
        </div>
      </div>
    </div>
    <div class="row align-items-baseline mt-2">
      <input-checkbox v-model="getReadyToWork" :options="{ id: 'ready_to_work', transPrefix: `${captainTransPrefix}.fields`, label: 'ready_to_work', disabled: true }"/>
      <input-checkbox v-model="getConnected" :options="{ id: 'connected', transPrefix: `${captainTransPrefix}.fields`, label: 'connected', disabled: true }"/>
      <input-checkbox v-model="suspended" :options="{ id: 'suspended', transPrefix: `${captainTransPrefix}.fields`, label: 'suspended', disabled: true }"/>
      <input-checkbox v-model="deleted" :options="{ id: 'deleted', transPrefix: `${captainTransPrefix}.fields`, label: 'deleted', disabled: true }"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SupplierTrackingCaptainCard",
  props: {
    captain: {
      type: Object
    }
  },
  data() {
    return {
      captainRoutePrefix: "suppliers_portal.captains",
      captainPermissionPrefix: "supplier_captains",
      captainTransPrefix: "captains",
      id: "",
      name: "",
      phone: "",
      supplier: "",
      shiftStartTime: "",
      readyToWork: false,
      connected: false,
      suspended: false,
      deleted: false,
      workingStatus: "",
      todayDeliveries: 0
    };
  },
  mounted() {
    this.id = this.captain.id;
    this.name = this.captain.name;
    this.phone = this.captain.phone;
    this.supplier = this.captain.supplier;
    this.shiftStartTime = this.captain.shiftStartTime ? this.$moment(this.captain.shiftStartTime).format("Y-MM-DD hh:mm:ss A") : "-";
    this.readyToWork = this.captain.readyToWork;
    this.connected = this.captain.connected;
    this.suspended = this.captain.suspended;
    this.deleted = this.captain.deleted;
    this.workingStatus = this.captain.workingStatus;
    this.todayDeliveries = this.captain.todayDeliveredOrdersCount;
  },
  computed: {
    ...mapGetters(["getCaptains"]),
    getConnected: {
      get() {
        return this.getCaptains[this.id]?.connected || false;
      },
      set(connected) {
        return connected;
      }
    },
    getReadyToWork: {
      get() {
        return this.getCaptains[this.id]?.readyToWork || false;
      },
      set(readyToWork) {
        return readyToWork;
      }
    }
  }
};
</script>

<style scoped>

</style>