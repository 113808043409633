<template>
  <a href="javascript:;" @click.prevent="openPopup(captainId)" class="btn btn-primary btn-sm">
    <span class="d-none d-lg-inline mr-2">{{ $t(`labels.restore_captain`) }}</span>
    <span><i class="fa fa-undo"></i></span>
  </a>
</template>

<script>
import {CAPTAINS_RESTORE} from 'actions/captains';

export default {
  name: "CaptainActionsRestore",
  props: {
    captainId: {}
  },
  data() {
    return {};
  },
  methods: {
    openPopup(id) {
      swal({
        title: this.$i18n.t("labels.restore_captain"),
        text: this.$i18n.t("messages.restore_captain"),
        icon: "warning",
        showCancelButton: true,
        closeOnConfirm: false,
        buttons: [
          this.$i18n.t("labels.cancel"),
          this.$i18n.t("labels.submit"),
        ]
      }).then(value => value && this.restoreCaptain(id));
    },
    restoreCaptain(id) {
      this.$store.dispatch(CAPTAINS_RESTORE, {id: id})
          .then(() => {
            this.$emit('success');
            swal({
              icon: 'success',
              text: this.$i18n.t("messages.restore_captain_success"),
              buttons: false,
              timer: 5000
            });
          })
          .catch(error => {
            let message = "";
            if (error.response) {
              let responseData = error.response.data;

              if (responseData.errors && responseData.errors.error) {
                message = responseData.errors.error;
              } else {
                message = this.$i18n.t("messages.restore_captain_error");
              }
            }
            if (message) {
              swal({
                icon: 'error',
                text: message,
                buttons: false,
                timer: 5000
              });
            }
          })
    }
  }
}
</script>

<style scoped>

</style>