<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <select-device-types v-model="device_type" :options="{ id: 'device_type', transPrefix:`${transPrefix}.fields`, label: 'device_type', error: fieldErrors.deviceType }"/>
              <input-number v-model="build_number" :options="{ id: 'build_number', step: 1, transPrefix: `${transPrefix}.fields`, label: 'build_number', error: fieldErrors.buildNumber }"/>
              <input-text v-model="build_version" :options="{ id: 'build_version', transPrefix: `${transPrefix}.fields`, label: 'build_version', error: fieldErrors.buildVersion }"/>
              <input-text v-model="store_url" :options="{ id: 'store_url', transPrefix: `${transPrefix}.fields`, label: 'store_url', error: fieldErrors.storeUrl }"/>
              <select-update-actions v-model="update_action" :options="{ id: 'update_action', transPrefix:`${transPrefix}.fields`, label: 'update_action', error: fieldErrors.updateAction }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { APP_VERSIONS_CREATE as CRUD_ACTION_CREATE } from 'actions/app_versions';
  const CRUD_CODE="app_versions";
  export default {
    name: "AppVersionCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        device_type: '',
        build_number: '',
        build_version: '',
        store_url: '',
        update_action: '',
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    methods: {
      prepareRequestData(){
        return {
          deviceType: this.sanitizeField('device_type'),
          buildNumber: this.sanitizeField('build_number'),
          buildVersion: this.sanitizeField('build_version'),
          storeUrl: this.sanitizeField('store_url'),
          updateAction: this.sanitizeField('update_action'),
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),

        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    }
  };
</script>
