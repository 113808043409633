import CaptainCard from "@/views/tracking/partials/captainCard";
import Vue from "vue";

export class OperationsTrackingMap {
  constructor(trackingMapComponent) {
    this.trackingMapComponent = trackingMapComponent;
    this.allocationCircles = [];
    this.taskMarkers = [];
    this.captainMarkers = {};
    this.captainMarkersHidden = false;
    this.activeCaptain = {};
    this.captainPaths = [];
    this.highlightedCaptain = null;
  }

  init() {
    return this.trackingMapComponent.$google.then(google => {
      this.google = google;
      this.map = new this.google.maps.Map(document.getElementById(this.trackingMapComponent.id), {
        center: this.trackingMapComponent.centerPosition,
        zoom: this.trackingMapComponent.zoom
      });
      this.google.maps.event.addListener(this.map, "click", () => this.closeCaptainDetailsInfoWindow());
    });
  }

  registerCaptainDetailsFunction(handler) {
    this.captainDetailsFunction = handler;
  }

  addAllocationCircles(position) {
    const options = {
      map: this.map,
      center: position,
      strokeColor: "#808080",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: "#808080",
      fillOpacity: 0.2,
      clickable: false
    };
    this.allocationCircles = [
      new this.google.maps.Circle({ ...options, radius: 2000 }),
      new this.google.maps.Circle({ ...options, radius: 3000 }),
      new this.google.maps.Circle({ ...options, radius: 5000 })
    ];
  }

  deleteAllocationCircles() {
    this.allocationCircles.forEach(circle => circle.setMap(null));
    this.allocationCircles = [];
  }

  addTaskMarkers(tasks) {
    const icons = {
      PICK_UP: require("@/assets/theme/icons/task-types/PICK_UP.png"),
      ON_DEMAND_PICK_UP: require("@/assets/theme/icons/task-types/PICK_UP.png"),
      DELIVERY: require("@/assets/theme/icons/task-types/DELIVERY.png"),
      RETURNING: require("@/assets/theme/icons/task-types/RETURNING.png"),
      DEFAULT: require("@/assets/theme/icons/task-types/PICK_UP.png")
    };

    this.taskMarkers = tasks.map(
      task =>
        new this.google.maps.Marker({
          position: { lat: task.latitude, lng: task.longitude },
          map: this.map,
          icon: icons[task.taskType] ?? icons["DEFAULT"],
          zIndex: task.rank
        })
    );
  }

  deleteTaskMarkers() {
    this.taskMarkers.forEach(marker => marker.setMap(null));
    this.taskMarkers = [];
  }

  addCaptainMarker(captain) {
    if (captain.latitude && captain.longitude) {
      let marker = new this.google.maps.Marker({
        position: { lat: captain.latitude, lng: captain.longitude },
        map: this.captainMarkersHidden ? null : this.map
      });
      marker.setIcon(this.getCaptainMarkerIcon(captain));
      this.google.maps.event.addListener(marker, "click", this.getCaptainMarkerClickHandler(captain.id));
      this.captainMarkers[captain.id] = marker;
    }
  }

  getCaptainMarkerClickHandler(captainId) {
    return () => {
      const previousCaptainId = this.activeCaptain.infoWindow?.getMap() ? this.activeCaptain.captainId : null;
      this.closeCaptainDetailsInfoWindow();
      if (previousCaptainId !== captainId) {
        this.captainDetailsFunction(captainId).then(response => this.openCaptainDetailsInfoWindow(response.data));
      }
    };
  }

  openCaptainDetailsInfoWindow(captain) {
    const CaptainCardComponent = Vue.extend(CaptainCard);
    const captainCardInstance = new CaptainCardComponent({ parent: this.trackingMapComponent, propsData: { captain: captain } });
    captainCardInstance.$mount();
    const infoWindow = new this.google.maps.InfoWindow({ content: captainCardInstance.$el });
    infoWindow.open({ anchor: this.captainMarkers[captain.id], map: this.map });

    this.activeCaptain = { captainId: captain.id, captainCard: captainCardInstance, infoWindow: infoWindow };
  }

  closeCaptainDetailsInfoWindow() {
    this.activeCaptain.infoWindow?.close();
    this.activeCaptain.captainCard?.$destroy();
    this.activeCaptain = {};
  }

  addUpdateCaptainMarker(captain) {
    if (this.captainMarkers[captain.id]) {
      this.updateCaptainMarkerIcon(captain);
      this.updateCaptainMarkerPosition(captain);
    } else {
      this.addCaptainMarker(captain);
    }
  }

  updateCaptainMarkerIcon(captain) {
    if (this.captainMarkers[captain.id]) {
      this.captainMarkers[captain.id].setIcon(this.getCaptainMarkerIcon(captain));
    }
  }

  updateCaptainMarkerPosition(captain) {
    if (this.captainMarkers[captain.id] && captain.latitude && captain.longitude) {
      const latlng = new this.google.maps.LatLng(captain.latitude, captain.longitude);
      this.captainMarkers[captain.id].setPosition(latlng);
    }
  }

  showCaptainMarkers() {
    Object.values(this.captainMarkers).forEach(marker => marker.setMap(this.map));
    this.captainMarkersHidden = false;
  }

  showCaptainMarker(captainId) {
    this.captainMarkers[captainId]?.setMap(this.map);
  }

  hideCaptainMarkers() {
    Object.values(this.captainMarkers).forEach(marker => marker.setMap(null));
    this.captainMarkersHidden = true;
  }

  deleteCaptainMarker(captain) {
    if (this.captainMarkers[captain.id]) {
      this.captainMarkers[captain.id].setMap(null);
      delete this.captainMarkers[captain.id];
    }
  }

  deleteCaptainMarkers() {
    Object.values(this.captainMarkers).forEach(marker => marker.setMap(null));
    this.captainMarkers = {};
  }

  getCaptainMarkerIcon(captain) {
    const icon = this.getCaptainIconUrl(captain);
    let scaledSize = icon.size;
    if (this.highlightedCaptain && this.highlightedCaptain.id === captain.id) {
      scaledSize = new this.google.maps.Size(Math.floor(scaledSize.width * 1.4), Math.floor(scaledSize.height * 1.4));
    }
    const anchor = new this.google.maps.Point(Math.floor(scaledSize.width / 2), Math.floor(scaledSize.height / 2));
    return { url: icon.url, scaledSize: scaledSize, anchor: anchor };
  }

  getCaptainIconUrl(captain) {
    const icons = {
      DISCONNECTED: { url: require("@/assets/theme/icons/red_point.png"), size: new this.google.maps.Size(15, 15) },
      CONNECTED: { url: require("@/assets/theme/icons/green_point.png"), size: new this.google.maps.Size(15, 15) },
      BUSY: { url: require("@/assets/theme/icons/yellow_point.png"), size: new this.google.maps.Size(15, 15) },
      BUSY2: { url: require("@/assets/theme/icons/orange_point.png"), size: new this.google.maps.Size(15, 15) },
      IDLE: { url: require("@/assets/theme/icons/blue_point.png"), size: new this.google.maps.Size(15, 15) },
      NOT_READY_DISCONNECTED: { url: require("@/assets/theme/icons/red_point_red_donut.png"), size: new this.google.maps.Size(21, 21) },
      NOT_READY_BUSY: { url: require("@/assets/theme/icons/yellow_point_red_donut.png"), size: new this.google.maps.Size(21, 21) },
      NOT_READY_BUSY2: { url: require("@/assets/theme/icons/orange_point_red_donut.png"), size: new this.google.maps.Size(21, 21) }
    };

    let icon = null;
    if (!captain.connected) {
      icon = captain.readyToWork ? icons["DISCONNECTED"] : icons["NOT_READY_DISCONNECTED"];
    } else if (!captain.orders || captain.orders.length === 0) {
      icon = this.isCaptainIdle(captain) ? icons["IDLE"] : icons["CONNECTED"];
    } else if (captain.orders.length === 1) {
      icon = captain.readyToWork ? icons["BUSY"] : icons["NOT_READY_BUSY"];
    } else if (captain.orders.length > 1) {
      icon = captain.readyToWork ? icons["BUSY2"] : icons["NOT_READY_BUSY2"];
    }
    return icon;
  }

  isCaptainIdle(captain) {
    if (captain.lastDeliveryTime) {
      const moment = this.trackingMapComponent.$moment;
      return moment.duration(moment(new Date()).diff(moment(captain.lastDeliveryTime))).asMinutes() > 30;
    }
    return false;
  }

  setMapCenter(position, zoom) {
    this.previousCenter = this.map.getCenter();
    this.previousZoom = this.map.getZoom();
    this.map.setCenter(position);
    this.map.setZoom(zoom);
  }

  resetMapCenter() {
    this.map.setCenter(this.previousCenter);
    this.map.setZoom(this.previousZoom);
  }

  addCaptainPaths(captain, orders) {
    if (this.captainMarkers[captain.id]) {
      this.highlightedCaptain = captain;
      const marker = this.captainMarkers[captain.id];
      marker.setIcon(this.getCaptainMarkerIcon(captain));
      orders.forEach(order => this.addCaptainPath(marker, order.tasks))
    }
  }

  deleteCaptainPaths(captain) {
    if (this.captainMarkers[captain.id]) {
      this.highlightedCaptain = null;
      this.captainMarkers[captain.id].setIcon(this.getCaptainMarkerIcon(captain));
      this.captainPaths.forEach(path => path.setMap(null));
      this.captainPaths = [];
    }
  }

  addCaptainPath(captainMarker, tasks) {
    let startPoint = { lat: captainMarker.getPosition().lat(), lng: captainMarker.getPosition().lng() };
    const taskPoints = [...tasks]
        .sort((a, b) => a.rank - b.rank)
        .map(task => ({ lat: task.latitude, lng: task.longitude }));
    const fullPathPoints = [startPoint, ...taskPoints];
    for (let i = 0; i < fullPathPoints.length - 1; i++) {
      this.captainPaths.push(this.getPolyline(fullPathPoints[i], fullPathPoints[i + 1]));
    }
  }

  getPolyline(startLocation, endLocation) {
    return new this.google.maps.Polyline({
      path: [startLocation, endLocation],
      icons: [{ icon: { path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW }, offset: "100%" }],
      map: this.map,
      strokeWeight: 2
    });
  }
}
