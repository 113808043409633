<template xmlns="http://www.w3.org/1999/html">
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${referralProgramsRoutePrefix}.index`}"  class="btn btn-primary btn-sm">
            <i class="fa fa-arrow-left"></i>
          </router-link>
        </template>

        <template v-slot:card-header-search>
          <search v-model="lastPage" :listHandler="getList"/>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td>
              <p class="d-inline">
                <strong class="text-danger">{{ item.refereeCaptainName }}</strong> (<strong class="text-danger">{{ item.refereeCaptainId }}</strong>)
                {{ $t(`${transPrefix}.labels.referred`) }}
                <strong class="text-indigo">{{ item.captainName }}</strong> (<strong class="text-indigo">{{ item.captainId }}</strong>)
                {{ $t(`${transPrefix}.labels.with_code`) }}
                <strong class="text-warning">{{ item.usedCode }}</strong>
                {{ $t(`${transPrefix}.labels.with_type`) }}
                <strong class="text-indigo">{{ item.type }}</strong>
                {{ $t(`${transPrefix}.labels.and_got`) }}
                <strong class="text-green">{{ item.revenue }}</strong>
                {{ $t(`${transPrefix}.labels.at`) }}
              </p>
              <p class="text-direction-ltr d-inline-block">
                <span>
                  <strong>
                    {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }}
                  </strong>
                </span>
              </p>
            </td>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>
        <template v-slot:card-footer>
          <export-action :crudCode="`referrals/${id}/logs`" v-can:report="permissionPrefix"></export-action>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import { mapGetters } from "vuex";
  import { REFERRAL_PROGRAMS_LOGS_LIST as CRUD_ACTION_LIST} from 'actions/referral_programs';
  const REFERRAL_PROGRAMS_CODE="referral_programs";
  const CRUD_CODE=`${REFERRAL_PROGRAMS_CODE}.logs`;
  const CRUD_PERMISSION_CODE=`${REFERRAL_PROGRAMS_CODE}`;
  import search from "./partials/search";
  export default {
    name: "ReferralProgramLogIndex",
    components:{
      search,
    },
    data(){

      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        referralProgramsRoutePrefix: REFERRAL_PROGRAMS_CODE,
        referralProgramsTransPrefix: REFERRAL_PROGRAMS_CODE,
        id:this.$router.currentRoute.params.id,
        tableHeaders:[],
        tableItemActions:[],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    computed:{
      ...mapGetters([
        "getSearchFilters","getSearchFiltersIfExists","getSearchFiltersSensitized"
      ])
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          pageId: this.getCurrentPage()-1,
          referralProgramId: this.id,
          ...this.getSearchFiltersSensitized
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        })
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page,
              ...this.getSearchFiltersIfExists
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
      changeStatus(index, id, isEnabled){
        this.$store.dispatch(CRUD_ACTION_UPDATE_STATUS, {
          id,
          value:isEnabled,
          data: {}
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
        })
        .catch(error => {
          let message="";
          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors && responseData.errors.error){
              message = responseData.errors.error;
            }
            else{
              message = this.$i18n.t("messages.update_error");
            }
          }
          if(message){
            swal({
              icon: 'error',
              text: message,
              buttons:false,
              timer: 5000
            });
          }
        })
      },
    }
  };
</script>
