<template>
    <abstract-dropdown-container v-model="selectedValue" :options="{...defaultOptions, ...options}">
        <template v-slot:hint-top>
            <slot name="hint-top"></slot>
        </template>
        <template v-slot:hint-bottom>
            <slot name="hint-bottom"></slot>
        </template>
    </abstract-dropdown-container>
</template>

<script>
    import {REFERRAL_PROGRAMS_TYPE_LIST as CRUD_ACTION_LIST} from 'actions/referral_programs';

    export default {
        name: "FieldsDropdownReferralProgramTypes",
        props: {
            value: {},
            options: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                selectedValue: this.value,
                defaultOptions: {
                    id:                                     "referral_program_types",
                    prepareResponseDataHandler:             this.prepareResponseData,
                    customLabelHandler:                     this.customLabel,
                    action:                                 CRUD_ACTION_LIST,
                }
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            }
        },
        methods: {
            customLabel(value) {
                return this.$i18n.t(value.name);
            },
            prepareResponseData(data) {
                let preparedData = [];
                data.forEach(function (value, index) {
                    preparedData.push({'id': value, 'name': `${value}`});
                });
                return preparedData;
            }
        }
    }
</script>

<style scoped>

</style>
