<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <router-link v-if="!serverError" :to="{ name: `${routePrefix}.edit`,params:{id} }"  class="btn btn-primary btn-sm"  v-can:edit="permissionPrefix">
            <i class="fa fa-edit"></i>
          </router-link>
        </card-header>
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <files-image v-model="image" :options="{ id: 'image', disabled: true }"/>
            </div>
            <div class="row" v-if="!serverError">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', disabled: true }"/>
              <input-text v-model="code" :options="{ id: 'code', transPrefix: `${transPrefix}.fields`, label: 'code', disabled: true }"/>
              <input-text v-model="swift_code" :options="{ id: 'swift_code', transPrefix: `${transPrefix}.fields`, label: 'swift_code', disabled: true }"/>
              <select-countries v-model="country_id" :options="{ id: 'country_id', label: 'country', disabled: true }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { BANKS_SHOW as CRUD_ACTION_SHOW } from 'actions/banks';
  const CRUD_CODE="banks";
  export default {
    name: "BankShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        code: '',
        name: '',
        swift_code: '',
        country_id: "",
        serverError: '',
        image:{
          file:'',
          thumbnail:'',
        },
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.code=response.data.code;
          this.name=response.data.name;
          this.swift_code=response.data.swiftCode;
          this.country_id=response.data.country?response.data.country.id:null;
          this.image.thumbnail=response.data.image?response.data.image.url:'';
        }).catch(error => {
          this.loading = false
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
