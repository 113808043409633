<template>
    <a href="javascript:;" @click.prevent="popup(itemID)" class="btn btn-primary btn-sm">
        <span class="d-none d-lg-inline mr-2">{{ $t(`labels.reset_password`)}}</span>
        <span><i class="fa fa-sync"></i></span>
    </a>
</template>

<script>
    import { CAPTAINS_REQUEST_RESET_PASSWORD } from 'actions/captains';
    export default {
        name: "CaptainActionsResetPassword",
        props: {
            itemID:{},
        },
        data(){
            return {}
        },
        methods:{
            popup(id){
                swal({
                    title: this.$i18n.t("labels.reset_password"),
                    text: this.$i18n.t("messages.reset_password"),
                    icon: "warning",
                    showCancelButton: true,
                    closeOnConfirm: false,
                    buttons: [
                        this.$i18n.t("labels.cancel"),
                        this.$i18n.t("labels.submit"),
                    ],
                }).then((value) => {
                    if(value){
                        this.action(id);
                    }
                });
            },
            action(id){
                this.$store.dispatch(CAPTAINS_REQUEST_RESET_PASSWORD, {
                    id,
                })
                .then(() => {
                    swal({
                        icon: 'success',
                        text: this.$i18n.t("messages.reset_password_successfully"),
                        buttons:false,
                        timer: 5000
                    });
                })
                .catch(error => {
                    let message="";
                    if(error.response){
                        let responseData=error.response.data;

                        if(responseData.errors && responseData.errors.error){
                            message = responseData.errors.error;
                        }
                        else{
                            message = this.$i18n.t("messages.reset_password_error");
                        }
                    }
                    if(message){
                        swal({
                            icon: 'error',
                            text: message,
                            buttons:false,
                            timer: 5000
                        });
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>