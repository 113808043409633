<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="row justify-content-end">
              <button class="btn btn-primary btn-sm mr-3" @click.prevent="refresh()">
                <i class="fa fa-sync"></i>
              </button>
            </div>
            <div class="row">
              <select-countries v-model="countryId" :options="{ id: 'country_id', label: 'country', placeholder: 'placeholders.all', error: fieldErrors.countryId, containerClass: 'col-12' }"/>
            </div>
            <div v-if="countryId">
              <AllInOneOrdersChart :chart-data="orders" />
              <OrdersByDeliveryTimeChart :chart-data="orders" />
              <CancelledOrdersChart :chart-data="orders" />
              <SuppliersPerformanceOrdersChart :chart-data="orders" />
              <AcceptanceRatesChart :chart-data="captains" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AllInOneOrdersChart from "../charts/all-in-one-orders-chart";
import OrdersByDeliveryTimeChart from "../charts/orders-by-delivery-time-chart";
import SuppliersPerformanceOrdersChart from "../charts/suppliers-performance-orders-chart";
import AcceptanceRatesChart from "../charts/acceptance-rates-chart";
import CancelledOrdersChart from "../charts/cancelled-orders-chart";
import {MONITORING_ORDERS_EXTENDED, MONITORING_CAPTAINS_EXTENDED} from "actions/monitoring";

const CRUD_CODE = "monitoring";
export default {
  name: "TreemapMonitoringView",
  components: {
    AllInOneOrdersChart,
    OrdersByDeliveryTimeChart,
    SuppliersPerformanceOrdersChart,
    AcceptanceRatesChart,
    CancelledOrdersChart
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_CODE,
      countryId: 1,
      orders: [],
      captains: [],
      fieldErrors: {}
    };
  },
  methods: {
    getOrders() {
      this.$store.dispatch(MONITORING_ORDERS_EXTENDED, { countryId: this.countryId })
        .then(response => (this.orders = response.data));
    },
    getCaptains() {
      this.$store.dispatch(MONITORING_CAPTAINS_EXTENDED, { countryId: this.countryId })
          .then(response => (this.captains = response.data));
    },
    refresh() {
      this.getOrders();
      this.getCaptains();
    }
  },
  mounted() {
    this.getOrders();
    this.getCaptains();
  },
  watch: {
    countryId: function(newVal) {
      if (newVal) {
        this.getOrders();
        this.getCaptains();
      }
    }
  }
};
</script>

<style scoped>

</style>
