import {
    TICKETS_LIST as CRUD_ACTION_LIST,
    TICKETS_SHOW as CRUD_ACTION_SHOW,
    TICKETS_OWNER_TYPE_LIST as CRUD_ACTION_OWNER_TYPE_LIST,
    TICKETS_RELATED_TYPE_LIST as CRUD_ACTION_RELATED_TYPE_LIST,
    TICKETS_REFERENCE_TYPE_LIST as CRUD_ACTION_REFERENCE_TYPE_LIST,
    TICKETS_ROOTS_LIST as ROOTS_CRUD_ACTION_LIST,
    TICKETS_ROOTS_SHOW as ROOTS_CRUD_ACTION_SHOW,
    TICKETS_ROOTS_CREATE as ROOTS_CRUD_ACTION_CREATE,
    TICKETS_ROOTS_UPDATE as ROOTS_CRUD_ACTION_UPDATE,
    TICKETS_ROOTS_DELETE as ROOTS_CRUD_ACTION_DELETE,
    TICKETS_ROOTS_UPDATE_STATUS as ROOTS_CRUD_ACTION_UPDATE_STATUS,
    TICKETS_ROOTS_TYPE_LIST as ROOTS_CRUD_ACTION_TYPE_LIST,
    TICKETS_ROOTS_LANGUAGE_CODE_LIST as ROOTS_CRUD_ACTION_LANGUAGE_CODE_LIST,
    TICKETS_ROOTS_TYPES_LIST as TYPES_CRUD_ACTION_LIST,
    TICKETS_ROOTS_TYPES_CREATE as TYPES_CRUD_ACTION_CREATE,
    TICKETS_ROOTS_TYPES_SHOW as TYPES_CRUD_ACTION_SHOW,
    TICKETS_ROOTS_TYPES_UPDATE as TYPES_CRUD_ACTION_UPDATE,
    TICKETS_ROOTS_TYPES_UPDATE_STATUS as TYPES_CRUD_ACTION_UPDATE_STATUS,
    TICKETS_ROOTS_TYPES_ACTIONS_LIST as ACTIONS_CRUD_ACTION_LIST,
    TICKETS_ROOTS_TYPES_ACTIONS_CREATE as ACTIONS_CRUD_ACTION_CREATE,
    TICKETS_ROOTS_TYPES_ACTIONS_SHOW as ACTIONS_CRUD_ACTION_SHOW,
    TICKETS_ROOTS_TYPES_ACTIONS_UPDATE as ACTIONS_CRUD_ACTION_UPDATE,
    TICKETS_ROOTS_TYPES_ACTIONS_UPDATE_STATUS as ACTIONS_CRUD_ACTION_UPDATE_STATUS,
    TICKETS_ROOTS_TYPES_ACTIONS_TYPE_LIST as ACTIONS_CRUD_ACTION_TYPE_LIST,


} from "../actions/tickets";
import axioses from "@/axios/config";
import qs from "qs"
const additionalHeaders={
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};
const routePrefix='tickets';
const rootsRoutePrefix='ticket/roots';
const typesRoutePrefix='types';
const actionsRoutePrefix='actions';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_OWNER_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, {root: true});
        axioses.base
          .get(`/${routePrefix}/ownerTypes`, {params})
          .then(response => {
              resolve(response);
              commit('STOP_LOADING', params.withLoading, {root: true});
          })
          .catch(error => {
              commit('STOP_LOADING', params.withLoading, {root: true});
              reject(error);
          });
    });
  },
  [CRUD_ACTION_RELATED_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, {root: true});
        axioses.base
            .get(`/${routePrefix}/relatedTypes`, {params})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, {root: true});
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, {root: true});
                reject(error);
            });
    });
  },
  [CRUD_ACTION_REFERENCE_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, {root: true});
        axioses.base
            .get(`/${routePrefix}/referenceTypes`, {params})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, {root: true});
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, {root: true});
                reject(error);
            });
    });
  },
  [ROOTS_CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${rootsRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [ROOTS_CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
        axioses.base
            .post(`/${rootsRoutePrefix}/create`, fData,additionalHeaders)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [ROOTS_CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${rootsRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ROOTS_CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData=new FormData();
        Object.keys(params.data).forEach(key=>{
            if(typeof params.data[key] !== "undefined"  && params.data[key] !==null){
                fData.append(key,params.data[key]);
            }
        });
        axioses.base
            .put(`/${rootsRoutePrefix}/${params.id}`,fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ROOTS_CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${rootsRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ROOTS_CRUD_ACTION_UPDATE_STATUS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let statusList={false:'DISABLE',true:'ENABLE'};

        axioses.base
            .patch(`/${rootsRoutePrefix}/${params.id}/${statusList[params.value]}`, {...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ROOTS_CRUD_ACTION_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${rootsRoutePrefix}/types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [ROOTS_CRUD_ACTION_LANGUAGE_CODE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${rootsRoutePrefix}/supported_languages`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [TYPES_CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${rootsRoutePrefix}/${params.ticketId}/${typesRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [TYPES_CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
        axioses.base
            .post(`/${rootsRoutePrefix}/${params.ticketId}/${typesRoutePrefix}/create`, fData,additionalHeaders)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [TYPES_CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${rootsRoutePrefix}/${params.ticketId}/${typesRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [TYPES_CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData=new FormData();
        Object.keys(params.data).forEach(key=>{
            if(typeof params.data[key] !== "undefined"  && params.data[key] !==null){
                fData.append(key,params.data[key]);
            }
        });
        axioses.base
            .put(`/${rootsRoutePrefix}/${params.ticketId}/${typesRoutePrefix}/${params.id}`,fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [TYPES_CRUD_ACTION_UPDATE_STATUS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let statusList={false:'DISABLE',true:'ENABLE'};

        axioses.base
            .patch(`/${rootsRoutePrefix}/${params.ticketId}/${typesRoutePrefix}/${params.id}/${statusList[params.value]}`, {...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ACTIONS_CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${rootsRoutePrefix}/${params.ticketId}/${typesRoutePrefix}/${params.typeId}/${actionsRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [ACTIONS_CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
        axioses.base
            .post(`/${rootsRoutePrefix}/${params.ticketId}/${typesRoutePrefix}/${params.typeId}/${actionsRoutePrefix}/create`, fData,additionalHeaders)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
   },
  [ACTIONS_CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${rootsRoutePrefix}/${params.ticketId}/${typesRoutePrefix}/${params.typeId}/${actionsRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ACTIONS_CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData=new FormData();
        Object.keys(params.data).forEach(key=>{
            if(typeof params.data[key] !== "undefined"  && params.data[key] !==null){
                fData.append(key,params.data[key]);
            }
        });
        axioses.base
            .put(`/${rootsRoutePrefix}/${params.ticketId}/${typesRoutePrefix}/${params.typeId}/${actionsRoutePrefix}/${params.id}`,fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ACTIONS_CRUD_ACTION_UPDATE_STATUS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let statusList={false:'DISABLE',true:'ENABLE'};

        axioses.base
            .patch(`/${rootsRoutePrefix}/${params.ticketId}/${typesRoutePrefix}/${params.typeId}/${actionsRoutePrefix}/${params.id}/${statusList[params.value]}`, {...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ACTIONS_CRUD_ACTION_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${rootsRoutePrefix}/${params.ticketId}/${typesRoutePrefix}/${params.typeId}/${actionsRoutePrefix}/types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
