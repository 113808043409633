<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <input-text v-model="appBuild" :options="{ id: 'app_build', transPrefix: `${transPrefix}.fields`, label: 'app_build', disabled: true }"/>
              <input-text v-model="appVersion" :options="{ id: 'app_version', transPrefix: `${transPrefix}.fields`, label: 'app_version', disabled: true }"/>
              <input-text v-model="bundleId" :options="{ id: 'bundle_id', transPrefix: `${transPrefix}.fields`, label: 'bundle_id', disabled: true }"/>
              <input-text v-model="deviceManufacturer" :options="{ id: 'deviceManufacturer', transPrefix: `${transPrefix}.fields`, label: 'manufacturer', disabled: true }"/>
              <input-text v-model="deviceModel" :options="{ id: 'deviceModel', transPrefix: `${transPrefix}.fields`, label: 'device_model', disabled: true }"/>
              <input-text v-model="deviceType" :options="{ id: 'deviceType', transPrefix: `${transPrefix}.fields`, label: 'device_type', disabled: true }"/>
              <input-text v-model="language" :options="{ id: 'language', label: 'language', disabled: true }"/>
              <input-text v-model="os" :options="{ id: 'os', transPrefix: `${transPrefix}.fields`, label: 'os', disabled: true }"/>
              <input-text v-model="openedAt" :options="{ id: 'opened_at', label: 'opened_at', disabled: true , inputClass: 'form-control text-direction-ltr text-left' }"/>
            </div>
            <div class="row" v-if="!serverError">
              <input-checkbox v-model="enableNotifications" :options="{ id: 'enableNotifications', transPrefix: `${transPrefix}.fields`, label: 'enable_notifications', disabled: true }"/>
              <input-checkbox v-model="locationPermissionAllowed" :options="{ id: 'locationPermissionAllowed', transPrefix: `${transPrefix}.fields`, label: 'location_permission_allowed', disabled: true }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { CAPTAINS_DEVICES_SHOW as CRUD_ACTION_SHOW } from 'actions/captains';
  const CAPTAINS_CODE="captains";
  const CRUD_CODE=`${CAPTAINS_CODE}.devices`;
  const CRUD_PERMISSION_CODE=`${CAPTAINS_CODE}_devices`;

  export default {
    name: "SupplierShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        captainsRoutePrefix: `${CAPTAINS_CODE}`,
        captainsTransPrefix: `${CAPTAINS_CODE}`,
        id:this.$router.currentRoute.params.id,
        device_id:this.$router.currentRoute.params.device_id,
        appBuild: "",
        appVersion: "",
        bundleId: "",
        deviceManufacturer: "",
        deviceModel: "",
        deviceType: "",
        enableNotifications: false,
        language: "",
        locationPermissionAllowed: false,
        openedAt: "",
        os: "",
        serverError: '',
        image:{
          file:'',
          thumbnail:'',
        },
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
          device_id: this.device_id,
        }).then(response=>{
          this.appBuild=response.data.appBuild;
          this.appVersion=response.data.appVersion;
          this.bundleId=response.data.bundleId;
          this.deviceManufacturer=response.data.deviceManufacturer;
          this.deviceModel=response.data.deviceModel;
          this.deviceType=response.data.deviceType;
          this.enableNotifications=response.data.enableNotifications;
          this.language=response.data.language;
          this.locationPermissionAllowed=response.data.locationPermissionAllowed;
          this.openedAt=response.data.openedAt?this.$moment(response.data.openedAt).format("Y-MM-DD hh:mm:ss A"):"";
          this.os=response.data.os;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
