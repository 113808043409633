import {
    REPORTS_LIST as CRUD_ACTION_LIST,
    REPORTS_CREATE as CRUD_ACTION_CREATE
} from "../../actions/suppliers_portal/reports";
import axioses from "@/axios/config";
import qs from "qs";
const routePrefix='suppliers/reports';

const state = {};

const getters = {};

const actions = {
    [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
        return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.supplierPortal
            .get(`${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
        });
    },
    [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
        return new Promise((resolve, reject) => {
            axioses.supplierPortal
                .post(`${routePrefix}/create`, params)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
};
