import {
    CAPTAINS_LIST as CRUD_ACTION_LIST,
    CAPTAINS_CREATE as CRUD_ACTION_CREATE,
    CAPTAINS_SHOW as CRUD_ACTION_SHOW,
    CAPTAINS_UPDATE as CRUD_ACTION_UPDATE,
    CAPTAINS_DELETE as CRUD_ACTION_DELETE,
    CAPTAINS_UPDATE_RIDER_SUSPENSION_STATUS,
    CAPTAINS_RESTORE,
    CAPTAINS_WALLET_SHOW,
    CAPTAINS_WALLET_TRANSACTIONS_LIST,
    CAPTAINS_WALLET_TRANSACTIONS_ADJUSTMENT,
    CAPTAINS_WALLET_TRANSACTIONS_TYPE_LIST,
    CAPTAINS_REQUEST_RESET_PASSWORD,
    CAPTAINS_ADD_CAPABILITIES as CRUD_ACTION_ADD_CAPABILITIES,
    CAPTAINS_REMOVE_CAPABILITIES as CRUD_ACTION_REMOVE_CAPABILITIES,
    CAPTAINS_DEVICES_LIST as DEVICES_ACTION_LIST,
    CAPTAINS_DEVICES_SHOW as DEVICES_ACTION_SHOW,
    CAPTAINS_ID_TYPE_LIST as CRUD_ACTION_ID_TYPE_LIST,
    CAPTAINS_STATUS_LIST as CRUD_ACTION_STATUS_LIST,
    CAPTAINS_WORKING_STATUS_LIST as CRUD_ACTION_WORKING_STATUS_LIST,
    CAPTAINS_FINTECH_TYPE_LIST as CRUD_ACTION_FINTECH_TYPE_LIST,
} from "../actions/captains";
import axioses from "@/axios/config";
import qs from "qs";

const additionalHeaders={
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};

const routePrefix='captains';
const walletRoutePrefix='wallet';
const transactionsRoutePrefix='transactions';
const devicesRoutePrefix='devices';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });

      axioses.base
          .get(`/${routePrefix}`,{ params, paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })} })
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
        axioses.base
            .post(`/${routePrefix}/create`, fData,additionalHeaders)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData=new FormData();
        Object.keys(params.data).forEach(key=>{
            if(typeof params.data[key] !== "undefined"  && params.data[key] !==null){
                fData.append(key,params.data[key]);
            }
        });
        axioses.base
            .put(`/${routePrefix}/${params.id}`,fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CAPTAINS_UPDATE_RIDER_SUSPENSION_STATUS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let suspendedStatusList={false:'UN_SUSPEND',true:'SUSPEND'};

        axioses.base
            .patch(`/${routePrefix}/rider/${params.id}/suspension/${suspendedStatusList[params.value]}`, {...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CAPTAINS_RESTORE]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
            commit('START_LOADING', null, { root: true });

            axioses.base
                .patch(`/${routePrefix}/${params.id}/restore`, {...params.data})
                .then(response => {
                    resolve(response);
                    commit('STOP_LOADING', null, { root: true });
                })
                .catch(error => {
                    commit('STOP_LOADING', null, { root: true });
                    reject(error);
                });
      });
  },
  [CAPTAINS_WALLET_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${walletRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CAPTAINS_WALLET_TRANSACTIONS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${walletRoutePrefix}/${params.id}/${transactionsRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params.data, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [CAPTAINS_WALLET_TRANSACTIONS_ADJUSTMENT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .post(`/${walletRoutePrefix}/${transactionsRoutePrefix}/${params.id}/adjustment`,{ ...params.data })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [CAPTAINS_REQUEST_RESET_PASSWORD]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });

        axioses.base
            .patch(`/${routePrefix}/${params.id}/reset-password`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_ADD_CAPABILITIES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .put(`/${routePrefix}/${params.id}/capabilities/${params.capability_id}/add`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_REMOVE_CAPABILITIES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .delete(`/${routePrefix}/${params.id}/capabilities/${params.capability_id}/delete`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [CAPTAINS_WALLET_TRANSACTIONS_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${walletRoutePrefix}/${transactionsRoutePrefix}/types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [DEVICES_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}/${devicesRoutePrefix}`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [DEVICES_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}/${devicesRoutePrefix}/${params.device_id}`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_ID_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let response ={
            data: ["IQAMA", "ID"]
        };
        resolve(response);
    });
  },
  [CRUD_ACTION_STATUS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let response ={
            data: ["NEWLY_CREATED", "PENDING_FOR_APPROVAL", "ACTIVE", "DEACTIVE"]
        };
        resolve(response);
    });
  },
  [CRUD_ACTION_WORKING_STATUS_LIST]: ({ commit, dispatch }, params) => {
        return new Promise((resolve, reject) => {
            let response ={
                data: ["FREE", "BUSY"]
            };
            resolve(response);
        });
  },
  [CRUD_ACTION_FINTECH_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let response ={
            data: ["STC_PAY"]
        };
        resolve(response);
    });
  }
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
