<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <files-image v-model="image" :options="{ id: 'image', label: 'image', error: fieldErrors.profilePicture, containerClass: 'col-12 col-md-4' }"/>
              <files-image v-model="identity" :options="{ id: 'identity', transPrefix: `${transPrefix}.fields`, label: 'identity', error: fieldErrors.identity, containerClass: 'col-12 col-md-4' }"/>
              <files-image v-model="driving_license" :options="{ id: 'driving_license', transPrefix: `${transPrefix}.fields`, label: 'driving_license', error: fieldErrors.drivingLicense, containerClass: 'col-12 col-md-4' }"/>
              <files-image v-model="registration_license" :options="{ id: 'registration_license', transPrefix: `${transPrefix}.fields`, label: 'registration_license', error: fieldErrors.registrationLicense, containerClass: 'col-12 col-md-4' }"/>
            </div>
            <div class="row">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', error: fieldErrors.name }"/>
              <input-email :value="email" :options="{ id: 'email', label: 'email', preventSpace: true, error: fieldErrors.email, readonly: true }"/>
              <select-countries v-model="country_id" :options="{ id: 'country_id', label: 'country', action: countries_list_action, error: fieldErrors.countryId }"/>
              <select-cities v-model="default_city_id" :country_id="country_id" :options="{ id: 'default_city_id', transPrefix :`${transPrefix}.fields`, label: 'default_city', action: cities_list_action, disabled: !country_id, error: fieldErrors.defaultCityId }"/>
              <input-text v-model="mobile_number" :options="{ id: 'mobile_number', label: 'mobile_number', error: fieldErrors['userMobile.mobileNumber'] }"/>
              <input-date v-model="date_of_birth" :options="{ id: 'date_of_birth', label: 'date_of_birth', error: fieldErrors['details.dateOfBirth'] }"/>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="nationality" class="d-block">{{ $t(`fields.nationality`)}}</label>
                  <vue-country-code id="nationality" class="w-25" @onSelect="onSelectNationality" :defaultCountry="nationality" :dropdownOptions="{ disabledDialCode: true }"/>
                  <input-text :value="nationality_label" :options="{ error: fieldErrors['details.nationality'], disabled: true ,fullLayout: false , inputClass: 'form-control w-75 d-inline-block' }"/>
                  <span v-if="fieldErrors['details.nationality']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['details.nationality'] }}</strong>
                  </span>
                </div>
              </div>
              <input-text v-model="id_number" :options="{ id: 'id_number', transPrefix: `${transPrefix}.fields`, label: 'id_number', preventSpace: true, error: fieldErrors.idNumber }"/>
              <select-captain-id-type v-model="id_type" :options="{ id: 'id_type', transPrefix: `${transPrefix}.fields`, label: 'id_type', error: fieldErrors.idType }"/>
              <input-password v-model="password" :options="{ id: 'password', label: 'password', error: fieldErrors.password }"/>
              <input-text v-model="iban_name" :options="{ id: 'iban_name', transPrefix: `${transPrefix}.fields`, label: 'iban_name', error: fieldErrors['iban.name'] }"/>
              <input-text v-model="iban_number" :options="{ id: 'iban_number', transPrefix: `${transPrefix}.fields`, label: 'iban_number', error: fieldErrors['iban.iban'] }"/>
              <select-banks v-model="iban_bank_id" :options="{ id: 'iban_bank_id', transPrefix: `${transPrefix}.fields`, label: 'iban_bank', action: banks_list_action, error: fieldErrors['iban.bankId'] }"/>
              <select-fintech-type v-model="fintech_type" :options="{ id: 'fintech_type', transPrefix: `${transPrefix}.fields`, label: 'fintech_type', error: fieldErrors['fintechAccount.accountType'] }"/>
              <input-text v-model="fintech_id" :options="{ id: 'fintech_id', transPrefix: `${transPrefix}.fields`, label: 'fintech_id', error: fieldErrors['fintechAccount.accountId'] }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { CAPTAINS_CREATE as CRUD_ACTION_CREATE } from 'actions/suppliers_portal/captains';
  import { COUNTRIES_PUBLIC_LIST } from 'actions/countries';
  import { CITIES_PUBLIC_LIST } from 'actions/cities';
  import { BANKS_PUBLIC_LIST } from 'actions/banks';

  const CAPTAINS_CODE="captains";
  const CRUD_CODE=`suppliers_portal.${CAPTAINS_CODE}`;
  export default {
    name: "SuppliersPortalCaptainCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CAPTAINS_CODE,
        image:{
          file:'',
          thumbnail:'',
        },
        driving_license:{
          file:'',
          thumbnail:'',
        },
        registration_license:{
          file:'',
          thumbnail:'',
        },
        identity:{
          file:'',
          thumbnail:'',
        },
        name: '',
        password: '',
        mobile_number: '',
        nationality: '',
        nationality_label: '',
        date_of_birth: '',
        iban_name:'',
        iban_number:'',
        status:'',
        iban_bank_id:'',
        id_number:'',
        id_type:'',
        default_city_id:'',
        country_id: "",
        fintech_type: "",
        fintech_id: "",
        serverError: '',
        fieldErrors: {},
        loading: false,
        banks_list_action:BANKS_PUBLIC_LIST,
        countries_list_action:COUNTRIES_PUBLIC_LIST,
        cities_list_action:CITIES_PUBLIC_LIST,
      }
    },
    computed:{
      email:{
        get: function(){
          return `${this.id_number}@brisk.delivery`
        }
      }
    },
    methods: {
      prepareIbanRequestData(){
        let iban={};
        if(this.iban_name || this.iban_bank_id || this.iban_number){
          iban= {
            "iban.name": this.sanitizeField('iban_name'),
            "iban.iban": this.sanitizeField('iban_number'),
            "iban.bankId": this.sanitizeField('iban_bank_id'),
          };
        }

        return iban;
      },
      prepareFintechRequestData() {
        let fintechAccount={};
        if (this.fintech_type && this.fintech_id) {
            fintechAccount= {
              "fintechAccount.accountType": this.sanitizeField('fintech_type'),
              "fintechAccount.accountId" : this.sanitizeField('fintech_id')
            }
        }
        return fintechAccount;
      },
      prepareDetailsRequestData() {
        let details={};
        if(this.date_of_birth || this.nationality){
          details= {
            "details.nationality": this.sanitizeField('nationality'),
            "details.dateOfBirth": this.sanitizeField('date_of_birth'),
          };
        }
        return details;
      },
      prepareRequestData(){ 
        return {
          email: this.sanitizeField('email'),
          name: this.sanitizeField('name'),
          countryId: this.sanitizeField('country_id'),
          password: this.sanitizeField('password'),
          idType: this.sanitizeField('id_type'),
          idNumber: this.sanitizeField('id_number'),
          defaultCityId: this.sanitizeField('default_city_id'),
          "userMobile.mobileNumber":this.sanitizeField('mobile_number'),
          ...this.prepareIbanRequestData(),
          ...this.prepareFile('image',"profilePicture"),
          ...this.prepareFile('driving_license',"drivingLicense"),
          ...this.prepareFile('registration_license',"registrationLicense"),
          ...this.prepareFile('identity',"identity"),
          ...this.prepareFintechRequestData(),
          ...this.prepareDetailsRequestData(),
        }
      },
      create() {
        this.loading = true
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
      onSelectNationality({name, iso2, dialCode}) {
        this.nationality=iso2.toLowerCase();
        this.nationality_label=`${name} (${iso2})`;
      },
    }
  };
</script>
