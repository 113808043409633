import Index from "@/views/tickets/index";
import Show from "@/views/tickets/show";
import RootsIndex from "@/views/tickets/roots/index";
import RootsCreate from "@/views/tickets/roots/create";
import RootsShow from "@/views/tickets/roots/show";
import RootsEdit from "@/views/tickets/roots/edit";
import TypesIndex from "@/views/tickets/roots/types/index";
import TypesCreate from "@/views/tickets/roots/types/create";
import TypesShow from "@/views/tickets/roots/types/show";
import TypesEdit from "@/views/tickets/roots/types/edit";
import ActionsIndex from "@/views/tickets/roots/types/actions/index";
import ActionsCreate from "@/views/tickets/roots/types/actions/create";
import ActionsShow from "@/views/tickets/roots/types/actions/show";
import ActionsEdit from "@/views/tickets/roots/types/actions/edit";

import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='tickets';
const PERMISSION_PREFIX=`${CODE}`;
const ROOTS_CODE='roots';
const ROOTS_PERMISSION_PREFIX=`${PERMISSION_PREFIX}`;
const TYPES_CODE='types';
const TYPES_PERMISSION_PREFIX=`${PERMISSION_PREFIX}`;
const ACTIONS_CODE='actions';
const ACTIONS_PERMISSION_PREFIX=`${PERMISSION_PREFIX}`;

const routes = [
  {
    path: `${CODE}/${ROOTS_CODE}`,
    name:`${CODE}.${ROOTS_CODE}.index`,
    component: RootsIndex,
    meta: {
      'can': [`${ROOTS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ROOTS_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${ROOTS_CODE}.title`,
          },
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${ROOTS_CODE}/create`,
    name:`${CODE}.${ROOTS_CODE}.create`,
    component: RootsCreate,
    meta: {
      'can': [`${ROOTS_PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ROOTS_CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${ROOTS_CODE}.title`,
          },
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${ROOTS_CODE}/:id`,
    name:`${CODE}.${ROOTS_CODE}.show`,
    component: RootsShow,
    meta: {
      'can': [`${ROOTS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ROOTS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${ROOTS_CODE}.title`,
          },
          {
            'title': ':id'
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${ROOTS_CODE}/:id/edit`,
    name:`${CODE}.${ROOTS_CODE}.edit`,
    component: RootsEdit,
    meta: {
      'can': [`${ROOTS_PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ROOTS_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${ROOTS_CODE}.title`,
          },
          {
            'title': ':id'
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id`,
    name:`${CODE}.show`,
    component: Show,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${ROOTS_CODE}/:id/${TYPES_CODE}`,
    name:`${CODE}.${ROOTS_CODE}.${TYPES_CODE}.index`,
    component: TypesIndex,
    meta: {
      'can': [`${TYPES_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${ROOTS_CODE}.title`,
          },
          {
            'title': ':id'
          },
          {
            'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.title`,
          },
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${ROOTS_CODE}/:id/${TYPES_CODE}/create`,
    name:`${CODE}.${ROOTS_CODE}.${TYPES_CODE}.create`,
    component: TypesCreate,
    meta: {
      'can': [`${TYPES_PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${ROOTS_CODE}.title`,
          },
          {
            'title': ':id'
          },
          {
            'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.title`,
          },
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${ROOTS_CODE}/:id/${TYPES_CODE}/:type_id`,
    name:`${CODE}.${ROOTS_CODE}.${TYPES_CODE}.show`,
    component: TypesShow,
    meta: {
      'can': [`${TYPES_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${ROOTS_CODE}.title`,
          },
          {
            'title': ':id'
          },
          {
            'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.title`,
          },
          {
            'title': ':type_id'
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${ROOTS_CODE}/:id/${TYPES_CODE}/:type_id/edit`,
    name:`${CODE}.${ROOTS_CODE}.${TYPES_CODE}.edit`,
    component: TypesEdit,
    meta: {
      'can': [`${TYPES_PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${ROOTS_CODE}.title`,
          },
          {
            'title': ':id'
          },
          {
            'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.title`,
          },
          {
            'title': ':type_id'
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${ROOTS_CODE}/:id/${TYPES_CODE}/:type_id/${ACTIONS_CODE}`,
    name:`${CODE}.${ROOTS_CODE}.${TYPES_CODE}.${ACTIONS_CODE}.index`,
    component: ActionsIndex,
    meta: {
      'can': [`${ACTIONS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.${ACTIONS_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${ROOTS_CODE}.title`,
          },
          {
            'title': ':id'
          },
          {
            'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.title`,
          },
          {
            'title': ':type_id'
          },
          {
            'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.${ACTIONS_CODE}.title`,
          },
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${ROOTS_CODE}/:id/${TYPES_CODE}/:type_id/${ACTIONS_CODE}/create`,
    name:`${CODE}.${ROOTS_CODE}.${TYPES_CODE}.${ACTIONS_CODE}.create`,
    component: ActionsCreate,
    meta: {
      'can': [`${ACTIONS_PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.${ACTIONS_CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${ROOTS_CODE}.title`,
          },
          {
            'title': ':id'
          },
          {
            'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.title`,
          },
          {
            'title': ':type_id'
          },
          {
            'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.${ACTIONS_CODE}.title`,
          },
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${ROOTS_CODE}/:id/${TYPES_CODE}/:type_id/${ACTIONS_CODE}/:action_id`,
    name:`${CODE}.${ROOTS_CODE}.${TYPES_CODE}.${ACTIONS_CODE}.show`,
    component: ActionsShow,
    meta: {
      'can': [`${ACTIONS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.${ACTIONS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${ROOTS_CODE}.title`,
          },
          {
            'title': ':id'
          },
          {
            'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.title`,
          },
          {
            'title': ':type_id'
          },
          {
            'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.${ACTIONS_CODE}.title`,
          },
          {
            'title': ':action_id'
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/${ROOTS_CODE}/:id/${TYPES_CODE}/:type_id/${ACTIONS_CODE}/:action_id/edit`,
    name:`${CODE}.${ROOTS_CODE}.${TYPES_CODE}.${ACTIONS_CODE}.edit`,
    component: ActionsEdit,
    meta: {
      'can': [`${ACTIONS_PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.${ACTIONS_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.${ROOTS_CODE}.title`,
          },
          {
            'title': ':id'
          },
          {
            'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.title`,
          },
          {
            'title': ':type_id'
          },
          {
            'title': `${CODE}.${ROOTS_CODE}.${TYPES_CODE}.${ACTIONS_CODE}.title`,
          },
          {
            'title': ':action_id'
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
