<template>
    <searchContainer :searchHandler="search" :resetHandler="reset">
        <template v-slot:items>
            <div class="row">
                <input-number v-model="filters.captain_id" :options="{ id: 'captain_id', step: 1, label: 'captain_id' }"/>
                <input-number v-model="filters.wallet_id" :options="{ id: 'wallet_id', step: 1, label: 'wallet_id' }"/>
                <input-text v-model="filters.note" :options="{ id: 'note', step: 1, label: 'note' }"/>
                <select-wallet-transaction-types v-model="filters.types" :options="{ id: 'wallet_transaction_types', label: 'type', placeholder: 'placeholders.all', multiple: true }"/>
            </div>
            <div class="row">
                <input-date v-model="filters.created_from" :options="{ id: 'created_from', label: 'created_from' }"/>
                <input-date v-model="filters.created_to" :options="{ id: 'created_to', label: 'created_to' }"/>
            </div>
        </template>
    </searchContainer>
</template>
<script>

    import {mapGetters} from "vuex";
    import {CRUD_SEARCH_RESET} from "actions/crud";
    import searchContainer from "@/components/crud/card/CardSearch";

    const CRUD_CODE = `wallets_transactions`;
    export default {
        name: "WalletTransactionSearch",
        components: {
            searchContainer
        },
        props: {
            listHandler: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                transPrefix: CRUD_CODE,
                filters: {
                    note: this.getFilterByKey('note'),
                    types: this.getFilterByKey('types'),
                    created_from: this.getFilterByKey('created_from'),
                    created_to: this.getFilterByKey('created_to'),
                    captain_id: this.getFilterByKey('captain_id'),
                    wallet_id: this.getFilterByKey('wallet_id'),
                },
                filtersMap: {
                    note: 'note',
                    types: 'types',
                    created_from: 'startDate',
                    created_to: 'endDate',
                    captain_id: 'captainId',
                    wallet_id: 'walletId',
                }
            }
        },
        mounted() {
            this.setSearchParams(this.filters, this.filtersMap);
        },
        computed: {
            ...mapGetters([
                "getSearchFilters"
            ])
        },
        methods: {
            search() {
                this.resetPagination();
                this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                this.pushRoute(this.getSearchFilters);
                this.listHandler();
            },
            reset() {
                this.resetPagination();
                this.$store.dispatch(CRUD_SEARCH_RESET);
                this.filters = this.clearFilters(this.filters);
                this.pushRoute();
                this.listHandler();
            },
            resetPagination() {
                //Reset Pagination
                this.$emit('input', 0);
            },
            pushRoute(query = {}) {
                this.$router.push({name: this.$route.name, query}).catch(() => {});
            },
        }
    };
</script>
