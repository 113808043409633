<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div v-if="serverError" class="alert alert-danger">
                <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
              </div>
            </div>
          </div>
          <div class="row" v-if="!serverError">
            <files-image v-model="image" :options="{ id: 'image', disabled: true }"/>
          </div>
          <div class="row" v-if="!serverError">
            <input-email v-model="email" :options="{ id: 'email', label: 'email', disabled: true }"/>
            <input-text v-model="name" :options="{ id: 'name', label: 'name', disabled: true }"/>
            <input-text v-model="supplier_name" :options="{ id: 'supplier', label: 'supplier', disabled: true }"/>
            <input-text v-model="mobile_number" :options="{ id: 'mobile_number', label: 'mobile_number', disabled: true }"/>
            <input-text v-model="role" :options="{ id: 'roles', label: 'roles', disabled: true }"/>
          </div>
        </div>
        <!-- Card footer -->
        <div class="card-footer">
          <div class="row">
            <div class="col text-right">
              <router-link :to="{ name: `${routePrefix}.index`,params: {id}}"  class="btn btn-neutral btn-sm">
                <i class="fa fa-arrow-left"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { SUPPLIERS_USERS_SHOW as CRUD_ACTION_SHOW } from 'actions/suppliers';
  const SUPPLIERS_CODE="suppliers";
  const CRUD_CODE=`${SUPPLIERS_CODE}.users`;
  export default {
    name: "SupplierUserShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: `${CRUD_CODE}`,
        suppliersRoutePrefix: `${SUPPLIERS_CODE}`,
        suppliersTransPrefix: `${SUPPLIERS_CODE}`,
        id:this.$router.currentRoute.params.id,
        user_id:this.$router.currentRoute.params.user_id,
        image:{
          file:'',
          thumbnail:'',
        },
        email: '',
        name: '',
        role: '',
        mobile_number: '',
        supplier_name: '',
        serverError: '',
        responseStatus:'',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          supplierId: this.id,
          id: this.user_id,
        }).then(response=>{
          this.email=response.data.email;
          this.name=response.data.name;
          this.role=response.data.role;
          this.mobile_number=response.data.userMobile;
          this.supplier_name=response.data.supplierName;
          this.image.thumbnail=response.data.profilePicture?response.data.profilePicture.url:'';
        }).catch(error => {
          this.loading = false
          this.serverError='';
          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;
            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }
          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
