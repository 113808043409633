<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <a href="javascript:;"
             class="btn btn-primary btn-sm"
             data-target="#modal-transaction-adjustment"
             data-toggle="modal"
             v-can:edit="permissionPrefix"
          >
            <span class="d-none d-lg-inline mr-2">{{ $t(`labels.adjust`)}}</span>
            <span>
              <i class="fa fa-adjust"></i>
            </span>
          </a>
          <router-link :to="{ name: `${walletRoutePrefix}.show`,params:{id} }"  class="btn btn-primary btn-sm">
            <i class="fa fa-arrow-left"></i>
          </router-link>
        </template>

        <template v-slot:card-header-search>
          <search v-model="lastPage" :listHandler="getList"/>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td> {{ item.transactionTypeTitle }} </td>
            <td> {{ item.balance }} </td>
            <td> {{ item.transactionType }} </td>
            <td> {{ item.amount }} </td>
            <td> {{ item.note }} </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>

        <template v-slot:card-footer>
          <export-action :crudCode="`wallet/${id}/transactions`" v-can:report="permissionPrefix"></export-action>
        </template>
      </card-container>
    </div>
    <div aria-hidden="true" aria-labelledby="modal-transaction-adjustment" class="modal fade" id="modal-transaction-adjustment"
         role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">{{$t('labels.transactions_adjustment')}}</h6>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form role="form" @submit.prevent="adjust">
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div v-if="serverError" class="alert alert-danger">
                    <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                  </div>
                </div>
              </div>
              <div class="row">
                <input-text v-model="note" :options="{ id: 'note', label: 'note', error: fieldErrors.note, containerClass: 'col-12 col-md-12' }"/>
                <input-number v-model="amount" :options="{ id: 'amount', label: 'amount', error: fieldErrors.amount, containerClass: 'col-12 col-md-12' }"/>
              </div>
            </div>
            <div class="modal-footer">
            <button class="btn btn-danger btn-sm ml-auto" data-dismiss="modal" type="button">
              {{ $t('labels.close') }}
            </button>
            <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
              <span v-if="loading" class="spinner-border spinner-border-sm"></span>
              {{ $t('labels.submit') }}
            </button>
          </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

  import {mapGetters} from "vuex";
  import { CAPTAINS_WALLET_TRANSACTIONS_LIST as CRUD_ACTION_LIST,CAPTAINS_WALLET_TRANSACTIONS_ADJUSTMENT } from 'actions/captains';
  import search from "./partials/search";
  const CAPTAINS_CODE="captains";
  const WALLET_CODE=`${CAPTAINS_CODE}.wallet`;
  const CRUD_CODE=`${WALLET_CODE}.transactions`;
  const CRUD_PERMISSION_CODE=`wallets`;
  export default {
    name: "CaptainWalletTransactionsIndex",
    components:{
      search,
    },
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        walletRoutePrefix: `${WALLET_CODE}`,
        walletTransPrefix: `${WALLET_CODE}`,
        captainsRoutePrefix: `${CAPTAINS_CODE}`,
        captainsTransPrefix: `${CAPTAINS_CODE}`,
        id:this.$router.currentRoute.params.id,
        wallet_id:this.$router.currentRoute.params.wallet_id,
        tableHeaders:[
          {
            'title':`fields.title`
          },
          {
            'title':`fields.balance`
          },
          {
            'title':`fields.type`
          },
          {
            'title':`fields.amount`
          },
          {
            'title':`fields.note`
          },
          {
            'title':`fields.created_at`
          }
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0,
        serverError: '',
        fieldErrors: {},
        loading: false,
        note:"",
        amount:""
      }
    },
    mounted() {
      this.getList();
    },
    computed:{
      ...mapGetters([
        "getSearchFilters","getSearchFiltersIfExists","getSearchFiltersSensitized"
      ])
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          data:{
            pageId: this.getCurrentPage()-1,
            ...this.getSearchFiltersSensitized
          },
          id:this.wallet_id
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        });
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page,
              ...this.getSearchFiltersIfExists
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
      prepareRequestData(){
        return {
          note: this.sanitizeField('note'),
          amount: this.sanitizeField('amount'),
        }
      },
      adjust() {
        this.loading = true;
        this.$store.dispatch(CAPTAINS_WALLET_TRANSACTIONS_ADJUSTMENT, {
          id: this.wallet_id,
          data: {
            ...this.prepareRequestData()
          },
        })
        .then(() => {
          document.querySelector('#modal-transaction-adjustment [data-dismiss]').click();
          this.loading = false;
          this.amount=null;
          this.note=null;
          this.fieldErrors={};
          this.serverError='';
          this.getList();
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.process_has_successfully"),
            buttons:false,
            timer: 5000
          });

        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    }
  };
</script>
