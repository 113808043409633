<template>
  <div class="row">
    <div class="col">
      <card-container :title="$router.currentRoute.meta.title" :headers="tableHeaders">

        <template v-slot:card-header>
          <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm"  v-can:edit="permissionPrefix">
            <i class="fa fa-plus"></i>
          </router-link>
        </template>

        <template v-slot:table-rows>
          <tr v-if="futureConfigs.length" class="text-center">
            <td colspan="999"><h3>{{ $t('allocation_configs.future_configs_title') }}</h3></td>
          </tr>
          <tr v-for="item in futureConfigs" :key="item.id" v-bind:class="{
                  'future-config-bg-create': item.action === 'CREATE',
                  'future-config-bg-update': item.action === 'UPDATE',
                  'future-config-bg-delete': item.action === 'DELETE'
                }">
            <td> {{ item.name }} </td>
            <td> {{ item.type }} </td>
            <td>
              {{ item.version }}
              <i class="fa mx-2 fa-info-circle" v-b-tooltip.hover="{ animation: false }" :title="getFutureConfigHint(item)"></i>
            </td>
            <td> {{ item.country }} </td>
            <td> {{ item.city }} </td>
            <td> {{ item.zone }} </td>
            <td> {{ item.platform }} </td>
            <td> {{ item.supplier }} </td>
            <td>
              <input-checkbox v-model="item.enabled" :options="{ id: 'status', onChangeHandler: changeStatus, onChangeParams: { id: item.id, value: !item.enabled, versionStatus: 'future' }, fullLayout: false, disabled: !can(permissionPrefix,'edit') || ['DEFAULT'].includes(item.type) }"/>
            </td>
            <td class="text-direction-ltr"> {{ item.appliedAt | moment("Y-MM-DD hh:mm:ss A") }} </td>
            <table-item-actions>
              <template v-slot:additional-items>
                <table-item-actions-show v-if="item.action !== 'DELETE'" :options="{ route_name: 'allocation_configs.show', can: 'settings_can_view' }" :params="{ id: item.id, versionStatus: 'future' }" />
                <table-item-actions-edit v-if="item.action !== 'DELETE'" :options="{ route_name: 'allocation_configs.edit', can: 'settings_can_edit' }" :params="{ id: item.id, versionStatus: 'future' }" />
                <cancel-future-allocation-config :id="item.id" :success-callback="getList" :title="getCancelActionHint(item)" v-can:edit="permissionPrefix" />
              </template>
            </table-item-actions>
          </tr>
          <tr class="text-center">
            <td colspan="999"><h3>{{ $t('allocation_configs.current_configs_title') }}</h3></td>
          </tr>
          <tr v-for="item in currentConfigs" :key="item.id">
            <td> {{ item.name }} </td>
            <td> {{ item.type }} </td>
            <td>
              {{ item.version }}
              <i class="fa mx-2" v-bind:class="{ 'fa-clock': getFutureConfig(item.id), 'fa-check text-success': !getFutureConfig(item.id) }" v-b-tooltip.hover="{ animation: false }" :title="getConfigHint(item)"></i>
            </td>
            <td> {{ item.country }} </td>
            <td> {{ item.city }} </td>
            <td> {{ item.zone }} </td>
            <td> {{ item.platform }} </td>
            <td> {{ item.supplier }} </td>
            <td>
              <input-checkbox v-model="item.enabled" :options="{ id: 'status', onChangeHandler: changeStatus, onChangeParams: { id: item.id, value: !item.enabled, versionStatus: 'current' }, fullLayout: false, disabled: !can(permissionPrefix,'edit') || getFutureConfig(item.id) || ['DEFAULT'].includes(item.type) }"/>
            </td>
            <td class="text-direction-ltr"> {{ item.appliedAt | moment("Y-MM-DD hh:mm:ss A") }} </td>
            <table-item-actions>
              <template v-slot:additional-items>
                <table-item-actions-show :options="{ route_name: 'allocation_configs.show', can: 'settings_can_view' }" :params="{ id: item.id, versionStatus: 'current' }" />
                <table-item-actions-edit v-if="!getFutureConfig(item.id)" :options="{ route_name: 'allocation_configs.edit', can: 'settings_can_edit' }" :params="{ id: item.id, versionStatus: 'current' }" />
                <table-item-actions-delete v-if="!getFutureConfig(item.id) && !['DEFAULT'].includes(item.type)" :options="{ route_name: 'allocation_configs.edit', can: 'settings_can_edit', action: deleteAction, successCallback: getList }" :params="{ id: item.id, versionStatus: 'current' }" />
              </template>
            </table-item-actions>
          </tr>
          <tr class="text-center" v-if="!currentConfigs.length">
            <td colspan="999">{{ $t('messages.no_result_found') }}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import {
    FUTURE_ALLOCATION_CONFIGS_LIST,
    FUTURE_ALLOCATION_CONFIGS_UPDATE_STATUS,
    ALLOCATION_CONFIGS_LIST,
    ALLOCATION_CONFIGS_DELETE,
    ALLOCATION_CONFIGS_UPDATE_STATUS
  } from 'actions/allocation_configs';
  import CancelFutureAllocationConfig from './actions/cancel';

  const CRUD_CODE="allocation_configs";
  const CRUD_PERMISSION_CODE="settings";
  export default {
    name: "AllocationConfigIndex",
    components: {
      CancelFutureAllocationConfig
    },
    data() {
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        tableHeaders:[
          {
            'title': `fields.name`
          },
          {
            'title':`fields.type`
          },
          {
            'title':`fields.version`
          },
          {
            'title':`fields.country`
          },
          {
            'title':`fields.city`
          },
          {
            'title':`fields.zone`
          },
          {
            'title': `fields.platform`
          },
          {
            'title':`fields.supplier`
          },
          {
            'title':`fields.enabled`
          },
          {
            'title':`fields.applied_at`
          },
          {
            'title':`fields.actions`
          }
        ],
        futureConfigs: [],
        currentConfigs: [],
        deleteAction: ALLOCATION_CONFIGS_DELETE
      }
    },
    activated() {
      this.getList();
    },
    methods: {
      getList() {
        this.$store.dispatch(FUTURE_ALLOCATION_CONFIGS_LIST)
            .then(response => this.futureConfigs = response.data)
        this.$store.dispatch(ALLOCATION_CONFIGS_LIST)
            .then(response => this.currentConfigs = response.data)
      },
      getFutureConfig(currentConfigId) {
        return this.futureConfigs.find(futureConfig => futureConfig.allocationConfigId === currentConfigId);
      },
      getConfigHint(config) {
        const futureConfig = this.getFutureConfig(config.id);
        const status = futureConfig?.action?.toLowerCase() || 'current';
        const appliedAt = this.$moment(futureConfig?.appliedAt).format("Y-MM-DD hh:mm:ss A");
        return this.$i18n.t(`allocation_configs.tooltip.config_status_${status}`, [appliedAt]);
      },
      getFutureConfigHint(config) {
        const status = config?.action?.toLowerCase() || 'current';
        const appliedAt = this.$moment(config?.appliedAt).format("Y-MM-DD hh:mm:ss A");
        return this.$i18n.t(`allocation_configs.tooltip.config_status_${status}`, [appliedAt]);
      },
      getCancelActionHint(config) {
        const status = config.action.toLowerCase();
        return this.$i18n.t(`allocation_configs.tooltip.cancel_action_${status}`);
      },
      changeStatus(event, params){
        const action = params.versionStatus === 'future' ? FUTURE_ALLOCATION_CONFIGS_UPDATE_STATUS : ALLOCATION_CONFIGS_UPDATE_STATUS;
        this.$store.dispatch(action, {
          id: params.id,
          value:params.value,
          data: {}
        })
        .then(() => {
          swal({
              icon: 'success',
              text: this.$i18n.t("messages.update_successfully"),
              buttons:false,
              timer: 5000
            });
          this.getList();
        })
        .catch(error => {
          let message="";
          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors && responseData.errors.error){
              message = responseData.errors.error;
            }
            else{
              message = this.$i18n.t("messages.update_error");
            }
          }
          if(message){
            swal({
              icon: 'error',
              text: message,
              buttons:false,
              timer: 5000
            });
          }
        })
      },
    }
  };
</script>

<style scoped>
  .future-config-bg-delete {
    background-color: #ffc7c7;
  }

  .future-config-bg-create {
    background-color: #d0efcf;
  }

  .future-config-bg-update {
    background-color: #fff2b6;
  }

  .allocation-config-tooltip {
    white-space:nowrap;
    max-width:none;
  }
</style>
