<template>
  <keep-alive :include="includePattern" :exclude="excludeItems" max="1">
    <router-view/>
  </keep-alive>
</template>

<script>
  export default {
    data() {
      return {
        includePattern: /Index$|Edit$|Create$|ProfileMe/i,
        excludeItems: [
          "OrderAllocationWindowIndex",
          "CaptainWalletTransactionsIndex",
          "TrackingIndex"
        ]
      }
    }
  }
</script>
