import Index from "@/views/tracking/index";
import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='tracking';
const PERMISSION_PREFIX='tracking';
const routes = [
  {
    path: `${CODE}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`,'g-sidenav-pinned'],
      'addClass' : [`g-sidenav-hidden`],
      'title': `${CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': ``,
        'show': false,
        'container_fluid': false,
        'add_primary_color': false,
        'items': []
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
