<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <files-image v-model="image" :options="{ id: 'image', label: 'image', error: fieldErrors.platformImage }"/>
            </div>
            <div class="row">
              <input-text v-model="en_name" :options="{ id: 'en_name', label: 'en_name', error: fieldErrors.name }"/>
              <input-text v-model="ar_name" :options="{ id: 'ar_name', label: 'ar_name', error: fieldErrors.nameAr }"/>
              <input-text v-model="jwt_callback_token" :options="{ id: 'jwt_callback_token', transPrefix: `${transPrefix}.fields`, label: 'jwt_callback_token', error: fieldErrors.jwtCallbackToken }"/>
              <input-text v-model="web_hook_url" :options="{ id: 'web_hook_url', transPrefix: `${transPrefix}.fields`, label: 'web_hook_url', error: fieldErrors.webHookUrl }"/>
              <input-text v-model="order_prefix_url" :options="{ id: 'order_prefix_url', transPrefix: `${transPrefix}.fields`, label: 'order_prefix_url', error: fieldErrors.orderPrefixUrl }"/>
              <select-countries v-model="country_id" :options="{ id: 'country_id', label: 'country', error: fieldErrors.countryId }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { PLATFORMS_CREATE as CRUD_ACTION_CREATE } from 'actions/platforms';

  const CRUD_CODE="platforms";
  export default {
    name: "PlatformCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        image:{
          file:'',
          thumbnail:'',
        },
        ar_name: '',
        en_name: '',
        jwt_callback_token: '',
        web_hook_url: '',
        order_prefix_url: '',
        country_id: "",
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    methods: {
      prepareRequestData(){
        return {
          name: this.sanitizeField('en_name'),
          nameAr: this.sanitizeField('ar_name'),
          jwtCallbackToken: this.sanitizeField('jwt_callback_token'),
          webHookUrl: this.sanitizeField('web_hook_url'),
          orderPrefixUrl: this.sanitizeField('order_prefix_url'),
          countryId: this.sanitizeField('country_id'),
          ...this.prepareFile('image',"platformImage"),
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData()
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
    }
  };
</script>
