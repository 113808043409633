<template>
    <a href="javascript:;"
       class="btn btn-primary btn-sm"
       :data-target="modalSelector"
       data-toggle="modal"
       @click="setActiveParams(index,itemID)"
       v-b-tooltip.hover="{ animation: false}"
       :title="$t('messages.countdown_title')"
    >
        <i class="fas fa-clock"></i>
    </a>
</template>

<script>
    export default {
        name: "WithdrawRequestActionsCountdown",
        props: {
            itemID:{
                type: Number
            },
            index: {
                type: Number
            },
            modalSelector:{
                type:String,
                default:"#modal-countdown"
            },
        },
        data(){
            return {}
        },
        methods:{
            setActiveParams(index,id) {
                this.$emit('input',{
                    activeId : id,
                    activeIndex : index,
                });
            },
        }
    }
</script>

<style scoped>

</style>
