<template>
    <div aria-hidden="true" aria-labelledby="modal-retry-one" class="modal fade" id="modal-retry-one"  role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">{{ $t('messages.retry_withdrawal_one_title')}}</h6>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form role="form" @submit.prevent="retryWithdrawal">
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div v-if="serverError" class="alert alert-danger">
                    <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                  </div>
                </div>
                <div class="col-12 text-center">
                  <span v-if="loading" class="spinner-border"></span>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-12 col-lg-10" v-if="!loading" v-html="$t('messages.retry_withdrawal_one_text', [createdAt, amount])">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-danger btn-sm ml-auto" data-dismiss="modal" type="button">
                {{ $t('labels.close') }}
              </button>
              <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                {{ $t('labels.submit') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>

<script>
import {
  WITHDRAW_REQUESTS_RETRY_ONE
} from 'actions/withdraw_requests';
import {mapGetters} from "vuex";
    export default {
        name: "WithdrawRequestActionsModalsRetryOne",
        props: {
          activeRetryOneParams:{
            type:Object,
            default: () => {}
          },
          successCallback: {
            type: Function,
            default: () => { }
          }
        },
        data(){
            return {
                serverError: '',
                fieldErrors: {},
                loading: false,
                withdraw_id: '',
                createdAt: '',
                amount: 0
            }
        },
        computed:{
          ...mapGetters(["getSearchFilters", "getSearchFiltersSensitized"]),
        },
        watch:{
          activeRetryOneParams: function(newVal) {
            this.serverError=null;
            this.withdraw_id = newVal.id;
            this.createdAt = this.$moment(newVal.createdAt).format("Y-MM-DD");
            this.amount = newVal.amount;
          },
        },
        methods:{
            retryWithdrawal() {
              this.loading = true;
              this.$store.dispatch(WITHDRAW_REQUESTS_RETRY_ONE, { id: this.withdraw_id })
                  .then(response => {
                    document.querySelector('#modal-retry-one [data-dismiss]').click();
                    this.successCallback();
                    swal({
                      icon: 'success',
                      text: response.data.message,
                      buttons:false,
                      timer: 5000
                    });
                  })
                  .catch(error => this.handleError(error))
                  .finally(() => this.loading = false)
            },
            handleError(error) {
              this.loading = false;
              this.fieldErrors={};
              this.serverError='';

              if(error.response){
                let responseData=error.response.data;

                if(responseData.errors){
                  this.fieldErrors=responseData.errors;
                }else{
                  this.serverError = 'Invalid Request.'
                }
              }else{
                this.serverError = this.$i18n.t("messages.no_internet_connection");
              }

              if(this.fieldErrors.error){
                this.serverError = this.fieldErrors.error;
              }
            }
        }
    }
</script>

<style scoped>

</style>
