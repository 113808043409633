export const TRACKING_ADD_CAPTAINS = "TRACKING_ADD_CAPTAINS";
export const TRACKING_ADD_UPDATE_CAPTAIN = "TRACKING_ADD_UPDATE_CAPTAIN";
export const TRACKING_DELETE_CAPTAIN = "TRACKING_DELETE_CAPTAIN";
export const TRACKING_RESET_CAPTAINS = "TRACKING_RESET_CAPTAINS ";
export const TRACKING_SET_ACTIVE_ORDER = "TRACKING_SET_ACTIVE_ORDER";
export const TRACKING_SET_ACTIVE_CAPTAIN = "TRACKING_SET_ACTIVE_CAPTAIN";
export const TRACKING_SET_MAP_CONFIG = "TRACKING_SET_MAP_CONFIG";
export const TRACKING_SET_MAP_FILTERS = "TRACKING_SET_MAP_FILTERS";
export const TRACKING_ASSIGNMENT_CANDIDATES_LIST = "TRACKING_ASSIGNMENT_CANDIDATES_LIST";
export const TRACKING_SET_HIGHLIGHTED_CANDIDATE = "TRACKING_SET_HIGHLIGHTED_CANDIDATE";
export const TRACKING_CAPTAINS_LIST = "TRACKING_CAPTAINS_LIST";
export const TRACKING_CAPTAIN_SHOW = "TRACKING_CAPTAIN_SHOW";
export const TRACKING_ORDERS_LIST = "TRACKING_ORDERS_LIST";
export const TRACKING_ADD_ORDERS = "TRACKING_ADD_ORDERS";
export const TRACKING_ADD_UPDATE_ORDER = "TRACKING_ADD_UPDATE_ORDER";
export const TRACKING_DELETE_ORDER = "TRACKING_DELETE_ORDER";
export const TRACKING_RESET_ORDERS = "TRACKING_RESET_ORDERS";
export const TRACKING_ORDERS_LOADING = "TRACKING_ORDERS_LOADING";
