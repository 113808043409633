<template>
    <div  aria-hidden="true" aria-labelledby="modal-countdown" class="modal fade" id="modal-countdown"  role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">{{ $t('messages.countdown_title')}}</h6>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
              <div class="col-12 text-center">
                <span v-if="loading" class="spinner-border"></span>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-lg-10" v-if="!loading" >
                  <h3 class="text-blue mb-3">{{ $t('fields.total_wallet_balances')}} : <strong> {{ totalWalletBalances }}  </strong> </h3>
              </div>
              <div class="col-12 col-lg-10" v-if="!loading" >
                <div class="alert bg-gradient-dark text-center">
                  <h2 class="text-white mb-0">{{ $t('fields.next_round_balance')}} : <strong> {{ roundBalance }}  </strong> </h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <countdown :time="getDiffDateInMilliseconds()" v-if="!loading" v-slot="{ days, hours, minutes, seconds }">
                <div class="container flip-clock">

                  <!-- Start Days -->
                  <span class="flip-clock__piece" id="day" >
                    <span class="flip-clock__card flip-card">
                      <b class="flip-card__top">{{ days  | twoDigits }}</b>
                      <b class="flip-card__bottom" v-bind:data-value="days  | twoDigits"></b>
                      <b class="flip-card__back" v-bind:data-value="days+1 | twoDigits"></b>
                      <b class="flip-card__back-bottom" v-bind:data-value="days+1 | twoDigits"></b>
                    </span>
                    <span class="flip-clock__slot">{{ $t('labels.days') }}</span>
                  </span>
                  <!-- End Days -->

                  <!-- Hours -->
                  <span class="flip-clock__piece" id="hours" >
                    <span class="flip-clock__card flip-card">
                      <b class="flip-card__top">{{ hours | twoDigits }}</b>
                      <b class="flip-card__bottom" v-bind:data-value="hours  | twoDigits"></b>
                      <b class="flip-card__back" v-bind:data-value="(hours+1)%24 | twoDigits"></b>
                      <b class="flip-card__back-bottom" v-bind:data-value="(hours+1)%24 | twoDigits"></b>
                    </span>
                    <span class="flip-clock__slot">{{ $t('labels.hours') }}</span>
                  </span>
                  <!-- End Hours -->


                  <!-- Start Minutes -->
                  <span class="flip-clock__piece" id="minutes" >
                    <span class="flip-clock__card flip-card">
                      <b class="flip-card__top">{{ minutes | twoDigits }}</b>
                      <b class="flip-card__bottom" v-bind:data-value="minutes | twoDigits"></b>
                      <b class="flip-card__back" v-bind:data-value="(minutes +1)%60 | twoDigits"></b>
                      <b class="flip-card__back-bottom" v-bind:data-value="(minutes +1)%60 | twoDigits"></b>
                    </span>
                    <span class="flip-clock__slot">{{ $t('labels.minutes') }}</span>
                  </span>
                  <!-- End Minutes -->


                  <!-- Start Seconds -->
                  <span class="flip-clock__piece" id="seconds" >
                    <span class="flip-clock__card flip-card">
                      <b class="flip-card__top">{{ seconds | twoDigits }}</b>
                      <b class="flip-card__bottom" v-bind:data-value="seconds | twoDigits"></b>
                      <b class="flip-card__back" v-bind:data-value="seconds+1 | twoDigits"></b>
                      <b class="flip-card__back-bottom" v-bind:data-value="seconds+1 | twoDigits"></b>
                    </span>
                    <span class="flip-clock__slot">{{ $t('labels.seconds') }}</span>
                  </span>
                  <!-- End Seconds -->

                </div>
              </countdown>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary btn-sm ml-auto" data-dismiss="modal" type="button">
              {{ $t('labels.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    import { WITHDRAW_REQUESTS_COUNTDOWN as CRUD_ACTION_COUNTDOWN } from 'actions/withdraw_requests';
    export default {
        name: "WithdrawRequestActionsModalsCountdown",
        props: {
            activeParams:{
                type:Object,
                default:function(){
                  return {};
                }
            },
            successCallback: {
                type: Function,
                default: () => { }
            },
        },
        data(){
            return {
                serverError: '',
                fieldErrors: {},
                loading: false,
                roundAt:"",
                roundBalance:"",
                totalWalletBalances: "",
                activeId:null,
                activeIndex:null,
            }
        },
        watch:{
          activeParams:function(newVal, oldVal) {
            this.serverError=null;
            this.activeId=newVal.activeId;
            this.activeIndex=newVal.activeIndex;
            this.getCountdown();
          },
        },
        methods:{
            getDiffDateInMilliseconds(){
              let timeInMilliseconds= 0;

              if(this.roundAt){
                timeInMilliseconds = this.$moment(this.roundAt).diff(this.$moment());
              }

              return timeInMilliseconds >= 0 ? timeInMilliseconds : 0;
            },
            getCountdown(){
              this.loading = true;
              this.$store.dispatch(CRUD_ACTION_COUNTDOWN).then(response=>{
                this.loading = false;
                this.roundAt=response.data.roundAt;
                this.roundBalance=response.data.roundBalance;
                this.totalWalletBalances = response.data.totalWalletBalances;
              }).catch(error => {
                this.loading = false;
                this.fieldErrors={};
                this.serverError='';

                if(error.response){
                  let responseData=error.response.data;

                  if(responseData.errors){
                    this.fieldErrors=responseData.errors;
                  }else{
                    this.serverError = 'Invalid Request.'
                  }
                }else{
                  this.serverError = this.$i18n.t("messages.no_internet_connection");
                }

                if(this.fieldErrors.error){
                  this.serverError = this.fieldErrors.error;
                }
              });
            },
        },
      filters: {
        twoDigits(value) {
          if (value.toString().length <= 1) {
            return '0' + value.toString();
          }
          return value.toString();
        },
      },
    }
</script>

<style scoped lang="scss">
  .flip-clock {
    text-align: center;
    perspective: 600px;
    margin: 0 auto;

    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }
  }

  .flip-clock__piece {
    display: inline-block;
    margin: 0 0.2vw;

    @media (min-width: 1000px) {
      margin: 0 5px;
    }
  }

  .flip-clock__slot {
    font-size: 1rem;
    line-height: 1.5;
    display: block;
  }

  $halfHeight: 0.72em;
  $borderRadius: 0.15em;

  .flip-card {
    display: block;
    position: relative;
    padding-bottom: $halfHeight;
    font-size: 2.25rem;
    line-height: 0.95;
  }

  @media (min-width: 1000px) {
    .flip-clock__slot {
      font-size: 1.2rem;
    }
    .flip-card {
      font-size: 4rem;
    }
  }

  .flip-card__top,
  .flip-card__bottom,
  .flip-card__back-bottom,
  .flip-card__back::before,
  .flip-card__back::after {
    display: block;
    height: $halfHeight;
    color: #aaaaaa;
    background: #222;
    padding: 0.23em 0.15em 0.4em;
    border-radius: $borderRadius $borderRadius 0 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    width: 2.1em;
    height: $halfHeight;
  }

  .flip-card__top-4digits,
  .flip-card__bottom-4digits,
  .flip-card__back-bottom-4digits,
  .flip-card__back-4digits::before,
  .flip-card__back-4digits::after {
    display: block;
    height: $halfHeight;
    color: #aaaaaa;
    background: #222;
    padding: 0.23em 0.15em 0.4em;
    border-radius: $borderRadius $borderRadius 0 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    width: 2.65em;
    height: $halfHeight;
  }

  .flip-card__bottom,
  .flip-card__back-bottom,
  .flip-card__bottom-4digits,
  .flip-card__back-bottom-4digits {
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 0;
    border-top: solid 1px #000;
    background: #393939;
    border-radius: 0 0 $borderRadius $borderRadius;
    pointer-events: none;
    overflow: hidden;
    z-index: 2;
  }

  .flip-card__back-bottom,
  .flip-card__back-bottom-4digits {
    z-index: 1;
  }

  .flip-card__bottom::after,
  .flip-card__back-bottom::after,
  .flip-card__bottom-4digits::after,
  .flip-card__back-bottom-4digits::after {
    display: block;
    margin-top: -$halfHeight;
  }

  .flip-card__back::before,
  .flip-card__bottom::after,
  .flip-card__back-bottom::after,
  .flip-card__back-4digits::before,
  .flip-card__bottom-4digits::after,
  .flip-card__back-bottom-4digits::after {
    content: attr(data-value);
  }

  .flip-card__back,
  .flip-card__back-4digits {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0%;
    pointer-events: none;
  }

  .flip-card__back::before,
  .flip-card__back-4digits::before {
    position: relative;
    overflow: hidden;
    z-index: -1;
  }

  .flip .flip-card__back::before,
  .flip .flip-card__back-4digits::before {
    z-index: 1;
    animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
    animation-fill-mode: both;
    transform-origin: center bottom;
  }

  .flip .flip-card__bottom,
  .flip .flip-card__bottom-4digits {
    transform-origin: center top;
    animation-fill-mode: both;
    animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
  }

  @keyframes flipTop {
    0% {
      transform: rotateX(0deg);
      z-index: 2;
    }
    0%,
    99% {
      opacity: 1;
    }
    100% {
      transform: rotateX(-90deg);
      opacity: 0;
    }
  }

  @keyframes flipBottom {
    0%,
    50% {
      z-index: -1;
      transform: rotateX(90deg);
      opacity: 0;
    }
    51% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
      z-index: 5;
    }
  }
</style>
