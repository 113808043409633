import Index from "@/views/platforms/index";
import Create from "@/views/platforms/create";
import Show from "@/views/platforms/show";
import Edit from "@/views/platforms/edit";
import UsersIndex from "@/views/platforms/users/index";
import UsersCreate from "@/views/platforms/users/create";
import UsersShow from "@/views/platforms/users/show";
import AppsIndex from "@/views/platforms/apps/index";
import AppsCreate from "@/views/platforms/apps/create";
import AppsShow from "@/views/platforms/apps/show";
import AppsEdit from "@/views/platforms/apps/edit";
import ConfigsShow from "@/views/platforms/configs/show";
import ConfigsEdit from "@/views/platforms/configs/edit";
import FilesShow from "@/views/platforms/files/show";
import InvoicesIndex from "@/views/platforms/invoices/index";
import InvoicesCreate from "@/views/platforms/invoices/create";
import DetailsShow from "@/views/platforms/details/show";
import DetailsEdit from "@/views/platforms/details/edit";
import SettingsShow from "@/views/platforms/settings/show";
import SettingsEdit from "@/views/platforms/settings/edit";
import BranchesIndex from "@/views/platforms/branches/index";
import BranchesShow from "@/views/platforms/branches/show";
import BrandsIndex from "@/views/platforms/brands/index";


import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='platforms';
const PERMISSION_PREFIX='platforms';
const USERS_CODE='users';
const USERS_PERMISSION_PREFIX='platform_users';
const APPS_CODE='apps';
const APPS_PERMISSION_PREFIX='platform_apps';
const CONFIGS_CODE='configs';
const CONFIGS_PERMISSION_PREFIX='platform_configs';
const FILES_CODE='files';
const FILES_PERMISSION_PREFIX='platform_files';
const INVOICES_CODE='invoices';
const INVOICES_PERMISSION_PREFIX='platform_invoices';
const DETAILS_CODE='details';
const DETAILS_PERMISSION_PREFIX='platforms';
const SETTINGS_CODE='settings';
const SETTINGS_PERMISSION_PREFIX='platform_settings';
const BRANCHES_CODE='branches';
const BRANCHES_PERMISSION_PREFIX='platform_branches';
const BRANDS_CODE='brands';
const BRANDS_PERMISSION_PREFIX='platform_brands';


const routes = [
  {
    path: `${CODE}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/create`,
    name:`${CODE}.create`,
    component: Create,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id`,
    name:`${CODE}.show`,
    component: Show,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `:id`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/edit`,
    name:`${CODE}.edit`,
    component: Edit,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_update`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `:id`,
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${USERS_CODE}`,
    name:`${CODE}.${USERS_CODE}.index`,
    component: UsersIndex,
    meta: {
      'can': [`${USERS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${USERS_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${USERS_CODE}.title`,
          },
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${USERS_CODE}/create`,
    name:`${CODE}.${USERS_CODE}.create`,
    component: UsersCreate,
    meta: {
      'can': [`${USERS_PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${USERS_CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${USERS_CODE}.title`,
          },
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${USERS_CODE}/:user_id`,
    name:`${CODE}.${USERS_CODE}.show`,
    component: UsersShow,
    meta: {
      'can': [`${USERS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${USERS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${USERS_CODE}.title`,
          },
          {
            'title': ':user_id',
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${APPS_CODE}`,
    name:`${CODE}.${APPS_CODE}.index`,
    component: AppsIndex,
    meta: {
      'can': [`${APPS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${APPS_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${APPS_CODE}.title`,
          },
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${APPS_CODE}/create`,
    name:`${CODE}.${APPS_CODE}.create`,
    component: AppsCreate,
    meta: {
      'can': [`${APPS_PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${APPS_CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${APPS_CODE}.title`,
          },
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${APPS_CODE}/:app_id`,
    name:`${CODE}.${APPS_CODE}.show`,
    component: AppsShow,
    meta: {
      'can': [`${APPS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${APPS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${APPS_CODE}.title`,
          },
          {
            'title': ':app_id',
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${APPS_CODE}/:app_id/edit`,
    name:`${CODE}.${APPS_CODE}.edit`,
    component: AppsEdit,
    meta: {
      'can': [`${APPS_PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${APPS_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${APPS_CODE}.title`,
          },
          {
            'title': ':app_id',
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${CONFIGS_CODE}`,
    name:`${CODE}.${CONFIGS_CODE}.show`,
    component: ConfigsShow,
    meta: {
      'can': [`${CONFIGS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${CONFIGS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${CONFIGS_CODE}.title`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${CONFIGS_CODE}/edit`,
    name:`${CODE}.${CONFIGS_CODE}.edit`,
    component: ConfigsEdit,
    meta: {
      'can': [`${CONFIGS_PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${CONFIGS_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${CONFIGS_CODE}.title`,
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${FILES_CODE}`,
    name:`${CODE}.${FILES_CODE}.show`,
    component: FilesShow,
    meta: {
      'can': [`${FILES_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${FILES_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${FILES_CODE}.title`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${INVOICES_CODE}`,
    name:`${CODE}.${INVOICES_CODE}.index`,
    component: InvoicesIndex,
    meta: {
      'can': [`${INVOICES_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${INVOICES_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${INVOICES_CODE}.title`,
          },
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${INVOICES_CODE}/create`,
    name:`${CODE}.${INVOICES_CODE}.create`,
    component: InvoicesCreate,
    meta: {
      'can': [`${INVOICES_PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${INVOICES_CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${INVOICES_CODE}.title`,
          },
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${DETAILS_CODE}`,
    name:`${CODE}.${DETAILS_CODE}.show`,
    component: DetailsShow,
    meta: {
      'can': [`${DETAILS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${DETAILS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${DETAILS_CODE}.title`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${DETAILS_CODE}/edit`,
    name:`${CODE}.${DETAILS_CODE}.edit`,
    component: DetailsEdit,
    meta: {
      'can': [`${DETAILS_PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${DETAILS_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${DETAILS_CODE}.title`,
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${SETTINGS_CODE}`,
    name:`${CODE}.${SETTINGS_CODE}.show`,
    component: SettingsShow,
    meta: {
      'can': [`${SETTINGS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${SETTINGS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${SETTINGS_CODE}.title`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${SETTINGS_CODE}/edit`,
    name:`${CODE}.${SETTINGS_CODE}.edit`,
    component: SettingsEdit,
    meta: {
      'can': [`${SETTINGS_PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${SETTINGS_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${SETTINGS_CODE}.title`,
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}`,
    name:`${CODE}.${BRANCHES_CODE}.index`,
    component: BranchesIndex,
    meta: {
      'can': [`${BRANCHES_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${BRANCHES_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id`,
    name:`${CODE}.${BRANCHES_CODE}.show`,
    component: BranchesShow,
    meta: {
      'can': [`${BRANCHES_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${BRANCHES_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            'title': ':branch_id',
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANDS_CODE}`,
    name:`${CODE}.${BRANDS_CODE}.index`,
    component: BrandsIndex,
    meta: {
      'can': [`${BRANDS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${BRANDS_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${BRANDS_CODE}.title`,
          },
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
