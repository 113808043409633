<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div v-if="serverError" class="alert alert-danger">
                <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
              </div>
            </div>
          </div>
          <div class="row ">
            <input-text v-model="filters.name" :options="{ id: 'filter_name', transPrefix: 'labels', label: 'search', containerClass: 'col-12 col-md-3' }"/>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="btn-group pb-1 btn-group-toggle w-100 scrollbar-outer" data-toggle="buttons">
                <label class="btn btn-secondary text-nowrap" v-for="item in keyItems" v-bind:class="{'active':filters.key===item.key}">
                  <input type="radio" name="filter_key" autocomplete="off" v-model="filters.key" v-bind:value="item.key">
                  <span class="heading-small">{{$t(item.label)}}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row" v-if="isItemFound()">
            <input-checkbox v-for="permission in getPermissionsByFilter()" :key="permission.key" :value="permission.can" :options="{ id: `permission_${permission.id}`, onChangeHandler: addRemovePermission, onChangeParams: { permission }, containerClass: 'col-12 col-md-3' }">
              <template v-slot:hint>
                <label :for="`permission_${permission.id}`">
                  {{ permission.title }}
                  <p class="heading-small text-muted">
                    <small> {{ permission.description }} </small>
                  </p>
                </label>
              </template>
            </input-checkbox>
          </div>
        </div>
        <!-- Card footer -->
        <div class="card-footer">
          <div class="row">
            <div class="col text-right">
              <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                <i class="fa fa-arrow-left"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ROLES_PERMISSIONS_LIST as CRUD_ACTION_SHOW,ROLES_PERMISSIONS_UPDATE as CRUD_ACTION_UPDATE,ROLES_PERMISSIONS_DELETE as CRUD_ACTION_DELETE } from 'actions/roles';
  import $ from "jquery";
  const CRUD_CODE="roles";
  const PERMISSIONS_CODE="permissions";
  export default {
    name: "RolePermissionEdit",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionsRoutePrefix: `${CRUD_CODE}.${PERMISSIONS_CODE}`,
        permissionsTransPrefix: `${CRUD_CODE}.${PERMISSIONS_CODE}`,
        id:this.$router.currentRoute.params.id,
        filters:{
          key:'',
          name:'',
        },
        keyItems:[
          {
            'label':'labels.all',
            'key':''
          },
          {
            'label':'captains.title',
            'key':'captains'
          },
          {
            'label':'captains.fintechs.title',
            'key':'fintechs'
          },
          {
            'label':'captains.wallet.title',
            'key':'wallets'
          },
          {
            'label':'envelopes.title',
            'key':'captain_envelopes'
          },
          {
            'label':'countries.title',
            'key':'countries'
          },
          {
            'label':'cities.title',
            'key':'cities'
          },
          {
            'label':'zones.title',
            'key':'locals'
          },
          {
            'label':'orders.title',
            'key':'orders'
          },
          {
            'label':'platforms.title',
            'key':'platform'
          },
          {
            'label':'banks.title',
            'key':'banks'
          },
          {
            'label':'app_versions.title',
            'key':'app_versions'
          },
          {
            'label':'users.title',
            'key':'users'
          },
          {
            'label':'settings.title',
            'key':'settings'
          },
          {
            'label':'tracking.title',
            'key':'tracking'
          },
          {
            'label':'suppliers.title',
            'key':'supplier'
          },
          {
            'label':'captain_bonuses.title',
            'key':'captain_bonuses'
          },
          {
            'label':'referral_programs.title',
            'key':'referral_programs'
          },
          {
            'label':'tickets.title',
            'key':'tickets'
          },
          {
            'label':'withdraw_requests.title',
            'key':'withdraw_requests'
          },
          {
            'label':'captain_working_summaries.title',
            'key':'captain_working_summary'
          },
        ],
        permissions: [],
        serverError: '',
        fieldErrors: {},
        responseStatus:'',
        loading: false,
      }
    },
    mounted() {
      $('.scrollbar-inner,.scrollbar-outer').scrollbar().scrollLock();
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.permissions=response.data;
        }).catch(error => {
          this.loading = false
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      getPermissionsByFilter(){
        let permissions=this.permissions;
        Object.keys(this.filters).forEach(key=>{
          let filterValue=this.filters[key];
          if(filterValue){
            switch (key) {
              case 'key':
                permissions=permissions.filter(function(item){
                  return item.key.search(`${filterValue}`)!==-1;
                });
                break;
              case 'name':
                permissions=permissions.filter(function(item){
                  return item.title.search(`${filterValue}`)!==-1 || item.description.search(`${filterValue}`)!==-1 ;
                });
                break;
            }
          }
        });
        return permissions;
      },
      getIndexOfPermission(permission){
        return this.permissions.findIndex(function(item){
          return item.id ===permission.id;
        });
      },
      getAction(isUpdate){
        let action=CRUD_ACTION_DELETE;
        if(isUpdate){
          action=CRUD_ACTION_UPDATE;
        }
        return action;
      },
      addRemovePermission(event, params){

        let isCan=event.target.checked,
            index=this.getIndexOfPermission(params.permission),
            action=this.getAction(event.target.checked);

        this.$store.dispatch(action, {
          id:this.id,
          permission_id:params.permission.id,
          data: {}
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
          this.permissions[index].can=isCan;
        })
        .catch(error => {
          this.permissions[index].can=!isCan;
          event.target.checked=!isCan;
          let message="";
          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors && responseData.errors.error){
              message = responseData.errors.error;
            }else{
              message = this.$i18n.t("messages.update_error");
            }
          }
          if(message){
            swal({
              icon: 'error',
              text: message,
              buttons:false,
              timer: 5000
            });
          }
        })
      }
    },
  };
</script>
