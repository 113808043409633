<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header border-0">
            <div class="row justify-content-end">
              <button class="btn btn-primary btn-sm" @click.prevent="generateTodayReport()" :disabled="loading" v-can:report="permissionPrefix">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                <span><i class="fa fa-file"></i> {{ $t(`labels.today_report`) }}</span>
              </button>
              <router-link :to="{ name: `${routePrefix}.report`}" class="btn btn-primary btn-sm"  v-can:report="permissionPrefix">
                <span><i class="fa fa-file-export"></i> {{ $t(`labels.custom_report`) }}</span>
              </router-link>
              <router-link :to="{ name: `${routePrefix}.assignment_methods_report`}" class="btn btn-primary btn-sm"  v-can:report="permissionPrefix">
                <span><i class="fa fa-file-export"></i> {{ $t(`labels.assignment_methods_report`) }}</span>
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <select-countries v-model="countryId" :options="{ id: 'country_id', label: 'country', placeholder: 'placeholders.all', error: fieldErrors.countryId }"/>
              <select-cities v-model="cityId" :country_id="countryId" :options="{ id: 'city_id', label: 'city', placeholder: 'placeholders.all', disabled: !countryId, error: fieldErrors.cityId }"/>
              <select-platforms v-model="platformId" :options="{ id: 'platform_id', label: 'platform', placeholder: 'placeholders.all', error: fieldErrors.platformId }"/>
              <select-platform-brands v-model="brandIds" :platform_id="platformId" :options="{ id: 'brands', label: 'brands', placeholder: 'placeholders.all', error: fieldErrors.brandIds, disabled: !platformId, multiple: true }"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center text-white h3">{{ $t(`${transPrefix}.today_statistics`) }}</div>
    </div>
    <div class="row">
      <MonitoringCard :onClickHandler="openOrdersIndex({ order_status: ['CANCELED', 'FAILED_TO_ASSIGN', 'RETURNED'] })" :title="$t(`${transPrefix}.cancelled_orders_count`)" :value="cancelledOrdersCount" icon="fas fa-ban" color="bg-red" />
      <MonitoringCard :onClickHandler="openOrdersIndex()" :title="$t(`${transPrefix}.total_orders_count`)" :value="totalOrdersCount" icon="fas fa-box" color="bg-blue" />
      <MonitoringCard :title="$t(`${transPrefix}.average_delivery_time`)" :value="averageDeliveryTime" icon="fas fa-clock" color="bg-purple" />
      <MonitoringCard :title="$t(`${transPrefix}.acceptance_rate`)" :value="acceptanceRate" icon="fas fa-handshake" color="bg-gray" />
    </div>
    <div class="row">
      <MonitoringCard :onClickHandler="openOrdersIndex({ assignment_method: 'ASSIGNED' })" :title="$t(`${transPrefix}.auto_assigned_orders`)" :value="autoAssignedOrdersCount" icon="fas fa-cogs" color="bg-blue" />
      <MonitoringCard :onClickHandler="openOrdersIndex({ assignment_method: 'FORCEFULLY_ASSIGNED' })" :title="$t(`${transPrefix}.forcefully_assigned_orders`)" :value="forcefullyAssignedOrdersCount" icon="fas fa-cogs" color="bg-yellow" />
      <MonitoringCard :onClickHandler="openOrdersIndex({ assignment_method: 'MANUALLY_ASSIGNED' })" :title="$t(`${transPrefix}.manually_assigned_orders`)" :value="manuallyAssignedOrdersCount" icon="fas fa-random" color="bg-info" />
      <MonitoringCard :onClickHandler="openOrdersIndex({ assignment_method: 'REASSIGNED_TO' })" :title="$t(`${transPrefix}.reassigned_orders`)" :value="reAssignedOrdersCount" icon="fas fa-arrows-alt-h" color="bg-warning" />
    </div>
    <div class="row align-items-center">
      <div class="col-3 p-0">
        <MonitoringCard :title="$t(`${transPrefix}.clubbed_orders`)" :value="clubbedOrdersCount" icon="fas fa-cubes" color="bg-warning" />
      </div>
      <div class="col-3 p-0">
        <MonitoringCard :onClickHandler="openOrdersIndex({ order_timeline_status: 'LATE' })" :title="$t(`${transPrefix}.late_orders`)" :value="lateOrdersCount" icon="fas fa-exclamation" color="bg-danger" />
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center text-white h3">{{ $t(`${transPrefix}.captains`) }}</div>
    </div>
    <div class="row align-items-center">
      <MonitoringCard :title="$t(`${transPrefix}.total_captains`)" :value="totalCaptainsCount" icon="fas fa-users" color="bg-pink" />
      <MonitoringCard :title="$t(`${transPrefix}.ready_to_work_captains`)" :value="readyToWorkCaptainsCount" icon="fas fa-globe" color="bg-blue" />
      <MonitoringCard :title="$t(`${transPrefix}.not_ready_to_work_captains`)" :value="notReadyToWorkCaptainsCount" icon="fas fa-times-circle" color="bg-red" />
      <MonitoringCard :title="$t(`${transPrefix}.connected_captains`)" :value="connectedCaptainsCount" icon="fas fa-wifi" color="bg-green" />
    </div>

    <div class="row align-items-center">
      <MonitoringCard :title="$t(`${transPrefix}.not_connected`)" :value="notConnectedCaptainsCount" icon="fas fa-ban" color="bg-red" />
      <MonitoringCard :title="$t(`${transPrefix}.free_captains`)" :value="freeCaptainsCount" icon="fas fa-taxi" color="bg-green" />
      <MonitoringCard :title="$t(`${transPrefix}.idle_captains`)" :value="idleCaptainsCount" icon="fas fa-clock" color="bg-blue" />
      <MonitoringCard :title="$t(`${transPrefix}.busy_captains_with_one_order`)" :value="busyCaptainsWithOneOrderCount" icon="fas fa-truck" color="bg-yellow" />
    </div>
    <div class="row align-items-center">
      <div class="col-3 p-0">
        <MonitoringCard :title="$t(`${transPrefix}.busy_captains_with_more_than_one_order`)" :value="busyCaptainsWithMoreThanOneOrderCount" icon="fas fa-truck-moving" color="bg-orange" />
      </div>
    </div>
  </div>
</template>

<script>
import MonitoringCard from "../partials/card";
import {
  MONITORING_REPORT,
  MONITORING_STATISTICS
} from "actions/monitoring";

const CRUD_CODE = "monitoring";
export default {
  name: "ClassicMonitoringView",
  components: {
    MonitoringCard
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_CODE,
      countryId: 1,
      cityId: null,
      platformId: null,
      brandIds: null,
      updateTrackingInfoIntervalId: null,
      cancelledOrdersCount: 0,
      totalOrdersCount: 0,
      averageDeliveryTime: 0,
      acceptanceRate: 0,
      totalCaptainsCount: 0,
      readyToWorkCaptainsCount: 0,
      notReadyToWorkCaptainsCount: 0,
      connectedCaptainsCount: 0,
      freeCaptainsCount: 0,
      idleCaptainsCount: 0,
      busyCaptainsWithOneOrderCount: 0,
      busyCaptainsWithMoreThanOneOrderCount: 0,
      notConnectedCaptainsCount: 0,
      autoAssignedOrdersCount: 0,
      forcefullyAssignedOrdersCount: 0,
      manuallyAssignedOrdersCount: 0,
      reAssignedOrdersCount: 0,
      clubbedOrdersCount: 0,
      lateOrdersCount: 0,
      fieldErrors: {},
      loading: false,
      fromDate: "",
      toDate: ""
    }
  },
  mounted() {
    this.getTrackingInfo();
    this.updateTrackingInfoIntervalId = setInterval(this.getTrackingInfo, 300000);
  },
  beforeDestroy() {
    clearInterval(this.updateTrackingInfoIntervalId);
  },
  methods: {
    getTrackingInfo() {
      this.$store.dispatch(MONITORING_STATISTICS, { countryId: this.countryId, cityId: this.cityId, platformId: this.platformId, brandIds: this.brandIds })
          .then(statistics => {
            this.cancelledOrdersCount = statistics.data.cancelledOrdersCount;
            this.totalOrdersCount = statistics.data.totalOrdersCount;
            this.averageDeliveryTime = statistics.data.averageDeliveryTime ? statistics.data.averageDeliveryTime.toFixed(2) + " MIN" : "-";
            this.acceptanceRate = statistics.data.acceptanceRate ? Math.round(statistics.data.acceptanceRate * 100) + "%" : "-";
            this.autoAssignedOrdersCount = statistics.data.autoAssignedOrdersCount;
            this.forcefullyAssignedOrdersCount = statistics.data.forcefullyAssignedOrdersCount;
            this.manuallyAssignedOrdersCount = statistics.data.manuallyAssignedOrdersCount;
            this.reAssignedOrdersCount = statistics.data.reAssignedOrdersCount;
            this.clubbedOrdersCount = statistics.data.clubbedOrdersCount;
            this.lateOrdersCount = statistics.data.lateOrdersCount;

            this.totalCaptainsCount = statistics.data.totalCaptainsCount;
            this.readyToWorkCaptainsCount = statistics.data.readyToWorkCaptainsCount;
            this.notReadyToWorkCaptainsCount = statistics.data.notReadyToWorkCaptainsCount;
            this.connectedCaptainsCount = statistics.data.connectedCaptainsCount;
            this.freeCaptainsCount = statistics.data.freeCaptainsCount;
            this.idleCaptainsCount = statistics.data.idleCaptainsCount;
            this.busyCaptainsWithOneOrderCount = statistics.data.busyCaptainsWithOneOrderCount;
            this.busyCaptainsWithMoreThanOneOrderCount = statistics.data.busyCaptainsWithMoreThanOneOrderCount;
            this.notConnectedCaptainsCount = statistics.data.notConnectedCaptainsCount;
          });
    },
    generateTodayReport() {
      this.loading = true;

      const now = new Date();
      this.fromDate = this.$moment(now).subtract(6, "hours").startOf("day").add(6, "hours");
      this.toDate = this.$moment(now);
      this.$store.dispatch(MONITORING_REPORT, {
        fromDate: this.prepareDateTime("fromDate"),
        toDate: this.prepareDateTime("toDate")
      })
          .then(response => {
            swal({
              icon: 'success',
              text: response.data.message,
              buttons:false,
              timer: 5000
            });
          })
          .catch(() => {
            swal({
              icon: 'error',
              text: this.$i18n.t("messages.export_error"),
              buttons:false,
              timer: 5000
            });
          })
          .finally(() => this.loading = false);
    },
    openOrdersIndex(additionalQueryParams) {
      return () => {
        let routeData = this.$router.resolve({
          name: `orders.index`,
          query: {
            country_id: this.countryId,
            city_id: this.cityId,
            platform_id: this.platformId,
            brand_id: this.brandIds,
            created_from: this.$moment(new Date()).format("YYYY-MM-DD"),
            created_to: this.$moment(new Date()).format("YYYY-MM-DD"),
            ...additionalQueryParams
          }
        });
        window.open(routeData.href, "_blank");
      }
    }
  },
  watch: {
    countryId: function() {
      this.getTrackingInfo();
    },
    cityId: function() {
      this.getTrackingInfo();
    },
    platformId: function() {
      this.getTrackingInfo();
    },
    brandIds: function() {
      this.getTrackingInfo();
    }
  }
};
</script>

<style scoped>
.margin-30 {
  margin-bottom: 30px;
}
</style>
