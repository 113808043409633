<template>
    <div v-bind:class="[defaultOptions.containerClass]" v-if="defaultOptions.fullLayout">
        <div class="form-group">
            <label v-if="getLabel()" :for="defaultOptions.id">{{ $t(getLabel()) }}</label>
            <slot name="hint-top"></slot>
            <abstract-dropdown v-if="defaultOptions.type === 'dropdown'" v-model="selectedValue" :items="items" :options="{...defaultOptions, ...options}"/>
            <abstract-dropdown-api v-if="defaultOptions.type === 'dropdown_api'" v-model="selectedValue" :no-api-items="items" :options="{...defaultOptions, ...options}"/>
            <slot name="hint-bottom"></slot>
            <span v-if="defaultOptions.error" class="invalid-feedback d-block" role="alert">
                <strong>{{ defaultOptions.error }}</strong>
            </span>
        </div>
    </div>
    <abstract-dropdown v-else-if="defaultOptions.type === 'dropdown'" v-model="selectedValue" :items="items" :options="{...defaultOptions, ...options}"/>
    <abstract-dropdown-api v-else-if="defaultOptions.type === 'dropdown_api'" v-model="selectedValue" :no-api-items="items" :options="{...defaultOptions, ...options}"/>
</template>

<script>
    export default {
        name: "AbstractsDropdownContainer",
        props: {
            value: {},
            items: {
                type: Array,
                default: () => []
            },
            options: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                selectedValue: this.value,
                defaultOptions: {
                    fullLayout:                             true,
                    disabled:                               false,
                    error:                                  "",
                    transPrefix:                            "fields",
                    type:                                   "dropdown_api",
                    label:                                  "",
                    containerClass:                         "col-12 col-md-6",
                    ...this.options
                }
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            },
            options: {
                deep: true,
                handler: function (newVal, oldVal) {
                    this.defaultOptions = { ...this.defaultOptions, ...newVal};
                }
            }
        },
        methods: {
            getLabel() {
                return this.defaultOptions.label ? `${this.defaultOptions.transPrefix}.${this.defaultOptions.label}`: "";
            },
        }
    }
</script>

<style scoped>

</style>
