<template>
  <div>
    <tracking />
    <counters />
  </div>
</template>

<script>
import tracking from "./partials/tracking";
import counters from "./partials/counters";
import $ from "jquery";

export default {
  name: "SupplierTrackingIndex",
  components: {
    tracking,
    counters
  },
  mounted() {
    $(".footer").hide();
  }
};
</script>
