import Me from "@/views/profile/me";
import Password from "@/views/profile/change_password";
import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='profile';
const routes = [
  {
    path: `${CODE}`,
    name:`${CODE}.me`,
    component: Me,
    meta: {
      'removeClass' : [`bg-default`],
      'title': `${CODE}.title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.me`,
        'show': true,
        'items': [
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/change_password`,
    name:`${CODE}.change_password`,
    component: Password,
    meta: {
      'removeClass' : [`bg-default`],
      'title': `${CODE}.change_password_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.me`,
        'show': true,
        'items': [
          {
            'title': `${CODE}.change_password_title`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
