<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <input-number v-model="perTask" :options="{ id: 'perTask', transPrefix: `${transPrefix}.fields`, label: 'per_task', error: fieldErrors.perTask }"/>
              <input-number v-model="kmStartAfter" :options="{ id: 'kmStartAfter', transPrefix: `${transPrefix}.fields`, label: 'km_start_after', error: fieldErrors.kmStartAfter }"/>
              <input-number v-model="perKm" :options="{ id: 'perKm', transPrefix: `${transPrefix}.fields`, label: 'per_km', error: fieldErrors.perKm }"/>
              <input-number v-model="maxOrderAmount" :options="{ id: 'maxOrderAmount', transPrefix: `${transPrefix}.fields`, label: 'max_order_amount', error: fieldErrors.maxOrderAmount }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.show` }" class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  SUPPLIERS_CONFIGS_SHOW as CRUD_ACTION_SHOW,
  SUPPLIERS_CONFIGS_UPDATE as CRUD_ACTION_UPDATE,
} from "actions/suppliers";
const SUPPLIERS_CODE = "suppliers";
const CRUD_CODE = `${SUPPLIERS_CODE}.configs`;
export default {
  name: "SupplierConfigEdit",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      suppliersRoutePrefix: `${SUPPLIERS_CODE}`,
      suppliersTransPrefix: `${SUPPLIERS_CODE}`,
      id: this.$router.currentRoute.params.id,
      perTask: "",
      kmStartAfter: "",
      maxOrderAmount: "",
      perKm: "",
      serverError: "",
      fieldErrors: {},
      responseStatus: "",
      formMode: 'edit',
      loading: false,
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    isItemFound() {
      return this.responseStatus !== 404;
    },
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          supplierId: this.id,
        })
        .then((response) => {
          this.perTask = response.data.data.perTask;
          this.kmStartAfter = response.data.data.kmStartAfter;
          this.maxOrderAmount = response.data.data.maxOrderAmount;
          this.perKm = response.data.data.perKm;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    prepareRequestData() {
      return {
        perTask: this.sanitizeField("perTask"),
        kmStartAfter: this.sanitizeField("kmStartAfter"),
        maxOrderAmount: this.sanitizeField("maxOrderAmount"),
        perKm: this.sanitizeField("perKm"),
      };
    },
    update() {
      this.loading = true;
      this.$store
        .dispatch(CRUD_ACTION_UPDATE, {
          supplierId: this.id,
          data: {
            ...this.prepareRequestData(),
          },
        })
        .then(() => {
          this.loading = false;
          swal({
            icon: "success",
            text: this.$i18n.t("messages.update_successfully"),
            buttons: false,
            timer: 5000,
          });
          this.$router.push({
            name: `${this.routePrefix}.show`,
            params: { id: this.id },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.fieldErrors = {};
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
  },
};
</script>
