import Vue                      from "vue";
import Loading                  from "../lib/loading";
import CenterContainer          from "../lib/center-container";
import Paginate                 from 'vuejs-paginate';
import VueCountdown             from '@chenfengyuan/vue-countdown';

//Global Component
Vue.component("loading", Loading);
Vue.component("center-container", CenterContainer);

Vue.component('paginate', Paginate);

Vue.component(VueCountdown.name, VueCountdown);

//Card Component
import CardHeader from '@/components/crud/card/CardHeader';
import CardFooter from '@/components/crud/card/CardFooter';
import CardContainer from '@/components/crud/card/CardContainer';

Vue.component('card-container', CardContainer);
Vue.component('card-header', CardHeader);
Vue.component('card-footer', CardFooter);

//Table Component
import TableHeader from '@/components/crud/table/TableHeader';
import TableContainer from '@/components/crud/table/TableContainer';
import TableItemActions from '@/components/crud/table/TableItemActions';
import TableItemActionsShow from '@/components/crud/table/item_actions/Show';
import TableItemActionsEdit from '@/components/crud/table/item_actions/Edit';
import TableItemActionsDelete from '@/components/crud/table/item_actions/Delete';

Vue.component('table-container', TableContainer);
Vue.component('table-header', TableHeader);
Vue.component('table-item-actions', TableItemActions);
Vue.component('table-item-actions-show', TableItemActionsShow);
Vue.component('table-item-actions-edit', TableItemActionsEdit);
Vue.component('table-item-actions-delete', TableItemActionsDelete);

//Crud Actions
import ExportAction from '@/components/crud/actions/Export';

Vue.component('export-action', ExportAction);

// Packages

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

//Dropdown Fields
import SelectCountries from '@/components/fields/dropdown/countries';
import SelectCities from '@/components/fields/dropdown/cities';
import SelectZones from '@/components/fields/dropdown/zones';
import SelectBanks from '@/components/fields/dropdown/banks';
import SelectPlatforms from '@/components/fields/dropdown/platforms';
import SelectDeviceTypes from '@/components/fields/dropdown/device_types';
import SelectUpdateActions from '@/components/fields/dropdown/update_actions';
import SelectCaptains from '@/components/fields/dropdown/captains';
import SelectCapabilities from '@/components/fields/dropdown/capabilities';
import SelectCaptainIdType from '@/components/fields/dropdown/captain_id_type';
import SelectCaptainStatus from '@/components/fields/dropdown/captain_status';
import SelectCaptainWorkingStatus from '@/components/fields/dropdown/captain_working_status';
import SelectPaymentTypes from '@/components/fields/dropdown/payment_types';
import SelectOrderType from '@/components/fields/dropdown/order_types';
import SelectOrderAssignmentMethods from '@/components/fields/dropdown/order_assignment_methods';
import SelectOrderStatus from '@/components/fields/dropdown/order_status';
import SelectOrderGiftStatus from '@/components/fields/dropdown/order_gift_status';
import SelectOrderTimelineStatus from '@/components/fields/dropdown/order_timeline_status';
import SelectRoles from '@/components/fields/dropdown/roles';
import SelectFintechType from '@/components/fields/dropdown/fintech_type';
import SelectYesNo from '@/components/fields/dropdown/yes_no';
import SelectLanguageCodes from '@/components/fields/dropdown/language_codes';
import SelectTransactionTypes from '@/components/fields/dropdown/transaction_types';
import SelectWithdrawStatus from '@/components/fields/dropdown/withdraw_status';
import SelectCaptainsRegistrationRequestsStatus from '@/components/fields/dropdown/captains_registration_requests_status';
import SelectWalletTransactionTypes from '@/components/fields/dropdown/wallet_transaction_types';
import SelectAppDeviceTypes from '@/components/fields/dropdown/app_device_types';
import SelectSupplierTypes from '@/components/fields/dropdown/supplier_types';
import SelectSuppliers from '@/components/fields/dropdown/suppliers';
import SelectAllocationConfigTypes from '@/components/fields/dropdown/allocation_config_types';
import SelectCaptainBonusTypes from '@/components/fields/dropdown/captain_bonus_types';
import SelectCaptainBonusOwnerTypes from '@/components/fields/dropdown/captain_bonus_owner_types';
import SelectWalletOwnerTypes from '@/components/fields/dropdown/wallet_owner_types';
import SelectReferralProgramTypes from '@/components/fields/dropdown/referral_program_types';
import SelectAnalysisTypes from '@/components/fields/dropdown/analysis_types';
import SelectTicketRootTypes from '@/components/fields/dropdown/ticket_root_types';
import SelectTicketRootLanguageCodes from '@/components/fields/dropdown/ticket_root_language_codes';
import SelectTicketRootTypeActionTypes from '@/components/fields/dropdown/ticket_root_type_action_types';
import SelectWithdrawRejectReasons from '@/components/fields/dropdown/withdraw_reject_reasons';
import SelectPlatformInvoiceStatuses from '@/components/fields/dropdown/platform_invoice_statuses';
import SelectSupplierReportStatuses from '@/components/fields/dropdown/supplier_report_statuses';
import SelectOrderCancellationReasons from '@/components/fields/dropdown/order_cancellation_reasons';
import SelectTicketOwnerTypes from '@/components/fields/dropdown/ticket_owner_types';
import SelectTicketRelatedTypes from '@/components/fields/dropdown/ticket_related_types';
import SelectTicketReferenceTypes from '@/components/fields/dropdown/ticket_reference_types';
import SelectAllocationWindowStatuses from '@/components/fields/dropdown/allocation_window_statuses';
import SelectEnvelopeType from "@/views/envelopes/partials/select-envelope-type";
import SelectEnvelopeSenderId from "@/views/envelopes/partials/select-envelope-sender-id";
import SelectPlatformBrands from "@/components/fields/dropdown/platform_brands";


Vue.component('select-countries', SelectCountries);
Vue.component('select-cities', SelectCities);
Vue.component('select-zones', SelectZones);
Vue.component('select-banks', SelectBanks);
Vue.component('select-platforms', SelectPlatforms);
Vue.component('select-device-types', SelectDeviceTypes);
Vue.component('select-update-actions', SelectUpdateActions);
Vue.component('select-capabilities', SelectCapabilities);
Vue.component('select-captains', SelectCaptains);
Vue.component('select-captains', SelectCaptains);
Vue.component('select-captain-id-type', SelectCaptainIdType);
Vue.component('select-fintech-type', SelectFintechType);
Vue.component('select-captain-status', SelectCaptainStatus);
Vue.component('select-captain-working-status', SelectCaptainWorkingStatus);
Vue.component('select-payment-types', SelectPaymentTypes);
Vue.component('select-order-types', SelectOrderType);
Vue.component('select-order-assignment-methods', SelectOrderAssignmentMethods);
Vue.component('select-order-status', SelectOrderStatus);
Vue.component('select-order-gift-status', SelectOrderGiftStatus);
Vue.component('select-order-timeline-status', SelectOrderTimelineStatus);
Vue.component('select-roles', SelectRoles);
Vue.component('select-yes-no', SelectYesNo);
Vue.component('select-language-codes', SelectLanguageCodes);
Vue.component('select-transaction-types', SelectTransactionTypes);
Vue.component('select-withdraw-status', SelectWithdrawStatus);
Vue.component('select-captains-registration-requests-status', SelectCaptainsRegistrationRequestsStatus);
Vue.component('select-wallet-transaction-types', SelectWalletTransactionTypes);
Vue.component('select-app-device-types', SelectAppDeviceTypes);
Vue.component('select-supplier-types', SelectSupplierTypes);
Vue.component('select-suppliers', SelectSuppliers);
Vue.component('select-allocation-config-types', SelectAllocationConfigTypes);
Vue.component('select-captain-bonus-types', SelectCaptainBonusTypes);
Vue.component('select-captain-bonus-owner-types', SelectCaptainBonusOwnerTypes);
Vue.component('select-wallet-owner-types', SelectWalletOwnerTypes);
Vue.component('select-referral-program-types', SelectReferralProgramTypes);
Vue.component('select-analysis-types', SelectAnalysisTypes);
Vue.component('select-ticket-root-types', SelectTicketRootTypes);
Vue.component('select-ticket-root-language-codes', SelectTicketRootLanguageCodes);
Vue.component('select-ticket-root-type-action-types', SelectTicketRootTypeActionTypes);
Vue.component('select-withdraw-reject-reasons', SelectWithdrawRejectReasons);
Vue.component('select-platform-invoice-statuses', SelectPlatformInvoiceStatuses);
Vue.component('select-supplier-report-statuses', SelectSupplierReportStatuses);
Vue.component('select-order-cancellation-reasons', SelectOrderCancellationReasons);
Vue.component('select-ticket-owner-types', SelectTicketOwnerTypes);
Vue.component('select-ticket-related-types', SelectTicketRelatedTypes);
Vue.component('select-ticket-reference-types', SelectTicketReferenceTypes);
Vue.component('select-allocation-window-statuses', SelectAllocationWindowStatuses);
Vue.component('select-envelope-type', SelectEnvelopeType);
Vue.component('select-envelope-sender-id', SelectEnvelopeSenderId);
Vue.component('select-platform-brands', SelectPlatformBrands);


//Search Fields
import SearchPlatformBranches from '@/components/fields/search/platform_branches';

Vue.component('search-platform-branches', SearchPlatformBranches);


//Map Fields
import MapsGeneral from '@/components/fields/maps/general';
import MapsHeat from '@/components/fields/maps/heat_map';
import MapsTasks from '@/components/fields/maps/tasks';
import MapsPickAddress from '@/components/fields/maps/pick_address';

Vue.component('maps-general', MapsGeneral);
Vue.component('maps-heat', MapsHeat);
Vue.component('maps-tasks', MapsTasks);
Vue.component('maps-pick-address', MapsPickAddress);


// Files Fields
import FilesImage from '@/components/fields/files/image';
import FilesSingle from '@/components/fields/files/single';
Vue.component('files-image', FilesImage);
Vue.component('files-single', FilesSingle);

// Files Fields
import InputCheckbox from '@/components/fields/input/checkbox';
import InputText from '@/components/fields/input/text';
import InputEmail from '@/components/fields/input/email';
import InputPassword from '@/components/fields/input/password';
import InputTextarea from '@/components/fields/input/textarea';
import InputNumber from '@/components/fields/input/number';
import InputTime from '@/components/fields/input/time';
import InputDate from '@/components/fields/input/date';
import InputDatetime from '@/components/fields/input/datetime';

Vue.component('input-checkbox', InputCheckbox);
Vue.component('input-text', InputText);
Vue.component('input-email', InputEmail);
Vue.component('input-password', InputPassword);
Vue.component('input-textarea', InputTextarea);
Vue.component('input-number', InputNumber);
Vue.component('input-time', InputTime);
Vue.component('input-date', InputDate);
Vue.component('input-datetime', InputDatetime);


//Abstract
import AbstractInput from '@/components/fields/abstracts/input';
import AbstractInputContainer from '@/components/fields/abstracts/input_container';
import AbstractDropdown from '@/components/fields/abstracts/dropdown';
import AbstractDropdownApi from '@/components/fields/abstracts/dropdown_api';
import AbstractDropdownContainer from '@/components/fields/abstracts/dropdown_container';


Vue.component('abstract-input', AbstractInput);
Vue.component('abstract-input-container', AbstractInputContainer);
Vue.component('abstract-dropdown', AbstractDropdown);
Vue.component('abstract-dropdown-api', AbstractDropdownApi);
Vue.component('abstract-dropdown-container', AbstractDropdownContainer);

