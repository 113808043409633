export const WITHDRAW_REQUESTS_LIST = "WITHDRAW_REQUESTS_LIST";
export const WITHDRAW_REQUESTS_CREATE = "WITHDRAW_REQUESTS_CREATE";
export const WITHDRAW_REQUESTS_UPDATE = "WITHDRAW_REQUESTS_UPDATE";
export const WITHDRAW_REQUESTS_DELETE = "WITHDRAW_REQUESTS_DELETE";
export const WITHDRAW_REQUESTS_SHOW = "WITHDRAW_REQUESTS_SHOW";
export const WITHDRAW_REQUESTS_REJECT = "WITHDRAW_REQUESTS_REJECT";
export const WITHDRAW_REQUESTS_ACCEPT = "WITHDRAW_REQUESTS_ACCEPT";
export const WITHDRAW_REQUESTS_REJECT_REASONS_LIST = "WITHDRAW_REQUESTS_REJECT_REASONS_LIST";
export const WITHDRAW_REQUESTS_BULK_ACCEPT = "WITHDRAW_REQUESTS_BULK_ACCEPT";
export const WITHDRAW_REQUESTS_COUNTDOWN = "WITHDRAW_REQUESTS_COUNTDOWN";
export const WITHDRAW_REQUESTS_TYPE_LIST = "WITHDRAW_REQUESTS_TYPE_LIST";
export const WITHDRAW_REQUESTS_STATUS_LIST = "WITHDRAW_REQUESTS_STATUS_LIST";
export const WITHDRAW_REQUESTS_RETRY_SUMMARY = "WITHDRAW_REQUESTS_RETRY_SUMMARY";
export const WITHDRAW_REQUESTS_RETRY_ONE = "WITHDRAW_REQUESTS_RETRY_ONE";
export const WITHDRAW_REQUESTS_RETRY_BULK = "WITHDRAW_REQUESTS_RETRY_BULK";
