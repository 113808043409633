<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <router-link v-if="!serverError" :to="{ name: `${routePrefix}.edit`,params:{id} }"  class="btn btn-primary btn-sm" v-can:edit="permissionPrefix">

            <i class="fa fa-edit"></i>
          </router-link>
        </card-header>
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', disabled: true }"/>
              <select-referral-program-types v-model="type" :options="{ id: 'referral_program_types', label: 'type', disabled: true }"/>
              <select-countries v-model="country_id" :options="{ id: 'country_id', label: 'country', disabled: true }"/>
              <input-number v-model="revenue" :options="{ id: 'revenue', label: 'revenue', disabled: true }"/>
              <input-number v-model="maximum_limit" :options="{ id: 'maximum_limit', step: 1, label: 'maximum_limit', disabled: true }"/>
            </div>
            <div class="row" v-if="!serverError">
              <input-checkbox v-model="enabled" :options="{ id: 'enabled', label: 'enabled', disabled: true }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { REFERRAL_PROGRAMS_SHOW as CRUD_ACTION_SHOW } from 'actions/referral_programs';
  const CRUD_CODE="referral_programs";
  export default {
    name: "ReferralProgramShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: `${CRUD_CODE}`,
        id:this.$router.currentRoute.params.id,
        country_id: '',
        name: '',
        revenue: '',
        maximum_limit: '',
        type: '',
        enabled: false,
        expired: false,
        serverError: '',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.type=response.data.type;
          this.maximum_limit=response.data.maximumLimit;
          this.country_id=response.data.countryId;
          this.name=response.data.name;
          this.revenue=response.data.revenue;
          this.enabled=response.data.enabled;
          this.type=response.data.type;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
