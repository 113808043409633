import {
    WITHDRAW_REQUESTS_LIST as CRUD_ACTION_LIST,
    WITHDRAW_REQUESTS_CREATE as CRUD_ACTION_CREATE,
    WITHDRAW_REQUESTS_SHOW as CRUD_ACTION_SHOW,
    WITHDRAW_REQUESTS_DELETE as CRUD_ACTION_DELETE,
    WITHDRAW_REQUESTS_UPDATE as CRUD_ACTION_UPDATE,
    WITHDRAW_REQUESTS_REJECT as CRUD_ACTION_REJECT,
    WITHDRAW_REQUESTS_ACCEPT as CRUD_ACTION_ACCEPT,
    WITHDRAW_REQUESTS_REJECT_REASONS_LIST as CRUD_ACTION_REJECT_REASONS_LIST,
    WITHDRAW_REQUESTS_BULK_ACCEPT as CRUD_ACTION_BULK_ACCEPT,
    WITHDRAW_REQUESTS_COUNTDOWN as CRUD_ACTION_COUNTDOWN,
    WITHDRAW_REQUESTS_TYPE_LIST as CRUD_ACTION_TYPE_LIST,
    WITHDRAW_REQUESTS_STATUS_LIST as STATUS_ACTION_LIST,
    WITHDRAW_REQUESTS_RETRY_SUMMARY,
    WITHDRAW_REQUESTS_RETRY_ONE,
    WITHDRAW_REQUESTS_RETRY_BULK
} from "../actions/withdraw_requests";
import axioses from "@/axios/config";
import qs from "qs";

const routePrefix='withdraw_requests';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
          .then(response => {
              resolve(response);
              commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
              commit('STOP_LOADING', params.withLoading, { root: true });
              reject(error);
          });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.base
          .post(`/${routePrefix}/create`, params)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_REJECT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .patch(`/${routePrefix}/${params.id}/REJECT`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_ACCEPT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .patch(`/${routePrefix}/${params.id}/ACCEPT`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_REJECT_REASONS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}/reject_reasons`,{ params })
          .then(response => {
              resolve(response);
              commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
              commit('STOP_LOADING', params.withLoading, { root: true });
              reject(error);
          });
    });
  },
  [CRUD_ACTION_BULK_ACCEPT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .patch(`/${routePrefix}/accept`, params.data.ids)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_COUNTDOWN]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .get(`/${routePrefix}/upcoming-round`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [CRUD_ACTION_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let response ={
            data: ["STC_PAY", "BANK_TRANSFER"]
        };
        resolve(response);
    });
  },
  [STATUS_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let response ={
            data: ["INIT", "PENDING", "ACCEPTED", "TRANSFERRED", "REJECTED", "FAILED", "PROCESSING_TRANSFER"]
        };
        resolve(response);
    });
  },
  [WITHDRAW_REQUESTS_RETRY_SUMMARY]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          axioses.base
              .get(`/${routePrefix}/retry`, { params, paramsSerializer: params => qs.stringify(params, { skipNulls: true, indices: false }) })
              .then(response => {
                  resolve(response);
              })
              .catch(error => {
                  reject(error);
              });
      });
  },
  [WITHDRAW_REQUESTS_RETRY_ONE]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          commit('START_LOADING', null, { root: true });
          axioses.base
              .patch(`/${routePrefix}/retry/${params.id}`)
              .then(response => {
                  resolve(response);
                  commit('STOP_LOADING', null, { root: true });
              })
              .catch(error => {
                  commit('STOP_LOADING', null, { root: true });
                  reject(error);
              });
      });
  },
  [WITHDRAW_REQUESTS_RETRY_BULK]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          commit('START_LOADING', null, { root: true });
          axioses.base
              .patch(`/${routePrefix}/retry`, { ...params })
              .then(response => {
                  resolve(response);
                  commit('STOP_LOADING', null, { root: true });
              })
              .catch(error => {
                  commit('STOP_LOADING', null, { root: true });
                  reject(error);
              });
      });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
