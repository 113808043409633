<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <router-link v-if="!serverError" :to="{ name: `${transactionsRoutePrefix}.index`,params:{id,wallet_id} }"  class="btn btn-primary btn-sm">
            <span class="d-none d-lg-inline mr-2">{{ $t(`${transactionsRoutePrefix}.title`)}}</span>
            <span>
              <i class="fa fa-money-bill"></i>
            </span>
          </router-link>
        </card-header>
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', disabled: true }"/>
              <input-text v-model="balance" :options="{ id: 'balance', label: 'balance', disabled: true }"/>
              <input-text v-model="currency_en_name" :options="{ id: 'currency_en_name', transPrefix:`${walletTransPrefix}.fields`, label: 'currency.en_name', disabled: true }"/>
              <input-text v-model="currency_ar_name" :options="{ id: 'currency_ar_name', transPrefix:`${walletTransPrefix}.fields`, label: 'currency.ar_name', disabled: true }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { CAPTAINS_WALLET_SHOW as CRUD_ACTION_SHOW } from 'actions/captains';
  const CRUD_CODE="captains";
  const WALLET_CODE="wallet";
  const TRANSACTION_CODE="transactions";
  export default {
    name: "CaptainWalletShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        walletRoutePrefix: `${CRUD_CODE}.${WALLET_CODE}`,
        walletTransPrefix: `${CRUD_CODE}.${WALLET_CODE}`,
        transactionsRoutePrefix: `${CRUD_CODE}.${WALLET_CODE}.${TRANSACTION_CODE}`,
        transactionsTransPrefix: `${CRUD_CODE}.${WALLET_CODE}.${TRANSACTION_CODE}`,
        id:this.$router.currentRoute.params.id,
        wallet_id:0,
        currency_en_name:"",
        currency_ar_name:"",
        balance:"",
        name:'',
        serverError: '',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.wallet_id=response.data.id;
          this.balance=response.data.balance;
          this.name=response.data.name;
          this.currency_en_name=response.data.currency?response.data.currency.name:'';
          this.currency_ar_name=response.data.currency?response.data.currency.nameAr:'';
        }).catch(error => {
          this.loading = false
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
