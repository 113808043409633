<template>
  <div class="main-content">
    <!-- Header -->
    <div class="header bg-gradient-primary py-5 pb-6">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5 text-center">
              <h1 class="text-white">{{ $t('labels.welcome') }}</h1>
              <p class="text-lead text-white">
                {{ $t('auth.login.welcome') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100" ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>{{ $t('auth.login.with_credentials') }}</small>
              </div>
              <form role="form" @submit.prevent="login">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
                <div class="form-group mb-3">
                  <div class="input-group input-group-merge input-group-alternative" >
                    <div class="input-group-prepend">
                      <span class="input-group-text" >
                        <i class="ni ni-email-83"></i>
                      </span>
                    </div>
                    <input-text v-model="username" :options="{ id: 'username', fullLayout: false, placeholder: 'fields.username' }"/>
                  </div>
                  <span v-if="fieldErrors.email" role="alert" class="invalid-feedback d-block">
                    <strong>{{fieldErrors.email}}</strong>
                  </span>
                </div>
                <div class="form-group">
                  <div class="input-group input-group-merge input-group-alternative">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="ni ni-lock-circle-open"></i>
                      </span>
                    </div>
                    <input-password v-model="password" :options="{ id: 'password', fullLayout: false, placeholder: 'fields.password' }"/>
                  </div>
                  <span v-if="fieldErrors.password" role="alert" class="invalid-feedback d-block">
                    <strong>{{fieldErrors.password}}</strong>
                  </span>
                </div>
                <div class="custom-control custom-control-alternative custom-checkbox d-none">
                  <input class="custom-control-input" id=" customCheckLogin" type="checkbox"/>
                  <label class="custom-control-label" for=" customCheckLogin">
                    <span class="text-muted">Remember me</span>
                  </label>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary my-4" :disabled="loading">
                    {{ $t('labels.sign_in') }}
                    <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6 text-left">
              <router-link :to="{ name: 'auth.password.forget' }" class="text-light"><small> {{ $t('labels.forget_password') }}</small></router-link>
            </div>
            <div class="col-6 text-right d-none">
              <a href="#" class="text-light">
                <small>Create new account</small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { AUTH_REQUEST } from "actions/auth";

  export default {
    name: "Login",
  components: {},
  data() {
    return {
      username: '',
      password: '',
      serverError: '',
      fieldErrors: {},
      loading: false,
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.$store.dispatch(AUTH_REQUEST, {
        username: this.username,
        password: this.password,
      })
      .then(() => {
        this.$router.push({ name: 'dashboard.index' })
      })
      .catch(error => {
        this.loading = false;
        this.password = '';
        this.fieldErrors={};
        this.serverError='';

        if(error.response){
          let responseData=error.response.data;

          if(responseData.errors){
              this.fieldErrors=responseData.errors;
          }else{
            this.serverError = 'Invalid Request.'
          }
        }else{
          this.serverError = this.$i18n.t("messages.no_internet_connection");
        }

        if(this.fieldErrors.error){
          this.serverError = this.fieldErrors.error;
        }
      })
    }
  }
};
</script>
