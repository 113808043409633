<template>
    <a href="javascript:;"
       class="btn btn-primary btn-sm"
       v-bind:class="{ 'disabled': !enableRetryButton }"
       data-target="#modal-retry-bulk"
       data-toggle="modal"
       @click="openRetryPopup"
       v-b-tooltip.hover="{ animation: false }"
       :title="$t('messages.retry_withdrawal_bulk_title')">
      {{ $t(`labels.retry`) }} <i class="fas fa-sync"></i>
    </a>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "WithdrawRequestActionsRetryBulk",
        props: {
            value: {},
            selectedElementsCount: {}
        },
        data() {
            return {}
        },
        computed: {
          ...mapGetters(["getSearchFilters", "getSearchFiltersSensitized"]),
          enableRetryButton() {
            const isFailedStatusSet = this.getSearchFilters.withdraw_status?.length === 1
                && this.getSearchFilters.withdraw_status[0] === 'FAILED'
                || this.getSearchFilters.withdraw_status === 'FAILED';
            const isDateRangeSet = this.getSearchFilters.created_from
                && this.getSearchFilters.created_to;
            return isFailedStatusSet && isDateRangeSet && this.selectedElementsCount > 0;
          }
        },
        methods: {
          openRetryPopup() {
            this.$emit('input',{ fetchRetrySummary: true });
          }
        }
    }
</script>

<style scoped>

</style>
