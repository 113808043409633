<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <input-text v-model="legalName" :options="{ id: 'legalName', transPrefix: `${transPrefix}.fields`, label: 'legal_name', error: fieldErrors.legalName }"/>
              <input-text v-model="legalNameAr" :options="{ id: 'legalNameAr', transPrefix: `${transPrefix}.fields`, label: 'legal_name_ar', error: fieldErrors.legalNameAr }"/>
              <input-email v-model="billingEmail" :options="{ id: 'billingEmail', transPrefix: `${transPrefix}.fields`, label: 'billing_email', error: fieldErrors.billingEmail }"/>
              <input-text v-model="vatNumber" :options="{ id: 'vatNumber', transPrefix: `${transPrefix}.fields`, label: 'vat_number', error: fieldErrors.vatNumber }"/>
              <input-text v-model="crNumber" :options="{ id: 'crNumber', transPrefix: `${transPrefix}.fields`, label: 'cr_number', error: fieldErrors.crNumber }"/>
              <input-text v-model="zipCode" :options="{ id: 'zipCode', transPrefix: `${transPrefix}.fields`, label: 'zip_code', error: fieldErrors.zipCode }"/>
              <input-text v-model="poBox" :options="{ id: 'poBox', transPrefix: `${transPrefix}.fields`, label: 'po_box', error: fieldErrors.poBox }"/>
              <input-text v-model="addressLine1" :options="{ id: 'addressLine1', transPrefix: `${transPrefix}.fields`, label: 'address_line_1', error: fieldErrors.addressLine1 }"/>
              <input-text v-model="addressLine2" :options="{ id: 'addressLine2', transPrefix: `${transPrefix}.fields`, label: 'address_line_2', error: fieldErrors.addressLine2 }"/>
              <input-number v-model="invoiceDueDateAfter" :options="{ id: 'invoiceDueDateAfter', transPrefix: `${transPrefix}.fields`, label: 'invoice_due_date_after', error: fieldErrors.invoiceDueDateAfter }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.show` }" class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  PLATFORMS_DETAILS_SHOW as CRUD_ACTION_SHOW,
  PLATFORMS_DETAILS_UPDATE as CRUD_ACTION_UPDATE,
} from "actions/platforms";
const PLATFORMS_CODE = "platforms";
const CRUD_CODE = `${PLATFORMS_CODE}.details`;
export default {
  name: "PlatformDetailEdit",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      platformsRoutePrefix: `${PLATFORMS_CODE}`,
      platformsTransPrefix: `${PLATFORMS_CODE}`,
      id: this.$router.currentRoute.params.id,
      legalName: "",
      legalNameAr : "",
      billingEmail: "",
      vatNumber: "",
      crNumber: "",
      zipCode: "",
      poBox: "",
      addressLine1: "",
      addressLine2: "",
      invoiceDueDateAfter: "",
      serverError: "",
      fieldErrors: {},
      responseStatus: "",
      formMode: 'edit',
      loading: false,
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    isItemFound() {
      return this.responseStatus !== 404;
    },
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          platformId: this.id,
        })
        .then((response) => {
          this.billingEmail = response.data.billingEmail;
          this.legalNameAr = response.data.legalNameAr;
          this.legalName = response.data.legalName;
          this.vatNumber = response.data.vatNumber;
          this.crNumber = response.data.crNumber;
          this.zipCode = response.data.zipCode;
          this.invoiceDueDateAfter = response.data.invoiceDueDateAfter;
          this.poBox = response.data.poBox;
          this.addressLine1 = response.data.addressLine1;
          this.addressLine2 = response.data.addressLine2;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    prepareRequestData() {
      return {
        billingEmail: this.sanitizeField("billingEmail"),
        legalName: this.sanitizeField("legalName"),
        legalNameAr : this.sanitizeField("legalNameAr"),
        crNumber: this.sanitizeField("crNumber"),
        vatNumber: this.sanitizeField("vatNumber"),
        invoiceDueDateAfter: this.sanitizeField("invoiceDueDateAfter"),
        zipCode: this.sanitizeField("zipCode"),
        poBox: this.sanitizeField("poBox"),
        addressLine1: this.sanitizeField("addressLine1"),
        addressLine2: this.sanitizeField("addressLine2"),
      };
    },
    update() {
      this.loading = true;
      this.$store
        .dispatch(CRUD_ACTION_UPDATE, {
          platformId: this.id,
          data: {
            ...this.prepareRequestData(),
          },
        })
        .then(() => {
          this.loading = false;
          swal({
            icon: "success",
            text: this.$i18n.t("messages.update_successfully"),
            buttons: false,
            timer: 5000,
          });
          this.$router.push({
            name: `${this.routePrefix}.show`,
            params: { id: this.id },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.fieldErrors = {};
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
  },
};
</script>
