<template>
  <div aria-labelledby="captain-tab" class="tab-pane fade" id="captain" role="tabpanel" v-if="fields.id">
    <div class="row">
      <input-text v-model="fields.id" :options="{ id: 'id', label: 'id', disabled: true }"/>
      <input-text v-model="fields.name" :options="{ id: 'name', label: 'name', disabled: true }"/>
      <input-text v-model="fields.email" :options="{ id: 'email', label: 'email', disabled: true }"/>
      <input-text v-model="fields.mobileNumber" :options="{ id: 'mobile_number', label: 'mobile_number', disabled: true }"/>
      <input-text v-model="fields.idNumber" :options="{ id: 'id_number', transPrefix: 'captains.fields', label: 'id_number', disabled: true }"/>
      <select-captain-id-type v-model="fields.idType" :options="{ id: 'id_type', transPrefix: 'captains.fields',  label: 'id_type', disabled: true }"/>
      <select-captain-status v-model="fields.status" :options="{ id: 'status', label: 'status', disabled: true }"/>
      <select-captain-working-status v-model="fields.workingStatus" :options="{ id: 'working_status', transPrefix: 'captains.fields', label: 'working_status', disabled: true }"/>
      <input-checkbox v-model="fields.readyToWork" :options="{ id: 'ready_to_work', transPrefix: 'captains.fields', label: 'ready_to_work', disabled: true }"/>
      <input-checkbox v-model="fields.connected" :options="{ id: 'connected', transPrefix: 'captains.fields', label: 'connected', disabled: true }"/>
      <input-checkbox v-model="fields.suspended" :options="{ id: 'connected', transPrefix: 'captains.fields', label: 'suspended', disabled: true }"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: "OrderTabsCaptain",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      fields: {
        type: Object,
        default: () => { }
      },
    },
  };
</script>
