<template>
    <div v-bind:class="[defaultOptions.containerClass]" v-if="defaultOptions.fullLayout">
        <div class="form-group">
            <template v-if="['checkbox','checkbox-custom'].includes(defaultOptions.type)">
                <div class="row">
                    <div class="col-8">
                        <label v-if="getLabel()" :for="defaultOptions.id">{{ $t(getLabel()) }}</label>
                        <slot name="hint"></slot>
                        <span v-if="!defaultOptions.disabled && defaultOptions.error" class="invalid-feedback d-block" role="alert">
                            <strong>{{ defaultOptions.error }}</strong>
                        </span>
                    </div>
                    <div class="col-4 text-right">
                        <abstract-input v-model="selectedValue" :options="{...defaultOptions, ...options}"/>
                    </div>
                </div>
            </template>
            <template v-else>
                <label v-if="getLabel()" :for="defaultOptions.id">{{ $t(getLabel()) }}</label>
                <slot name="hint"></slot>
                <abstract-input v-model="selectedValue" :options="{...defaultOptions, ...options}"/>
                <span v-if="!defaultOptions.disabled && defaultOptions.error" class="invalid-feedback" role="alert">
                    <strong>{{ defaultOptions.error }}</strong>
                </span>
            </template>
        </div>
    </div>
    <abstract-input v-else v-model="selectedValue" :options="{...defaultOptions, ...options}"/>
</template>

<script>
    export default {
        name: "AbstractsInputContainer",
        props: {
            value: {},
            options: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                selectedValue: this.value,
                defaultOptions: {
                    fullLayout:                             true,
                    disabled:                               false,
                    preventSpace:                           false,
                    error:                                  "",
                    transPrefix:                            "fields",
                    label:                                  "",
                    containerClass:                         "col-12 col-md-6",
                    ...this.options
                }
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            },
            options: {
                deep: true,
                handler: function (newVal, oldVal) {
                    this.defaultOptions = { ...this.defaultOptions, ...newVal};
                }
            }
        },
        methods: {
            getLabel() {
                return this.defaultOptions.label ? `${this.defaultOptions.transPrefix}.${this.defaultOptions.label}`: "";
            },
        }
    }
</script>

<style scoped>

</style>
