<template>
    <abstract-dropdown-container v-model="selectedValue" :items="items" :options="{...defaultOptions, ...options}">
        <template v-slot:hint-top>
            <slot name="hint-top"></slot>
        </template>
        <template v-slot:hint-bottom>
            <slot name="hint-bottom"></slot>
        </template>
    </abstract-dropdown-container>
</template>

<script>
    import {PLATFORMS_BRANDS_LIST as CRUD_ACTION_LIST} from "actions/platforms";

    export default {
        name: "FieldsDropdownPlatformBrands",
        props: {
            value: {},
            options: {
                type: Object,
                default: () => {}
            },
            platform_id: {}
        },
        data() {
            return {
                items: [],
                selectedValue: this.value,
                currentPage: 0,
                pageCount: 1,
                defaultOptions: {
                    id:                                     "platform_brands",
                    prepareResponseDataHandler:             this.prepareResponseData,
                    customLabelHandler:                     this.customLabel,
                    isLoading:                              false,
                    type:                                   'dropdown'
                }
            }
        },
      watch: {
        value: function (newVal, oldVal) {
          this.selectedValue = newVal;
        },
        selectedValue: function (newVal, oldVal) {
          this.$emit('input', newVal);
        },
        platform_id: function (newVal, oldVal) {
          this.items = [];
          this.currentPage = 0;
          this.pageCount = 1;
          this.selectedValue = null;
          this.$emit('input', null);
          this.getList();
        }
      },
      mounted() {
        this.getList();
      },
      methods: {
        getList() {
          if (this.platform_id) {
            this.defaultOptions.isLoading = true;
            return this.$store.dispatch(CRUD_ACTION_LIST, {
              pageId: this.currentPage,
              withLoading: false,
              platformId: this.platform_id
            }).then(response => {
              this.items = [...this.items, ...response.data.data];
              this.pageCount = response.data.pageCount;
              this.currentPage++;
            }).then(() => {
              if (this.pageCount > this.currentPage) {
                return this.getList();
              }
            }).then(() => {
              this.defaultOptions.isLoading = false;
            });
          }
        },
        customLabel(value) {
          return value.name;
        },
        prepareResponseData(data){
          return data;
        }
      }
    }
</script>

<style scoped>

</style>
