import {
    MONITORING_STATISTICS,
    MONITORING_REPORT,
    MONITORING_ORDERS_EXTENDED,
    MONITORING_CAPTAINS_EXTENDED, ASSIGNMENT_METHODS_REPORT
} from "actions/monitoring";
import axioses from "@/axios/config";
import qs from "qs";

const routePrefix = "monitoring";

const state = {};

const getters = {};

const actions = {
    [MONITORING_STATISTICS]: ({ commit, dispatch }, params) => {
        return new Promise((resolve, reject) => {
            commit('START_LOADING', params.withLoading, { root: true });
            axioses.base
                .get(`/${routePrefix}/statistics`, { params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
                .then(response => {
                    commit('STOP_LOADING', params.withLoading, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('STOP_LOADING', params.withLoading, { root: true });
                    reject(error);
                });
        });
    },
    [MONITORING_ORDERS_EXTENDED]: ({ commit, dispatch }, params) => {
        return new Promise((resolve, reject) => {
            commit('START_LOADING', params.withLoading, { root: true });
            axioses.base
                .get(`/${routePrefix}/extended/orders`, { params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
                .then(response => {
                    commit('STOP_LOADING', params.withLoading, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('STOP_LOADING', params.withLoading, { root: true });
                    reject(error);
                });
        });
    },
    [MONITORING_CAPTAINS_EXTENDED]: ({ commit, dispatch }, params) => {
        return new Promise((resolve, reject) => {
            commit('START_LOADING', params.withLoading, { root: true });
            axioses.base
                .get(`/${routePrefix}/extended/captains`, { params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
                .then(response => {
                    commit('STOP_LOADING', params.withLoading, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('STOP_LOADING', params.withLoading, { root: true });
                    reject(error);
                });
        });
    },
    [MONITORING_REPORT]: ({ commit, dispatch }, params) => {
        return new Promise((resolve, reject) => {
            commit('START_LOADING', params.withLoading, { root: true });
            axioses.base
                .get(`/${routePrefix}/report`, { params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
                .then(response => {
                    commit('STOP_LOADING', params.withLoading, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('STOP_LOADING', params.withLoading, { root: true });
                    reject(error);
                });
        });
    },
    [ASSIGNMENT_METHODS_REPORT]: ({ commit, dispatch }, params) => {
        return new Promise((resolve, reject) => {
            commit('START_LOADING', params.withLoading, { root: true });
            axioses.base
                .get(`/${routePrefix}/assignment_methods_report`, { params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
                .then(response => {
                    commit('STOP_LOADING', params.withLoading, { root: true });
                    resolve(response);
                })
                .catch(error => {
                    commit('STOP_LOADING', params.withLoading, { root: true });
                    reject(error);
                });
        });
    }
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
};
