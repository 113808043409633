<template>
    <searchContainer :searchHandler="search" :resetHandler="reset">
        <template v-slot:items>
            <div class="row">
                <input-number v-model="filters.user_id" :options="{ id: 'user_id', transPrefix: `${transPrefix}.fields`, label: 'user_id' }"/>
                <select-audit-logs-item-types v-model="filters.item_types" :options="{ id: 'item_types', transPrefix: `${transPrefix}.fields`, label: 'item_types', placeholder: 'placeholders.all', multiple: true }"/>
                <input-number v-model="filters.item_id" :options="{ id: 'item_id', transPrefix: `${transPrefix}.fields`, label: 'item_id' }"/>
                <select-audit-logs-event-types v-model="filters.event_types" :options="{ id: 'event_types', transPrefix: `${transPrefix}.fields`, label: 'event_types', placeholder: 'placeholders.all', multiple: true }"/>
            </div>
            <div class="row">
                <input-date v-model="filters.event_date_from" :options="{ id: 'event_date_from', label: 'event_date_from' }"/>
                <input-date v-model="filters.event_date_to" :options="{ id: 'event_date_to', label: 'event_date_to' }"/>
            </div>
        </template>
    </searchContainer>
</template>
<script>

    import {mapGetters} from "vuex";
    import {CRUD_SEARCH_RESET} from "actions/crud";
    import searchContainer from "@/components/crud/card/CardSearch";
    import selectAuditLogsItemTypes from "./select-audit-logs-item-types";
    import selectAuditLogsEventTypes from "./select-audit-logs-event-types";

    const CRUD_CODE = "audit_logs";
    export default {
        name: "AuditLogsSearch",
        components: {
            searchContainer,
            selectAuditLogsItemTypes,
            selectAuditLogsEventTypes
        },
        props: {
            listHandler: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                transPrefix: CRUD_CODE,
                filters: {
                  user_id: this.getFilterByKey('user_id'),
                  item_types: this.getFilterByKey('item_types'),
                  item_id: this.getFilterByKey('item_id'),
                  event_types: this.getFilterByKey('event_types'),
                  event_date_from: this.getFilterByKey('event_date_from'),
                  event_date_to: this.getFilterByKey('event_date_to'),
                },
                filtersMap: {
                  user_id: 'userId',
                  item_types: 'itemTypes',
                  item_id: 'itemId',
                  event_types: 'eventTypes',
                  event_date_from: 'startTime',
                  event_date_to: 'endTime',
                }
            }
        },
        mounted() {
            this.setSearchParams(this.filters, this.filtersMap);
        },
        computed: {
            ...mapGetters([
                "getSearchFilters"
            ])
        },
        methods: {
            search() {
                this.resetPagination();
                this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                this.pushRoute(this.getSearchFilters);
                this.listHandler();
            },
            reset() {
                this.resetPagination();
                this.$store.dispatch(CRUD_SEARCH_RESET);
                this.filters = this.clearFilters(this.filters);
                this.pushRoute();
                this.listHandler();
            },
            resetPagination() {
                //Reset Pagination
                this.$emit('input', 0);
            },
            pushRoute(query = {}) {
                this.$router.push({name: this.$route.name, query}).catch(() => {});
            }
        }
    };
</script>
