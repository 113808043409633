<template>
  <div aria-labelledby="general-tab" class="tab-pane fade show active" id="general" role="tabpanel">
    <div class="row">
      <input-text v-model="fields.backend_id" :options="{ id: 'backend_id', transPrefix: `${transPrefix}.fields`, label: 'backend_id', disabled: true }"/>
      <input-text v-model="fields.id" :options="{ id: 'id', transPrefix: `${transPrefix}.fields`, label: 'id', disabled: true }"/>
      <input-text v-model="fields.earnings" :options="{ id: 'earnings', transPrefix: `${transPrefix}.fields`, label: 'earnings', disabled: true }"/>
      <input-text v-model="fields.km_total_distance" :options="{ id: 'km_total_distance', transPrefix: `${transPrefix}.fields`, label: 'km_total_distance', disabled: true }"/>
      <select-payment-types v-model="fields.payment_type" :options="{ id: 'payment_type', transPrefix: `${transPrefix}.fields`,  label: 'payment_type', disabled: true }"/>
      <select-order-status v-model="fields.order_status" :options="{ id: 'order_status', label: 'status', disabled: true }"/>
      <input-text v-if="fields.type" v-model="fields.type" :options="{ id: 'type', label: 'type', disabled: true }"/>
      <input-text v-if="fields.city" v-model="fields.city" :options="{ id: 'city', label: 'city', disabled: true }"/>
      <input-text v-if="fields.createdAt" v-model="fields.createdAt" :options="{ id: 'created_at', label: 'created_at', disabled: true }"/>
      <input-text v-if="fields.allocation_window_id" v-model="fields.allocation_window_id" :options="{ id: 'allocation_window_id', label: 'allocation_window_id', disabled: true }"/>
      <input-text v-if="fields.dispatched_by" v-model="fields.dispatched_by" :options="{ id: 'dispatched_by', label: 'dispatched_by', disabled: true }"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: "OrderTabsGeneral",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      fields: {
        type: Object,
        default: () => { }
      },
    },
    data(){
      return {
      }
    },
  };
</script>
