<template>
    <searchContainer :searchHandler="search" :resetHandler="reset">
        <template v-slot:items>
            <div class="row">
                <input-text v-model="filters.backend_id" :options="{ id: 'backend_id', transPrefix: `${transPrefix}.fields`, label: 'backend_id' }"/>
                <select-payment-types v-model="filters.payment_type" :options="{ id: 'payment_type', transPrefix: `${transPrefix}.fields`, label: 'payment_type', placeholder: 'placeholders.all' }"/>
                <select-order-status v-model="filters.order_status" :options="{ id: 'order_status', label: 'status', placeholder: 'placeholders.all', multiple: true }"/>
                <select-countries v-model="filters.country_id" :options="{ id: 'country_id', label: 'country',action: countries_list_action, placeholder: 'placeholders.all', disabled: canNotSelectCountry() }"/>
                <select-cities v-model="filters.city_id" :country_id="filters.country_id" :options="{ id: 'city_id', label: 'city', action: cities_list_action, placeholder: 'placeholders.all', disabled: canNotSelectCity() }"/>
            </div>
            <div class="row">
                <input-date v-model="filters.created_from" :options="{ id: 'created_from', label: 'created_from' }"/>
                <input-date v-model="filters.created_to" :options="{ id: 'created_to', label: 'created_to' }"/>
            </div>
        </template>
    </searchContainer>
</template>
<script>

    import {mapGetters} from "vuex";
    import {CRUD_SEARCH_RESET} from "actions/crud";
    import searchContainer from "@/components/crud/card/CardSearch";
    import {COUNTRIES_PUBLIC_LIST} from 'actions/countries';
    import {CITIES_PUBLIC_LIST} from 'actions/cities';

    const CRUD_CODE = "orders";
    export default {
        name: "PlatformsPortalOrderSearch",
        components: {
            searchContainer
        },
        props: {
            listHandler: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                transPrefix: CRUD_CODE,
                filters: {
                    backend_id: this.getFilterByKey('backend_id'),
                    payment_type: this.getFilterByKey('payment_type'),
                    order_status: this.getFilterByKey('order_status'),
                    country_id: this.getFilterByKey('country_id'),
                    city_id: this.getFilterByKey('city_id'),
                    created_from: this.getFilterByKey('created_from'),
                    created_to: this.getFilterByKey('created_to'),
                },
                filtersMap: {
                    backend_id: 'backendId',
                    captain_id: 'captainId',
                    city_id: 'cityId',
                    payment_type: 'paymentType',
                    order_status: 'orderStatus',
                    created_from: 'startDate',
                    created_to: 'endDate',
                },
                countries_list_action: COUNTRIES_PUBLIC_LIST,
                cities_list_action: CITIES_PUBLIC_LIST,
            }
        },
        mounted() {
            this.setSearchParams(this.filters, this.filtersMap);
        },
        computed: {
            ...mapGetters([
                "getSearchFilters"
            ])
        },
        methods: {
            search() {
                this.resetPagination();
                this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                this.pushRoute(this.getSearchFilters);
                this.listHandler();
            },
            reset() {
                this.resetPagination();
                this.$store.dispatch(CRUD_SEARCH_RESET);
                this.filters = this.clearFilters(this.filters);
                this.pushRoute();
                this.listHandler();
            },
            resetPagination() {
                //Reset Pagination
                this.$emit('input', 0);
            },
            pushRoute(query = {}) {
                this.$router.push({name: this.$route.name, query}).catch(() => {});
            },
            canSelectCountry() {
                return true;
            },
            canNotSelectCountry() {
                return !this.canSelectCountry();
            },
            canSelectCity() {
                return this.filters.country_id;
            },
            canNotSelectCity() {
                return !this.canSelectCity();
            },
        }
    };
</script>
