<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm"  v-can:add="permissionPrefix">
            <i class="fa fa-plus"></i>
          </router-link>
        </template>

        <template v-slot:card-header-search>
          <search v-model="lastPage" :listHandler="getList"/>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td>
              <div class="media align-items-center">
                <a v-if="getImageThumbnail(item)" href="javascript:;" class="avatar rounded-circle">
                  <img alt="Image placeholder" :src="getImageThumbnail(item)">
                </a>
                <div class="media-body">
                  <span class="name mb-0 text-sm"></span>
                </div>
              </div>
            </td>
            <td> {{ item.name }} </td>
            <td> {{ item.type }} </td>
            <td>
              <input-checkbox v-model="item.usesWallet" :options="{ id: 'wallet_enabled', onChangeHandler: changeWalletEnabledStatus, onChangeParams: { id: item.id, value: !item.usesWallet }, fullLayout: false }"/>
            </td>
            <td>
              <input-checkbox v-model="item.fullTimeDrivers" :options="{ id: 'full_time_drivers', onChangeHandler: changeFullTimeDriversStatus, onChangeParams: { id: item.id, value: !item.fullTimeDrivers }, fullLayout: false, disabled: item.type === 'CAPTAINS_BRAND_DEDICATED' }"/>
            </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <table-item-actions :actions="tableItemActions" :params="{'id':item.id}"/>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import {  mapGetters } from "vuex";
  import { SUPPLIERS_LIST as CRUD_ACTION_LIST, SUPPLIERS_DELETE as CRUD_ACTION_DELETE, SUPPLIERS_UPDATE as CRUD_ACTION_UPDATE} from 'actions/suppliers';
  import search from "./partials/search";
  const CRUD_CODE="suppliers";
  const CRUD_PERMISSION_CODE=`${CRUD_CODE}`;
  export default {
    name: "SupplierIndex",
    components:{
      search,
    },
    data(){

      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        tableHeaders:[
          {
            'title':`fields.image`
          },
          {
            'title':`fields.name`
          },
          {
            'title':`fields.type`
          },
          {
            'title':'fields.wallet_enabled'
          },
          {
            'title':'fields.full_time_drivers'
          },
          {
            'title':`fields.created_at`
          },
          {
            'title':`fields.actions`
          }
        ],
        tableItemActions:[
          {
            code:'show',
            route_name:`${CRUD_CODE}.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
          },
          {
            code:'details',
            route_name:`${CRUD_CODE}.details.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
            color_class:`text-red`,
            icon:`fas fa-at`,
            title: `${CRUD_CODE}.details.title`,
          },
          {
            code:'users',
            route_name:`${CRUD_CODE}.users.index`,
            color_class:`text-indigo`,
            icon:`fas fa-users`,
            can:`supplier_users_can_view`,
            title: `${CRUD_CODE}.users.title`,
          },
          {
            code:'configs',
            route_name:`${CRUD_CODE}.configs.show`,
            color_class:`text-orange`,
            icon: `fas fa-sliders-h`,
            can:`supplier_configs_can_view`,
            title: `${CRUD_CODE}.configs.title`,
          },
          {
            code:'files',
            route_name:`${CRUD_CODE}.files.show`,
            color_class:`text-gray`,
            icon: `fas fa-folder-open`,
            can: `platform_files_can_view`,
            title: `${CRUD_CODE}.files.title`,
          },
          {
             code:'reports',
             route_name:`${CRUD_CODE}.reports.index`,
             color_class:`text-blue`,
             icon:`fa fa-file-invoice`,
             can:`supplier_reports_can_view`,
            title: `${CRUD_CODE}.reports.title`,
          },
          {
            code:'delete',
            can:`${CRUD_PERMISSION_CODE}_can_delete`,
            action:CRUD_ACTION_DELETE,
            successCallback:this.getList
          },
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    computed:{
      ...mapGetters([
        "getSearchFilters","getSearchFiltersIfExists","getSearchFiltersSensitized"
      ])
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          pageId: this.getCurrentPage()-1,
          ...this.getSearchFiltersSensitized
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        })
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page,
              ...this.getSearchFiltersIfExists
            }
          });
          this.getList();
        }
      },
      changeWalletEnabledStatus(event, params) {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE, {
          id: params.id,
          data: {
            ...this.prepareRequestData(params.value)
          },
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
      changeFullTimeDriversStatus(event, params) {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE, {
          id: params.id,
          data: {
            fullTimeDrivers: params.value
          },
        })
            .then(() => {
              swal({
                icon: 'success',
                text: this.$i18n.t("messages.update_successfully"),
                buttons:false,
                timer: 5000
              });
              this.$router.push({ name: `${this.routePrefix}.index` })
            })
            .catch(error => {
              this.loading = false;
              this.fieldErrors={};
              this.serverError='';

              if(error.response){
                this.responseStatus=error.response.status;
                let responseData=error.response.data;

                if(responseData.errors){
                  this.fieldErrors=responseData.errors;
                }else{
                  this.serverError = 'Invalid Request.'
                }
              }else{
                this.serverError = this.$i18n.t("messages.no_internet_connection");
              }

              if(this.fieldErrors.error){
                this.serverError = this.fieldErrors.error;
              }
            })
      },
      prepareRequestData(val){
        return {
          usesWallet: val,
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
      getImageThumbnail(item){
        let image='';
        if(item.image){
          image=item.image.url;
        }
        return image;
      }
    }
  };
</script>
