<template>
  <div class="col">
    <div class="card card-stats">
      <!-- Card body -->
      <div class="card-body" @click="onCardClick" v-bind:class="{ 'btn text-left': onClickHandler }">
        <div class="row">
          <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0 text-custom-dashboard">{{ title }}</h5>
            <span class="h2 font-weight-bold mb-0">{{ value !== undefined ? value : "N/A" }}</span>
          </div>
          <div class="col-auto">
            <div class="icon icon-shape text-white rounded-circle shadow" v-bind:class="[color]">
              <i class="" v-bind:class="[icon]"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonitoringCard",
  props: {
    title: {},
    value: {},
    icon: {},
    color: {},
    onClickHandler: { type: Function }
  },
  methods: {
    onCardClick() {
      if (this.onClickHandler) {
        this.onClickHandler();
      }
    }
  }
};
</script>
