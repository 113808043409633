<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', error: fieldErrors.name }">
                <template v-slot:hint>
                  <small class="text-danger">* ({{ $t(`labels.required`) }})</small>
                </template>
              </input-text>
              <input-text v-model="phone" :options="{ id: 'phone', label: 'phone', error: fieldErrors.phone }"/>
              <input-number v-model="latitude" :options="{ id: 'latitude', label: 'latitude', error: fieldErrors.latitude }">
                <template v-slot:hint>
                  <small class="text-danger">* ({{ $t(`labels.required`) }})</small>
                  <a href="#" class="mx-1 text-blue" @click.prevent="showPickupMapPicker">
                    <i class="fa fa-map-pin "></i>
                  </a>
                </template>
              </input-number>
              <input-number v-model="longitude" :options="{ id: 'longitude', label: 'longitude', error: fieldErrors.longitude }">
                <template v-slot:hint>
                  <small class="text-danger">* ({{ $t(`labels.required`) }})</small>
                  <a href="#" class="mx-1 text-blue" @click.prevent="showPickupMapPicker">
                    <i class="fa fa-map-pin "></i>
                  </a>
                </template>
              </input-number>
              <input-textarea v-model="description" :options="{ id: 'description', label: 'description', error: fieldErrors.description, containerClass: 'col-12' }"/>
              <div v-if="pickupMapPicker" class="col-12 position-fixed top-9 left-0 p-5"  style="z-index: 99999">
                <button class="btn btn-danger btn-sm" @click.prevent="hidePickupMapPicker">
                  {{ $t(`labels.close`) }}
                  <i class="fa fa-window-close"></i>
                </button>
                <maps-pick-address :position="{lat:latitude,lng:longitude}" v-model="pickupMarkerPosition"/>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index` }"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { BRANCHES_SHOW as CRUD_ACTION_SHOW,BRANCHES_UPDATE as CRUD_ACTION_UPDATE } from 'actions/platforms_portal/branches';
  const PLATFORMS_CRUD_CODE="platforms";
  const BRANCHES_CRUD_CODE="branches";
  const PLATFORMS_BRANCHES_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.${BRANCHES_CRUD_CODE}`;
  const CRUD_CODE=`platforms_portal.${BRANCHES_CRUD_CODE}`;
  const CRUD_TRANS_CODE=`${PLATFORMS_BRANCHES_CRUD_CODE}`;
  export default {
    name: "PlatformsPortalBranchEdit",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_TRANS_CODE,
        platformsRoutePrefix: `${PLATFORMS_CRUD_CODE}`,
        platformsTransPrefix: `${PLATFORMS_CRUD_CODE}`,
        id:this.$router.currentRoute.params.id,
        phone: '',
        name: '',
        longitude: '',
        latitude: '',
        description: '',
        pickupMapPicker:false,
        pickupMarkerPosition:{},
        serverError: '',
        fieldErrors: {},
        responseStatus:'',
        formMode: 'edit',
        loading: false,
      }
    },
    mounted() {
      this.getItem();
    },
    watch:{
      pickupMarkerPosition:function(newVal,oldVal){
        this.latitude=newVal.lat;
        this.longitude=newVal.lng;
      },
    },
    methods: {
      isItemFound(){
        return this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.latitude=response.data.latitude;
          this.name=response.data.name;
          this.longitude=response.data.longitude;
          this.description=response.data.description;
          this.phone=response.data.phone;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      prepareRequestData(){
        return {
          phone: this.sanitizeField('phone'),
          name: this.sanitizeField('name'),
          longitude: this.sanitizeField('longitude'),
          latitude: this.sanitizeField('latitude'),
          description: this.sanitizeField('description'),
        }
      },
      update() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE, {
          id: this.id,
          data: {
            ...this.prepareRequestData()
          },
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
      hidePickupMapPicker(){
        this.pickupMapPicker=false;
      },
      showPickupMapPicker(){
        this.pickupMapPicker=true;
      },
    },
  };
</script>
