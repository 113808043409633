<template>
  <div class="row">
    <div class="col">
      <card-container :title="$router.currentRoute.meta.title">

        <template v-slot:card-header/>

        <template v-slot:card-header-search>
          <search :listHandler="getList" :resetHandler="resetCallback" :fieldErrors="fieldErrors"/>
        </template>

        <template v-slot:items-container>
          <div class="row m-0 px-3">
            <div v-for="item in getItemSortedIndex"  v-bind:class="{'col-xl-12': isMapType(item.type), 'col-xl-4 col-md-6': !isMapType(item.type)}">
              <div class="card chart-item card-stats">
                <!-- Card body -->
                <div class="card-body">
                  <div class="row">
                    <template v-if="isChartType(item.type)">
                      <div class="col-12">
                        <h5 class="card-title text-uppercase text-muted mb-0 text-custom-dashboard">{{ item.title }}</h5>
                      </div>
                      <div class="col-12">
                        <GChart
                                :type="getGoogleChartTypeBy(item.type)"
                                :data="prepareChartData(item.data)"
                                :options="getGoogleChartOptionsByType(item.type,item)"
                        />
                      </div>

                    </template>
                    <template v-else-if="isMapType(item.type)">
                      <div class="col-12" >
                        <h5 class="card-title text-uppercase text-muted mb-2 text-center text-custom-dashboard">{{ item.title }}</h5>
                      </div>
                      <div class="col-12" >
                        <maps-heat  v-if="is_loaded" :height="400" :points="prepareMapData(item.data)" />
                      </div>
                    </template>
                    <template v-else>
                      <div class="col-12">
                        <h5 class="card-title text-uppercase text-muted mb-0 text-custom-dashboard">{{ item.title }}</h5>
                      </div>
                      <div class="col-12 text-center mb-sm-9 mb-md-auto avg-value-container">
                        <span class="h2 font-weight-bold mb-0 text-indigo avg-value" v-for="dataItem in item.data">{{ dataItem.value?dataItem.value:'-' }}</span>
                      </div>
                      <div class="col-12 avg-chart">
                      </div>
                    </template>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:card-footer>
          <export-action :crudCode="routePrefix" v-can:report="permissionPrefix"></export-action>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import { mapGetters } from "vuex";
  import { ANALYSIS_LIST as CRUD_ACTION_LIST} from 'actions/analysis';
  import { GChart } from 'vue-google-charts'
  import search from "./partials/search";
  const CRUD_CODE="analysis";
  const CRUD_PERMISSION_CODE=`${CRUD_CODE}`;
  export default {
    name: "AnalysisIndex",
    components: {
      GChart,
      search,
    },
    data() {
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        is_loaded: false,
        items:[],
        fieldErrors:{},
      }
    },
    computed:{
      getItemSortedIndex(){
        return this.items.sort(function(a, b) {
          return a.index - b.index;
        });
      },
      ...mapGetters([
        "getSearchFilters","getSearchFiltersIfExists","getSearchFiltersSensitized"
      ])
    },
    methods: {
      getList(){
        this.is_loaded = false;
        this.$store.dispatch(CRUD_ACTION_LIST,{
          ...this.getSearchFiltersSensitized
        }).then(response=>{
          this.items=response.data;
          this.is_loaded = true;
          this.fieldErrors={};
        }).catch(()=>{
          this.items=[];
          this.fieldErrors={
            platformId:this.$i18n.t('validation.required'),
          };
        });
      },
      resetCallback(){
        this.fieldErrors={};
        this.items=[];
      },
      prepareChartData(data){
        let preparedData=[];
        preparedData.push(["date","value"]);
        data.forEach(function (arrayItem) {
          preparedData.push([arrayItem.label,arrayItem.value]);
        });
        return preparedData
      },
      prepareMapData(data) {
        let preparedData=[];
        data.forEach(function (arrayItem) {
          preparedData.push(new google.maps.LatLng(arrayItem.label, arrayItem.value));
        });
        return preparedData
      },
      getChartTypes(){
        return ['PIE','LINE'];
      },
      getMapTypes() {
        return ['HEAT_MAP'];
      },
      isChartType(type){
        return this.getChartTypes().includes(type);
      },
      isMapType(type){
        return this.getMapTypes().includes(type);
      },
      getGoogleChartTypeBy(type){
        let googleType="";
        switch (type) {
          case "PIE":
            googleType="PieChart";
            break;
          case "LINE":
            googleType="LineChart";
            break;
        }

        return googleType;
      },
      getGoogleChartOptionsByType(type,item){
        let googleChartOptions={
          height: 300,
          sliceVisibilityThreshold: 0,
        };
        switch (type) {
        case "PIE":
          googleChartOptions = {
            ...googleChartOptions,
            ...{
              pieHole: 0.6,
              'chartArea': {'width': '97%', 'height': '90%'},
              'legend': {'position': 'bottom'},
            },
          };
          break;
        case "LINE":
          googleChartOptions = {
            ...googleChartOptions,
            ...{
              'chartArea': {'width': '85%', 'height': '90%'},
              vAxis: {
                gridlines: {
                  color: 'transparent'
                }
              }
            }
          };
          break;
        }

        return googleChartOptions;
      }
    }
  };
</script>

<style type="text/css" scoped>
  .card.chart-item{
    box-shadow: 0 0 3px 0 rgb(0 0 0);
  }
  @media (min-width: 768px){
    .avg-chart{
      height: 109px;
    }
  }

  .avg-value-container{
    margin-top:25%;
  }
  .avg-value{
    font-size: 60px;
  }

</style>
