<template>
    <div class="row" v-if="isSearchShow">
        <div class="col-md-12 mt-2">
            <div class="accordion" id="search">
                <div class="">
                    <div class="border-primary p-0 card-header" v-bind:class="{'collapsed':filtersIsEmpty}" data-toggle="collapse" data-target="#searchItems" v-bind:aria-expanded="filtersIsEmpty?'false':'true'">
                        <h3 class="text-primary">{{ $t('labels.filters') }}</h3>
                    </div>
                    <div id="searchItems" class="collapse" v-bind:class="{'show':!filtersIsEmpty}">
                        <div class="card-body">
                            <form role="form" @submit.prevent="searchHandler" @reset="resetHandler">
                                <slot name="items"></slot>
                                <div class="row d-flex flex-row-reverse">
                                    <div class="col-12 text-right">
                                        <button type="reset" class="btn-sm btn btn-primary">
                                            <span>{{ $t('labels.reset') }}</span>
                                            <i class="fa fa-sync"></i>
                                        </button>
                                        <button type="submit" class=" btn-sm btn btn-primary">
                                            <span>{{ $t('labels.search') }}</span>
                                            <i class="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    export default {
        name: "CardSearch",
        props: {
            searchHandler:{
                type: Function,
                default: () => { }
            },
            resetHandler:{
                type: Function,
                default: () => { }
            }
        },
        data(){
            return {

            }
        },
        computed: {
            ...mapGetters(["filtersIsEmpty","isSearchShow"]),
        }

    }
</script>

<style scoped>

</style>
