<template>
 <div class="row">
     <div class="col">
     <card-container
             :title="$router.currentRoute.meta.title"
             :headers="tableHeaders"
             :lastPage="lastPage"
             :totalElements="totalElements"
             :currentPage="getCurrentPage()"
             :clickHandler="pagination">

         <template v-slot:card-header>
           <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm" v-can:add="permissionPrefix">
             <i class="fa fa-plus"></i>
           </router-link>
         </template>


         <template v-slot:table-rows>
         <tr v-for="item in items" :key="item.id">
             <td>
                 <a v-if="item.report" :href="item.report.url" class="nav-link" target="_blank"> {{ $t('labels.download') }}</a>
             </td>
             <td> {{ item.status }} </td>
             <td class="text-direction-ltr"> {{ item.fromDate | moment("Y-MM-DD") }} </td>
             <td class="text-direction-ltr"> {{ item.toDate | moment("Y-MM-DD") }} </td>
             <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
         </tr>
         <tr class="text-center" v-if="!items.length">
             <td colspan="999">{{ $t('messages.no_result_found')}}</td>
         </tr>
         </template>

         <template v-slot:card-footer>
         </template>
     </card-container>
     </div>
 </div>
 </template>
 <script>

 import { REPORTS_LIST as CRUD_ACTION_LIST} from 'actions/suppliers_portal/reports';
 const REPORTS_CODE="supplier_reports";
 const CRUD_CODE=`suppliers_portal.${REPORTS_CODE}`;
 const CRUD_PERMISSION_CODE=`supplier_${REPORTS_CODE}`;
 export default {
     name: "SuppliersPortalReportIndex",
     data(){
         return {
             routePrefix: CRUD_CODE,
             permissionPrefix: CRUD_PERMISSION_CODE,
             tableHeaders:[
             {
                 'title':`fields.report`
             },
             {
                 'title':`fields.status`
             },
             {
                 'title':`fields.from_date`
             },
             {
                 'title':`fields.to_date`
             },
             {
                 'title':`fields.created_at`
             }
             ],
             items: [],
             perPage:20,
             totalElements:0,
             lastPage:0,
         }
     },
     mounted() {
         this.getList();
     },
     methods: {
         getList(){
             this.$store.dispatch(CRUD_ACTION_LIST,{
             pageId: this.getCurrentPage()-1,
             ...this.getSearchFiltersSensitized
             }).then(response=>{
             this.items=response.data.data;
             this.perPage=response.data.perPage;
             this.totalElements=response.data.totalElements;
             this.lastPage=response.data.pageCount;
             });
         },
         pagination(page){
             if(page!== this.getCurrentPage()){
             this.$router.push({
                 name: `${this.routePrefix}.index`,
                 query:{
                 page,
                 ...this.getSearchFiltersIfExists
                 }
             });
             this.getList();
             }
         },
         getCurrentPage(){
             let currentPage=this.$route.query.page??1;
             return parseInt(currentPage>=1?currentPage:1);
         },
     }
 };
 </script>
