<template>
    <div class="col-12 col-md-6">
        <select-capabilities v-bind:value="capabilitiesIds" :options="{ id: 'capabilities', label: 'capabilities', placeholder: can(`${permissionPrefix}_capabilities_can_edit`) ? 'placeholders.dropdown' : 'messages.no_item_found', disabled: !can(`${permissionPrefix}_capabilities_can_edit`), onAddHandler: addCapability, onRemoveHandler: removeCapability, multiple: true, containerClass: '' }">
            <template v-slot:hint-bottom>
                <span v-if="loading">
                    <span class="spinner-border spinner-border-sm"></span>
                    {{ $t(`labels.saving`)}}
                </span>
                <span v-if="message" v-bind:class="{'text-success':isSuccessMessage,'text-danger':!isSuccessMessage}">
                    <i class="fa" v-bind:class="{'fa-check-circle':isSuccessMessage,'fa-window-close':!isSuccessMessage}"></i>
                    {{$t(message)}}
                </span>
            </template>
        </select-capabilities>
    </div>
</template>

<script>
    import { CAPTAINS_ADD_CAPABILITIES as CRUD_ACTION_ADD_CAPABILITIES, CAPTAINS_REMOVE_CAPABILITIES as CRUD_ACTION_REMOVE_CAPABILITIES, } from 'actions/captains';
    export default {
        name: "CaptainFieldsCapabilities",
        props: {
            itemID:{},
            capabilities:{
                type:Array,
                default:function(){
                    return [];
                }
            },
            permissionPrefix:{
                type: String,
                default: ""
            },
        },
        data(){
            return {
                loading:false,
                message:"",
                isSuccessMessage:true,
            }
        },
        computed:{
            capabilitiesIds :{
                get: function () {
                    let capabilitiesIds=[];
                    Object.keys(this.capabilities).forEach(key=>{
                        let capability=this.capabilities[key];
                        capabilitiesIds.push("" + capability.id);
                    });
                    return capabilitiesIds;
                },
            }
        },
        methods:{
            addCapability(capability){
                let action=CRUD_ACTION_ADD_CAPABILITIES;
                this.addRemoveCapability(action,capability,this.itemID);
            },
            removeCapability(capability){
                let action=CRUD_ACTION_REMOVE_CAPABILITIES;
                this.addRemoveCapability(action,capability,this.itemID);
            },
            addRemoveCapability(action,capability,id){
                this.loading=true;
                this.message="";
                this.$store.dispatch(action, {
                    id,
                    capability_id:capability.id,
                })
                .then(() => {
                    this.loading=false;
                    this.message="messages.capability_updated_successfully";
                    this.isSuccessMessage=true;
                })
                .catch(error => {
                    this.loading=false;
                    this.isSuccessMessage=false;
                    this.message="";
                    let message="";
                    if(error.response){
                        let responseData=error.response.data;

                        if(responseData.errors && responseData.errors.error){
                            message = responseData.errors.error;
                        }else{
                            message = this.$i18n.t("messages.capability_updated_error");
                        }
                    }
                    if(message){
                        this.message=message;
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>
