import {
    TRACKING_CAPTAINS_LIST,
    TRACKING_CAPTAIN_SHOW
} from "actions/suppliers_portal/tracking";
import axioses from "@/axios/config";

const routePrefix='tracking';

const state = {};

const getters = {};

const actions = {
  [TRACKING_CAPTAINS_LIST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', false, { root: true });

      axioses.supplierPortal
          .get(`/${routePrefix}/captains`)
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', false, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', false, { root: true });
            reject(error);
          });
    });
  },
  [TRACKING_CAPTAIN_SHOW]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          commit('START_LOADING', null, { root: true });
          axioses.supplierPortal
              .get(`/${routePrefix}/captain/${params.id}`)
              .then(response => {
                  resolve(response);
                  commit('STOP_LOADING', null, { root: true });
              })
              .catch(error => {
                  commit('STOP_LOADING', null, { root: true });
                  reject(error);
              });
      });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
