<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <files-image v-model="image" :options="{ id: 'image', label: 'image', error: fieldErrors.supplierImage }"/>
            </div>
            <div class="row">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', error: fieldErrors.name }"/>
              <select-supplier-types v-model="type" :options="{ id: 'supplier_type', label: 'type', error: fieldErrors.type }"/>
              <select-countries v-model="country_id" :options="{ id: 'country_id', label: 'country', error: fieldErrors.countryId }"/>
              <select-platforms v-if="needPlatformID()" v-model="platform_id" :options="{ id: 'platform_id', label: 'platform', error: fieldErrors.ownerId }"/>
              <select-platform-brands v-if="needBrands()" v-model="brandIds" :platform_id="platform_id" :options="{ id: 'brands', label: 'brands', error: fieldErrors.brandIds, multiple: true }"/>
              <input-checkbox v-model="usesWallet" :options="{ id: 'wallet_enabled', transPrefix: `${transPrefix}.fields`, label: 'wallet_enabled', error: fieldErrors.usesWallet, containerClass: 'col-12 col-md-6 mt-5' }"/>
              <input-checkbox v-model="fullTimeDrivers" :options="{ id: 'full_time_drivers', transPrefix: `${transPrefix}.fields`, label: 'full_time_drivers', error: fieldErrors.fullTimeDrivers, containerClass: 'col-12 col-md-6 mt-5', disabled: needBrands() }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { SUPPLIERS_CREATE as CRUD_ACTION_CREATE } from 'actions/suppliers';

  const CRUD_CODE="suppliers";
  export default {
    name: "SupplierCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        name: '',
        type: '',
        usesWallet: false,
        fullTimeDrivers: false,
        country_id: "",
        platform_id: "",
        brandIds: [],
        image:{
          file:'',
          thumbnail:'',
        },
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    computed:{
      owner_id() {
        return this.type !== "CAPTAINS" ? this.platform_id : null;
      }
    },
    watch: {
      type: function(newVal) {
        this.fullTimeDrivers = newVal === 'CAPTAINS_BRAND_DEDICATED';
      }
    },
    methods: {
      prepareRequestData(){
        return {
          type: this.sanitizeField('type'),
          name: this.sanitizeField('name'),
          usesWallet: this.sanitizeField('usesWallet'),
          fullTimeDrivers: this.sanitizeField('fullTimeDrivers'),
          countryId: this.sanitizeField('country_id'),
          ownerId: this.sanitizeField('owner_id'),
          brandIds: this.brandIds,
          ...this.prepareFile('image',"supplierImage"),
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
      needPlatformID(){
        return ["CAPTAINS_PLATFORM_DEDICATED", "CAPTAINS_BRAND_DEDICATED"].includes(this.type);
      },
      needBrands() {
        return ["CAPTAINS_BRAND_DEDICATED"].includes(this.type);
      }
    }
  };
</script>
