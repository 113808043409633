<template>
    <abstract-input-container :options="{...defaultOptions, ...options}">

        <template v-slot:hint>
            <div v-if="!thumbnail && !defaultOptions.disabled" class="custom-theme-file" v-bind:class="{'is-invalid':defaultOptions.error}">
                <label :for="defaultOptions.id" class="btn btn-success btn-sm">
                    {{ $t('labels.upload') }} <i class="fa fa-upload"></i>
                </label>
            </div>
            <div v-else class="custom-theme-file" v-bind:class="{'is-invalid':defaultOptions.error}">
                <img v-if="defaultOptions.downloadButton" :src="getThumbnail()" class="img-thumbnail mh-100 mw-100 w-50 h-50 mb-2 d-block">
                <a v-else :href="thumbnail" target="_blank">
                    <img v-if="thumbnail" :src="getThumbnail()" class="img-thumbnail mh-100 mw-100 w-50 h-50 mb-2 d-block">
                </a>
                <h6 v-if="originalName"> {{ originalName }}</h6>
                <button type="button" v-if="!defaultOptions.disabled" class="btn btn-danger btn-sm" @click.prevent="removeImage">
                    {{ $t('labels.delete_item') }} <i class="fa fa-trash"></i>
                </button>
                <a v-if="defaultOptions.downloadButton" :href="thumbnail" class="btn btn-primary btn-sm" target="_blank" download>
                    {{ $t('labels.download') }} <i class="fa fa-download"></i>
                </a>
            </div>
            <slot name="hint"></slot>
        </template>
    </abstract-input-container>
</template>

<script>
    export default {
        name: "FieldsFilesSingle",
        props: {
            value: {},
            options: {
                type: Object,
                default: () => {}
            }
        },
        data(){
            return {
                originalValue: this.value,
                thumbnail:this.value.thumbnail,
                originalName: this.value.original_name,
                file:'',
                defaultOptions: {
                    id:                                     "input-file",
                    inputClass:                             "form-control d-none",
                    type:                                   "file",
                    downloadButton:                         true,
                    forceRenderFileAsThumbnail:             false,
                    onChangeHandler:                        this.onFileChange,
                    ...this.options,
                }
            }
        },
        watch: {
            originalValue: {
                deep: true,
                handler: function (newVal, oldVal) {
                    this.thumbnail = newVal.thumbnail;
                    this.originalName = newVal.original_name;
                }
            },
            options: {
                deep: true,
                handler: function (newVal, oldVal) {
                    this.defaultOptions = { ...this.defaultOptions, ...newVal};
                }
            },

        },
        methods: {
            onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;

                this.createImage(files[0]);
            },
            createImage(file) {
                let reader = new FileReader();
                let vm = this;

                this.file=file;
                this.originalName = file.name;

                reader.onload = (e) => {
                    vm.thumbnail = e.target.result;
                };

                reader.readAsDataURL(file);

                this.prepareImageObject();
            },
            removeImage(e) {
                this.file='';
                this.thumbnail='';
                this.originalName='';
                this.prepareImageObject();
            },
            prepareImageObject(){
                this.$emit('input',{
                    file:this.file,
                    thumbnail:this.thumbnail,
                    original_name:this.originalName
                });
            },
            getThumbnail(){
                if(this.defaultOptions.forceRenderFileAsThumbnail || this.thumbnail.toLowerCase().search(/image|\.jpg|\.jpeg|\.png|\.gif|\.bmp/i)!==-1){
                    return this.thumbnail;
                }
                return require('@/assets/file_sample.png');
            }
        }
    }
</script>

<style scoped>

</style>
