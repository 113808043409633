<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <input-text v-model="captain.id" :options="{ id: 'captain_id', label: 'captain_id', disabled: true }"/>
              <input-text v-model="captain.name" :options="{ id: 'captain_name', label: 'captain_name', disabled: true }"/>
              <input-text v-model="captain.supplierId" :options="{ id: 'supplier_id', label: 'supplier_id', disabled: true }"/>
              <input-text v-model="captain.supplierName" :options="{ id: 'supplier_name', label: 'supplier_name', disabled: true }"/>
              <input-text v-if="city" v-model="city.countryId" :options="{ id: 'country_id', label: 'country_id', disabled: true }"/>
              <input-text v-if="city" :value="$i18n.locale === 'ar' ? city.nameAr : city.name" :options="{ id: 'city', label: 'city', disabled: true }"/>
              <input-text :value="km_total_distance  | twoDigitsFraction" :options="{ id: 'km_total_distance', label: 'km_total_distance', disabled: true }"/>
              <input-text v-model="delivered_orders_count" :options="{ id: 'delivered_orders_count', label: 'delivered_orders_count', disabled: true }"/>
              <input-text v-model="total_working_minutes" :options="{ id: 'total_working_minutes', label: 'total_working_minutes', disabled: true }"/>
              <input-text v-model="total_working_hours" :options="{ id: 'total_working_hours', label: 'total_working_hours', disabled: true }"/>
              <input-time v-model="started_at" :options="{ id: 'started_at', label: 'started_at', disabled: true }"/>
              <input-time v-model="finished_at" :options="{ id: 'finished_at', label: 'finished_at', disabled: true }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { CAPTAIN_WORKING_SUMMARIES_SHOW as CRUD_ACTION_SHOW } from 'actions/captain_working_summaries';
  const CRUD_CODE="captain_working_summaries";
  const CRUD_PERMISSION_CODE=`captain_working_summary`;
  export default {
    name: "CaptainWorkingSummaryShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        id:this.$router.currentRoute.params.id,
        city:{},
        captain:{},
        km_total_distance:"",
        delivered_orders_count:"",
        total_working_minutes:"",
        total_working_hours:"",
        started_at:"",
        finished_at:"",
        serverError: '',
        refereeCode: '',
        responseStatus:'',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus && this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.started_at=response.data.startedAt?this.$moment(response.data.startedAt).format("HH:mm"):"";
          this.finished_at=response.data.finishedAt?this.$moment(response.data.finishedAt).format("HH:mm"):"";
          this.km_total_distance=response.data.kmTotalDistance;
          this.delivered_orders_count=response.data.deliveredOrdersCount;
          this.total_working_minutes=response.data.totalWorkingMinutes;
          this.total_working_hours=response.data.totalWorkingHours;
          this.city=response.data.city;
          this.captain=response.data.captain?response.data.captain:{};
          this.responseStatus=response.status;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    },
  };
</script>
