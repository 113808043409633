<template>
  <div aria-labelledby="customers-tab" class="tab-pane fade" id="customers" role="tabpanel">
    <div class="row" v-for="item in items" :key="item.id">
      <div class="col-12 font-weight-bold"><span>{{ $t(`${transPrefix}.lists.customer_type.${item.customerType}`) }}</span></div>
      <input-text v-model="item.backendId" :options="{ id: 'backend_id', transPrefix: `${transPrefix}.fields`, label: 'customer_id', disabled: true }"/>
      <input-text v-model="item.name" :options="{ id: 'name', label: 'name', disabled: true }"/>
    </div>
    <div class="row">
      <input-textarea v-model="notes" :options="{ id: 'notes', transPrefix: `${transPrefix}.fields`, label: 'notes', disabled: true, containerClass: 'col-12' }"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: "OrderTabsCustomers",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      items: {
        type: Array,
        default: () => []
      },
      notes: {
        type: String,
        default: ''
      }
    },
  };
</script>
