<template>
    <abstract-dropdown
            v-model="selectedValue"
            :items="items"
            :options="defaultOptions"
    />
</template>

<script>
    export default {
        name: "AbstractsDropdownApi",
        props: {
            value: {},
            noApiItems: {
              type: Array,
              default: () => []
            },
            options: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                currentPage: -1,
                items: this.noApiItems,
                perPage: 20,
                totalElements: 0,
                lastPage: 1,
                selectedValue: this.value,
                defaultOptions: {
                    id:                             "dropdown-api",
                    isLoading:                      false,
                    paginationHandler:              this.pagination,
                    customLabelHandler:             function(value){ return value.name; },
                    prepareResponseDataHandler:     function(data){ return data; },
                    params:                         {},
                    ...this.options
                }
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            },
            options: {
                deep: true,
                handler: function (newVal, oldVal) {
                    this.defaultOptions = { ...this.defaultOptions, ...newVal};
                }
            }
        },
        mounted() {
            if (!this.isEmpty(this.value)) {
                this.pagination(true);
            }
        },
        methods: {
            getList() {
                this.defaultOptions.isLoading = true;
                this.$store.dispatch(this.defaultOptions.action, {
                    pageId: this.getCurrentPage(),
                    withLoading: false,
                    ...this.defaultOptions.params
                }).then(response => {
                    if (response.data.perPage) {
                        this.items = [...this.items, ...response.data.data];
                        this.perPage = response.data.perPage;
                        this.totalElements = response.data.totalElements;
                        this.lastPage = response.data.pageCount - 1;
                    } else {
                        this.items = [...this.items, ...this.defaultOptions.prepareResponseDataHandler(response.data)];
                        this.lastPage = this.lastPage - 1;
                    }
                    this.defaultOptions.isLoading = false;
                });
            },
            pagination(isVisible) {
                if (this.hasNextPage() && isVisible) {
                    this.currentPage += 1;
                    this.getList();
                }
            },
            getCurrentPage() {
                return this.currentPage;
            },
            getLastPage() {
                return this.lastPage;
            },
            hasNextPage() {
                return this.getCurrentPage() < this.getLastPage();
            },
            isEmpty(value) {
                return ((!Array.isArray(value) && !value) || (Array.isArray(value) && value.length === 0));
            },
        }
    }
</script>

<style scoped>

</style>
