<template>
    <div  aria-hidden="true" aria-labelledby="modal-reject-registration_requests" class="modal fade" id="modal-reject-registration_requests"  role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">{{ $t('messages.reject_item')}}</h6>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form role="form" @submit.prevent="reject">
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div v-if="serverError" class="alert alert-danger">
                    <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                  </div>
                </div>
              </div>
              <div class="row">
                <input-textarea v-model="reason" :options="{ id: 'reason', label: 'reason', error: fieldErrors.reason, containerClass: 'col-12 col-md-12' }"/>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-danger btn-sm ml-auto" data-dismiss="modal" type="button">
                {{ $t('labels.close') }}
              </button>
              <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                {{ $t('labels.submit') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>

<script>
    import { CAPTAINS_REGISTRATION_REQUESTS_UPDATE_STATUS as CRUD_ACTION_UPDATE_STATUS } from "actions/captains_registration_requests";
    export default {
        name: "CaptainRegistrationRequestActionsModalsReject",
        props: {
            itemID:{},
            successCallback: {
                type: Function,
                default: () => { }
            },
        },
        data(){
            return {
                serverError: '',
                fieldErrors: {},
                loading: false,
                reason:"",
            }
        },
        watch:{
          itemID:function(newVal, oldVal) {
            this.serverError=null;
            this.reason="";
          },
        },
        computed:{
          activeId:function () {
            return  this.itemID
          }
        },
        methods:{
            reject(){
                this.loading = true
                this.$store.dispatch(CRUD_ACTION_UPDATE_STATUS, {
                    id:this.activeId,
                    type:'reject',
                    data: {
                        ...this.prepareRequestData()
                    },
                })
                .then(() => {
                    this.$emit('input',false);
                    document.querySelector('#modal-reject-registration_requests [data-dismiss]').click();
                    this.loading = false;
                    this.reason="";
                    this.fieldErrors={};
                    this.serverError='';
                    this.successCallback();
                    swal({
                        icon: 'success',
                        text: this.$i18n.t("messages.reject_successfully"),
                        buttons:false,
                        timer: 5000
                    });
                })
                .catch(error => {
                    this.loading = false
                    this.fieldErrors={};
                    this.serverError='';

                    if(error.response){
                        let responseData=error.response.data;

                        if(responseData.errors){
                            this.fieldErrors=responseData.errors;
                        }else{
                            this.serverError = 'Invalid Request.'
                        }
                    }else{
                        this.serverError = this.$i18n.t("messages.no_internet_connection");
                    }

                    if(this.fieldErrors.error){
                        this.serverError = this.fieldErrors.error;
                    }
                })
            },
            prepareRequestData(){
                return {
                    reason: this.sanitizeField('reason'),
                }
            },

        }
    }
</script>

<style scoped>

</style>
