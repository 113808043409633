<template>
  <div aria-hidden="true" aria-labelledby="modal-rejection-reasons" class="modal fade" id="modal-rejection-reasons" role="dialog" tabindex="-1" >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" >
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="modal-title-default">
            {{ $t("labels.rejection_reasons") }}
          </h6>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button" >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <ul class="list">
              <li v-for="(reason,index) in rejectionReasons" :key="index">
                {{reason.reason}}  <p style="color: #1496ba; font-size: 12px;">    - {{reason.rejectedBy}}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary ml-auto" data-dismiss="modal" type="button" >
            {{ $t("labels.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "CaptainRegistrationRequestActionsModalsRejectReasons",
        props: {
            rejectionReasons:{},
        },
    }
</script>

<style scoped>

</style>