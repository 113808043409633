<template>
   <div class="row">
     <div class="col">
       <card-container
               :title="$router.currentRoute.meta.title"
               :headers="tableHeaders"
               :lastPage="lastPage"
               :totalElements="totalElements"
               :currentPage="getCurrentPage()"
               :clickHandler="pagination">

         <template v-slot:card-header>
           <router-link :to="{ name: `${suppliersRoutePrefix}.index`}"  class="btn btn-primary btn-sm">
             <i class="fa fa-arrow-left"></i>
           </router-link>
           <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm" v-can:add="permissionPrefix">
             <i class="fa fa-plus"></i>
           </router-link>
         </template>

         <template v-slot:table-rows>
           <tr v-for="(item,index) in items" :key="item.id">
             <td>
               <a v-if="item.report" :href="item.report.url" class="nav-link" target="_blank"> {{ $t('labels.download') }}</a>
             </td>
             <td> {{ item.status }} </td>
             <td class="text-direction-ltr"> {{ item.fromDate | moment("Y-MM-DD") }} </td>
             <td class="text-direction-ltr"> {{ item.toDate | moment("Y-MM-DD") }} </td>
             <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }} </td>
             <table-item-actions :actions="tableItemActions" :params="{id,'report_id':item.id}">
               <template v-slot:additional-items>
                 <change-status-action v-model="activeParams" :itemID="item.id" :index="index" :additionalParams="{activeStatus:item.status}" :successCallback="getList" v-can:edit="permissionPrefix"/>
               </template>
             </table-item-actions>
           </tr>
           <tr class="text-center" v-if="!items.length">
             <td colspan="999">{{ $t('messages.no_result_found')}}</td>
           </tr>
         </template>
       </card-container>
       <change-status-action-modal :activeParams="activeParams" :additionalParams="{supplierId:id}"  :successCallback="getList" v-can:edit="permissionPrefix"/>
     </div>
   </div>
 </template>
 <script>

   import { SUPPLIERS_REPORTS_LIST as CRUD_ACTION_LIST} from 'actions/suppliers';
   import changeStatusAction from "./actions/change_status";
   import changeStatusActionModal from "./actions/modals/change_status";
   const SUPPLIERS_CRUD_CODE="suppliers";
   const CRUD_CODE=`${SUPPLIERS_CRUD_CODE}.reports`;
   const CRUD_TRANS_CODE=`${CRUD_CODE}`;
   const CRUD_PERMISSION_CODE=`supplier_reports`;
   export default {
     name: "SupplierReportIndex",
     components:{
       changeStatusAction,
       changeStatusActionModal,
     },
     data(){
       return {
         routePrefix: `${CRUD_CODE}`,
         transPrefix: `${CRUD_TRANS_CODE}`,
         permissionPrefix: CRUD_PERMISSION_CODE,
         suppliersRoutePrefix: SUPPLIERS_CRUD_CODE,
         suppliersTransPrefix: SUPPLIERS_CRUD_CODE,
         id:this.$router.currentRoute.params.id,
         tableHeaders:[
           {
             'title':`fields.report`
           },
           {
             'title':`fields.status`
           },
           {
             'title':`fields.from_date`
           },
           {
             'title':`fields.to_date`
           },
           {
             'title':`fields.created_at`
           },
           {
             'title':`fields.actions`
           }
         ],
         tableItemActions:[],
         items: [],
         perPage:20,
         totalElements:0,
         lastPage:0,
         activeParams:{},
       }
     },
     mounted() {
       this.getList();
     },
     methods: {
       getList(){
         this.$store.dispatch(CRUD_ACTION_LIST,{
           supplierId:this.id,
           pageId: this.getCurrentPage()-1,
         }).then(response=>{
           this.items=response.data.data;
           this.perPage=response.data.perPage;
           this.totalElements=response.data.totalElements;
           this.lastPage=response.data.pageCount;
         });
       },
       pagination(page){
         if(page!== this.getCurrentPage()){
           this.$router.push({
             name: `${this.routePrefix}.index`,
             query:{
               page
             }
           });
           this.getList();
         }
       },
       getCurrentPage(){
         let currentPage=this.$route.query.page??1;
         return parseInt(currentPage>=1?currentPage:1);
       },
     }
   };
 </script>
