<template>
  <abstract-dropdown-container v-model="selectedValue" :options="{...defaultOptions, ...options}">
    <template v-slot:hint-top>
      <slot name="hint-top"></slot>
    </template>
    <template v-slot:hint-bottom>
      <slot name="hint-bottom"></slot>
    </template>
  </abstract-dropdown-container>
</template>

<script>
import {ENVELOPES_SENDER_ID_LIST} from "actions/envelopes";

export default {
  name: "FieldsDropdownEnvelopeSenderIds",
  props: {
    value: {},
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedValue: this.value,
      defaultOptions: {
        id: "envelope-sender-id",
        prepareResponseDataHandler: this.prepareResponseData,
        customLabelHandler: this.customLabel,
        action: ENVELOPES_SENDER_ID_LIST
      }
    };
  },
  watch: {
    value: function (newVal) {
      this.selectedValue = newVal;
    },
    selectedValue: function (newVal) {
      this.$emit('input', newVal);
    }
  },
  methods: {
    customLabel(value) {
      return value.name;
    },
    prepareResponseData(data) {
      let preparedData = [];
      data.forEach(function (value) {
        preparedData.push({'id': value, 'name': `${value}`});
      });
      return preparedData;
    }
  }
}
</script>

<style scoped>

</style>