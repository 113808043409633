import Index from "@/views/analysis/index";
import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='analysis';
const PERMISSION_PREFIX='analysis';
const routes = [
  {
    path: `${CODE}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
