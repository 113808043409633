<template>
  <a href="javascript:;" @click.prevent="showReAssignPopUp(itemID)" class="btn btn-warning btn-sm">
        <span class="d-none d-lg-inline mr-2">
          {{ $t(`labels.manually_reassign`) }}
        </span>
    <span>
          <i class="fas fa-arrows-alt-h"></i>
        </span>
  </a>
</template>

<script>
import {
  ORDERS_REASSIGN as CRUD_ACTION_REASSIGN
} from "actions/orders";

export default {
  name: "OrderActionsReAssign",
  props: {
    itemID: {}
  },
  data() {
    return {}
  },
  methods: {
    showReAssignPopUp(id) {
      swal({
        title: this.$i18n.t("labels.manually_reassign_order"),
        text: this.$i18n.t("messages.reassign_item"),
        type: "input",
        showCancelButton: true,
        closeOnConfirm: false,
        content: "input",
        buttons: [
          this.$i18n.t("labels.cancel"),
          this.$i18n.t("labels.manually_reassign"),
        ],
        inputPlaceholder: "Write something"
      }).then((value) => {
        const captainId = parseInt(value, 0);
        if (value !== null && Object.keys(value).length !== 0 && captainId) {
          this.reAssignOrder(id, captainId);
        }
      });
    },
    reAssignOrder(id, captain_id) {
      this.$store.dispatch(CRUD_ACTION_REASSIGN, {
        id,
        captain_id
      })
          .then(() => {
            this.$emit('input', true);
            swal({
              icon: "success",
              text: this.$i18n.t("messages.reassign_success"),
              buttons: false,
              timer: 5000,
            });
          })
          .catch((error) => {
            let message = "";
            if (error.response) {
              let responseData = error.response.data;

              if (responseData.errors && responseData.errors.error) {
                message = responseData.errors.error;
              } else {
                message = this.$i18n.t("messages.reassign_error");
              }
            }
            if (message) {
              swal({
                icon: "error",
                text: message,
                buttons: false,
                timer: 5000,
              });
            }
          });
    },
  }
}
</script>

<style scoped>

</style>