<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <select-captain-bonus-types v-model="type" :options="{ id: 'captain_bonus_type', label: 'type', error: fieldErrors.type }"/>
              <select-captain-bonus-owner-types v-model="owner_type" :options="{ id: 'captain_bonus_owner_type', label: 'owner_type', error: fieldErrors.ownerType }"/>
              <select-countries v-if="isOwnerTypeCountry() || isOwnerTypeCity() ||  isOwnerTypeZone()" v-model="country_id" :options="{ id: 'country_id', label: 'country', error: isOwnerTypeCountry() && !country_id ? fieldErrors.ownerId : '', disabled: isCountryDisabled() }"/>
              <select-cities v-if="isOwnerTypeCity() || isOwnerTypeZone()" v-model="city_id" :country_id="country_id" :options="{ id: 'city_id', label: 'city', error: isOwnerTypeCity() && !city_id ? fieldErrors.ownerId : '', disabled: isCityDisabled() }"/>
              <select-zones v-if="isOwnerTypeZone()" v-model="zone_id" :city_id="city_id" :options="{ id: 'zone_id', label: 'zone', error: isOwnerTypeZone() && !zone_id ? fieldErrors.ownerId : '', disabled: isZoneDisabled() }"/>
              <input-text v-model="name" :options="{ id: 'name', label: 'name', error: fieldErrors.name }"/>
              <input-textarea v-model="description" :options="{ id: 'description', label: 'description', error: fieldErrors.description }"/>
              <input-number v-model="revenue" :options="{ id: 'revenue', label: 'revenue', error: fieldErrors.revenue }"/>
              <input-datetime v-model="from_date" :options="{ id: 'from_date', label: 'from_date', error: fieldErrors.fromDate }"/>
              <input-datetime v-model="to_date" :options="{ id: 'to_date', label: 'to_date', error: fieldErrors.toDate }"/>
              <input-number v-model="whenDeliveredOrdersReaches" :options="{ id: 'whenDeliveredOrdersReaches', step: 1, transPrefix: `${transPrefix}.fields`, label: 'when_delivered_orders_reaches', error: fieldErrors['deliveryBonusData.whenDeliveredOrdersReaches'] }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { CAPTAIN_BONUSES_CREATE as CRUD_ACTION_CREATE } from 'actions/captain_bonuses';

  const CRUD_CODE="captain_bonuses";
  export default {
    name: "CaptainBonusCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        type: '',
        owner_type: '',
        revenue: '',
        country_id: "",
        city_id: '',
        zone_id: "",
        name: "",
        description: "",
        whenDeliveredOrdersReaches:"",
        from_date: '',
        to_date: '',
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    computed:{
      owner_id(){
        let owner_id;
        switch (this.owner_type) {
          case "COUNTRY":
            owner_id=this.country_id;
            break;
          case "CITY":
            owner_id=this.city_id;
            break;
          case "ZONE":
            owner_id=this.zone_id;
            break;
        }
        return owner_id;
      }
    },
    methods: {
      prepareDeliveryBonusRequestData() {
        let data={};
        if (["DELIVERY"].includes(this.type)) {
          data = {
            "deliveryBonusData": {
              "whenDeliveredOrdersReaches": this.sanitizeField('whenDeliveredOrdersReaches'),
            }
          };
        }
        return data;
      },
      prepareRequestData(){
        return {
          type: this.sanitizeField('type'),
          ownerType: this.sanitizeField('owner_type'),
          ownerId: this.sanitizeField('owner_id'),
          name: this.sanitizeField('name'),
          description: this.sanitizeField('description'),
          revenue: this.sanitizeField('revenue'),
          toDate: this.prepareDateTime('to_date'),
          fromDate: this.prepareDateTime('from_date'),
          ...this.prepareDeliveryBonusRequestData(),
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
      isOwnerTypeCountry(){
        return ["COUNTRY"].includes(this.owner_type);
      },
      isCountryEnabled(){
        return ["COUNTRY","CITY","ZONE"].includes(this.owner_type);
      },
      isCountryDisabled(){
        return !this.isCountryEnabled();
      },
      isOwnerTypeCity(){
        return ["CITY"].includes(this.owner_type);
      },
      isCityEnabled(){
        return ["CITY","ZONE"].includes(this.owner_type) && this.country_id;
      },
      isCityDisabled(){

        return !this.isCityEnabled();
      },
      isOwnerTypeZone(){
        return ["ZONE"].includes(this.owner_type);
      },
      isZoneEnabled(){
        return ["ZONE"].includes(this.owner_type) && this.city_id;
      },
      isZoneDisabled(){
        return !this.isZoneEnabled();
      }
    }
  };
</script>
