<template>
  <div>
    <div class="row">
      <div class="col-3">
        <div class="row">
          <div class="col-12">
            <input-checkbox v-model="isTreemapView" :options="{ id: 'isTreemapView', label: 'modes.treemap', transPrefix: `${transPrefix}`, containerClass: 'col-12 bg-white rounded pt-2' }"/>
          </div>
        </div>
      </div>
    </div>

    <TreemapMonitoringView v-if="isTreemapView" />
    <ClassicMonitoringView v-if="!isTreemapView" />
  </div>
</template>

<script>
import ClassicMonitoringView from "./views/classic_monitoring_view";
import TreemapMonitoringView from "./views/treemap_monitoring_view";

const CRUD_CODE = "monitoring";
export default {
  name: "MonitoringIndex",
  components: {
    ClassicMonitoringView,
    TreemapMonitoringView
  },
  data() {
    return {
      isTreemapView: false,
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_CODE,
    };
  }
};
</script>

<style scoped>

</style>
