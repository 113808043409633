export const SUPPLIERS_LIST = "SUPPLIERS_LIST";
export const SUPPLIERS_CREATE = "SUPPLIERS_CREATE";
export const SUPPLIERS_UPDATE = "SUPPLIERS_UPDATE";
export const SUPPLIERS_DELETE = "SUPPLIERS_DELETE";
export const SUPPLIERS_SHOW = "SUPPLIERS_SHOW";
export const SUPPLIERS_TYPE_LIST = "SUPPLIERS_TYPE_LIST";
export const SUPPLIERS_USERS_LIST = "SUPPLIERS_USERS_LIST";
export const SUPPLIERS_USERS_CREATE = "SUPPLIERS_USERS_CREATE";
export const SUPPLIERS_USERS_SHOW = "SUPPLIERS_USERS_SHOW";
export const SUPPLIERS_USERS_DELETE = "SUPPLIERS_USERS_DELETE";
export const SUPPLIERS_OF_CAPTAIN = "SUPPLIERS_OF_CAPTAIN";
export const SUPPLIERS_ATTACH_CAPTAIN = "SUPPLIERS_ATTACH_CAPTAIN";
export const SUPPLIERS_DE_ATTACH_CAPTAIN = "SUPPLIERS_DE_ATTACH_CAPTAIN";
export const SUPPLIERS_DETAILS_SHOW = "SUPPLIERS_DETAILS_SHOW";
export const SUPPLIERS_DETAILS_UPDATE = "SUPPLIERS_DETAILS_UPDATE";
export const SUPPLIERS_CONFIGS_SHOW = "SUPPLIERS_CONFIGS_SHOW";
export const SUPPLIERS_CONFIGS_UPDATE = "SUPPLIERS_CONFIGS_UPDATE";
export const SUPPLIERS_FILES_SHOW = "SUPPLIERS_FILES_SHOW";
export const SUPPLIERS_FILES_UPDATE = "SUPPLIERS_FILES_UPDATE";
export const SUPPLIERS_REPORTS_LIST = "SUPPLIERS_REPORTS_LIST";
export const SUPPLIERS_REPORTS_CREATE = "SUPPLIERS_REPORTS_CREATE";
export const SUPPLIERS_REPORTS_SHOW = "SUPPLIERS_REPORTS_SHOW";
export const SUPPLIERS_REPORTS_STATUS_LIST = "SUPPLIERS_REPORTS_STATUS_LIST";
export const SUPPLIERS_REPORTS_UPDATE_STATUS= "SUPPLIERS_REPORTS_UPDATE_STATUS";
