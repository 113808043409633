export const PLATFORMS_LIST = "PLATFORMS_LIST";
export const PLATFORMS_CREATE = "PLATFORMS_CREATE";
export const PLATFORMS_UPDATE = "PLATFORMS_UPDATE";
export const PLATFORMS_DELETE = "PLATFORMS_DELETE";
export const PLATFORMS_SHOW = "PLATFORMS_SHOW";
export const PLATFORMS_USERS_LIST = "PLATFORMS_USERS_LIST";
export const PLATFORMS_USERS_CREATE = "PLATFORMS_USERS_CREATE";
export const PLATFORMS_USERS_SHOW = "PLATFORMS_USERS_SHOW";
export const PLATFORMS_APPS_LIST = "PLATFORMS_APPS_LIST";
export const PLATFORMS_APPS_CREATE = "PLATFORMS_APPS_CREATE";
export const PLATFORMS_APPS_UPDATE = "PLATFORMS_APPS_UPDATE";
export const PLATFORMS_APPS_DELETE = "PLATFORMS_APPS_DELETE";
export const PLATFORMS_APPS_SHOW = "PLATFORMS_APPS_SHOW";
export const PLATFORMS_DEVICE_TYPE_LIST = "PLATFORMS_DEVICE_TYPE_LIST";
export const PLATFORMS_CONFIGS_SHOW = "PLATFORMS_CONFIGS_SHOW";
export const PLATFORMS_CONFIGS_UPDATE = "PLATFORMS_CONFIGS_UPDATE";
export const PLATFORMS_FILES_SHOW = "PLATFORMS_FILES_SHOW";
export const PLATFORMS_FILES_UPDATE = "PLATFORMS_FILES_UPDATE";
export const PLATFORMS_INVOICES_LIST = "PLATFORMS_INVOICES_LIST";
export const PLATFORMS_INVOICES_CREATE = "PLATFORMS_INVOICES_CREATE";
export const PLATFORMS_INVOICES_SHOW = "PLATFORMS_INVOICES_SHOW";
export const PLATFORMS_INVOICES_STATUS_LIST = "PLATFORMS_INVOICES_STATUS_LIST";
export const PLATFORMS_INVOICES_UPDATE_STATUS= "PLATFORMS_INVOICES_UPDATE_STATUS";
export const PLATFORMS_DETAILS_SHOW = "PLATFORMS_DETAILS_SHOW";
export const PLATFORMS_DETAILS_UPDATE = "PLATFORMS_DETAILS_UPDATE";
export const PLATFORMS_SETTINGS_SHOW = "PLATFORMS_SETTINGS_SHOW";
export const PLATFORMS_SETTINGS_UPDATE = "PLATFORMS_SETTINGS_UPDATE";
export const PLATFORMS_BRANCHES_LIST = "PLATFORMS_BRANCHES_LIST";
export const PLATFORMS_BRANCHES_SHOW = "PLATFORMS_BRANCHES_SHOW";
export const PLATFORMS_BRANDS_LIST = "PLATFORMS_BRANDS_LIST";
