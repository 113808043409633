<template>
  <div aria-labelledby="captain-tab" class="tab-pane fade" id="captain" role="tabpanel" v-if="fields.id">
    <div class="row">
      <input-text v-model="fields.id" :options="{ id: 'id', label: 'id', disabled: true }"/>
      <input-text v-model="fields.name" :options="{ id: 'name', label: 'name', disabled: true }"/>
      <input-text v-model="fields.mobileNumber" :options="{ id: 'mobile_number', label: 'mobile_number', disabled: true }"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: "OrderTabsCaptain",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      fields: {
        type: Object,
        default: () => { }
      },
    },
  };
</script>
