<template>
    <files-single v-model="selectedValue" :options="{...defaultOptions, ...options}"/>
</template>

<script>
    export default {
        name: "FieldsFilesImage",
        props: {
            value: {},
            options: {
                type: Object,
                default: () => {}
            }
        },
        data(){
            return {
                selectedValue: this.value,
                defaultOptions: {
                    id:                                     "input-file-image",
                    accept:                                 "image/*",
                    downloadButton:                         false,
                    forceRenderFileAsThumbnail:             true,
                    ...this.options,
                }
            }
        },
        watch: {
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            },
            options: {
                deep: true,
                handler: function (newVal, oldVal) {
                    this.defaultOptions = { ...this.defaultOptions, ...newVal};
                }
            }
        },
    }
</script>

<style scoped>

</style>
