<template>
    <div id="sidenav-counters-tracking" class="counters-container opened">
      <div class="row align-items-center">
        <div class="col-auto px-1">
          <div class="alert bg-light text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.total`)">
            <i class="fas fa-users mx-1"/>
            {{ totalCaptains }}
          </div>
        </div>
        <div class="col-auto px-1">
          <div class="alert bg-light text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.ready_to_work`)">
            <i class="fas fa-globe mx-1"/>
            {{ totalReadyToWorkCaptains }}
          </div>
        </div>
        <div class="col-auto px-1">
          <div class="alert bg-light text-dark p-2 border border-3 border-danger" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.not_ready_to_work`)">
            <i class="far fa-times-circle mx-1"/>
            {{ totalNotReadyToWorkCaptains }}
          </div>
        </div>
        <div class="col-auto px-1">
          <div class="alert bg-light text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.connected`)">
            <i class="fas fa-wifi mx-1"/>
            {{ totalConnectedCaptains }}
          </div>
        </div>
        <div class="col-auto px-1">
          <div class="alert bg-red text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.not_connected`)">
            <i class="fas fa-ban mx-1"/>
            {{ totalNotConnectedCaptains }}
          </div>
        </div>
        <div class="col-auto px-1">
          <div class="alert bg-light text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.location_unknown`)">
            <i class="fas fa-question-circle mx-1"/>
            {{ totalLocationUnknownCaptains }}
          </div>
        </div>
        <div class="col-auto px-1">
          <div class="alert bg-green text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.free`)">
            <i class="fas fa-taxi mx-1" />
            {{ totalFreeCaptains }}
          </div>
        </div>
        <div class="col-auto px-1">
          <div class="alert bg-blue text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.idle`)">
            <i class="fas fa-clock mx-1"/>
            {{ totalIdleCaptains }}
          </div>
        </div>
        <div class="col-auto px-1">
          <div class="alert bg-yellow text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.busy_captains_with_one_order`)">
            <i class="fas fa-truck mx-1"/>
            {{ totalBusyCaptainsWithOneOrder }}
          </div>
        </div>
        <div class="col-auto px-1">
          <div class="alert bg-orange text-dark p-2" v-b-tooltip.hover="{ animation: false }" :title="$t(`${transPrefix}.lists.counter.busy_captains_with_more_than_one_order`)">
            <i class="fas fa-truck-moving mx-1"/>
            {{ totalBusyCaptainsWithMoreThanOneOrder }}
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import $ from "jquery";
  const CRUD_CODE="tracking";
  export default {
    name: "TrackingCounter",
    data() {
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        isOpened: true
      }
    },
    mounted() {
      this.isOpened = this.getMapConfig.countersEnabled;
      if (!this.getMapConfig.countersEnabled) {
        $('#sidenav-counters-tracking').removeClass('opened').addClass(['closed', 'd-none']);
      }
    },
    watch: {
      getMapConfig: function(newVal) {
        if (newVal.countersEnabled !== this.isOpened) {
          newVal.countersEnabled ? this.openSideMenu() : this.hideSideMenu();
          this.isOpened = newVal.countersEnabled;
        }
      }
    },
    computed: {
      ...mapGetters(["getCaptains", "getMapConfig"]),
      totalCaptains() {
        return Object.values(this.getCaptains).length;
      },
      totalReadyToWorkCaptains() {
        return Object.values(this.getCaptains).filter(captain => captain.readyToWork).length;
      },
      totalNotReadyToWorkCaptains() {
        return Object.values(this.getCaptains).filter(captain => !captain.readyToWork).length;
      },
      totalConnectedCaptains() {
        return Object.values(this.getCaptains).filter(captain => captain.connected).length;
      },
      totalNotConnectedCaptains() {
        return Object.values(this.getCaptains).filter(captain => !captain.connected).length;
      },
      totalLocationUnknownCaptains() {
        return Object.values(this.getCaptains).filter(captain => !(captain.latitude && captain.longitude)).length;
      },
      totalFreeCaptains() {
        return Object.values(this.getCaptains)
            .filter(captain => captain.connected && captain.orders.length === 0 && !this.isCaptainIdle(captain.lastDeliveryTime))
            .length;
      },
      totalIdleCaptains() {
        return Object.values(this.getCaptains)
            .filter(captain => captain.connected && captain.orders.length === 0 && this.isCaptainIdle(captain.lastDeliveryTime))
            .length;
      },
      totalBusyCaptainsWithOneOrder() {
        return Object.values(this.getCaptains)
            .filter(captain => captain.orders.length === 1)
            .length;
      },
      totalBusyCaptainsWithMoreThanOneOrder() {
        return Object.values(this.getCaptains)
            .filter(captain => captain.orders.length > 1)
            .length;
      }
    },
    methods: {
      openSideMenu() {
        $('#sidenav-counters-tracking').removeClass(['d-none', 'closed']).addClass('opened');
      },
      hideSideMenu() {
        $('#sidenav-counters-tracking').removeClass('opened').addClass('closed');
      },
      isCaptainIdle(lastDeliveryTime) {
        return this.$moment.duration(this.$moment(new Date()).diff(this.$moment(lastDeliveryTime))).asMinutes() > 30;
      }
    }
  };
</script>

<style scoped>
  .border-3 {
    border-width: 3px !important;
  }
</style>
