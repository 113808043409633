<template>
   <div class="row">
     <div class="col">
       <div class="card">
         <!-- Card header -->
         <card-header :title="$router.currentRoute.meta.title" />
         <!-- Card body -->
         <form role="form" @submit.prevent="create">
           <div class="card-body">
             <div class="row">
               <div class="col-6">
                 <div v-if="serverError" class="alert alert-danger">
                   <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                 </div>
               </div>
             </div>
             <div class="row">
               <input-date v-model="from_date" :options="{ id: 'from_date', label: 'from_date', error: fieldErrors.fromDate }"/>
               <input-date v-model="to_date" :options="{ id: 'to_date', label: 'to_date', error: fieldErrors.toDate }"/>
             </div>
           </div>
           <!-- Card footer -->
           <div class="card-footer">
             <div class="row">
               <div class="col text-right">
                 <router-link :to="{ name: `${routePrefix}.index` }"  class="btn btn-neutral btn-sm">
                   <i class="fa fa-arrow-left"></i>
                 </router-link>
                 <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                   <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                   <i class="fa fa-save"></i>
                 </button>
               </div>
             </div>
           </div>
         </form>
       </div>
     </div>
   </div>
 </template>
 <script>
   import {REPORTS_CREATE as CRUD_ACTION_CREATE} from "actions/suppliers_portal/reports";

   const REPORTS_CODE="supplier_reports";
   const CRUD_CODE=`suppliers_portal.${REPORTS_CODE}`;

   export default {
     name: "SupplierPortalReportsCreate",
     data(){
       return {
         routePrefix: CRUD_CODE,
         from_date: '',
         to_date: '',
         serverError: '',
         fieldErrors: {},
         loading: false,
       }
     },
     methods: {
       prepareRequestData(){
         return {
           toDate: this.sanitizeField('to_date'),
           fromDate: this.sanitizeField('from_date'),
         }
       },
       create() {
         this.loading = true;
         this.$store.dispatch(CRUD_ACTION_CREATE, {
           ...this.prepareRequestData()
         })
         .then(() => {
           swal({
             icon: 'success',
             text: this.$i18n.t("messages.create_successfully"),
             buttons:false,
             timer: 5000
           });
           this.$router.push({ name: `${this.routePrefix}.index` })
         })
         .catch(error => {
           this.loading = false;
           this.fieldErrors={};
           this.serverError='';

           if(error.response){
             let responseData=error.response.data;

             if(responseData.errors){
               this.fieldErrors=responseData.errors;
             }else{
               this.serverError = 'Invalid Request.'
             }
           }else{
             this.serverError = this.$i18n.t("messages.no_internet_connection");
           }

           if(this.fieldErrors.error){
             this.serverError = this.fieldErrors.error;
           }
         })
       }
     }
   };
 </script>
