import Index from "@/views/captains/index";
import Create from "@/views/captains/create";
import Show from "@/views/captains/show";
import Edit from "@/views/captains/edit";
import WalletTransactionsIndex from "@/views/captains/wallet/transactions/index";
import WalletShow from "@/views/captains/wallet/show";
import IbanShow from "@/views/captains/ibans/show";
import IbanEdit from "@/views/captains/ibans/edit";
import FintechShow from "@/views/captains/fintechs/show";
import FintechEdit from "@/views/captains/fintechs/edit";
import DevicesIndex from "@/views/captains/devices/index";
import DevicesShow from "@/views/captains/devices/show";


import { ifAuthenticated, ifNotAuthenticated, commonBeforeEnter } from "../helper"

const CODE='captains';
const PERMISSION_PREFIX='captains';
const WALLET_CODE='wallet';
const TRANSACTIONS_CODE='transactions';
const WALLET_PERMISSION_PREFIX='wallets';
const IBANS_CODE='ibans';
const IBANS_PERMISSION_PREFIX='banks';
const FINTECHS_CODE='fintechs';
const FINTECHS_PERMISSION_PREFIX='fintechs';
const DEVICES_CODE='devices';
const DEVICES_PERMISSION_PREFIX='captains_devices';

const routes = [
  {
    path: `${CODE}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/create`,
    name:`${CODE}.create`,
    component: Create,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id`,
    name:`${CODE}.show`,
    component: Show,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `crud.show`,
          },
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/edit`,
    name:`${CODE}.edit`,
    component: Edit,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `crud.edit`,
          },
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${WALLET_CODE}`,
    name:`${CODE}.${WALLET_CODE}.show`,
    component: WalletShow,
    meta: {
      'can': [`${WALLET_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${WALLET_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${WALLET_CODE}.title`,
          },
          {
            'title': `crud.show`,
          },
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${WALLET_CODE}/:wallet_id/${TRANSACTIONS_CODE}`,
    name:`${CODE}.${WALLET_CODE}.${TRANSACTIONS_CODE}.index`,
    component: WalletTransactionsIndex,
    meta: {
      'can': [`${WALLET_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${WALLET_CODE}.${TRANSACTIONS_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${WALLET_CODE}.title`,
          },
          {
            'title': ':wallet_id',
          },
          {
            'title': `${CODE}.${WALLET_CODE}.${TRANSACTIONS_CODE}.title`,
          },
          {
            'title': `crud.list`,
          },
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${IBANS_CODE}`,
    name:`${CODE}.${IBANS_CODE}.show`,
    component: IbanShow,
    meta: {
      'can': [`${IBANS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${IBANS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${IBANS_CODE}.title`,
          },
          {
            'title': `crud.show`,
          },
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${IBANS_CODE}/:iban_id/edit`,
    name:`${CODE}.${IBANS_CODE}.edit`,
    component: IbanEdit,
    meta: {
      'can': [`${IBANS_PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${IBANS_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${IBANS_CODE}.title`,
          },
          {
            'title': ':iban_id',
          },
          {
            'title': `crud.edit`,
          },
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${FINTECHS_CODE}`,
    name:`${CODE}.${FINTECHS_CODE}.show`,
    component: FintechShow,
    meta: {
      'can': [`${FINTECHS_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${FINTECHS_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            title: `${CODE}.${FINTECHS_CODE}.title`,
          },
          {
            'title': `crud.show`,
          },
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/fintechs/:fintech_id/edit`,
    name:`${CODE}.${FINTECHS_CODE}.edit`,
    component: FintechEdit,
    meta: {
      'can': [`${FINTECHS_PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${FINTECHS_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${FINTECHS_CODE}.title`,
          },
          {
            'title': ':fintech_id',
          },
          {
            'title': `crud.show`,
          },
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${DEVICES_CODE}`,
    name:`${CODE}.${DEVICES_CODE}.index`,
    component: DevicesIndex,
    meta: {
      'can': [`${DEVICES_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${DEVICES_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${DEVICES_CODE}.title`,
          },
          {
            'title': `crud.list`,
          },
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${DEVICES_CODE}/:device_id`,
    name:`${CODE}.${DEVICES_CODE}.show`,
    component: DevicesShow,
    meta: {
      'can': [`${DEVICES_PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${CODE}.${DEVICES_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CODE}.${DEVICES_CODE}.title`,
          },
          {
            'title': ':device_id',
          },
          {
            'title': `crud.show`,
          },
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
