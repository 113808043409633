<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <input-text v-model="en_name" :options="{ id: 'en_name', label: 'en_name', error: fieldErrors.name }"/>
              <input-text v-model="ar_name" :options="{ id: 'ar_name', label: 'ar_name', error: fieldErrors.nameAr }"/>
              <input-text v-model="short_name" :options="{ id: 'short_name', transPrefix: `${transPrefix}.fields`, label: 'short_name', error: fieldErrors.shortName }"/>
              <input-text v-model="code_2" :options="{ id: 'code_2', transPrefix: `${transPrefix}.fields`, label: 'code_2', error: fieldErrors.code2 }"/>
              <input-text v-model="code_3" :options="{ id: 'code_3', transPrefix: `${transPrefix}.fields`, label: 'code_3', error: fieldErrors.code3 }"/>
              <input-text v-model="mobile_country_code" :options="{ id: 'mobile_country_code', transPrefix: `${transPrefix}.fields`, label: 'mobile_country_code', error: fieldErrors.mobileCountryCode }"/>
              <input-number v-model="mobile_number_length" :options="{ id: 'mobile_number_length', step: 1, transPrefix: `${transPrefix}.fields`, label: 'mobile_number_length', error: fieldErrors.mobileNumberLength }"/>
              <input-number v-model="tax_rate" :options="{ id: 'tax_rate', transPrefix: `${transPrefix}.fields`, label: 'tax_rate', error: fieldErrors.taxRate }"/>
              <input-text v-model="currency_en_name" :options="{ id: 'currency_en_name', transPrefix: `${transPrefix}.fields`, label: 'currency.en_name', error: fieldErrors['currency.name'] }"/>
              <input-text v-model="currency_ar_name" :options="{ id: 'currency_ar_name', transPrefix: `${transPrefix}.fields`, label: 'currency.ar_name', error: fieldErrors['currency.nameAr'] }"/>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12">
                <maps-general v-model="points" :points="oldPoints" :mode="map_mode"/>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { COUNTRIES_SHOW as CRUD_ACTION_SHOW,COUNTRIES_UPDATE as CRUD_ACTION_UPDATE } from 'actions/countries';
  const CRUD_CODE="countries";
  export default {
    name: "CountryEdit",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        en_name: '',
        ar_name: '',
        short_name: '',
        code_2: '',
        code_3: '',
        mobile_country_code: '',
        mobile_number_length: '',
        tax_rate: '',
        currency_en_name:"",
        currency_ar_name:"",
        points:[],
        oldPoints:[],
        map_mode:'edit',
        serverError: '',
        fieldErrors: {},
        responseStatus:'',
        formMode: 'edit',
        loading: false,
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.en_name=response.data.name;
          this.ar_name=response.data.nameAr;
          this.short_name=response.data.shortName;
          this.code_2=response.data.code2
          this.code_3=response.data.code3;
          this.mobile_country_code=response.data.mobileCountryCode;
          this.mobile_number_length=response.data.mobileNumberLength;
          this.tax_rate=response.data.taxRate;
          this.currency_en_name=response.data.currency?response.data.currency.name:'';
          this.currency_ar_name=response.data.currency?response.data.currency.nameAr:'';
          this.oldPoints=this.points=response.data.geom?response.data.geom.coordinates[0][0]:[];
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      prepareGeomRequestData(){
        let geom={};
        if(this.points.length ){
          geom.geom= {
            type: "MultiPolygon",
            coordinates:[[this.points]],
          };
        }

        return geom;
      },
      prepareRequestData(){
        return {
          name: this.sanitizeField("en_name"),
          nameAr: this.sanitizeField("ar_name"),
          shortName: this.sanitizeField("short_name"),
          code2: this.sanitizeField("code_2"),
          code3: this.sanitizeField("code_3"),
          mobileCountryCode: this.sanitizeField("mobile_country_code"),
          mobileNumberLength: this.sanitizeField("mobile_number_length"),
          taxRate: this.sanitizeField("tax_rate"),
          currency: {
            name: this.sanitizeField("currency_en_name"),
            nameAr: this.sanitizeField("currency_ar_name")
          },
          ...this.prepareGeomRequestData()
        }
      },
      update() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE, {
          id: this.id,
          data: {
            ...this.prepareRequestData()
          },
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    },
  };
</script>
