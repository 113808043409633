<template>
  <div aria-labelledby="customers-tab" class="tab-pane fade" id="customer" role="tabpanel" v-if="fields.backend_id">
    <div class="row">
      <input-text v-model="fields.backend_id" :options="{ id: 'backend_id', transPrefix: `${transPrefix}.fields`, label: 'customer_id', disabled: true }"/>
      <input-text v-model="fields.name" :options="{ id: 'name', label: 'name', disabled: true }"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: "OrderTabsCustomer",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      fields: {
        type: Object,
        default: () => { }
      },
    },
  };
</script>
