import Index from "@/views/suppliers_portal/captain_working_summaries/index";
import Show from "@/views/suppliers_portal/captain_working_summaries/show";
import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../../helper"

const URL_PREFIX='suppliers_portal/captain_working_summaries';
const CODE='suppliers_portal.captain_working_summaries';
const TRANS_CODE='captain_working_summaries';
const PERMISSION_PREFIX='supplier_captain_working_summaries';
const routes = [
  {
    path: `${URL_PREFIX}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${TRANS_CODE}.list_title`,
      'breadDetails': {
        'title': `${TRANS_CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${URL_PREFIX}/:id`,
    name:`${CODE}.show`,
    component: Show,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${TRANS_CODE}.show_title`,
      'breadDetails': {
        'title': `${TRANS_CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `:id`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
