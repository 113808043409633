<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <input-text v-model="owner_id" :options="{ id: 'owner_id', label: 'owner_id', disabled: true }"/>
              <select-ticket-owner-types v-model="owner_type" :options="{ id: 'ticket_owner_type', label: 'owner_type', disabled: true }"/>
              <input-text v-model="related_id" :options="{ id: 'related_id', label: 'related_id', disabled: true }"/>
              <select-ticket-related-types v-model="related_type" :options="{ id: 'ticket_related_type', label: 'related_type', disabled: true }"/>
              <select-ticket-reference-types v-model="reference_type" :options="{ id: 'ticket_reference_type', label: 'reference_type', disabled: true }"/>
              <input-text v-model="created_at" :options="{ id: 'created_at', label: 'created_at', disabled: true }"/>
              <input-textarea v-model="content" :options="{ id: 'content', label: 'content', disabled: true, containerClass: 'col-12' }"/>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12">
                  <label>{{ $t(`fields.attachments`)}}</label>
              </div>
              <template v-for="(attachment,index) in attachments">
                <files-single :value="{ thumbnail: attachment.file.url, original_name: attachment.file.originalFileName }" :options="{ id: `attachment_${index}`, transPrefix: '', disabled: true, containerClass: 'col-12 col-md-3' }"/>
              </template>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { TICKETS_SHOW as CRUD_ACTION_SHOW } from 'actions/tickets';
  const CRUD_CODE="tickets";
  const CRUD_PERMISSION_CODE=`${CRUD_CODE}`;

  export default {
    name: "TicketShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: `${CRUD_PERMISSION_CODE}`,
        id:this.$router.currentRoute.params.id,
        owner_id: '',
        owner_type: '',
        related_id: '',
        related_type: '',
        reference_type: '',
        content: '',
        created_at: '',
        attachments: [],
        serverError: '',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.owner_id=response.data.ownerId;
          this.owner_type=response.data.ownerType;
          this.related_id=response.data.relatedToId;
          this.related_type=response.data.relatedToType;
          this.reference_type=response.data.referenceType;
          this.content=response.data.content;
          this.attachments=response.data.attachments;
          this.created_at = response.data.createdAt?this.$moment(response.data.createdAt).format("Y-MM-DD hh:mm:ss A") : "";
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
