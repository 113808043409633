export const CAPTAINS_LIST = "CAPTAINS_LIST";
export const CAPTAINS_CREATE = "CAPTAINS_CREATE";
export const CAPTAINS_UPDATE = "CAPTAINS_UPDATE";
export const CAPTAINS_DELETE = "CAPTAINS_DELETE";
export const CAPTAINS_SHOW = "CAPTAINS_SHOW";
export const CAPTAINS_REQUEST_RESET_PASSWORD = "CAPTAINS_REQUEST_RESET_PASSWORD";
export const CAPTAINS_UPDATE_RIDER_SUSPENSION_STATUS = "CAPTAINS_UPDATE_RIDER_SUSPENSION_STATUS";
export const CAPTAINS_RESTORE = "CAPTAINS_RESTORE";
export const CAPTAINS_WALLET_SHOW = "CAPTAINS_WALLET_SHOW";
export const CAPTAINS_WALLET_TRANSACTIONS_LIST = "CAPTAINS_WALLET_TRANSACTIONS_LIST";
export const CAPTAINS_WALLET_TRANSACTIONS_ADJUSTMENT = "CAPTAINS_WALLET_TRANSACTIONS_ADJUSTMENT";
export const CAPTAINS_ADD_CAPABILITIES = "CAPTAINS_ADD_CAPABILITIES";
export const CAPTAINS_REMOVE_CAPABILITIES = "CAPTAINS_REMOVE_CAPABILITIES";
export const CAPTAINS_WALLET_TRANSACTIONS_TYPE_LIST = "CAPTAINS_WALLET_TRANSACTIONS_TYPE_LIST";
export const CAPTAINS_DEVICES_LIST = "CAPTAINS_DEVICES_LIST";
export const CAPTAINS_DEVICES_SHOW = "CAPTAINS_DEVICES_SHOW";
export const CAPTAINS_ID_TYPE_LIST = "CAPTAINS_ID_TYPE_LIST";
export const CAPTAINS_STATUS_LIST = "CAPTAINS_STATUS_LIST";
export const CAPTAINS_WORKING_STATUS_LIST = "CAPTAINS_WORKING_STATUS_LIST";
export const CAPTAINS_FINTECH_TYPE_LIST = "CAPTAINS_FINTECH_TYPE_LIST";

