import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFoundRoutes from "./modules/page_not_found";
import PageUnauthorizedRoutes from "./modules/page_unauthorized";
import Root from "./Root"
import authRoutes from "./modules/auth";
import dashboardRoutes from "./modules/dashboard";
import monitoringRoutes from "./modules/monitoring";
import analysisRoutes from "./modules/analysis";
import trackingRoutes from "./modules/tracking";
import countriesRoutes from "./modules/countries";
import citiesRoutes from "./modules/cities";
import zonesRoutes from "./modules/zones";
import usersRoutes from "./modules/users";
import banksRoutes from "./modules/banks";
import platformsRoutes from "./modules/platforms";
import captainsRoutes from "./modules/captains";
import appVersionsRoutes from "./modules/app_versions";
import ordersRoutes from "./modules/orders";
import allocationConfigsRoutes from "./modules/allocation_configs";
import allocationWindowsRoutes from "./modules/allocation_windows";
import withdrawConfigsRoutes from "./modules/withdraw_configs";
import withdrawRequestsRoutes from "./modules/withdraw_requests";
import rolesRoutes from "./modules/roles";
import envelopesRoutes from "./modules/envelopes";
import captainsRegistrationRequestsRoutes from "./modules/captains_registration_requests";
import captainWorkingSummriesRoutes from "./modules/captain_working_summaries";
import profileRoutes from "./modules/profile";
import platformsPortalOrdersRoutes from "./modules/platforms_portal/orders";
import platformsPortalUsersRoutes from "./modules/platforms_portal/users";
import platformsPortalAppsRoutes from "./modules/platforms_portal/apps";
import platformsPortalInvoicesRoutes from "./modules/platforms_portal/invoices";
import platformsPortalPlatformRoutes from "./modules/platforms_portal/platform";
import platformsPortalBranchesRoutes from "./modules/platforms_portal/branches";
import suppliersRoutes from "./modules/suppliers";
import suppliersPortalTrackingRoutes from "./modules/suppliers_portal/tracking";
import suppliersPortalOrdersRoutes from "./modules/suppliers_portal/orders";
import suppliersPortalCaptainsRoutes from "./modules/suppliers_portal/captains";
import suppliersPortalCaptainWorkingSummariesRoutes from "./modules/suppliers_portal/captain_working_summaries";
import suppliersPortalSupplierRoutes from "./modules/suppliers_portal/supplier";
import suppliersPortalReportsRoutes from "./modules/suppliers_portal/reports";
import captainBonusesRoutes from "./modules/captain_bonuses";
import walletRoutes from "./modules/wallets";
import walletTransactionRoutes from "./modules/wallet_transactions";
import referralProgramsRoutes from "./modules/referral_programs";
import ticketsRoutes from "./modules/tickets";
import auditLogsRoutes from "./modules/audit_logs";

import i18n, { loadLocaleMessagesAsync,defaultLocale,getLocaleFromPath } from "@/i18n";
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
  setDocumentTitle
} from "@/util/i18n/document"
import { supportedLocalesInclude } from "@/util/i18n/supported-locales"

Vue.use(VueRouter);
const { locale } = i18n;

const routes = [
    {
      path: "/",
      redirect: `${locale}/login`
    },
    {
      path: `/:locale`,
      component: Root,
      children: [
        ...authRoutes,
        ...dashboardRoutes,
        ...monitoringRoutes,
        ...analysisRoutes,
        ...trackingRoutes,
        ...usersRoutes,
        ...banksRoutes,
        ...countriesRoutes,
        ...citiesRoutes,
        ...zonesRoutes,
        ...appVersionsRoutes,
        ...platformsRoutes,
        ...captainsRoutes,
        ...ordersRoutes,
        ...allocationConfigsRoutes,
        ...allocationWindowsRoutes,
        ...withdrawConfigsRoutes,
        ...withdrawRequestsRoutes,
        ...rolesRoutes,
        ...envelopesRoutes,
        ...captainsRegistrationRequestsRoutes,
        ...captainWorkingSummriesRoutes,
        ...profileRoutes,
        ...suppliersRoutes,
        ...captainBonusesRoutes,
        ...walletRoutes,
        ...walletTransactionRoutes,
        ...referralProgramsRoutes,
        ...ticketsRoutes,
        ...platformsPortalOrdersRoutes,
        ...platformsPortalUsersRoutes,
        ...platformsPortalAppsRoutes,
        ...platformsPortalInvoicesRoutes,
        ...platformsPortalPlatformRoutes,
        ...platformsPortalBranchesRoutes,
        ...suppliersPortalTrackingRoutes,
        ...suppliersPortalCaptainsRoutes,
        ...suppliersPortalCaptainWorkingSummariesRoutes,
        ...suppliersPortalSupplierRoutes,
        ...suppliersPortalReportsRoutes,
        ...suppliersPortalOrdersRoutes,
        ...auditLogsRoutes,
        ...PageUnauthorizedRoutes,
        ...PageNotFoundRoutes,
      ],
  },
  ...PageNotFoundRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "active"
});

router.beforeEach((to, from, next) => {

  if(supportedLocalesInclude(getLocaleFromPath(to.fullPath))){

    const { locale }= to.params;
    loadLocaleMessagesAsync(locale).then(() => {
      setDocumentLang(locale)
      setDocumentDirectionPerLocale(locale)
      //TODO Add meta title
      //setDocumentTitle(i18n.t("app.title"))
    })
    next();
  }else{
    next(`${defaultLocale}${to.fullPath}`);
  }

})

export default router;
