<template>
    <searchContainer :searchHandler="search" :resetHandler="reset">
        <template v-slot:items>
            <div class="row">
                <input-number v-model="filters.captain_id" :options="{ id: 'captain_id', step: 1, transPrefix: `${transPrefix}.fields`, label: 'captain_id' }"/>
                <input-text v-model="filters.captain_name" :options="{ id: 'captain_name', label: 'name' }"/>
                <input-text v-model="filters.captain_email" :options="{ id: 'captain_email', label: 'email' }"/>
                <input-text v-model="filters.captain_mobile_number" :options="{ id: 'captain_mobile_number', label: 'mobile_number' }"/>
                <input-text v-model="filters.captain_id_number" :options="{ id: 'captain_id_number', transPrefix: `${transPrefix}.fields`, label: 'id_number' }"/>
            </div>
        </template>
    </searchContainer>
</template>
<script>

    import {mapGetters} from "vuex";
    import {CRUD_SEARCH_RESET} from "actions/crud";
    import searchContainer from "@/components/crud/card/CardSearch";

    const CRUD_CODE = "captains";
    export default {
        name: "SuppliersPortalCaptainSearch",
        components: {
            searchContainer
        },
        props: {
            listHandler: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                transPrefix: CRUD_CODE,
                filters: {
                    captain_id: this.getFilterByKey('captain_id'),
                    captain_name: this.getFilterByKey('captain_name'),
                    captain_email: this.getFilterByKey('captain_email'),
                    captain_mobile_number: this.getFilterByKey('captain_mobile_number'),
                    captain_id_number: this.getFilterByKey('captain_id_number'),
                },
                filtersMap: {
                    captain_id: 'captainId',
                    captain_name: 'name',
                    captain_email: 'email',
                    captain_mobile_number: 'mobileNumber',
                    captain_id_number: 'idNumber',
                }
            }
        },
        mounted() {
            this.setSearchParams(this.filters, this.filtersMap);
        },
        computed: {
            ...mapGetters([
                "getSearchFilters"
            ])
        },
        methods: {
            search() {
                this.resetPagination();
                this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                this.pushRoute(this.getSearchFilters);
                this.listHandler();
            },
            reset() {
                this.resetPagination();
                this.$store.dispatch(CRUD_SEARCH_RESET);
                this.filters = this.clearFilters(this.filters);
                this.pushRoute();
                this.listHandler();
            },
            resetPagination() {
                //Reset Pagination
                this.$emit('input', 0);
            },
            pushRoute(query = {}) {
                this.$router.push({name: this.$route.name, query}).catch(() => {});
            },
        }
    };
</script>
