<template>
  <abstract-dropdown-container v-model="selectedValue" :items="items" :options="{...defaultOptions }">
    <template v-slot:hint-top>
      <slot name="hint-top"></slot>
    </template>
    <template v-slot:hint-bottom>
      <slot name="hint-bottom"></slot>
    </template>
  </abstract-dropdown-container>
</template>

<script>
export default {
  name: "SelectChartCategories",
  props: {
    value: {}
  },
  data() {
    return {
      items: [
        { id: "city", name: "City" },
        { id: "status", name: "Status" },
        { id: "brand", name: "Brand" },
        { id: "supplier", name: "Supplier" },
        { id: "captain", name: "Captain" }
      ],
      selectedValue: this.value,
      defaultOptions: {
        id: "chart-categories",
        isLoading: false,
        type: 'dropdown',
        multiple: true,
        transPrefix: "monitoring",
        label: "categories",
        placeholder: 'placeholders.all',
        containerClass: 'col-12',
        customLabelHandler: this.customLabel,
        prepareResponseDataHandler: this.prepareResponseData
      }
    };
  },
  methods: {
    customLabel(value) {
      return `${value.name}`;
    },
    prepareResponseData(data){
      return data;
    }
  },
  watch: {
    value: function (newVal) {
      this.selectedValue = newVal;
    },
    selectedValue: function (newVal) {
      this.$emit('input', newVal);
    }
  }
}
</script>

<style scoped>

</style>