<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <router-link v-if="!serverError" :to="{ name: `${routePrefix}.change_password` }"  class="btn btn-primary btn-sm">
            <span class="d-none d-lg-inline mr-2">{{ $t(`labels.change_password`)}}</span>
            <span><i class="fa fa-key"></i></span>
          </router-link>
        </card-header>
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <files-image v-model="image" :options="{ id: 'image', disabled: true }"/>
            </div>
            <div class="row" v-if="!serverError">
              <input-text v-model="email" :options="{ id: 'email', label: 'email', disabled: true }"/>
              <input-text v-model="name" :options="{ id: 'name', label: 'name', disabled: true }"/>
              <input-text v-model="mobile_number" :options="{ id: 'mobile_number', label: 'mobile_number', disabled: true }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { PROFILE_ME as CRUD_ACTION_SHOW } from 'actions/profile';
  const CRUD_CODE="profile";
  export default {
    name: "ProfileMe",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        role: '',
        email: '',
        mobile_number: '',
        name: '',
        image:{
          file:'',
          thumbnail:'',
        },
        serverError: '',
        responseStatus:'',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW).then(response=>{
          this.role=response.data.user.role;
          this.email=response.data.user.email;
          this.name=response.data.user.name;
          this.mobile_number=response.data.user.userMobile?response.data.user.userMobile.mobileNumber:'';
          this.image.thumbnail=response.data.profilePicture?response.data.profilePicture.url:'';
        }).catch(error => {
          this.loading = false
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
