<template>
  <div>
    <nav class="navbar navbar-vertical pt-7 navbar-expand-xs navbar-light pb-0 component-sidenav" id="sidenav-assignment-candidates-tracking">
      <div class="scrollbar-inner bg-lighter">
        <div class="d-flex align-items-end bg-lighter position-fixed top-0 left-0 w-100">
          <div class="pt-3">
            <div class="row text-center mx-0 w-100">
              <div class="col-12">
                <h2>{{ $t(`tracking.assign`) }}</h2>
              </div>
            </div>
            <div class="pt-2 mx-0 row flex-row-reverse w-100">
              <div class="col-4 p-0 text-right">
                <a href="#" class="text-success p-2" @click.prevent="assign()">
                  <i class="fa fa-check"></i>
                </a>
                <a href="#" class="text-danger p-2" @click.prevent="closeAssignmentPanel()">
                  <i class="fa fa-times"></i>
                </a>
              </div>
              <div class="col-8">
                <div class="form-group position-relative mr--4 mt--1 mb-2" @keyup.enter.prevent="assign()">
                  <input-text v-model="selectedCandidateId" :options="{ id: 'selected_candidate_id', fullLayout: false , inputClass: 'form-control form-control-sm border-primary border-2', placeholder: 'tracking.captain_id_placeholder' }"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-inner bg-lighter">
          <!-- Collapse -->
          <div class="collapse navbar-collapse">
            <div v-if="loading" class="text-center position-fixed overflow-hidden bottom-0 top-0 mt-7 w-100 left-0 right-0 bg-lighter opacity-7 swal-overlay--show-modal">
              <span class="spinner-border loader text-dark m-auto position-absolute top-0 right-0 left-0 bottom-0 force-right-0"></span>
            </div>
            <!-- Nav items -->
            <ul class="navbar-nav">
              <li class="nav-item nav-link assignment-candidate-item mx-0 px-0"
                  :id="'captain_' + candidate.id"
                  v-for="candidate in assignmentCandidates"
                  :key="candidate.id"
                  @mouseover="setHighlightedCandidate(candidate, true)"
                  @mouseleave="setHighlightedCandidate(candidate, false)"
                  @click.prevent="selectCandidate(candidate)">
                <assignment-candidate v-bind="candidate" />
              </li>
              <li class="nav-item nav-link  mx-0 px-0 row" v-if="assignmentCandidates.length===0">
                <div class="col-12 text-center">
                  <h2 class="order-id"> {{ $t('messages.no_result_found')}} </h2>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import $ from "jquery";
  import {
    TRACKING_ASSIGNMENT_CANDIDATES_LIST,
    TRACKING_SET_ACTIVE_ORDER, TRACKING_SET_HIGHLIGHTED_CANDIDATE
  } from "actions/sockets/tracking";
  import {
    ORDERS_MANUAL_ASSIGN as CRUD_ACTION_MANUAL_ASSIGN,
    ORDERS_REASSIGN as CRUD_ACTION_REASSIGN
  } from "actions/orders";
  import assignmentCandidate from "./assignmentCandidate";

  export default {
    name: "TrackingSuggestedCaptains",
    props: {},
    components: {
      assignmentCandidate
    },
    data() {
      return {
        captainTransPrefix: "captains",
        loading: false,
        selectedCandidateId: null,
        assignmentCandidates: []
      }
    },
    computed:{
      ...mapGetters(["getActiveOrder", "getCaptains"])
    },
    watch: {
      getActiveOrder: function(newVal, oldVal) {
        if (['MANUALLY_ASSIGN', 'RE_ASSIGN'].includes(newVal.action)) {
          this.openSideMenu(newVal.action);
          this.fetchAssignmentCandidates(newVal);
        } else if (['MANUALLY_ASSIGN', 'RE_ASSIGN'].includes(oldVal.action)) {
          this.selectedCandidateId = null;
          this.assignmentCandidates = [];
          this.$store.commit(TRACKING_SET_ACTIVE_ORDER,{});
          this.hideSideMenu();
        }
      }
    },
    mounted() {
      $('.scrollbar-rail,.scrollbar-macosx,.scrollbar-light,.scrollbar-inner,.scrollbar-outer,.scrollbar-dynamic,.scrollbar-chrome').scrollbar().scrollLock();
      $('#sidenav-assignment-candidates-tracking').addClass(['closed', 'd-none', 'right-0']);
    },
    methods: {
      openSideMenu(action) {
        const side = action === 'RE_ASSIGN' ? 'left-0' : 'right-0';
        $('#sidenav-assignment-candidates-tracking').removeClass(['d-none', 'closed', 'left-0', 'right-0']).addClass([side, 'opened']);
      },
      hideSideMenu() {
        $('#sidenav-assignment-candidates-tracking').removeClass('opened').addClass('closed');
        $('#selected_candidate_id').blur();
      },
      fetchAssignmentCandidates(order) {
        this.loading = true;
        this.$store.dispatch(TRACKING_ASSIGNMENT_CANDIDATES_LIST,{ id: order.id, type: order.action })
            .then(response => this.assignmentCandidates = response.data)
            .finally(() => this.loading = false)
      },
      closeAssignmentPanel() {
        this.$store.commit(TRACKING_SET_ACTIVE_ORDER, {});
      },
      selectCandidate(candidate) {
        this.selectedCandidateId = candidate.id;
        $('#selected_candidate_id').focus();
      },
      setHighlightedCandidate(candidate, isHighlighted) {
        this.$store.commit(TRACKING_SET_HIGHLIGHTED_CANDIDATE, { ...this.getCaptains[candidate.id], isHighlighted: isHighlighted });
      },
      assign() {
        const captainId = parseInt(this.selectedCandidateId, 0);
        const order = this.getActiveOrder;
        let action = null;
        let title = null;
        let text = null;
        if (order.action === 'MANUALLY_ASSIGN') {
          action = CRUD_ACTION_MANUAL_ASSIGN;
          title = this.$i18n.t("tracking.manually_assign_title");
          text = this.$i18n.t("tracking.manually_assign_text", [order.backendId, this.selectedCandidateId]);
        } else if (order.action === 'RE_ASSIGN') {
          title = this.$i18n.t("tracking.re_assign_title");
          text = this.$i18n.t("tracking.re_assign_text", [order.backendId, order.captainId, this.selectedCandidateId]);
          action = CRUD_ACTION_REASSIGN;
        }

        if (action && captainId && order.id) {
          this.showConfirmationPopup(title, text)
              .then((confirmed) => {
                if (confirmed) {
                  this.closeAssignmentPanel();
                  return this.$store.dispatch(action, { id: order.id, captain_id: captainId })
                      .then(() => this.showSuccessPopup())
                      .catch((error) => this.showErrorPopup(error))
                }
              });
        }
      },
      showConfirmationPopup(title, text) {
        return swal({
          title: title,
          text: text,
          buttons: [this.$i18n.t("labels.cancel"), this.$i18n.t("labels.assign")]
        });
      },
      showSuccessPopup() {
        swal({
          icon: "success",
          text: this.$i18n.t("tracking.assign_success"),
          buttons: false,
          timer: 5000
        });
      },
      showErrorPopup(error) {
        const message = error.response?.data?.errors?.error || this.$i18n.t("tracking.assign_error");
        swal({
          icon: "error",
          text: message,
          buttons: false,
          timer: 5000
        });
      }
    }
  };
</script>

<style type="text/css"  scoped>
  .scrollbar-inner .scroll-element.scroll-y{
    background-color: #e5e5e594 !important;
    width: 8px !important;
    opacity: 1 !important;
  }

  .scrollbar-inner > .scroll-element .scroll-element_track, .scrollbar-inner > .scroll-element .scroll-bar{
    opacity: unset!important;
  }

  .loader.force-right-0{
    right:0!important;
  }
</style>
