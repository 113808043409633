<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <input-text v-model="name" :options="{ id: 'name', label: 'name', error: fieldErrors.name }"/>
              <select-referral-program-types v-model="type" :options="{ id: 'referral_program_types', label: 'type', error: fieldErrors.type }"/>
              <select-countries v-model="country_id" :options="{ id: 'country_id', label: 'country', error: fieldErrors.countryId }"/>
              <input-number v-model="revenue" :options="{ id: 'revenue', label: 'revenue', error: fieldErrors.revenue }"/>
              <input-number v-model="maximum_limit" :options="{ id: 'maximum_limit', step: 1, label: 'maximum_limit', error: fieldErrors.maximumLimit }"/>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { REFERRAL_PROGRAMS_SHOW as CRUD_ACTION_SHOW,REFERRAL_PROGRAMS_UPDATE as CRUD_ACTION_UPDATE } from 'actions/referral_programs';
  const CRUD_CODE="referral_programs";
  export default {
    name: "ReferralProgramEdit",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        country_id: '',
        name:"",
        revenue: '',
        maximum_limit:"",
        type:"",
        serverError: '',
        fieldErrors: {},
        responseStatus:'',
        formMode: 'edit',
        loading: false,
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.country_id=response.data.countryId;
          this.maximum_limit=response.data.maximumLimit;
          this.name=response.data.name;
          this.revenue=response.data.revenue;
          this.enabled=response.data.enabled;
          this.type=response.data.type;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      prepareRequestData(){
        return {
          maximumLimit: this.sanitizeField('maximum_limit'),
          countryId: this.sanitizeField('country_id'),
          name: this.sanitizeField('name'),
          revenue: this.sanitizeField('revenue'),
          type: this.sanitizeField('type'),
        }
      },
      update() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE, {
          id: this.id,
          data: {
            ...this.prepareRequestData()
          },
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    },
  };
</script>
