<template>
    <abstract-dropdown-container v-model="selectedValue" :items="items" :options="{...defaultOptions, ...options}">
        <template v-slot:hint-top>
            <slot name="hint-top"></slot>
        </template>
        <template v-slot:hint-bottom>
            <slot name="hint-bottom"></slot>
        </template>
    </abstract-dropdown-container>
</template>

<script>
    import {CITIES_LIST as CRUD_ACTION_LIST} from 'actions/cities';

    export default {
        name: "FieldsDropdownCities",
        props: {
            value: {},
            options: {
                type: Object,
                default: () => {}
            },
            country_id: {},
        },
        data() {
            return {
                currentPage: -1,
                items: [],
                perPage: 20,
                totalElements: 0,
                lastPage: 1,
                selectedValue: this.value,
                defaultOptions: {
                    id:                                     "city_id",
                    prepareResponseDataHandler:             this.prepareResponseData,
                    customLabelHandler:                     this.customLabel,
                    isLoading:                              false,
                    paginationHandler:                      this.pagination,
                    type:                                   'dropdown',
                    action:                                 CRUD_ACTION_LIST,
                    params:                                 {},
                    ...this.options
                }
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            },
            country_id: function (newVal, oldVal) {
                this.items = [];
                this.currentPage = -1;
                this.lastPage = 1;
                this.selectedValue = null;
                this.$emit('input', null);
                this.pagination(true);
            },
            options: {
                deep: true,
                handler: function (newVal, oldVal) {
                    this.defaultOptions = { ...this.defaultOptions, ...newVal};
                }
            },
        },
        mounted() {
            if (!this.isEmpty(this.value)) {
                this.pagination(true);
            }
        },
        methods: {
            getList() {
                if (this.country_id) {
                    this.defaultOptions.isLoading = true;
                    this.$store.dispatch(this.defaultOptions.action, {
                        pageId: this.getCurrentPage(),
                        withLoading: false,
                        countryId: this.country_id,
                        ...this.defaultOptions.params
                    }).then(response => {
                        if (response.data.perPage) {
                            this.items = [...this.items, ...response.data.data];
                            this.perPage = response.data.perPage;
                            this.totalElements = response.data.totalElements;
                            this.lastPage = response.data.pageCount - 1;
                        } else {
                            this.items = [...this.items, ...this.prepareResponseData(response.data)];
                            this.lastPage = this.lastPage - 1;
                        }
                        this.defaultOptions.isLoading = false;
                    });
                }
            },
            pagination(isVisible) {
                if (this.hasNextPage() && isVisible) {
                    this.currentPage += 1;
                    this.getList();
                }
            },
            getCurrentPage() {
                return this.currentPage;
            },
            getLastPage() {
                return this.lastPage;
            },
            hasNextPage() {
                return this.getCurrentPage() < this.getLastPage();
            },
            customLabel(value) {
                return this.$i18n.locale == 'ar' ? value.nameAr : value.name;
            },
            isEmpty(value) {
                return ((!Array.isArray(value) && !value) || (Array.isArray(value) && value.length === 0));
            },
            prepareResponseData(data){
                return data;
            }
        }
    }
</script>

<style scoped>

</style>
